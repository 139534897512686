import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';
// mui imports
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
	width: '100%',  // Ensure the DatePicker spans the full width
	'& .MuiFormControl-root': {
		width: '100%',
	},
	'& .MuiInputBase-root': {
		width: '100%',
	},
	'& input': {
		padding: 10,
	}
}));

interface FormInputDateProps {
	name: string,
	label: string,
	placeholder: string | undefined,
	disabled?: boolean | undefined
}

const FormInputDate: FC<FormInputDateProps> = ({ name, label, placeholder, disabled }) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue=''
			render={({
				field: { onChange, value },
				fieldState: { error },
			}: any) => (
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<StyledDatePicker
						label={label}
						disabled={disabled}
						views={['year', 'month', 'day']}
						onChange={onChange}
						value={moment(value)}
						format={'MMM, Do YYYY'}
					/>
				</LocalizationProvider>
			)}
		/>
	);
}

export default FormInputDate;
