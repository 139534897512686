import { useEffect, useMemo, useState } from "react";
import { Processing, PageState } from './Interfaces';
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";

const useProcessingReport = () => {
	const [data, setData] = useState<Processing>({
		page_state: {
			filters: {
				start: null,
				end: null,
				shift: null
			}
		},
		processing_report: {
			shifts: [],
			machines: [],
			lineplans: [],
			parts_produced: {},
			production_cost: {},
			sales_value: {}
		}
	});

	const { loading, sendMessage, isVisible, setPageState, isReady } = useWebsocket('/processing/report/', setData);

	let pageState: PageState = data.page_state;
	const processing_report = useMemo(() => data.processing_report, [data]);

	// Log and potentially re-set state when visibility changes
	useEffect(() => {
		if (!isVisible) {
			setPageState(pageState);
		}
	}, [isVisible]);

	return {
		loading, sendMessage, isVisible, setPageState,
		pageState, processing_report, isReady
	};
};

export default useProcessingReport;
