import React, { FunctionComponent, useMemo, useState } from 'react';
import { Box, Typography, useTheme, Grid } from '@mui/material/';
import useDepartmentProductivity from './useDepartmentProductivity';
import SemiCircleGauge from '../../charts/SemiCircleGauge';
import ProductivityDetailDialog from '../../dialogs/ProductivityDetailDialog'; // Adjust the import path as needed
import { useTimePeriod } from '../../TimePeriodContext';

const DepartmentProductivity: FunctionComponent = () => {
	const theme = useTheme();
	const { timePeriod } = useTimePeriod();
	const {
		overallMachineProductivity, machines,
		overallSecondaryProductivity, secondaryLines,
		overallFoamingProductivity, foamingLines,
		overallAssemblyProductivity, assemblyLines,
		productivityColor
	} = useDepartmentProductivity(timePeriod);

	const [open, setOpen] = useState(false);
	const [dialogType, setDialogType] = useState<string>('');
	const productivityDetails = useMemo(() => {
		if (dialogType === 'Machine') return machines;
		if (dialogType === 'Secondary') return secondaryLines;
		if (dialogType === 'Foaming') return foamingLines;
		return assemblyLines;
	}, [dialogType, timePeriod]);

	const handleOpen = (type: string) => {
		setDialogType(type);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box sx={{ flex: 1, pt: 3, border: 2, borderRadius: 3, borderColor: productivityColor, boxShadow: 1 }}> 
			<Typography variant='h4' sx={{ textAlign: 'center', mb: 3 }}>Department Productivity</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Box sx={{ height: 150 }}>
						<SemiCircleGauge
							height={225}
							value={overallMachineProductivity}
							label={'Machines'}
							onClick={() => handleOpen('Machine')}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box sx={{ height: 150 }}>
						<SemiCircleGauge
							height={225}
							value={overallSecondaryProductivity}
							label={'Secondary'}
							onClick={() => handleOpen('Secondary')}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box sx={{ height: 150 }}>
						<SemiCircleGauge
							height={225}
							value={overallFoamingProductivity}
							label={'Foaming'}
							onClick={() => handleOpen('Foaming')}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box sx={{ height: 150 }}>
						<SemiCircleGauge
							height={225}
							value={overallAssemblyProductivity}
							label={'Assembly'}
							onClick={() => handleOpen('Assembly')}
						/>
					</Box>
				</Grid>
			</Grid>
			<ProductivityDetailDialog type={dialogType} open={open} handleClose={handleClose} productivityDetails={productivityDetails} />
		</Box>
	);
};

export default DepartmentProductivity;
