import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// mui imports
import { useTheme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
// mui icons
// custom imports
import DropdownActions from '../globalComps/DropdownActions';
import {colors} from "../colors";
import BaseTooltip from "../globalComps/BaseTooltip";

const ITEM_NAME_INDEX: number = 0;

interface ActionsProps {
  actions: {icon: any, action: (id: number) => void, text: string}[];
  id: number;
  setExpandedId?: (id: number | undefined) => void;
  refreshObject?: (object: any, create: boolean) => void;
  name?: any;
  type?: any;
  object?: any | undefined
}
/**
 * Actions subcomponent
 * @param actions
 * @param id
 * @param setExpandedId
 * @param refreshObject
 * @param name
 * @param object
 */
const Actions: FC<ActionsProps> = ({actions, id, setExpandedId, refreshObject,
                                     name, object}) => {

  const doClick = (action: any) => {

    let arg: any
    if (object && action.actionType && action.actionType === 'object')
      arg = object
    else
      arg = id

    if (action.doRefresh && refreshObject)
      action.action(arg)
            .then((object: any) => refreshObject(object, false));
    else
      action.action(arg);
  }

  const listItem = (action: any) => {
    if (action.action !== 'location') {
      let subContent =
        <MenuItem onClick={() => doClick(action)} key={action.text} sx={{fontSize: '10px'}}>
          <ListItemIcon sx={{fontSize: 'inherit', minWidth: '32px'}}>{action.icon}</ListItemIcon>
          <ListItemText>{action.text}</ListItemText>
        </MenuItem>;

      let content: any;
      if (action.tooltip)
        content =
          <BaseTooltip text={action.tooltip}>
            {subContent}
          </BaseTooltip>
      else
        content = subContent;

      return [
        action.divideBefore ? <Divider /> : [],
        content,
        action.divideAfter && actions.length > 2 ? <Divider /> : []
      ];
    } else {

      let url: string;
      if (action.condition === 'has_child')
        url = `${action.location}/${name}/none/`;
      else if (action.condition === 'has_parent')
        url = `${action.location}/none/${name}/`;
      else
        url = `${action.location}/${name}/`;

      let subContent =
        <MenuItem component={Link} to={url} key={action.text} sx={{fontSize: '10px'}}>
          <ListItemIcon sx={{fontSize: 'inherit', minWidth: '32px'}}>{action.icon}</ListItemIcon>
          <ListItemText>{action.text}</ListItemText>
        </MenuItem>;

      let content: any;
      if (action.tooltip)
        content =
          <BaseTooltip text={action.tooltip}>
            {subContent}
          </BaseTooltip>
      else
        content = subContent;

      return [
        content,
        action.divideAfter && actions.length > 2 ? <Divider /> : []
      ];
    }
  }

  return (
    <TableCell>
      <Grid container justifyContent='end'>
        <DropdownActions setExpandedId={setExpandedId} id={id}>
          {actions.map((action: any) => {
            return [
              listItem(action)
            ];
          })}
        </DropdownActions>
      </Grid>
    </TableCell>
  );
}

interface BaseRowProps {
  color?: string;
  id: number;
  data: any[];
  tooltip: string;
  highlight: {background: string} | {},
  actions: {icon: any, action: (id: number) => void, text: string}[] | null;
  setExpandedId?: (id: number | undefined) => void;
  refreshObject?: (object: any, create: boolean) => void;
  children?: JSX.Element | JSX.Element[];
  object?: any | undefined
}
/**
 * Row base component
 * @param key
 * @param data: table row data to display
 * @param tooltip: tooltip text if any
 * @param actions: row actions i.e. delete, edit, etc...
 * @param object
 */
const BaseRow: FC<BaseRowProps> = ({id, color, data,
                                    tooltip, highlight, actions, setExpandedId,
                                    refreshObject, children, object}) => {

  const theme = useTheme();

  const tableCellEntry = (item: any) => {
    if (typeof item === 'object' && item.title)
      return item.title;
    else if (typeof item === 'object' && item.length > 1)
      return item.join(', ')
    else if (typeof item === 'object' && item.length === 0)
      return '-'
    else
      return item
  }

  const getRowColor = (color: string | undefined) => {
    if (theme.palette.mode === 'dark' && color)
      return colors.dark.table.row[color];
    else if (color)
      return colors.light.table.row[color];
  }

  // assign to empty object if it doesn't exist
  highlight = highlight ? highlight : {};
  let _color: any = {
    backgroundColor: getRowColor(color)
  }

  const content =
    <TableRow sx={Object.assign(highlight, _color)} className={theme.palette.mode === 'dark' ? 'dark scheduler-unplanned-item' : 'light scheduler-unplanned-item'}
              data-id={id}>
      {data.map((item: any, key: number) => (
        <TableCell align='center' key={key}>
          {tableCellEntry(item)}
        </TableCell>
      ))}
      {actions && actions.length > 0 ? <Actions actions={actions} id={id} object={object} setExpandedId={setExpandedId}
                                                refreshObject={refreshObject} name={data[ITEM_NAME_INDEX]} /> : <TableCell></TableCell>}
      {children}
    </TableRow>;

  if (tooltip && tooltip !== '')
    return <BaseTooltip text={tooltip}>{content}</BaseTooltip>
  else
    return content
}

export default BaseRow;
export { Actions };
