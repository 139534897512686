import {apiGet, apiPost} from "../../api";
import {api} from "../../globals";
import {AxiosError, AxiosResponse} from "axios";
import {doAlert} from "../PopupAlert/PopupAlert";

const SCHEDULING_NOTE_URL: string = `/communication/scheduling-arm-note/`;

// get
/**
 * getSchedulingArmLoadUnloadNotes - gets notes
 * @param armId
 * @param callBack
 */
const getSchedulingArmLoadUnloadNotes = (armId: number, callBack: (notes: any[], days: any[], weeks: any[], items: any[]) => void) => {
  apiGet(SCHEDULING_NOTE_URL, {armId: armId}, (resp) => {
    callBack(resp.data.notes, resp.data.days, resp.data.weeks, resp.data.items);
  })
}

const getLoadQueueItems = (armId: number, callBack: (items: any[]) => void) => {
  apiGet('/scheduling/arm-loading/', {armId: armId, loadQueue: true}, (resp) => {
    callBack(resp.data)
  })
}

// post
/**
 * updateSchedulingArmLoadUnloadNote - updates an individual note
 * @param noteId
 * @param data
 * @param callBack
 */
const updateSchedulingArmLoadUnloadNote = (noteId: number, data: any, callBack?: any) => {
  api.post(`${SCHEDULING_NOTE_URL}${noteId}/`, {updateNote: true, data: data})
    .then((resp: AxiosResponse) => {
      if (callBack) callBack(resp);
    })
    .catch((err: AxiosError) => {
      if (err.response) doAlert(err.response.data.message, 'error', true);
    })
}

const skipProduction = (machineId: number, callback: (data: any) => void) => {
  apiPost(`/processing/molding/${machineId}/`, {skipProduction: true}, (resp: any) => {
    callback(resp.data)
  })
}

export {
  getSchedulingArmLoadUnloadNotes,
  updateSchedulingArmLoadUnloadNote,
  getLoadQueueItems,
  skipProduction
}