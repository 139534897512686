import React, { FC, useEffect, useState } from 'react';
import { api } from '../../../../utils/globals';
import { AxiosError, AxiosResponse } from 'axios';
// mui icons
import ArrowCircleUp from '@mui/icons-material/ArrowCircleUp';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import Edit from "@mui/icons-material/Edit";
// custom imports
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert';
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion';
import ApiTable from '../../../../utils/table/ApiTable';
import {
  assignLoadAfter,
  assignToArm,
  loadToArm,
  unassignFromArm,
  updateMoldLoadQuantity
} from './MachineSchedulingApi';
import AssignToArm from "../../../../utils/modals/AssignToArm";
import AssignLoadAfterOrder from "../../../../utils/modals/AssignLoadAfterOrder";
import UpdateMoldLoadQuantity from "../../../../utils/modals/UpdateMoldLoadQuantityModal";

interface EnhancedLoadQueueProps {
  arm: any;
}
const EnhancedLoadQueue: FC<EnhancedLoadQueueProps> = ({arm}) => {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [suppressLoadDisplay, setSuppressLoadDisplay] = useState<boolean>(false);
  const [moveToOtherLoadQueueId, setMoveToOtherLoadQueueId] = useState<number | undefined>(undefined);
  const [loadAfterLoadQueueId, setLoadAfterLoadQueueId] = useState<number | undefined>(undefined);
  const [moldLoadQuantityId, setMoldLoadQuantityId] = useState<number | undefined>(undefined);

  // update table without showing load display
  const updateTable = () => {
    setSuppressLoadDisplay(true);
    setRefresh(!refresh);
  }

  useEffect(() => {}, [refresh])

  // refresh when load queue is expanded
  const onExpand = (evt: any, expand: boolean) => {if (expand) setRefresh(!refresh)}

  // actions
  const doLoadToMachine = (machine: string, orderId: number | undefined) => {
    setSuppressLoadDisplay(true);
    console.log('do load to machine :', machine, orderId)
    // loadToArm('loadQueue', arm.machine, orderId, () => {
    //   setRefresh(!refresh);
    // });
  }
  // moves item to other load queue
  const openMoveToOtherLoadQueue = (orderId: number | undefined) => setMoveToOtherLoadQueueId(orderId);
  const doMoveToOtherLoadQueue = (orderId: number | undefined, armId: number | undefined) => {
    assignToArm(orderId, armId, true, () => {updateTable()})
    closeMoveToOtherLoadQueue();
  }
  const closeMoveToOtherLoadQueue = () => {
    setMoveToOtherLoadQueueId(undefined);
  }
  // this is an extra queue step inside the load queue
  const openAssignLoadAfter = (orderId: number | undefined) => setLoadAfterLoadQueueId(orderId);
  const doAssignLoadAfter = (orderId: number | undefined, loadAfterId: number | undefined, loadAfterType: string | undefined) => {
    console.log(`do assign load after orderId=${orderId} loadAfter=${loadAfterId}, type=${loadAfterType}`)
    assignLoadAfter(orderId, loadAfterId, loadAfterType, () => closeAssignLoadAfter());
  }
  const closeAssignLoadAfter = () => {
    setLoadAfterLoadQueueId(undefined);
    updateTable();
  }
  // remove item from load queue
  const doRemoveLoadQueue = (orderId: number | undefined) => {
    setSuppressLoadDisplay(true);
    unassignFromArm(orderId, arm.id, () => setRefresh(!refresh));
  }
  // update mold load quantity
  const openUpdateMoldLoadQuantity = (orderId: number | undefined) => setMoldLoadQuantityId(orderId);
  const closeUpdateMoldLoadQuantity = () => setMoldLoadQuantityId(undefined);
  const doUpdateMoldLoadQuantity = (orderId: number, quantity: number) => {
    updateMoldLoadQuantity(orderId, quantity, () => {
      closeUpdateMoldLoadQuantity();
      updateTable();
    })
    console.log(`update loadqueue mold quantity id=${orderId} quantity=${quantity}`)
  }

  // toggle the ability for lower permission users to be able to load this item to the assigned arm
  const toggleUnderPrivilegeLoadable = (id: number) => {
    api.post(`/scheduling/enhanced-loadqueue/${id}/`, {toggleField: 'under_privilege_loadable'})
      .then((resp: AxiosResponse) => {
        console.log('toggle resp :', resp)
      })
      .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
  }

  return (
    <>
      <tr>
        <td colSpan={arm.loaded_count + 1}>
          <BaseAccordion title='Load Queue' onChange={onExpand} variant='subtitle2'>

            <ApiTable
              objectName={''}
              headers={['', 'Load After', 'Order', 'Item', 'Arm', 'Description', 'Balance', 'Ship Date', 'Mold Takt', 'Mold Volume', 'Mold Quantity', 'Mold In Stock', '']}
              rowFields={['load_after', 'number', 'item', 'under_privilege_loadable', 'description', 'balance', 'ship_date', 'takt_time', 'mold_volume', 'mold_load_quantity', 'mold_in_stock']}
              clickToToggleFields={[
                {field: 'under_privilege_loadable', action: toggleUnderPrivilegeLoadable, tooltip: 'allow this item to be loaded'}
              ]}
              rowActions={[
                {icon: <ArrowCircleUp />, action: (orderId: number | undefined) => doLoadToMachine(arm.machine, orderId), text: 'Load to Machine', noCondition: true},
                {icon: <ArrowUpward />, action: openAssignLoadAfter, text: 'Assign load after order', noCondition: true, tooltip: 'Choose a Machine Loaded or Load Queue Order on this Arm to load this Order on after.'},
                {icon: <ArrowRightAlt />, action: (orderId: number | undefined) => openMoveToOtherLoadQueue(orderId),
                  text: 'Move to other Load Queue', noCondition: true},
                {icon: <Edit />, action: (orderId: number | undefined) => openUpdateMoldLoadQuantity(orderId), tooltip: 'Update mold load quantity',
                  text: 'Update Mold Load Quantity', noCondition: true},
                {icon: <RemoveCircleOutline />, action: doRemoveLoadQueue, text: 'Remove from Load Queue', noCondition: true}
              ]}
              dataField={'objects'}
              url={`/scheduling/enhanced-loadqueue/?armId=${arm.id}`}
              orderable
              orderableBlacklist={['Load After']}
              sortable
              sortableUrl={{url: '/scheduling/enhanced-loadqueue/', id: arm.id}}
              refresh={refresh}
              suppressLoadDisplay={suppressLoadDisplay}
            />

          </BaseAccordion>
        </td>
      </tr>
      <AssignToArm orderItemId={moveToOtherLoadQueueId} onClose={closeMoveToOtherLoadQueue}
                   onAssign={doMoveToOtherLoadQueue} move />
      <AssignLoadAfterOrder orderId={loadAfterLoadQueueId} onClose={closeAssignLoadAfter} doAssign={doAssignLoadAfter} />
      <UpdateMoldLoadQuantity orderItemId={moldLoadQuantityId} onClose={closeUpdateMoldLoadQuantity}
                              doUpdate={doUpdateMoldLoadQuantity} />
    </>
  );
}

export default EnhancedLoadQueue;
