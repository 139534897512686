import React, {FC, useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import moment from 'moment'
// mui imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
// custom imports
import {DateFormat, FormSpacing} from '../../../../../utils/globals'
import FormInputDate from '../../../../../utils/form/FormInputDate'
import FormInputNumber from '../../../../../utils/form/FormInputNumber'
import FormInputSwitch from '../../../../../utils/form/FormInputSwitch'
import BaseTooltip from '../../../../../utils/globalComps/BaseTooltip'

interface FindCompatibleFilterFormProps {
  values: any
  machineName: string
  tempDiff: number
  timeDiff: number
  onFilter: (data: any) => void
}
const FindCompatibleFilterForm: FC<FindCompatibleFilterFormProps> = ({values, machineName, tempDiff,
                                                                       timeDiff, onFilter}) => {

  const methods: any = useForm()
  const { reset, handleSubmit } = methods

  const doReset = () => reset({...values, ship_date: moment(values.ship_date, DateFormat)})

  useEffect(() => {
    if (values) doReset()
  }, [values, tempDiff, timeDiff])

  return (
    <form onSubmit={handleSubmit(onFilter)}>
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          <BaseTooltip text='Filters out orders with a ship date earlier than this date'>
            <Grid item xs={1.7}>
              <FormInputDate name='ship_date' label='Ship Date' placeholder='Ship Date' />
            </Grid>
          </BaseTooltip>
          <BaseTooltip text='Filters out orders with a balance to mold lower than this balance'>
            <Grid item xs={1.7}>
              <FormInputNumber name='balance__gt' label='Lower Balance' />
            </Grid>
          </BaseTooltip>
          <BaseTooltip text='Filters out orders with a balance to mold greater than this balance'>
            <Grid item xs={1.7}>
              <FormInputNumber name='balance__lt' label='Upper Balance' />
            </Grid>
          </BaseTooltip>
          <BaseTooltip text={`Filters orders with an oven temperature within +/- ${values.temp_diff} of ${values.oven_temp}`}>
            <Grid item xs={1.7}>
              <FormInputNumber name='temp_diff' label='Oven Temp +/-' />
            </Grid>
          </BaseTooltip>
          <BaseTooltip text={`Filters orders with an oven time within +/- ${values.time_diff} of ${values.oven_time}`}>
            <Grid item xs={1.7}>
              <FormInputNumber name='time_diff' label='Oven Time +/-' />
            </Grid>
          </BaseTooltip>
          <BaseTooltip text={`Filters out orders that do not have ${machineName} as a primary machine`}>
            <Grid item xs={1.7}>
              <FormInputSwitch name='primary_machine' label='Primary Machine' />
            </Grid>
          </BaseTooltip>
          <Grid container item xs={1.8} spacing={2} display='flex' justifyContent='flex-end'>
            <Grid item><Button type='submit' fullWidth variant='contained'>Submit</Button></Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </form>
  )
}

export default FindCompatibleFilterForm
