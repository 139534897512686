import React, {FC, useState} from "react";
// mui imports
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
// custom imports
import ProgressBar from "../../../../utils/globalComps/ProductivityProgress/ProgressBar";
import {useEffectApi} from "../../../../utils/globals";
import {getProgressBarData} from "./StatisticsApi";
import BaseContent from "../../../../utils/globalComps/BaseContent";
import ProgressTickedBar from "../../../../utils/globalComps/ProductivityProgress/ProgressTickedBar";


interface BarProps {
  tickData: any
  shiftId: number
  shiftName: number
  progress: number[]
  logged: number[]
  expected: number[]
  logName: string
}
const Bar: FC<BarProps> = ({tickData, shiftId, shiftName, progress, logged, expected, logName}) => {
  return tickData && tickData[shiftId] ?
    (
      <ProgressTickedBar progress={progress[shiftId]} ticks={tickData[shiftId]}>
        <Typography variant='subtitle1'>{shiftName} Logged {logName} / Shift target {logName}: <strong>{logged[shiftId]} / {expected[shiftId]}</strong></Typography>
      </ProgressTickedBar>
    )
    :
    (
      <ProgressBar progress={progress[shiftId]}>
        <Typography variant='subtitle1'><strong>{shiftName} Shift</strong> Logged {logName} / Shift target {logName}: <strong>{logged[shiftId]} / {expected[shiftId]}</strong></Typography>
      </ProgressBar>
    )
}


interface ProgressBarsProps {
  machineId?: number | undefined
  lineplanId?: number | undefined
  shifts: any[]
  dates: string[]
  refresh?: boolean | undefined
}
const ProgressBars: FC<ProgressBarsProps> = ({machineId, lineplanId, shifts, dates,
                                               refresh}) => {

  const [noData, setNoData] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [loggedIns, setLoggedIns] = useState<any>(undefined)
  const [tickData, setTickData] = useState<any>(undefined)
  const [progress, setProgress] = useState<any>()

  // machine
  const [expectedArms, setExpectedArms] = useState<any>(undefined)
  const [loggedArms, setLoggedArms] = useState<any>(undefined)

  // lineplan

  useEffectApi(() => {

    const shiftIds: number[] = shifts.map((shift: any) => shift.id)

    // console.log('line plan id :', lineplanId)

    getProgressBarData({
      shifts: shiftIds, machineId: machineId, lineplanId: lineplanId, unitDateRange: dates,
      singleDay: dates[0] === dates[1]
    }, (data: any) => {
      if (data) {
        setLoggedIns(data.loggedIns)
        setExpectedArms(data.expected)
        setLoggedArms(data.logged)
        setTickData(data.ticks)
        setProgress(data.progress)
      } else
        setNoData(true)

      setLoading(false)
    })

  }, [machineId, shifts, dates, refresh])

  if (!noData)
    return (
      <BaseContent loading={loading} sx={{width: '100%'}}>
        <Grid container spacing={1}>
          {shifts.map((shift: any, key: number) => (
            <Grid item xs={12} key={key} sx={key >= 1 ? {mt: 2} : {}}>
              {machineId && expectedArms && loggedArms && progress && expectedArms[shift.id] && loggedArms[shift.id] && progress[shift.id] ?
                <Bar tickData={tickData} shiftId={shift.id} shiftName={shift.min_name} progress={progress} logged={loggedArms} expected={expectedArms} logName='arms' />
                :
                <></>
              }
              {lineplanId ?
                <Bar tickData={tickData} shiftId={shift.id} shiftName={shift.min_name} progress={progress} logged={loggedArms} expected={expectedArms} logName='takt' />
                :
                <></>
              }
              {loggedIns && loggedIns[shift.id] && loggedIns[shift.id].length > 0 ?
                <Typography variant='body1'>Logged in: {loggedIns[shift.id].map((loggedIn: any, key: number) => (
                    <strong key={key}>{loggedIn.name} x{loggedIn.count} {key < loggedIns[shift.id].length-1 ? '| ' : ''}</strong>
                  ))}
                </Typography>
                :
                <></>
              }
            </Grid>
          ))}
        </Grid>
      </BaseContent>
    )
  else
    return <></>
}

export default ProgressBars
