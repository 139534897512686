import React, { createContext, useContext } from 'react';
import { Item, ScrapCode, Machine, Arm, Lineplan } from '../hooks/Interfaces'; // Assuming these are your interfaces
import { Permissions } from '../../../../../websocket/hooks/types';

// Define the shape of your context data
interface OrdersContextProps {
	items: Item[];
	scrapCodes: ScrapCode[];
	machines: Machine[];
	arms: Arm[];
	lineplans: Lineplan[];
	permissions: Permissions
}

// Create the context with an empty default value
export const OrdersContext = createContext<OrdersContextProps | undefined>(undefined);

// Custom hook to use the OrdersContext
export const useOrdersContext = () => {
	const context = useContext(OrdersContext);
	if (!context) {
		throw new Error('useOrders must be used within an OrdersProvider');
	}
	return context;
};

// Example OrdersProvider component
export const OrdersProvider: React.FC<{ value: OrdersContextProps }> = ({ children, value }) => {
	return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
};
