import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';

interface FormInputPasswordProps {
  name: string,
  label: string
}
/**
 * FormInputPassword comp
 * @param name
 * @param label
 * @constructor
 */
const FormInputPassword: FC<FormInputPasswordProps> = ({name, label}) => {
  const { control } = useFormContext();

  return (
    <Controller name={name} control={control} render={({field: {onChange, value}, fieldState: {error}, formState, }) => (
      <TextField
        helperText={error ? error.message: null}
        size='small'
        error={!!error}
        onChange={onChange}
        value={value ? value : ''}
        fullWidth
        label={label}
        variant='outlined'
        type='password'
       />
    )} />
  );
}

export default FormInputPassword;
