import { FC, useMemo } from "react"
import { Table, Column } from '../../../../../websocket/tables/Table'
import { OverallStatistics } from "../hooks/Interfaces"

interface OverallMachineStatisticsProps {
	machineData: OverallStatistics | {}
}

const OverallMachineStatistics: FC<OverallMachineStatisticsProps> = ({machineData}) => {

	const columns: Column[] = [
		{ 
			header: 'Conforming', headerAlign: 'center',
			value: 'conforming', valueAlign: 'center'
		},
		{ 
			header: 'Non Conforming', headerAlign: 'center', 
			value: 'non_conforming', valueAlign: 'center' 
		},
		{ 
			header: 'Non Conforming %', headerAlign: 'center',
			value: 'non_conforming_percent', valueAlign: 'center' 
		},
		{ 
			header: 'Logged Arms',headerAlign: 'center', 
			value: 'logged_arms', valueAlign: 'center' 
		},
		{ 
			header: 'Skipped Arms',headerAlign: 'center', 
			value: 'skipped_arms', valueAlign: 'center' 
		},
		{ 
			header: 'Molds Loaded',headerAlign: 'center', 
			value: 'molds_loaded', valueAlign: 'center' 
		},
		{ 
			header: 'Molds Unloaded',headerAlign: 'center', 
			value: 'molds_unloaded', valueAlign: 'center' 
		},
	]

	const isEmpty = (obj: {} ) => obj == undefined || Object.keys(obj).length === 0;
	const rows = isEmpty(machineData) ? [] : [machineData]

	return (
		<Table
			columns={columns}
			rows={rows}
		/>
	)
}

export default OverallMachineStatistics