import { useEffect, useMemo, useState } from "react";
import { Statistics } from './Interfaces';
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";
import { format } from 'date-fns';

const useStatistics = () => {
	const [data, setData] = useState<Statistics>({ page_state: { date: null }, machine_statistics: {} });
	const { loading, sendMessage, isVisible, setPageState } = useWebsocket('/processing/production_statistics/', setData);

	let pageState = data.page_state;
	const statistics = useMemo(() => data.machine_statistics, [data]);

	useEffect(() => {
		if (!isVisible) {
			setPageState(pageState);
		}
	}, [isVisible]);

	const setDateRange = (start: Date, end: Date) => {
		sendMessage('set_date_range', { start: format(start, 'yyyy-MM-dd'), end: format(end, 'yyyy-MM-dd') });
	};

	return {
		loading, sendMessage,
		pageState, statistics,
		setDateRange
	};
};

export default useStatistics;
