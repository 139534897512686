import React, { FC } from 'react'
import Draggable from 'react-draggable'
// mui imports
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Breakpoint } from '@mui/system';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
// mui icon imports
import Close from '@mui/icons-material/Close';
import { button } from "../globals";
import Box from "@mui/material/Box";
import InfoText from "../globalComps/InfoText";
import Paper from "@mui/material/Paper";

const DraggableComponent = (props: any) => {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

interface ContentProps {
  title?: string;
  closeHandler: () => void;
  closeButton?: boolean;
  dividers?: boolean;
  actionInfoText?: string;
  actions?: any[];
  children: JSX.Element | JSX.Element[];
  contentSx?: any;
}
const Content: FC<ContentProps> = ({title, closeButton, closeHandler, dividers,
                                     contentSx, children,
                                     actionInfoText, actions}) => {
  return (
    <>
      {title ? <DialogTitle sx={{cursor: 'move'}} id='draggable-dialog-title'>{title}</DialogTitle> : <></>}
      {closeButton ?
        <Box position='absolute' top={7} right={7}>
          <IconButton onClick={closeHandler}>
            <Close />
          </IconButton>
        </Box>
        :
        <></>}
      <DialogContent dividers={!!dividers} sx={contentSx}>
        {children}
      </DialogContent>
      {actions && actions.length > 0 ?
        <DialogActions sx={{display: 'flex'}}>
          {actionInfoText ? <InfoText text={actionInfoText} /> : <></>}
          {actions.map((act: any, key: number) => button(act, key))}
        </DialogActions>
        :
        <></>}
    </>
  )
}

interface BaseModalProps {
  title?: string;
  open: boolean;
  transition?: any;
  closeHandler: () => void;
  closeButton?: boolean;
  dividers?: boolean;
  keepMounted?: boolean;
  actionInfoText?: string;
  actions?: any[];
  children: JSX.Element | JSX.Element[];
  maxWidth?: Breakpoint;
  fullScreen?: boolean | undefined
  scroll?: any;
  paperProps?: any;
  contentSx?: any;
  doSubmit?: (data: any) => void
  preventClose?: boolean;
}
const BaseModal: FC<BaseModalProps> = ({title, open, transition, closeHandler, closeButton,
                                         dividers, keepMounted, children,
                                         actionInfoText, actions, maxWidth, fullScreen, scroll,
                                         paperProps, contentSx, doSubmit, preventClose}) => {

  const handleClose = (event: object, reason: string) => {
    if (preventClose && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    closeHandler();
  };

  return (
      <Dialog open={open} maxWidth={maxWidth ? maxWidth : 'sm'} keepMounted={!!keepMounted} fullWidth onClose={handleClose}
              TransitionComponent={transition} PaperProps={paperProps ? paperProps : {}} scroll={scroll}
              PaperComponent={DraggableComponent} fullScreen={fullScreen}>
        <Content title={title} closeButton={closeButton} closeHandler={closeHandler} dividers={dividers}
                 contentSx={contentSx} actionInfoText={actionInfoText} actions={actions}>
          <>
            {doSubmit !== undefined ?
              <form onSubmit={doSubmit}>
                {children}
              </form>
              :
              <>{children}</>
            }
          </>
        </Content>
      </Dialog>
  );
}

export default BaseModal;
