import { FC, useMemo } from "react";
import { Table, Column } from '../../../../../websocket/tables/Table';
import { ProcessedOrder } from "../hooks/Interfaces";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface OrdersProcessedProps {
    orderData: ProcessedOrder[]
}

const OrdersProcessed: FC<OrdersProcessedProps> = ({ orderData }) => {
    const columns: Column[] = [
        {
            header: 'Order',
            value: 'order'
        },
        {
            header: 'Item',
            value: 'item'
        },
        {
            header: 'Ship Date',
            value: 'ship_date'
        },
        {
            header: 'Balance', headerAlign: 'center',
            value: 'balance', valueAlign: 'center'
        },
        {
            header: 'Conforming Parts', headerAlign: 'center',
            value: 'conforming_parts', valueAlign: 'center'
        },
        {
            header: 'Non Conforming Parts', headerAlign: 'center',
            value: 'non_conforming_parts', valueAlign: 'center'
        },
        {
            header: 'Skipped Count', headerAlign: 'center',
            value: 'skipped_count', valueAlign: 'center'
        },
    ];

    const rows = useMemo(() => orderData ?? [], [orderData]);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="orders-processed-content"
                id="orders-processed-header"
								sx={{marginY: 0, borderBottom: 1, borderColor: 'rgb(128, 128, 128, 0.2)'}}
            >
							<Typography variant='h6' marginY={0}>Processed Orders</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
                <Table columns={columns} rows={rows} sx={{borderTopLeftRadius: 0, borderTopRightRadius: 0}} />
            </AccordionDetails>
        </Accordion>
    );
}

export default OrdersProcessed;
