import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useTheme } from '@mui/material';

interface ScrapBarChartProps {
	data: { name: string; conforming: number; nonConforming: number }[];
	height: number;
}

const ScrapBarChart: React.FC<ScrapBarChartProps> = ({ data, height }) => {
	const theme = useTheme();

	const chartOptions: ApexOptions = {
		chart: {
			type: 'bar',
			stacked: true,
			animations: {
				enabled: true,
				easing: 'easeinout',
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 150
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350
				}
			}
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '45%',
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: data.map((item) => item.name),
			labels: {
				style: {
					colors: theme.palette.text.secondary,
				},
			},
			axisBorder: {
				color: theme.palette.divider,
			},
			axisTicks: {
				color: theme.palette.divider,
			},
		},
		yaxis: {
			title: {
				text: 'Total Parts',
				style: {
					color: theme.palette.text.primary,
				},
			},
			labels: {
				style: {
					colors: theme.palette.text.secondary,
				},
			},
		},
		fill: {
			opacity: 1,
			colors: [theme.palette.primary.main, theme.palette.error.main],
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return val.toString();
				},
			},
		},
		legend: {
			show: false
		},
		responsive: [{
			breakpoint: 480,
			options: {
				plotOptions: {
					bar: {
						columnWidth: '100%'
					}
				},
				xaxis: {
					categories: data.map((item) => item.name),
					labels: {
						show: false
					}
				},
				yaxis: {
					show: false
				}
			}
		}]
	};

	const series = [
		{
			name: 'Conforming Parts',
			data: data.map((item) => item.conforming),
		},
		{
			name: 'Non-Conforming Parts',
			data: data.map((item) => item.nonConforming),
		},
	];

	return (
		<ReactApexChart
			options={chartOptions}
			series={series}
			type="bar"
			height={height}
		/>
	);
};

export default ScrapBarChart;
