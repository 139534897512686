import React, { FC, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
// mui imports
// mui icons
import Add from '@mui/icons-material/Add'
import Print from '@mui/icons-material/Print'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
// custom imports
import { api, generateBomPdf, useEffectApi } from '../../../../utils/globals'
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import { apiGet } from '../../../../utils/api'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import ApiTable from '../../../../utils/table/ApiTable'
import BomItemForm from './BomItemForm'

const itemUrl: string = '/inventory/items/'
const url: string = '/inventory/bomitems/'
const filterOptions = [
  {text: 'All'},
  {text: 'Assembly', childTypes: ['Assembly', 'Moldable', 'Kit', 'Component']},
  {text: 'Moldable', childTypes: ['Powder', 'Component']},
  {text: 'Kit', childTypes: ['Component']},
  {text: 'No Bom', childTypes: []},
]

const BomItems: FC = () => {
  const params = useParams()

  const [bomId, setBomId] = useState<number | undefined>(undefined)
  const [bomFormOpen, setBomFormOpen] = useState<boolean>(false)
  const [childItems, setChildItems] = useState<any[]>([])
  const [filteredChildItems, setFilteredChildItems] = useState<any[]>([])
  const [expandedId, setExpandedId] = useState<number | undefined>(undefined)
  const [parentItem, setParentItem] = useState<string | undefined>(undefined)
  const [refreshParentTable, setRefreshParentTable] = useState<boolean | undefined>(undefined)
  const [refreshChildTable, setRefreshChildTable] = useState<boolean | undefined>(undefined)

  // refresh edited item helper
  const refreshObject = (updatedData: any, create: boolean) => {
    if (create)
      setRefreshChildTable(!refreshChildTable)
			setRefreshParentTable(!refreshParentTable)
  }

  const closeFormHandler = (submit: boolean, data: any, create: boolean) => {
    console.log('bom close form')
		console.log(data, create)
    refreshObject(data, create)
    setBomId(undefined)
    setBomFormOpen(false)
  }

  const expandedIdHandler = (id: number | undefined) => {
    console.log('expandedIdHandler', id)
    setExpandedId(id)
  }

  useEffectApi(() => {

    document.title = 'BOM Items | RotoEdgePro'

    apiGet(url, {childItems: true}, (resp: AxiosResponse) => {
      setChildItems(resp.data.childItems)
    })

  }, [])

  const doFilterChildItems = (type: any) => {

    let filterOption: any
    for (const fOp of filterOptions)
      if (fOp.text === type)
        filterOption = fOp

    setFilteredChildItems(childItems.filter((child: any) => filterOption.childTypes.includes(child.type)))
  }

  // parent row actions
  const newBom = () => {
    api.get(`${itemUrl}${expandedId}/`)
      .then((resp: AxiosResponse) => {
        // console.log('bom items resp :', resp.data.object[0])
        doFilterChildItems(resp.data.object[0].type_name)
        setParentItem(resp.data.object[0].name)
        setBomId(undefined)
        setBomFormOpen(true)
      })
      .catch((err: AxiosError) => {
        console.log('err :', err)
      })
  }
  const printBom = () => {
    apiGet(url, {parentId: expandedId}, (resp: any) => {
      generateBomPdf(resp.data['objects'])
    })
  }

  // child row actions
  const ignoreInMrp = (id: number, toggleField: string) => {
    return api.post(`${url}${id}/`, {toggleField: toggleField})
      .then((resp: AxiosResponse) => resp.data.object)
      .catch((err: AxiosError) => {
        if (err.response) doAlert(err.response.data.message, 'error', true)
      })
  }
  const ignoreInMrpToggle = (id: number) => ignoreInMrp(id, 'ignore_in_mrp')

  return (
    <>
      <PageTitle title='Bill of Materials Management' />
      <ApiTable
        objectName='BOM'
        headers={['', 'Name', 'Description', 'Type', 'Takt time', 'Material Count', '']}
        rowFields={['name', 'description', 'type_name', 'takt_time', 'child_bom_count']}
        dataField='objects'
        url={url}
				refresh={refreshParentTable}
        rowActions={[
          {text: 'New BOM Item', icon: <Add />, action: newBom, noCondition: true, permission: 'create'},
          {text: 'Print BOM', icon: <Print />, action: printBom, noCondition: true, divideAfter: true},
          // {text: 'View Item', icon: <VisibilityOutlined />, action: 'location', location: '/services/items', noCondition: true},
        ]}
        orderable
        filterable
        filters={filterOptions}
        searchable
        preQ={params.q}
        childSearchable
        preChildQ={params.childQ}
        searchPlaceholder='Search Parent Items...'
        childSearchPlaceholder='Search Child Items...'
        exportable
        importable
        expandable
        setExpandedId={expandedIdHandler}
        expandableParams={{
          tableName: '',
          objectName: 'BOM Item',
          titleVariant: 'h5',
          headers: ['Child Item', 'Child Description', 'Type', 'Quantity', 'Bin Location', 'Include in MRP', ''],
          rowFields: ['child_item', 'child_description', 'type_name', 'quantity', 'bin_location', 'ignore_in_mrp'],
          rowActions: [
            // {text: 'View Item', icon: <VisibilityOutlined />, action: 'location', location: '/services/items', noCondition: true},
          ],
          clickToToggleFields: [
            {field: 'ignore_in_mrp', action: ignoreInMrpToggle, tooltip: 'Include In MRP'}
          ],
          orderable: true,
          orderableBlacklist: ['Child Description', 'Bin Location', 'Include in MRP'],
          editable: true,
          deletable: true,
          dataField: 'objects',
          url: url,
          formComponent: BomItemForm,
          formParams: {childItems: childItems},
          setExpandedId: expandedIdHandler,
          refresh: refreshChildTable
        }}
        FormComponent={<BomItemForm open={bomFormOpen} id={bomId} closeForm={closeFormHandler}
                                    childItems={filteredChildItems} parentItem={parentItem} />}
        overrideFormParams={true}
      />
    </>
  )
}

export default BomItems
