import {apiGet, apiPost} from "../../../utils/api";

/**
 * Gets base page data for a lineplan page
 * @param id lineplan id
 * @param callback
 */
const getLineplanPageData = (id: number, callback: (data: any) => void) => {
  apiGet(`/processing/lineplan/${id}/`, {getData: true}, (resp: any) => {
    callback(resp.data)
  })
}

/**
 * Gets the process instructions pdf for a given item id
 * @param id item id
 * @param callback
 */
const getProcessInstructions = (id: number, callback: (data: any) => void) => {
  apiGet(`/inventory/items/${id}/`, {blob: true, processInstructions: true}, (resp: any) => {
    callback(resp.data)
  })
}

/**
 * gets order data to fill log form
 * @param lpid: lineplan id
 * @param id: order id
 * @param type: order stage (secondary/foam/assembly)
 * @param callback
 */
const getLogData = (lpid: number, id: number, type: number, callback: (data: any) => void) => {
  apiGet(`/processing/lineplan/${lpid}/`, {orderId: id, type: type, logData: true}, (resp: any) => {
    callback(resp.data)
  })
}

/**
 * gets child data to fill nc log form
 * @param id lineplan id
 * @param childId child order id
 * @param callback
 */
const getChildItemLogData = (id: number, childId: number, callback: (data: any) => void) => {
  apiGet(`/processing/lineplan/${id}/`, {childId: childId, childData: true}, (resp: any) => {
    callback(resp.data)
  })
}

/**
 * logs production on an item (post molding)
 * @param id lineplan id
 * @param ncOnly is logging nc only (for assembly child items)
 * @param data the data to log
 * @param callback
 */
const logProduction = (id: number, ncOnly: boolean, data: any, callback: (data: any) => void) => {
  apiPost(`/processing/lineplan/${id}/`, {...data, logProduction: true, ncOnly: ncOnly},
    (resp: any) => {
    callback(resp.data)
  })
}

/**
 * @param id: order id
 * @param callback
 */ 
const getPullBomData = (lineplanID: number, orderID: number | undefined, callback: (data: any) => void) => {
  apiGet(`/processing/lineplan/${lineplanID}/`, {pullBom: true, id: orderID }, (resp: any) => callback(resp.data))
}

export {
  getLineplanPageData, getProcessInstructions, getLogData, getChildItemLogData, logProduction, getPullBomData
}