import React, {FC, useEffect, useState} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// mui imports
// mui icon imports
import ContentCopy from '@mui/icons-material/ContentCopy';
// custom imports
import BaseModal from '../../../../utils/modals/BaseModal';
import FormInputAutocomplete from "../../../../utils/form/FormInputAutocomplete";


interface CopyFromModalProps {
  open: boolean;
  copyFromList: any[];
  closeHandler: () => void;
  doCopy: (id: number | undefined) => void;
}
const CopyFromModal: FC<CopyFromModalProps> = ({open, copyFromList, closeHandler, doCopy}) => {
  const [selectedObjectId, setSelectedObjectId] = useState<number | undefined>(undefined);

  const methods = useForm({defaultValues: {setupsheet: ''}});

  useEffect(() => {}, [open])

  const copyHandler = () => doCopy(selectedObjectId);
  const onChangeHandler = (data: any) => setSelectedObjectId(data.id);

  return (
    <>
      <BaseModal title='Copy From' open={open} closeHandler={closeHandler} closeButton dividers actions={[
        {text: 'Copy From', action: copyHandler, icon: <ContentCopy />}
      ]}>
        <FormProvider {...methods}>
          <FormInputAutocomplete name='setupsheet' label='Setupsheet' options={copyFromList}
                                 myOnChange={onChangeHandler} />
        </FormProvider>
      </BaseModal>
    </>
  );
}

export default CopyFromModal;
