import {apiGet, apiPost} from "../../../../utils/api";

const URL: string = '/productivity/personnel/'

// get
const getFormData = (callBack: (data: any) => void) => {
  apiGet(URL, {formData: true}, (resp) => callBack(resp.data));
}

const toggleAutoShiftLogout = (callback: (data: any) => void) => {
  apiPost(URL, {autoShiftLogout: true}, (resp) => callback(resp.data))
}

export {
  getFormData, toggleAutoShiftLogout
}