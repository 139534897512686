import React, {FC, useMemo, useState} from "react";
// mui imports
import Typography from "@mui/material/Typography";
// custom imports
import ProgressBar from "../../../../utils/globalComps/ProductivityProgress/ProgressBar";
import { ArmLogBar } from "./hooks/Interfaces";
import ProgressTickedBar from "../../../../utils/globalComps/ProductivityProgress/ProgressTickedBar";
import { Grid } from "@mui/material";


interface BarProps {
  bar: ArmLogBar
}
const Bar: FC<BarProps> = ({bar}) => {
    return (
      // <ProgressBar progress={bar.progress} ticks={bar.ticks}>
      //   <Typography variant='subtitle1'><strong>{bar.shift} Shift</strong> Logged {bar.logged} / Shift target {bar.expected}</Typography>
      // </ProgressBar>
			<Grid sx={{paddingBottom: 1}}>
				<Grid sx={{display: 'flex', justifyContent: 'space-between', paddingX: 2}}>
					<Grid sx={{display: 'inherit', alignItems: 'baseline'}}>
						<Typography variant="h6" fontWeight={'bold'} paddingRight={1}>{bar.shift} Shift:</Typography>
						<Typography variant='h6' fontWeight={'regular'}> Logged {bar.logged} / Expected {bar.expected}</Typography>
					</Grid>
					<Grid sx={{display: 'fleinheritx', alignItems: 'baseline'}}>
						<Typography variant='h6' fontWeight={'regular'}> Productivity: {bar.avg_productivity}</Typography>
					</Grid>
				</Grid>
				<Grid 
					sx={{
						paddingTop: 2,
						paddingBottom: 1,
						paddingRight: bar.progress == 100 ? 2 : 0,
						display: 'flex', 
						justifyContent: 'center'
					}}
				>
					<ProgressTickedBar progress={bar.progress} ticks={bar.ticks} flatEnd={bar.progress == 100 ? true : false}><></></ProgressTickedBar>
				</Grid>
				<Grid sx={{ display: 'flex', alignItems: 'baseline', paddingX: 2 }}>
						<Typography fontWeight={'bold'} paddingRight={1}>Logged in:</Typography>
						{bar.log_ins.map((login: { name: string, count: number }, key: number) => (
							<React.Fragment key={key}>
								<Typography paddingRight={1}>{login.name}</Typography>
								{key !== bar.log_ins.length - 1 && <Typography paddingRight={1}>|</Typography>}
							</React.Fragment>
						))}
				</Grid>
			</Grid>
      
    )
}

interface ProgressBarsProps {
  armLogData: ArmLogBar[]
}
const ArmLogBars: FC<ProgressBarsProps> = ({armLogData}) => {

	const barData = useMemo(() => armLogData ?? [], [armLogData])

	return (
		<Grid>
			{barData.map((bar: ArmLogBar, key: number) => (
				<Grid 
					sx={{
						marginBottom: 2
					}} 
					key={key}
				>
					<Bar bar={bar} ></Bar>
				</Grid>
			))}
		</Grid>
	)
}

export default ArmLogBars
