import React from "react";

interface CustomDragContainerProps {
  armId?: number | undefined;
  dragContainerRef: any;
  children?: any | undefined;
}

const CustomDragContainer: React.FC<CustomDragContainerProps> = ({
  armId,
  dragContainerRef,
  children,
}) => {

  return (
    <div
      id="unqueuedItemsContainer"
      className={`unqueuedItemsContainer-${armId}`}
      ref={dragContainerRef}
    >
      {children}
    </div>
  );
};

export default CustomDragContainer;
