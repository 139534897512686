import React, { FC } from 'react';
// mui imports
import Tooltip from "@mui/material/Tooltip";

interface BaseTooltipProps {
  text?: string | undefined;
  placement?: any;
  children: JSX.Element;
}
const BaseTooltip: FC<BaseTooltipProps> = ({text, placement, children}) => {
  if (text !== undefined && text !== '')
    return <Tooltip 
			title={text ? text : ''} 
			PopperProps={{
				modifiers: [
					{
						name: 'offset',
						options: {
							// offset: [0, 10],
						},
					},
				],
			}} 
			leaveDelay={100} 
			placement={placement} 
			enterDelay={100}>{children}
		</Tooltip>;
  else
    return children;
}

export default BaseTooltip;
