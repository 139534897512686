import React, { useState } from 'react';
import PageTitle from '../../../../utils/globalComps/PageTitle';
import BaseContent from '../../../../utils/globalComps/BaseContent';
import useOrders from './hooks/useOrders';
import OrderTable from './tables/OrderTable';
import OrderActions from './OrderActions';
import { Box, SelectChangeEvent } from '@mui/material';
import { OrdersContext } from './context/OrdersContext'; 

interface NewOrdersProps { }

const NewOrders: React.FC<NewOrdersProps> = () => {
	document.title = 'Orders | RotoEdgePro';

	const {
		loading, sendMessage, isReady,
		pageState, permissions, orders, 
		items, scrapCodes, machines, arms, lineplans, 
		syncEnabled
	} = useOrders();

	return (
		<OrdersContext.Provider value={{ 
			items: items, 
			scrapCodes: scrapCodes, 
			machines: machines, 
			arms: arms, 
			lineplans: lineplans,
			permissions: permissions
		}}>
			<OrderActions
				pageState={pageState}
				syncEnabled={syncEnabled}
				sendMessage={sendMessage}
				orders={orders}
			/>
			<Box sx={{ px: 2, py: 1 }}>
				<OrderTable
					pageState={pageState}
					orders={orders}
					sendMessage={sendMessage}
				/>
			</Box>
		</OrdersContext.Provider>
	);
};

export default NewOrders;
