import React, { FC, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
// icons
import ScheduleSend from '@mui/icons-material/ScheduleSend'
// mui
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
// custom
import BaseModal from './BaseModal'
import FormInputAutocomplete from '../form/FormInputAutocomplete'
import FormInputText from '../form/FormInputText'
import {useEffectApi} from '../globals'
import {apiGet} from '../api'
import Typography from "@mui/material/Typography";

interface ScheduleToMachineModalProps {
  orderItemId: number | undefined
  machineList: any[]
  closeHandler: () => void
  doSchedule: (orderItemId: number | undefined, machineId: number | undefined) => void
  moveMachine?: boolean
  addMachineItemType?: string
}
const ScheduleToMachine: FC<ScheduleToMachineModalProps> = ({orderItemId, machineList, closeHandler, doSchedule,
                                                              moveMachine, addMachineItemType}) => {
  const [selectedObjectId, setSelectedObjectId] = useState<number | undefined>(undefined)
  const [selectedObjectName, setSelectedObjectName] = useState<string>('')
  const [machines, setMachines] = useState<any[]>([])
  const [open, setOpen] = useState<boolean>(false)

  const [machineLoaded, setMachineLoaded] = useState<any[]>([])
  const [armScheduled, setArmScheduled] = useState<any[]>([])
  const [machineScheduled, setMachineScheduled] = useState<any[]>([])
  const [moldLoadStatus, setMoldLoadStatus] = useState<string | undefined>(undefined)

  useEffectApi(() => {
    let url: string = `/scheduling/machine-scheduling/${orderItemId}/`
    let params: any
    if (moveMachine)
      params = {type: 'scheduled'}
    else if (addMachineItemType)
      params = {type: addMachineItemType}

    if (orderItemId) {

      apiGet(url, {...params, getOrderName: true}, (resp: any) => {
        setSelectedObjectName(resp.data.name)
        setMachines(machineList.filter((m: any) => !resp.data.machine_ids.includes(m.id)))
        reset({machine: 'FSP-WA', order_item: resp.data.name})
      })

      apiGet(url, {...params, getScheduledInfo: true}, (resp: any) => {
        console.log('resp :', resp)
        setMachineLoaded(resp.data.machineLoaded)
        setArmScheduled(resp.data.armScheduled)
        setMachineScheduled(resp.data.machineScheduled)
        setMoldLoadStatus(resp.data.moldLoadStatus)
      })

      setOpen(true)
    } else
      setOpen(false)

  }, [orderItemId])

  const methods = useForm({defaultValues: {machine: 'FSP-WA', order_item: selectedObjectName}})
  const { reset } = methods

  // helpers
  const filterMachineList = () => {
    return machines
  }
  const scheduleHandler = () => {
    console.log('order :', orderItemId, selectedObjectId)
    doSchedule(orderItemId, selectedObjectId)
  }
  const onChangeHandler = (data: any) => setSelectedObjectId(data.id)

  return (
    <BaseModal
      title={moveMachine ? 'Move to different Machine' : 'Schedule to Machine'}
      open={open}
      closeHandler={closeHandler}
      closeButton
      dividers
      actions={[{text: moveMachine ? 'Move' : 'Schedule', action: scheduleHandler, icon: <ScheduleSend />}]}
    >
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={12}><FormInputText name='order_item' label='Order Item' disabled={true} /></Grid>
          <Grid item xs={12}>
            <FormInputAutocomplete name='machine' label='Machine' options={filterMachineList()} myOnChange={onChangeHandler} />
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          {moldLoadStatus ?
            <>
              <Grid item><Typography variant='h6'>Mold Schedule Information</Typography></Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}><Typography variant='subtitle1'>Mold: {moldLoadStatus} (In use/In stock)</Typography></Grid>
              </Grid>
            </>
            :
            <></>
          }
          {machineLoaded.length > 0 ?
            <Grid container item xs={12}>
              <Grid item xs={12}><Typography variant='subtitle1'>Loaded to:</Typography></Grid>
              {machineLoaded.map((obj: any, key: number) => (
                <Grid item xs={12} key={key}>
                  <Typography variant='body1'>{obj.armName} x {obj.count}</Typography>
                </Grid>
              ))}
            </Grid>
            :
            <></>
          }
          {armScheduled.length > 0 ?
            <Grid container item xs={12}>
              <Grid item xs={12}><Typography variant='subtitle1'>Scheduled to Arm(s):</Typography></Grid>
              {armScheduled.map((obj: any, key: number) => (
                <Grid item xs={12} key={key}>
                  <Typography variant='body1'>{obj.armName} x {obj.moldLoadQuantity}</Typography>
                </Grid>
              ))}
            </Grid>
            :
            <></>
          }
          {machineScheduled.length > 0 ?
            <Grid container item xs={12}>
              <Grid item xs={12}><Typography variant='subtitle1'>Scheduled to Machine(s):</Typography></Grid>
              {machineScheduled.map((obj: any, key: number) => (
                <Grid item xs={12} key={key}>
                  <Typography variant='body1'>{obj.machineName} x {obj.count}</Typography>
                </Grid>
              ))}
            </Grid>
            :
            <></>
          }
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default ScheduleToMachine
