import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../../websocket/hooks/useWebsocket";


interface MaintenanceRecord {
	id: number;
	performed_by: string;
	type: string
	date: string; 
	description: string
}

interface MaintenanceHistory {
	page_state: {};
	history: MaintenanceRecord[];
}


const useMaintenanceHistory = (id: number, type: 'Mold' | 'Machine') => {
	const [data, setData] = useState<MaintenanceHistory>({
		page_state: {},
		history: []
	});

	const { loading, sendMessage, isVisible, setPageState } = useWebsocket('/inventory/maintenance-history/', setData, { id, type });

	const pageState = data.page_state;

	// Log and potentially re-set state when visibility changes
	useEffect(() => {
		if (!isVisible) {
			setPageState(pageState);
		}
	}, [isVisible]);

	const history = useMemo(() => data.history, [data]);

	return {
		loading, sendMessage,
		history
	};
};

export default useMaintenanceHistory;
