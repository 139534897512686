import React, { FC, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
// mui imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// mui icon imports
import FileUpload from '@mui/icons-material/FileUpload';
import Delete from '@mui/icons-material/Delete';
// custom imports
import FormInputText from '../../../../utils/form/FormInputText';
import FormInputNumber from '../../../../utils/form/FormInputNumber';
import FormInputDropdown from '../../../../utils/form/FormInputDropdown';
import FormInputSwitch from '../../../../utils/form/FormInputSwitch';
import InfoText from '../../../../utils/globalComps/InfoText';
import BomItemList from './BomItemList';
import ImportModal from '../../../../utils/modals/ImportModal/ImportModal';
import {api, FormSpacing, useEffectApi} from '../../../../utils/globals';
import { doConfirm } from '../../../../utils/modals/Confirm';
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert';
import ApiForm from '../../../../utils/form/ApiForm';
import {apiGet} from "../../../../utils/api";
import {fetchCycleInfo} from "./SetupsheetApi";

interface SetupsheetFormProps {
  id: number | undefined;
  open: boolean;
  closeForm: (submit: boolean, data: any, create: boolean) => void;
  machineName: string;
  dualTimes: boolean;
  timeDiffTemp: number;
	tempType: String;
  itemName: string;
  itemDescription: string;
  moldName: string;
  moldLocation: string;
  bomItems: any[];
  ventMaterials: string[];
  cycleList: any[];
  hasPdf?: boolean;
}
const SetupsheetForm: FC<SetupsheetFormProps> = ({id, open, closeForm, machineName,
                                                   dualTimes, timeDiffTemp, tempType, itemName,
                                                   itemDescription, moldName, moldLocation,
                                                   bomItems, ventMaterials, cycleList,
                                                   hasPdf}) => {

  const [machineSetupsheetUploadOpen, setMachineSetupsheetUploadOpen] = useState<boolean>(false);
  const [hasPdfFile, setHasPdfFile] = useState<boolean>(false);
  const [pdfFileUrl, setPdfFileUrl] = useState<string | undefined>(undefined);
  const [timeTempLock, setTimeTempLock] = useState<boolean>(false)
  const [defaultVals, setDefaultVals] = useState<any>({})

  const fetchUrl: string = id ? `/manufacturing/setupsheets/${id}/` : '';
  const submitUrl: string = '/manufacturing/setupsheets/';

  useEffectApi(() => {
    setHasPdfFile(!!hasPdf);

    setDefaultVals({
      item: itemName,
      machine: machineName,
      mold: moldName,
      mold_location: moldLocation,
      item_description: itemDescription
    })

    if (id && hasPdf) {

      apiGet(`${submitUrl}${id}/?getPdf=${true}`, {responseType: 'blob'}, (resp: any) => {
        if (resp.data.size > 32) {
          setPdfFileUrl(window.URL.createObjectURL(resp.data));
          setHasPdfFile(true);
        } else {
          setPdfFileUrl(undefined);
          setHasPdfFile(false);
        }
      })
    }
  }, [hasPdf, open])

  let rowThree: any;
  if (dualTimes)
    rowThree =
      <>
        <Grid item xs={2}><FormInputNumber name='oven_temperature' label='Oven Temperature' disabled={timeTempLock} /></Grid>
				<Grid item xs={3}><FormInputNumber name='oven_warm_factory_time' label={`Oven Time Plant > ${timeDiffTemp}°${tempType}`} disabled={timeTempLock} /></Grid>
        <Grid item xs={3}><FormInputNumber name='oven_cold_factory_time' label={`Oven Time Plant < ${timeDiffTemp}°${tempType}`} disabled={timeTempLock} /></Grid>
        <Grid item xs={2}><FormInputNumber name='arm_speed' label='Arm Speed' float /></Grid>
				<Grid item xs={2}><FormInputNumber name='plate_speed' label='Plate Speed' float /></Grid>
      </>
  else
    rowThree =
      <>
        <Grid item xs={3}><FormInputNumber name='oven_temperature' label='Oven Temperature' disabled={timeTempLock} /></Grid>
        <Grid item xs={3}><FormInputNumber name='oven_cold_factory_time' label='Oven Time' disabled={timeTempLock} /></Grid>
        <Grid item xs={3}><FormInputNumber name='arm_speed' label='Arm Speed' float /></Grid>
        <Grid item xs={3}><FormInputNumber name='plate_speed' label='Plate Speed' float /></Grid>
      </>

  const generateTableActions = () => {
    if (id) {
      let actionList: any = [{text: 'Upload PDF Instructions', action: openMachineImportModal, icon: <FileUpload />}];
      if (pdfFileUrl && hasPdfFile)
        actionList.push({text: 'Delete PDF Instructions', action: deleteSetupsheetOverridePdf, icon: <Delete />})
      return actionList;
    } else
      return [];
  }

  // machine setupsheet upload modal actions
  const openMachineImportModal = () => setMachineSetupsheetUploadOpen(true);
  const closeMachineImportModal = (uploaded: boolean, file: any | undefined) => {
    console.log('uploaded :', uploaded)
    if (uploaded) {  // Display pdf instructions
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {setHasPdfFile(true); setPdfFileUrl(reader.result)};
    }
    setMachineSetupsheetUploadOpen(false);
  }
  const deleteSetupsheetOverridePdf = () => {
    doConfirm(`Delete setupsheet PDF instructions for ${itemName} ${machineName}?`, () => {
      api.post(`${fetchUrl}`, {deletePdf: true})
        .then((resp: AxiosResponse) => {
          doAlert(resp.data.message, 'success', true);
          setPdfFileUrl(undefined);  // Remove pdf instructions display
        })
        .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
    });
  }

  // cycle choice, set temp/time and lock those fields
  const handleCycleChange = (e: any) => {
    if (id)
    fetchCycleInfo(id, e.target.value, (data: any) => {
      console.log('data :', data)
      if (data.noCycle) {
         setTimeTempLock(false)
      } else {
        setDefaultVals({
          ...defaultVals,
          oven_temperature: data.temp,
          oven_cold_factory_time: data.hi_time,
          oven_warm_factory_time: data.lo_time,
          cycle: data.id
        })
        setTimeTempLock(true)
      }
    })
  }

  return (
    <>
      <ApiForm
        name='Setupsheet Form'
        open={open}
        defaultValues={defaultVals}
        closeForm={closeForm}
        id={id}
        tableActions={generateTableActions()}
        fetchUrl={fetchUrl}
        submitUrl={submitUrl}
        large
        // copyFrom
      >
        <Grid container spacing={FormSpacing}>
          <Grid item xs={3}><FormInputText name='item' label='Item' disabled /></Grid>
          <Grid item xs={3}><FormInputText name='machine' label='Machine' disabled /></Grid>
          <Grid item xs={3}>
            <FormInputText name='mold' label='Mold' disabled />
          </Grid>
          <Grid item xs={3}>
            <FormInputText name='mold_location' label='Mold Location' disabled />
            <InfoText mt={10} contained text='Mold location can be changed in Mold Management' />
          </Grid>
        </Grid>
        <Grid container spacing={FormSpacing}>
          <Grid item xs={4}><FormInputText name='item_description' label='Item Description' disabled /></Grid>
          <Grid item xs={4}><FormInputText name='customer_name' label='Customer Name' /></Grid>
          <Grid item xs={4}><FormInputSwitch name='cooling_fixture' label='Cooling Fixture' /></Grid>
        </Grid>
        <Grid container justifyContent='center' sx={{mt: '10px'}}><Typography variant='h5'>Bill Of Materials</Typography></Grid>
        <BomItemList bomItems={bomItems} displayNote />
        <Grid container justifyContent='center'><Typography variant='h5'>Machine Cycle Information</Typography></Grid>
        <Grid container spacing={FormSpacing} sx={{mt: '0px'}}>
          <Grid item xs={6}>
            <FormInputDropdown name='cycle' label='Cycle' options={cycleList} myOnChange={handleCycleChange} />
            <InfoText text='Setting the cycle resets the form data to its last saved state, make sure to do this before
            any other changes.' />
          </Grid>
          <Grid item xs={6}><FormInputDropdown name='vent_material' label='Vent Material' options={ventMaterials} /></Grid>
        </Grid>
        <Grid container spacing={FormSpacing} sx={{mt: '0px'}}>
          {rowThree}
        </Grid>
        <Grid container spacing={FormSpacing} sx={{mt: '0px'}}>
          <Grid item xs={3}><FormInputNumber name='oven_reverse_time' label='Oven Reverse Time' /></Grid>
          <Grid item xs={3}><FormInputNumber name='oven_air_time' label='Oven Air Time' /></Grid>
          <Grid item xs={3}><FormInputNumber name='psi' label='PSI' /></Grid>
          <Grid item xs={3}><FormInputNumber name='wall_thickness' label='Wall Thickness' float /></Grid>
        </Grid>
        <Grid container spacing={FormSpacing} sx={{mt: '0px'}}>
          <Grid item xs={3}><FormInputNumber name='cooling_delay_1' label='1st Cooling Delay' float /></Grid>
          <Grid item xs={3}><FormInputNumber name='cooling_station_time_1' label='1st Cooling Station Time' float /></Grid>
          <Grid item xs={3}><FormInputNumber name='cooling_delay_2' label='2nd Cooling Delay' float /></Grid>
          <Grid item xs={3}><FormInputNumber name='cooling_station_time_2' label='2nd Cooling Station Time' float /></Grid>
        </Grid>
        <Grid container justifyContent='center' sx={{mt: '10px'}}><Typography variant='h5'>Additional Instructions</Typography></Grid>
        <Grid container spacing={FormSpacing} sx={{mt: '0px'}}>
          <Grid item xs={12}><FormInputText name='molding_demolding_instructions' label='Molding/Demolding Instructions' multiline /></Grid>
        </Grid>
        <Grid container spacing={FormSpacing} sx={{mt: '0px'}}>
          <Grid item xs={12}><FormInputText name='cooling_instructions' label='Cooling Instructions' multiline /></Grid>
        </Grid>
        {pdfFileUrl && hasPdfFile ?
          <div className='setupsheet-pdf'>
            <Grid container justifyContent='center'><Typography variant='h5'>PDF Instructions</Typography></Grid>
            <iframe title='pdf viewer' src={pdfFileUrl} frameBorder='0' style={{width: '100%', height: '80vh',
              marginTop: '20px'}} />
          </div> : <></>}
      </ApiForm>
      <ImportModal open={machineSetupsheetUploadOpen} closeHandler={closeMachineImportModal}
                   url={fetchUrl} title={'Upload Machine Specific Setupsheet'} typeName='pdf'
                   appendData={{type: 'machine_specific_pdf'}} doCheck />
    </>
  );
}

export default SetupsheetForm;
