
import {SECONDARY_LP, FOAMING_LP, ASSEMBLY_LP} from '../../globals'

const MOLDING: number = 1
const SECONDARY: number = 2
const FOAMING: number = 3
const ALL: number = 4
const ASSEMBLY: number = 5
const SHIPPING: number = 6

const getHeadersFromType = (type: number) => {
  let headers: string[] = ['Item', 'Description', 'Location', 'Factory', 'Order', 'Total', 'Conforming', 'Shift',
    'User', 'Created', 'Updated', 'Archived', '']

  if (type < ASSEMBLY)
    headers.splice(7, 0, 'Non Conforming')

  return headers
}

const getRowsFromType = (type: number) => {
  let rows: string[] = ['item', 'description', 'location', 'factory', 'order', 'total', 'conforming', 'shift', 'user',
    'created', 'updated', 'archived_display']

  if (type < ASSEMBLY)
    rows.splice(7, 0, 'non_conforming')

  return rows
}

const splitIdType = (idType: string) => {
  const unitType: number = parseInt(idType.split('-')[0])
  const unitId: number = parseInt(idType.split('-')[1])

  return [unitId, unitType]
}

const logTypeFromLineType = (lineType: number) => {
  if (lineType === SECONDARY_LP)
    return SECONDARY
  else if (lineType === FOAMING_LP)
    return FOAMING
  else if (lineType === ASSEMBLY_LP)
    return ASSEMBLY
  else return 0
}

export {
  getHeadersFromType, getRowsFromType, splitIdType, MOLDING, SECONDARY, FOAMING, ASSEMBLY, SHIPPING, ALL,
  logTypeFromLineType
}
