import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { styled } from '@mui/material/styles'
import { DateTimeFormat } from '../globals'

const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  'input': {
    maxHeight: '8px',
    width: '100%',
    maxWidth: '100%'
  }
}))

interface FormInputTimeProps {
  name: string,
  label: string,
  disabled: boolean,
}
/**
 * Datetime input comp
 * @param name
 * @param label
 * @param disabled
 * @constructor
 */
const FormInputDatetime: FC<FormInputTimeProps> = ({name, label, disabled}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name as never}
      control={control}
      render={
      ({
          field: {onChange, value},
          fieldState: {error},
      }: any) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledDateTimePicker
          label={label}
          disabled={disabled}
          onChange={onChange}
          format={DateTimeFormat}
          value={value}
        />
      </LocalizationProvider>
      )} />
  )
}

export default FormInputDatetime
