import React, { FC, useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form';
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import BaseModal from "../../../../utils/modals/BaseModal";
import {FormSpacing} from "../../../../utils/globals";
import FormInputNumber from "../../../../utils/form/FormInputNumber";
import FormInputText from "../../../../utils/form/FormInputText";

interface WorkerCounterFormModalProps {
  id: number
  name: string
  currentWorkerCount?: number | undefined
  onClose: () => void
  onSubmit: (data: any) => void
}
/**
 * WorkerCounterFormModal - updates lineplan worker count from lineplan scheduling
 * @param id: lineplan id
 * @param name: lineplan name
 * @param currentWorkerCount
 * @param onClose
 * @param onSubmit
 * @constructor
 */
const WorkerCounterFormModal: FC<WorkerCounterFormModalProps> = ({id, name, currentWorkerCount,
                                                                   onClose,onSubmit}) => {

  const [open, setOpen] = useState<boolean>(false)

  const methods = useForm();
  const { reset, handleSubmit } = methods;

  useEffect(() => {
    if (id) {
      if (currentWorkerCount !== undefined)
        reset({worker_count: currentWorkerCount, id: id})

      setOpen(true)
    } else
      setOpen(false)
  }, [id])

  return (
    <BaseModal
      title={`Set Worker Count ${name}`}
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[{text: 'Submit', action: handleSubmit(onSubmit)}]}
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          <FormInputText name='id' label='lineplan_id' hidden={true} />
          <Grid item xs={12}><FormInputNumber name='worker_count' label='Worker Count' min={1} /></Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default WorkerCounterFormModal