import React, { FC, useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form';
// custom imports
import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import FormInputDropdown from "../form/FormInputDropdown";
import FormInputText from "../form/FormInputText";
import {getLineplanOptions} from "../../components/pages/services/LinePlan/LinePlanApi";

interface AssignToLineplanModalProps {
  orderItem?: any | undefined
  type: number
  doAssign: (data: any) => void
  onClose: () => void
  move?: boolean | undefined
}
const AssignToLineplanModal: FC<AssignToLineplanModalProps> = ({orderItem, type, doAssign, onClose, move}) => {

  const [open, setOpen] = useState<boolean>(false)
  const [lineplanOptions, setLineplanOptions] = useState<any[]>([])

  useEffect(() => {
    let isActive = true

    if (orderItem) {

      const data: any = move ? {order_item: orderItem.name, lineplan_item: orderItem.id} :
        {order_item: orderItem.name, order_id: orderItem.id}

      reset(data)
      getLineplanOptions(type, (data: any) => setLineplanOptions(data))
      setOpen(true)
    } else
      setOpen(false)

    return () => {isActive = false}
  }, [orderItem])

  const methods: any = useForm();
  const { reset, handleSubmit } = methods;

  return (
    <BaseModal
      title={`${move ? 'Move' : 'Assign To'} Lineplan`}
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Submit', action: handleSubmit(doAssign)}
      ]}
    >
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <FormInputText name={move ? 'lineplan_item' : 'order_id'} label='Order Id' hidden={true} />
          <Grid item xs={12}><FormInputText name='order_item' label='Order Item' disabled={true} /></Grid>
          <Grid item xs={12}>
            <FormInputDropdown name='lineplan' label='Line Plan' options={lineplanOptions} />
          </Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default AssignToLineplanModal