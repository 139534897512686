import React, {FC, useEffect, useState} from 'react';
// mui imports
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// mui icon imports
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grading from '@mui/icons-material/Grading';
// custom imports
import { colors } from '../../colors';
import { doConfirm } from '../Confirm';
import { deleteMessage, toggleMessageHidden } from './ChannelModalApi';
import BaseTooltip from '../../globalComps/BaseTooltip';
import AcknowledgedUserList from "./AcknowledgeUsersList";

interface ChannelMessageProps {
  id: number;
  text: string;
  userName: string;
  createdAt: string;
  hidden: boolean;
  editedAt?: string;
  setEditId: (id: number | undefined) => void;
  reloadChannel: () => void;
  editable: boolean;
  hiddenAt: string;
  permissions?: any | undefined
}
const ChannelMessage: FC<ChannelMessageProps> = ({id, text, userName, createdAt, hidden,
                                                   editedAt, setEditId, reloadChannel, editable, hiddenAt,
                                                   permissions}) => {
  const theme: any = useTheme();

  const nameColor: string = colors[theme.palette.mode].channel.name;
  const msgHeadlineColor: string = colors[theme.palette.mode].channel.messageHeadline;
  const [acknowledgedById, setAcknowledgedById] = useState<number | undefined>(undefined);

  useEffect(() => {

  })

  // action helpers
  const openEditMessage = () => setEditId(id)
  const doDeleteMessage = () => {
    doConfirm('Delete this message?', () => {
      deleteMessage(id, (success: boolean) => {
        if (success)
          reloadChannel();
      });
    });
  }
  const doToggleMessageHidden = () => toggleMessageHidden(id, () => reloadChannel());

  const openAcknowledgedUsers = () => setAcknowledgedById(id);
  const closeAcknowledgedUsers = () => setAcknowledgedById(undefined);

  return (
    <>
      <Grid container sx={{mb: '5px', pt: '5px', borderTop: `2px solid ${msgHeadlineColor}`}}>
        <Grid item xs={12}>
          <Grid container direction='row'>
            <Grid item>
              <Typography sx={{color: nameColor}}>
                {userName}
              </Typography>
            </Grid>
            <Grid item sx={{ml: '5px'}}>
              <BaseTooltip text='Edited At'>
                <Typography variant='subtitle2'>
                  | {editedAt !== createdAt ? `Last updated at: ${editedAt}` : `Created at: ${createdAt}`}
                </Typography>
              </BaseTooltip>
            </Grid>
            <Grid item sx={{ml: 'auto'}}>

              <Grid container direction='row'>

                {hidden ?
                  <Grid item sx={{mr: '5px'}}>
                    <BaseTooltip text='Hidden At'>
                      <Typography variant='subtitle2'>
                        Hidden at: {hiddenAt} |
                      </Typography>
                    </BaseTooltip>
                  </Grid>
                  :
                  <></>}
                {permissions?.edit ?
                  <BaseTooltip text={hidden ? 'Show message' : 'Hide message'}>
                    <IconButton size='small' edge='start' color='inherit' onClick={doToggleMessageHidden}>
                      {hidden ? <VisibilityOff sx={{fontSize: '15px'}} /> : <Visibility sx={{fontSize: '15px'}} />}
                    </IconButton>
                  </BaseTooltip>
                  :
                  <></>
                }
                <BaseTooltip text='View users who have acknowledged this message'>
                  <IconButton size='small' edge='start' color='inherit' onClick={openAcknowledgedUsers}>
                    <Grading sx={{fontSize: '15px'}} />
                  </IconButton>
                </BaseTooltip>
                {editable && permissions?.edit ?
                  <BaseTooltip text='Edit message'>
                    <IconButton size='small' edge='start' color='inherit' onClick={openEditMessage}>
                      <Edit sx={{fontSize: '15px'}} />
                    </IconButton>
                  </BaseTooltip>
                  :
                  <></>
                }
                {editable && permissions?.delete ?
                  <BaseTooltip text='Delete message'>
                    <IconButton size='small' edge='start' color='inherit' onClick={doDeleteMessage}>
                      <Delete sx={{fontSize: '15px'}} />
                    </IconButton>
                  </BaseTooltip>
                  :
                  <></>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {text}
        </Grid>
      </Grid>
      <AcknowledgedUserList messageId={acknowledgedById} closeHandler={closeAcknowledgedUsers} />
    </>
  );
}

export default ChannelMessage;
