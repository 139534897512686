import React, { useState } from 'react';
import PageTitle from '../../../../utils/globalComps/PageTitle';
import BaseContent from '../../../../utils/globalComps/BaseContent';
import { Box, TextField, InputAdornment, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useInventory from './hooks/useInventory';
import InventoryTable from './tables/InventoryTable';
import ExcelImportDialog from '../../../../websocket/utils/ExcelImportDialog';

interface InventoryProps { }

const Inventory: React.FC<InventoryProps> = () => {
	document.title = 'Inventory | RotoEdgePro';

	const { loading, pageState, inventory, sendMessage, clearInventory } = useInventory();

	const [searchQuery, setSearchQuery] = useState('');
	const [itemType, setItemType] = useState(3); // default to MOLDABLE

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const handleItemTypeChange = (event: SelectChangeEvent<number>) => {
		clearInventory()
		const newType = event.target.value as number;
		setItemType(newType);
		pageState.filter.item_type = newType;
		sendMessage('update_page_state', { page_state: pageState });
	};

	const requiredHeaders = ["Item", "Quantity Added"];

	return (
		<>
			<PageTitle title='Inventory' />
			<BaseContent loading={loading}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingX: 3, marginBottom: 2 }}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						<ExcelImportDialog buttonSx={{ height: 40 }} requiredHeaders={requiredHeaders} sendMessage={sendMessage} />
						<Select
							value={itemType}
							onChange={handleItemTypeChange}
							variant="outlined"
							size="small"
							sx={{ minWidth: 120, height: 40, boxSizing: 'border-box' }} // Ensure the height matches the TextField
						>
							<MenuItem value={1}>Moldable</MenuItem>
							<MenuItem value={2}>Assembly</MenuItem>
							<MenuItem value={3}>Powder</MenuItem>
							<MenuItem value={4}>Component</MenuItem>
							<MenuItem value={5}>Kit</MenuItem>
						</Select>
					</Box>

					<TextField
						placeholder={`Search...`}
						variant="outlined"
						size="small"
						value={searchQuery}
						onChange={handleSearchChange}
						sx={{
							borderRadius: '20px',
							'& .MuiOutlinedInput-root': {
								borderRadius: '20px',
								height: 40,
								boxSizing: 'border-box'
							}
						}} // Ensure the height matches the Select and keep the border radius
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<Box sx={{ overflow: 'auto' }}>
					<InventoryTable
						inventory={inventory}
						sendMessage={sendMessage}
						searchQuery={searchQuery}
					/>
				</Box>
			</BaseContent>
		</>
	);
};

export default Inventory;
