import { FC } from "react";
import { TableContainer, Paper, Table as MUITable, TableHead, TableRow, TableCell, TableBody, SxProps } from "@mui/material";

export interface Column {
  header: string;
  value: string;
  headerAlign?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  valueAlign?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
}

interface TableProps {
  sx?: SxProps;
  columns: Column[];
  rows: any[];
  stickyHeader?: boolean;
}

export const Table: FC<TableProps> = ({ columns, rows, stickyHeader = false, sx }) => {
  return (
    <TableContainer component={Paper} sx={{...sx}}>
      <MUITable sx={{ minWidth: 650}} aria-label="simple table" stickyHeader={stickyHeader} size="small">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align={column.headerAlign || 'left'}>
                {column.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? rows.map((row, rowIndex) => (
            <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {columns.map((column, colIndex) => (
                <TableCell key={colIndex} align={column.valueAlign || 'left'}>
                  {row[column.value]}
                </TableCell>
              ))}
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={columns.length} sx={{ border: 0 }} align="center">
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
}

export default Table;
