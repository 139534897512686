import React, { FC } from 'react'
import { create } from 'zustand'
// mui imports
import DialogContentText from '@mui/material/DialogContentText'
// custom imports
import BaseModal from './BaseModal'

interface confirmStoreProps {
  message: string | string[],
  onSubmit?: () => void,
  close: () => void,
}
const useConfirmDialogStore = create((set: any) => ({
  message: '',
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined })
} as confirmStoreProps))

const Confirm: FC = () => {
  const { message, onSubmit, close } = useConfirmDialogStore()

  const handleConfirm = () => {
    if (onSubmit) onSubmit()
    close()
  }

  return (
    <BaseModal
      title='Confirm'
      open={Boolean(onSubmit)}
      closeHandler={close}
      actions={[
        {text: 'Cancel', outlined: true, action: close, left: true},
        {text: 'Confirm', action: handleConfirm, autoFocus: true}
      ]}
      maxWidth='xs'
      closeButton
      dividers
    >
      {message.constructor === Array ?
        <>
          {message.map((msg: string, key: number) => (
            <DialogContentText key={key}>{msg}</DialogContentText>
          ))}
        </>
        :
        <DialogContentText>{message}</DialogContentText>
      }
    </BaseModal>
  )
}

export default Confirm
export const doConfirm = (message: string | string[], onSubmit: () => void) => {
  useConfirmDialogStore.setState({
    message,
    onSubmit
  })
}
