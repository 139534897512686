import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Box, TextField, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, LinearProgress, useTheme } from '@mui/material';
import useOrderSync, { TokenField } from './hooks/useOrderSync';

type FormValues = {
	[key: string]: string;
};

const OrderSyncDialog = () => {
	const { sendMessage, syncs, tokenForm } = useOrderSync();

	// State for opening and closing dialogs
	const [open, setOpen] = useState(false);
	const [tokenDialogOpen, setTokenDialogOpen] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>({});
	const [progressValue, setProgressValue] = useState(0); // For 'determinate' variant
	const [progressVariant, setProgressVariant] = useState<'determinate' | 'indeterminate'>('determinate');
	const [progressColor, setProgressColor] = useState<'primary' | 'inherit'>('primary'); // Change this based on sync state
	const [syncing, setSyncing] = useState(false); // To track if sync is in progress
	const [firstSyncId, setFirstSyncId] = useState(syncs?.[0]?.id || null);

	const theme = useTheme();

	// Clears form values when the token dialog opens
	const handleTokenOpen = () => {
		const initialFormValues = (tokenForm || []).reduce((acc, field: TokenField) => {
			acc[field.field] = '';
			return acc;
		}, {} as FormValues);
		setFormValues(initialFormValues || {});
		setTokenDialogOpen(true);
	};

	const handleTokenClose = () => {
		setTokenDialogOpen(false);
	};

	const handleFormChange = (field: string, value: string) => {
		setFormValues((prevValues) => ({
			...prevValues,
			[field]: value,
		}));
	};

	const handleSaveTokens = () => {
		sendMessage('submit_tokens', { tokens: { ...formValues } });
		setTokenDialogOpen(false);
		setFormValues({});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Sync completion logic - switch to 'green' and reset after 3 seconds
	useEffect(() => {
		if (syncs?.[0]?.id !== firstSyncId && firstSyncId !== null) {
			// Sync completed, switch to green
			setSyncing(false);
			setProgressColor('inherit'); // Use custom color for green
			setProgressVariant('determinate');
			setProgressValue(100); // Set progress to 100%

			// After 3 seconds, reset back to empty
			setTimeout(() => {
				setProgressValue(0);
				setProgressColor('primary'); // Reset to default color
			}, 3000);
		}
	}, [syncs]);

	const handleSyncOrders = () => {
		// Start syncing, set to indeterminate
		setSyncing(true);
		setFirstSyncId(syncs.length > 0 ? syncs[0].id : 0);
		setProgressVariant('indeterminate'); // Switch to indeterminate for sync animation
		setProgressColor('primary'); // Default blue color
		sendMessage('sync_orders');
	};

	return (
		<div>
			<Button
				sx={{ borderRadius: 3 }}
				variant="outlined"
				color="primary"
				onClick={handleClickOpen}
			>
				Order Sync
			</Button>
			<Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: 5, textWrap: 'nowrap' }}} >
				<DialogTitle>Order Sync</DialogTitle>
				<DialogContent dividers>
					{/* Single Progress bar with dynamic state */}
					<Box sx={{ width: '100%', marginY: 2 }}>
						<LinearProgress
							variant={progressVariant}  // Dynamically switch between 'determinate' and 'indeterminate'
							value={progressValue}      // Used for 'determinate' mode
							color={progressColor}      // Dynamically set color ('primary' for blue, 'inherit' for green)
							sx={{
								borderRadius: 5,
								transition: 'all 1s ease', // Smooth transition for color/variant changes
								...(progressColor === 'inherit' && {
									'& .MuiLinearProgress-bar': {
										backgroundColor: theme.palette.success.light, // Set green color manually
									},
								}),
							}}
						/>
					</Box>

					{/* Syncs Table */}
					<TableContainer component={Paper} sx={{ marginTop: 2, borderRadius: 5 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell sx={{ textAlign: 'center' }}>Orders Created</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>Synced At</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{syncs && syncs.map((sync, index) => (
									<TableRow key={index}>
										<TableCell sx={{ textAlign: 'center' }}>{sync.orders_created}</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>{new Date(sync.created).toLocaleString()}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box>
						{tokenForm.length > 0 ? 
						<Button sx={{ borderRadius: 3 }} onClick={handleTokenOpen} color="secondary">
							Tokens
						</Button> : <></>}
					</Box>
					<Box>
						<Button sx={{ borderRadius: 3 }} onClick={handleClose} color="error">
							Close
						</Button>
						<Button
							onClick={handleSyncOrders}
							color="primary"
							disabled={syncing}
							sx={{ borderRadius: 3 }}
						>
							Sync
						</Button>
					</Box>
				</DialogActions>
			</Dialog>

			{/* Token Dialog */}
			<Dialog open={tokenDialogOpen} onClose={handleTokenClose} PaperProps={{ sx: { borderRadius: 5 } }}>
				<DialogTitle>Token Form</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>
						<Typography>
							Tokens will be securely stored on AWS Secret Manager
						</Typography>
					</DialogContentText>
					<Box component="form">
						{tokenForm && tokenForm.map((field: TokenField, index: number) => (
							<TextField
								key={index}
								label={field.label}
								name={field.field}
								fullWidth
								margin="normal"
								value={formValues[field.field] || ''}
								onChange={(e) => handleFormChange(field.field, e.target.value)}
							/>
						))}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button sx={{ borderRadius: 3 }} onClick={handleTokenClose} color="error">
						Close
					</Button>
					<Button sx={{ borderRadius: 3 }} onClick={handleSaveTokens} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default OrderSyncDialog;