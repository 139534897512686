import { DateHelper, EventStore } from "@bryntum/scheduler";
import Order from "./Order.js";
import {
  buildDependencies,
  rebuildSchedulerData,
  mapToOrderModel,
  mapToCounterWeightsModel,
} from "./Util";

export default class OrderStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: Order,
    };
  }

  constructor(config, misc) {
    super(config);
    this.armId = misc.armId;
    this.lastServerData = config.data;
  }

  // We set a static date here so that we can reference it as our current date. If the page was left open for 15 min,
  // we can't use new Date() inline because it wont align with the data model.
  currentDate = new Date();

  // Override add to reschedule any overlapping events caused by the add
  add(records, silent = false) {
    const me = this;

    // Flag to avoid rescheduling during in-progress rescheduling
    me.isRescheduling = true;
    me.beginBatch();

    if (!Array.isArray(records)) {
      records = [records];
    }

    super.add(records, silent);

    me.endBatch();
    me.isRescheduling = false;
  }

  onUpdate({ record, changes }) {
    // If the duration of the order was changed. This can ONLY happen if a counter weight is resized.
    if (changes.duration) {
      this.beginBatch();
      const durationDelta = changes.duration.value - changes.duration.oldValue;
      // If the counterwieght was resized with the start date handle (the left side of the counterweight)
      // We need to shift the counterwieght back to it's original startDate
      if (changes.startDate) {
        record.setStartDate(changes.startDate.oldValue, true); // ture means keep the event [order] duration
      }
      // Recursively grab all 'downstream' orders and shift their start dates by the duration change in the counterweight
      const downstreamOrders = this.getChildOrders(record, []);
      downstreamOrders.forEach((orderToAdjust) => {
        let newStart = DateHelper.add(
          orderToAdjust.startDate,
          durationDelta,
          "ms"
        );
        orderToAdjust.setStartDate(newStart, true);
      });
      this.endBatch();
    }
  }

  onRemove(obj) {
    // TO-DO: This workflow has problems IF multiple items are unassigned at the same time. Calling
    // this.records will return orders excluding the ones removed- where as calling this.data
    // will pull all orders regardless of if they were removed. We would likely need to make all of the
    // changes to the priority queue, and then call rebuildSchedulerData OUTSIDE of the records loop.
    obj.records.forEach((removedEvent) => {
      const armId = this.armId;
      const oldPreviousLoadOrder = removedEvent.data.previousLoadOrder;

      const allOrders = this.records.map((order) => {
        if (order.previousLoadOrder === removedEvent.data.id) {
          return {
            ...order.data,
            previousLoadOrder: oldPreviousLoadOrder,
          };
        }
        return order.data;
      });
      const stores = {
        eventStore: this,
        resourceStore: this.resourceStore,
        dependencyStore: this.dependencyStore,
      };
      rebuildSchedulerData(allOrders, armId, stores);
    });
  }

  onCommit() {
    // The resourceId is detached from the object so re-attach them
    const recs = this.allRecords.map((ord) => ({
      ...ord.data,
      resourceId: ord.data.resourceId,
    }));
    this.lastServerData = recs;
    super.commit();
  }

  revertChanges() {
    console.log("eventStore revert this.lastServerData", this.lastServerData);
    this.removeAll();
    this.add(this.lastServerData);

    this.dependencyStore.removeAll();
    const newDeps = buildDependencies(this.lastServerData);
    this.dependencyStore.add(newDeps);
  }

  splitEventsByResource(eventRecord, resourceEvents) {
    let futureEventsUnsorted = [],
      earlierEventsUnsorted = [],
      intersectingEvent = null;

    // Split events into future and earlier events. We won't include the eventRecord that was passed.
    // Also, track the intersecting event [if there was one] so that we can reference it in the popup.
    resourceEvents.forEach((event) => {
      if (event.data.id !== eventRecord.data.id) {
        if (event.startDate >= eventRecord.startDate) {
          futureEventsUnsorted.push(event);
        } else {
          earlierEventsUnsorted.push(event);
        }
        if (
          event.startDate < eventRecord.startDate &&
          eventRecord.startDate < event.endDate
        ) {
          intersectingEvent = event;
        }
      }
    });

    const earlierEvents = earlierEventsUnsorted.sort((a, b) =>
      a.startDate > b.startDate ? 1 : -1
    );

    const futureEvents = futureEventsUnsorted.sort((a, b) =>
      a.startDate > b.startDate ? 1 : -1
    );
    return [earlierEvents, futureEvents, intersectingEvent];
  }

  doesEventRequirePopup = (eventRecord, resourceEvents) => {
    const [earlierEvents, futureEvents, intersectingEvent] =
      this.splitEventsByResource(eventRecord, resourceEvents);

    const isLastEventInRow = futureEvents.length === 0;
    const isOnlyEventInRow =
      futureEvents.length === 0 && earlierEvents.length === 0;

    const onLastRow = this.isEventOnLastRow(eventRecord);

    // We can only auto schedule if the order is dropped as the last item in the row.
    // Might modify this if we want more functionality to load orders on the scheduler.
    const canAutoSchedule =
      (isLastEventInRow && !isOnlyEventInRow) ||
      (isOnlyEventInRow && onLastRow);
    // We need a popup if the dropped order has a startTime that intersects another order
    const needPopup = !canAutoSchedule && intersectingEvent !== null;
    return [
      canAutoSchedule, // Can we auto schedule with the dropped event. AKA: is the drop location valid
      needPopup,
      intersectingEvent,
      earlierEvents,
      futureEvents,
    ];
  };

  isEventOnLastRow = (eventRecord) => {
    const targtedResourceId = eventRecord.data.resourceId;
    const resourceIdMap = this.resourceStore.idMap;
    const onLastRow =
      resourceIdMap[targtedResourceId].index ===
      Object.keys(resourceIdMap).length - 1;
    return onLastRow;
  };

  getChildOrders = (eventRecord, children) => {
    const childOrders = this.records.filter(
      (ord) => ord.data.previousLoadOrder === eventRecord.data.id
    );
    childOrders.forEach((childOrder) => {
      children.push(childOrder);
      const extraChildren = this.getChildOrders(childOrder, children);
      children.concat(extraChildren);
    });

    return children;
  };
}
