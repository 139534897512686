import React, {FC, useEffect, useState} from 'react';
import { useTheme } from "@mui/material/styles";
// mui icon imports
import Visibility from "@mui/icons-material/Visibility";
// mui imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// custom imports
import BaseTooltip from "../BaseTooltip";
import { colors } from "../../colors";
import LoadQueueModal from "../../modals/LoadQueueModal";
import {getLoadQueueItems} from "./MachineLoadDisplayApi";
import SchedulerModal from "../../modals/SchedulerModal";

interface LoadQueueDisplayProps {
  armName: string;
  armId: number;
  refresh: boolean;
}
const LoadQueueBox: FC<LoadQueueDisplayProps> = ({armName, armId, refresh}) => {

  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [schedulerModalId, setSchedulerModalId] = useState<number | undefined>(undefined);
  const [scheduler, setScheduler] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([]);

  // colors
  const loadQueueOutlineColor: string = colors[theme.palette.mode].machineLoadDisplay.loadQueueOutline;

  useEffect(() => {
    getLoadQueueItems(armId, (data: any) => {
      if (!data.scheduler)
        setData(data);
      else
        setScheduler(true)
    })
  }, [armId, refresh])

  const openModal = () => setModalOpen(true);
  const onModalClose = () => setModalOpen(false);

  const openScheduler = () => setSchedulerModalId(armId)
  const closeScheduler = () => setSchedulerModalId(undefined)

  return (
    <>
      <Grid container sx={{outline: `3px solid ${loadQueueOutlineColor}`, padding: '5px'}}>
        <Grid item>
          <BaseTooltip text='View Arm Schedule'>
            <IconButton onClick={scheduler ? openScheduler : openModal} sx={{maxHeight: '20px', maxWidth: '30px', m: 'auto'}}>
              <Visibility sx={{maxHeight: '20px', maxWidth: '30px'}} />
            </IconButton>
          </BaseTooltip>
        </Grid>
        <Grid item>
          {data.map((item: any, key: number) => (
            <Typography key={key}>
              {item.number}
            </Typography>
          ))}
        </Grid>
      </Grid>
      <LoadQueueModal armName={armName} open={modalOpen} items={data} onClose={onModalClose} />
      <SchedulerModal id={schedulerModalId} name={armName} refresh={refresh} onClose={closeScheduler} readOnly={true} />
    </>
  );
}

export default LoadQueueBox;