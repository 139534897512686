import React, {FC, useEffect, useState} from "react";
import ApiForm from "../../../../utils/form/ApiForm";
import {FormSpacing} from "../../../../utils/globals";
import Grid from "@mui/material/Grid";
import FormInputText from "../../../../utils/form/FormInputText";


interface VentMaterialFormProps {
  id: number | undefined
  open: boolean
  closeForm: (submit: boolean) => void
}
const VentMaterialForm: FC<VentMaterialFormProps> = ({id, open, closeForm}) => {

  useEffect(() => {

  }, [open, id])

  return (
    <ApiForm
      name='Vent Material Form'
      open={open}
      defaultValues={{name: ''}}
      closeForm={closeForm}
      id={id}
      fetchUrl={`/inventory/vent-materials/${id}/`}
      submitUrl='/inventory/vent-materials/'
    >
      <Grid container spacing={FormSpacing}>
        <Grid item xs={12}>
          <FormInputText name='name' label='Name' />
        </Grid>
      </Grid>
    </ApiForm>
  )
}

export default VentMaterialForm
