import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FC } from "react";


interface ViewPresetDropdownProps {
  handleChange: any;
  presets: any[];
  selectedPreset: any;
}

const ViewPresetDropdown: FC<ViewPresetDropdownProps> = ({
  handleChange,
  presets,
  selectedPreset,
}) => {
  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Zoom</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="view-preset-select"
          value={selectedPreset}
          label="Zoom"
          onChange={handleChange}
          sx={{ marginRight: 1, marginLeft: 1 }}
        >
          {presets.map((preset: any, i: number) => (
            <MenuItem key={i} value={preset.id}>
              {preset.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ViewPresetDropdown
