import React, { FC, useState } from 'react';
import Grid from '@mui/material/Grid';
// custom imports
import FormInputText from '../../../../utils/form/FormInputText';
import FormInputPassword from '../../../../utils/form/FormInputPassword';
import FormInputSwitch from '../../../../utils/form/FormInputSwitch';
import FormInputDropdown from '../../../../utils/form/FormInputDropdown';
import ApiForm from '../../../../utils/form/ApiForm';
import { FormSpacing } from "../../../../utils/globals";
import FormInputAutocomplete from "../../../../utils/form/FormInputAutocomplete";

interface AccountFormProps {
  id: number | undefined;
  open: boolean;
  closeForm: (submit: boolean, data: any, create: boolean, created_id: number | undefined) => void;
  factories: any[];
  pGroups: any[];
}
/**
 * AccountForm comp
 * @param closeForm
 * @param open
 * @param id
 * @param factories
 */
const AccountForm: FC<AccountFormProps> = ({closeForm, open, id, factories, pGroups}) => {
  const [factoryName, setFactoryName] = useState<string>('');

  const passwordForm =
    <>
      <Grid item md={6}><FormInputPassword name='password' label='Password' /></Grid>
      <Grid item md={6}><FormInputPassword name='confirm_password' label='Confirm Password' /></Grid>
    </>;

  const fetchUrl: string = id ? `/main/accounts/${id}/` : '';
  const submitUrl: string = '/main/accounts/';

  const updateFactoryName = (e: any) => {
    console.log('update factory name e:', e.target.value)
    for (const factory of factories) {
      if (factory.value === e.target.value) {
        setFactoryName(factory.label);
        console.log(`setFactoryName(${factory.label})`);
      }
    }
  }

  const closeFormHandler = (submit: boolean, data: any, create: boolean, created_id: number | undefined) => {
    console.log('CLOSE FORM HANDLER :', data)

    closeForm(submit, data, create, created_id);
  }

  return (
    <ApiForm
      name='Account Form'
      open={open}
      defaultValues={{factory: '', username: '', email: '', first_name: '', last_name: '', is_active: true,
        override_code: undefined, employee_id: undefined, dark_mode: false, drawer_open: false, password: '',
        confirm_password: '', roto_permission_groups: []}}
      dataOverride={[{name: 'factory', value: factoryName}]}
      closeForm={closeFormHandler}
      id={id}
      fetchUrl={fetchUrl}
      submitUrl={submitUrl}
      timeFields={[]}
    >
      <Grid container spacing={FormSpacing}>
        <Grid item md={6}><FormInputDropdown name='factory_id' label='Factory' options={factories} multiple={false} myOnChange={updateFactoryName} /></Grid>
        <Grid item md={6}><FormInputAutocomplete name='roto_permission_groups' label='Permission Groups' options={pGroups} multiple /></Grid>
        <Grid item md={4}><FormInputText name='username' label='Username' /></Grid>
        <Grid item md={8}><FormInputText name='email' label='Email' /></Grid>
        <Grid item md={6}><FormInputText name='override_code' label='Override Code' /></Grid>
        <Grid item md={6}><FormInputText name='employee_id' label='Employee ID' /></Grid>
      {id === undefined ? passwordForm : <></>}
        <Grid item md={6}><FormInputText name='first_name' label='First Name' /></Grid>
        <Grid item md={6}><FormInputText name='last_name' label='Last Name' /></Grid>
        <Grid item md={6}><FormInputSwitch name='dark_mode' label='Dark Mode' /></Grid>
        <Grid item md={6}><FormInputSwitch name='drawer_open' label='Drawer Open' /></Grid>
      </Grid>
    </ApiForm>
  );
}

export default AccountForm;
