import React, { FC, useState } from 'react'
// mui imports
// mui icons
import LockIcon from '@mui/icons-material/Lock'
// custom imports
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import { apiGet } from '../../../../utils/api'
import { useEffectApi } from '../../../../utils/globals'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import AccountForm from './AccountForm'
import PasswordForm from './PasswordForm'
import ApiTable from '../../../../utils/table/ApiTable'

const url: string = '/main/accounts/'

const Accounts: FC = () => {

  const [passFormOpen, setPassFormOpen] = useState<boolean>(false)
  const [factoryList, setFactoryList] = useState<any[]>([])
  const [permissionGroupList, setPermissionGroupList] = useState<any[]>([])
  const [accountId, setAccountId] = useState<number | undefined>(undefined)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const handlePassFormClose = (success: boolean) => {
    setPassFormOpen(false)

    if (success)
      doAlert('Successfully updated password', 'success', true)
  }

  // table row actions
  const changeAccountPass = (id: number) => {
    setAccountId(id)
    setPassFormOpen(true)
  }

  // Get factory data on page load
  useEffectApi(() => {

    document.title = 'Accounts | RotoEdgePro'

    apiGet(url, {}, (resp: any) => {
      setFactoryList(resp.data.factories)
      setPermissionGroupList(resp.data.permission_groups)
      setIsAdmin(resp.data.is_admin)
    })

  }, [])

  const generateRowActions = () => {
    let actions: any[] = []

    if (isAdmin)
      actions.push({icon: <LockIcon />, action: changeAccountPass, text: 'Change password', noCondition: true})

    return actions
  }

  return (
    <>
      <PageTitle title='Accounts'/>

      <ApiTable
        tableName=''
        objectName='Account'
        titleVariant=''
        headers={['Factory', 'Email', 'Username', 'First Name', 'Last Name', 'Dark Mode', 'Last Login', '']}
        rowFields={['factory', 'email', 'username', 'first_name', 'last_name', 'dark_mode', 'last_login']}
        rowActions={generateRowActions()}
        tableActions={[]}
        dataField='objects'
        url={url}
        searchable
        editable
        deletable
        creatable
        orderable
        FormComponent={AccountForm}
        formParams={{factories: factoryList, pGroups: permissionGroupList}}
      />

      <PasswordForm closeForm={handlePassFormClose} open={passFormOpen} id={accountId} />
    </>
  )
}

export default Accounts
