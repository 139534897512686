import React, { FC, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// mui imports
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// custom imports
import moment from 'moment';
import BaseModal from '../../modals/BaseModal';
import { DateFormat, FormSpacing, startAndEndOfWeek, useEffectApi } from '../../globals';
import FormInputText from '../../form/FormInputText';
import FormInputDropdown from '../../form/FormInputDropdown';
import FormInputDateRange from '../../form/FormInputDateRange';
import { getOrderName, getShiftList } from './ProductionLogApi';
import { MOLDING, SECONDARY, FOAMING, ASSEMBLY, SHIPPING, ALL } from './ProductionLogHelpers';
import ProductionLogTable from './ProductionLogTable';
import ProgressBars from '../../../components/pages/reporting/statistics/ProgressBars';

interface ProductionLogModalProps {
  orderId?: number | undefined;
  machineId?: number | undefined;
  lineplanId?: number | undefined;
  type: number;
  open: boolean;
  onClose: () => void;
  allowTypeSelect?: boolean | undefined;
}

const ProductionLogModal: FC<ProductionLogModalProps> = ({ orderId, machineId, lineplanId, type, open, onClose, allowTypeSelect }) => {
  const [orderName, setOrderName] = useState<string>('');
  const [logParams, setLogParams] = useState<any>(undefined);
  const [shiftList, setShiftList] = useState<any[]>([]);
  const [progressBarsDates, setProgressBarsDates] = useState<string[]>([]);

  const methods = useForm({ defaultValues: { type: type, order: orderName, 'date-range': startAndEndOfWeek(new Date()) } });
  const { handleSubmit, setValue, reset, watch } = methods;

	const generateOptions = () => {
    return [
      {value: MOLDING, label: 'Molding'},
      {value: SECONDARY, label: 'Secondary'},
      {value: FOAMING, label: 'Foaming'},
      {value: ASSEMBLY, label: 'Assembly'},
      {value: SHIPPING, label: 'Shipping'},
      {value: ALL, label: 'All'},
    ]
  }
	
  const watchDateRange = watch('date-range');
	const watchLogType = watch('type')

  useEffect(() => {
    if (watchLogType || watchDateRange) {
      submitForm({ 'date-range': watchDateRange, logType: watchLogType, orderId: orderId });
    }
  }, [watchDateRange, watchLogType]);

  useEffectApi(() => {
    if (orderId) {
      getOrderName(orderId, type, (name: string) => {
        setOrderName(name);
        setValue('order', name);
      });
    }
    getShiftList((data: any) => setShiftList(data));
    if (logParams === undefined || (orderId && logParams.orderId !== orderId)) {
      submitForm({ 'date-range': startAndEndOfWeek(new Date()), logType: type, orderId: orderId });
    }
  }, [open, orderId, machineId, type]);

  const submitForm = (data: any) => {
    const [startDate, endDate] = data['date-range'];
		// if either date is null, don't submit
		if (!startDate || !endDate) return
    setLogParams({
      orderId: orderId,
      logType: data.logType ? data.logType : data.type,
      startDate: moment(startDate).format(DateFormat),
      endDate: moment(endDate).format(DateFormat),
      machineId: machineId, 
			lineplanId
    });
    updateProgressBarsDates([moment(startDate).toString(), moment(endDate).toString()]);
  };

  const resetForm = () => {
    reset({ type: type, order: orderName, 'date-range': startAndEndOfWeek(new Date()) });
  };

  const updateProgressBarsDates = (dates: string[]) => {
    setProgressBarsDates(dates);
  };

  return (
    <BaseModal title='Production Logs' open={open} closeHandler={onClose} closeButton dividers maxWidth='xl'>
      <Grid container justifyContent='center'>
        <FormProvider {...methods}>
          <Grid container spacing={FormSpacing} sx={{ overflow: 'visible' }}>
            {!orderId ? (
              <Grid item xs={4}>
                <FormInputDateRange name='date-range' label={{ start: 'Start', end: 'End' }} />
              </Grid>
            ) : null}
            {allowTypeSelect && (
              <Grid item xs={2}>
                <FormInputDropdown label='Type' name='type' options={generateOptions()} disabled={!allowTypeSelect} />
              </Grid>
            )}
            {orderId ? (
              <Grid item xs={2}>
                <FormInputText label='Order' name='order' disabled />
              </Grid>
            ) : null}
            {/* <Grid item xs={4}>
              {allowTypeSelect && (
                <div>
                  <Button variant='contained' onClick={handleSubmit(submitForm)} sx={{ mr: '10px' }}>
                    Submit
                  </Button>
                  <Button variant='outlined' onClick={resetForm}>
                    Reset
                  </Button>
                </div>
              )}
            </Grid> */}
          </Grid>
        </FormProvider>
        {![SHIPPING, ALL].includes(type) && progressBarsDates.length > 0 && open ? (
          <ProgressBars machineId={machineId} lineplanId={lineplanId} shifts={shiftList} dates={progressBarsDates} />
        ) : null}
        <Grid item xs={12}>
          <ProductionLogTable params={logParams} type={type} doLoad={open} />
        </Grid>
      </Grid>
    </BaseModal>
  );
};

export default ProductionLogModal;
