import React, { FC, useState, useEffect } from 'react';
// custom imports
import BaseModal from '../../utils/modals/BaseModal';

interface PdfViewerProps {
  file?: any;
  open: boolean;
  closeHandler: () => void;
  actions?: any[];
  itemId?: number | undefined;
}
const PdfViewer: FC<PdfViewerProps> = ({file, open, closeHandler, actions, itemId}) => {
  const [fileUrl, setFileUrl] = useState<any | undefined>('');

  useEffect(() => {
    if (file && typeof file !== 'string')
      setFileUrl(window.URL.createObjectURL(file));
    else if (file)
      setFileUrl(file);
  }, [file, setFileUrl]);

  if (file)
    return (
      <BaseModal
        title='PDF Instructions'
        open={open}
        closeHandler={closeHandler}
        closeButton
        dividers
        actions={actions}
        maxWidth={'lg'}
      >
        <iframe title='pdf viewer' src={fileUrl} frameBorder='0' style={{width: '100%', height: '75vh',
                marginTop: '20px'}} />
      </BaseModal>
    );
  else
    return <></>;
}

export default PdfViewer;
