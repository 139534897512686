import { FC, Fragment, useRef } from 'react'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { BryntumDateTimeField } from "@bryntum/scheduler-react";

interface PopupAddHardBreakProps {
  onSave: any;
  open: boolean;
  onClose: any;
}

const PopupAddHardBreak: FC<PopupAddHardBreakProps> = ({
  onSave,
  open,
  onClose,
}) => {
  const dateTimeRef: any = useRef(null);

  if (!open) {
    return <Fragment></Fragment>;
  }

  return (
    <Dialog onClose={onClose} open={open} maxWidth={false}>
      <Box mx={4} my={2}>
        <Typography variant="h6">Add Hard Break</Typography>
        <Divider sx={{ margin: "10px 0" }} />

        <BryntumDateTimeField ref={dateTimeRef} value={new Date()} />
        <Button
          variant="outlined"
          size={"small"}
          sx={{ display: "flex", marginTop: 4 }}
          onClick={() => {
            const value = dateTimeRef.current?.instance.value;
            if (value) {
              onSave({ startDate: value });
            }
            onClose();
          }}
        >
          Add
        </Button>
      </Box>
    </Dialog>
  );
};

export default PopupAddHardBreak