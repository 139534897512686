import React, { FC } from 'react'
// mui imports
import Grid from '@mui/material/Grid'
// custom imports
import { FormSpacing } from '../../../../utils/globals'
import FormInputText from '../../../../utils/form/FormInputText'
import FormInputAutocomplete from '../../../../utils/form/FormInputAutocomplete'
import ApiForm from '../../../../utils/form/ApiForm'

interface BomItemFormProps {
  id: number | undefined
  open: boolean,
  closeForm: (submit: boolean, data: any, create: boolean) => void
  childItems: any[]
  parentItem?: string
}
const BomItemForm: FC<BomItemFormProps> = ({id, open, closeForm, childItems, parentItem}) => {

  const fetchUrl: string = id ? `/inventory/bomitems/${id}/` : ''
  const submitUrl: string = '/inventory/bomitems/'

  return (
    <ApiForm
      name='Bom Item Form'
      open={open}
      defaultValues={{'parent_item': parentItem ? parentItem : ''}}
      closeForm={closeForm}
      id={id}
      fetchUrl={fetchUrl}
      submitUrl={submitUrl}
    >
      <Grid container spacing={FormSpacing} sx={{overflow: 'visible'}}>
        <Grid item xs={4}><FormInputText name='parent_item' label='Parent Item' disabled={true} /></Grid>
        <Grid item xs={4}><FormInputAutocomplete name='child_item' label='Child Item' options={childItems} /></Grid>
        <Grid item xs={4}><FormInputText name='quantity' label='Quantity' /></Grid>
      </Grid>
    </ApiForm>
  )
}

export default BomItemForm
