import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import moment from 'moment'
// mui imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
// mui icons
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import Logout from '@mui/icons-material/Logout'
// custom imports
import { DateFormat, FormSpacing, startAndEndOfWeek, useEffectApi } from '../../../../utils/globals'
import { doConfirm } from '../../../../utils/modals/Confirm'
import { deleteLogin, editLogin, logoutPersonnel } from './PersonnelLoginApi'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import ApiTable from '../../../../utils/table/ApiTable'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import InfoText from '../../../../utils/globalComps/InfoText'
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion'
import PersonnelLoginForm from './PersonnelLoginForm'
import FormInputDateRange from '../../../../utils/form/FormInputDateRange'

interface PersonnelLoginsProps {}
const PersonnelLogins: FC<PersonnelLoginsProps> = ({}) => {

  const [refresh, setRefresh] = useState<boolean>(false)
  const [suppressLoadLineplan, setSuppressLoadLineplan] = useState<boolean>(false)
  const [suppressLoadMachine, setSuppressLoadMachine] = useState<boolean>(false)
  const [doLoadLineplan, setDoLoadLineplan] = useState<boolean>(false)
  const [doLoadMachine, setDoLoadMachine] = useState<boolean>(false)
  const [refreshLineplan, setRefreshLineplan] = useState<boolean>(false)
  const [refreshMachine, setRefreshMachine] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<string>(moment(startAndEndOfWeek(new Date())[0]).format(DateFormat))
  const [endDate, setEndDate] = useState<string>(moment(startAndEndOfWeek(new Date())[1]).format(DateFormat))
  const [machineData, setMachineData] = useState<any[]>([])
  const [lineplanData, setLineplanData] = useState<any[]>([])

  const [loginEditId, setLoginEditId] = useState<number | undefined>(undefined)
  const [loginEditMachine, setLoginEditMachine] = useState<boolean>(false)
  const [personnelFormErrors, setPersonnelFormErrors] = useState<any[]>([])

  const methods = useForm({defaultValues: {'date-range': startAndEndOfWeek(new Date())}})
  const { reset, handleSubmit } = methods

  const doRefreshLineplan = () => setRefreshLineplan(!refreshLineplan)
  const doRefreshMachine = () => setRefreshMachine(!refreshMachine)

  useEffectApi(() => {
    document.title = 'Personnel Statistics | RotoEdgePro'
  }, [refresh])

  // helpers
  const lineplanExpanded = (e: any, expanded: boolean) => {
    console.log('line plan expanded!')
    if (expanded) {
      setSuppressLoadLineplan(false)
      setDoLoadLineplan(true)
      doRefreshLineplan()
    } else
      setDoLoadLineplan(false)
  }
  const machineExpanded  = (e: any, expanded: boolean) => {
    console.log('machine expanded!')
    if (expanded) {
      setSuppressLoadMachine(false)
      setDoLoadMachine(true)
      doRefreshMachine()
    } else
      setDoLoadMachine(false)
  }
  // const dataCallback = (data: any[], machine: boolean) => {
  //   console.log('data callback :', data.length, 'machine :', machine)
  //   if (machine)
  //     setMachineData(data)
  //   else
  //     setLineplanData(data)
  // }
  const getPersonnelNameFromLoginId = (loginId: number, machine: boolean) => {
    const data: any[] = machine ? machineData : lineplanData
    for (const login of data)
      if (loginId === login.id)
        return login.name
    return ''
  }

  // page actions
  const submitForm = (data: any) => {
    setStartDate(moment(data['date-range'][0]).format(DateFormat))
    setEndDate(moment(data['date-range'][1]).format(DateFormat))
    doRefreshLineplan()
    doRefreshMachine()
  }
  const resetForm = () => {
    reset({'date-range': startAndEndOfWeek(new Date())})
    if (startDate !== moment(new Date()).format(DateFormat) || endDate !== moment(new Date()).format(DateFormat)) {
      setStartDate(moment(new Date()).format(DateFormat))
      setEndDate(moment(new Date()).format(DateFormat))
      doRefreshLineplan()
      doRefreshMachine()
    }
  }
  // row actions
  // dropdown row actions
  const submitEditForm = (data: any) => {
    if (loginEditId) {
      editLogin(loginEditId, data, (_: any) => closeEditForm(), (err: AxiosError) => {
        console.log('errrors :', err)
        // setPersonnelFormErrors

      })
    }
  }
  const openEditForm = (id: number, machine: boolean) => {
    setLoginEditId(id)
    setLoginEditMachine(machine)
  }
  const closeEditForm = () => {
    setLoginEditId(undefined)
    setLoginEditMachine(false)
  }
  /**
   * Forces a logged in personnel to logout.
   * @param loginId
   * @param machine
   */
  const doLogout = (loginId: number, machine: boolean) => {
    const name: string = getPersonnelNameFromLoginId(loginId, machine)

    doConfirm(`Force ${name} to logout?`, () => {
      logoutPersonnel(loginId, (_) => {
        if (machine) {
          setSuppressLoadMachine(true)
          doRefreshMachine()
        } else {
          setSuppressLoadLineplan(true)
          doRefreshLineplan()
        }
      })
    })
  }
  const doDeleteLogin = (loginId: number, machine: boolean) => {
    const name: string = getPersonnelNameFromLoginId(loginId, machine)

    doConfirm(`Delete ${name} login instance?`, () => {
      deleteLogin(loginId, (_) => {
        if (machine) {
          setSuppressLoadMachine(true)
          doRefreshMachine()
        } else {
          setSuppressLoadLineplan(true)
          doRefreshLineplan()
        }
      })
    })
  }

  return (
    <>
      <PageTitle title='Personnel Statistics' legend={[
        {text: 'Logged In', color: '#95da71'}
      ]} />
      <BaseContent loading={false}>
        <FormProvider {...methods}>
          <Grid container spacing={FormSpacing}>
            <Grid item>
              <FormInputDateRange name='date-range' label={{start: 'Start', end: 'End'}} />
            </Grid>
            <Grid item xs={2} container spacing={1}>
              <Grid item><Button variant='contained' onClick={handleSubmit(submitForm)}>Submit</Button></Grid>
              <Grid item><Button variant='outlined' onClick={resetForm}>Reset</Button></Grid>
            </Grid>
          </Grid>
        </FormProvider>
        <InfoText text='Time fields are in minutes' mt={10} />
        <BaseAccordion title='Line Plan' onChange={lineplanExpanded}>
          <ApiTable
            doLoad={doLoadLineplan}
            suppressLoadDisplay={suppressLoadLineplan}
            legend={[]}
            objectName=''
            refresh={refreshLineplan}
            headers={['', 'ID', 'Name', 'Total Login Time', 'Total Billed Time', 'Total Takt Time', 'Total Conforming Parts',
              'Total N/C Parts', 'N/C %', 'Avg Personnel Productivity', 'Avg Line Productivity',
              'Avg Billed Productivity', '']}
            rowFields={['id', 'name', 'login_time', 'billed_time', 'takt_time', 'conforming', 'non_conforming',
              'non_conforming_rate', 'productivity', 'location_productivity', 'billed_productivity']}
            rowActions={[
              {icon: <Logout />, text: 'Logout', action: (id: number) => {doLogout(id, false)}, condition: 'color', conditionValue: 'logged_in', permission: 'delete'}
            ]}
            dataField={'objects'}
            url={'/productivity/login/'}
            extraParams={{lineplan: true, start: startDate, end: endDate}}
            searchable
            refreshable
            expandable
            expandableParams={{
              suppressLoadDisplay: suppressLoadLineplan,
              objectName: '',
              dataField: 'objects',
              headers: ['Location', 'Login', 'Logout', 'Billed Time', 'Takt Time', 'Conforming Parts', 'N/C Parts', 'N/C %',
                'Personnel Productivity', 'Line Productivity', 'Billed Productivity', ''],
              rowFields: ['location', 'login_time', 'logout_time', 'billed_time', 'takt_time', 'conforming_parts',
                'non_conforming_parts', '-', 'productivity', 'location_productivity', 'billed_productivity'],
              rowActions: [
                {text: 'Edit', icon: <Edit />, action: (id: number) => {openEditForm(id, false)}, permission: 'edit', condition: 'color', conditionValue: ''},
                {text: 'Delete', icon: <Delete />, action: (id: number) => {doDeleteLogin(id, true)}, permission: 'delete', condition: 'color', conditionValue: ''}
              ],
              url: '/productivity/login/',
              extraParams: {lineplan: true, start: startDate, end: endDate},
            }}
            // dataCallback={(data: any[]) => {dataCallback(data, false)}}
          />
        </BaseAccordion>
        <BaseAccordion title='Machine' onChange={machineExpanded}>
          <ApiTable
            doLoad={doLoadMachine}
            suppressLoadDisplay={suppressLoadMachine}
            legend={[]}
            refresh={refreshMachine}
            objectName=''
            headers={['', 'ID', 'Name', 'Total Login Time', 'Total Billed Time', 'Total Takt Time', 'Total Conforming Parts',
              'Total N/C Parts', 'N/C %', 'Avg Personnel Productivity', 'Avg Machine Productivity',
              'Avg Billed Productivity', '']}
            rowFields={['id', 'name', 'login_time', 'billed_time', 'takt_time', 'conforming', 'non_conforming',
              'non_conforming_rate', 'productivity', 'location_productivity', 'billed_productivity']}
            tableActions={[]}
            rowActions={[
              {icon: <Logout />, text: 'Logout', action: (id: number) => {doLogout(id, true)}, condition: 'color', conditionValue: 'logged_in', permission: 'delete'}
            ]}
            dataField={'objects'}
            url={'/productivity/login/'}
            extraParams={{machine: true, start: startDate, end: endDate}}
            searchable
            refreshable
            expandable
            expandableParams={{
              suppressLoadDisplay: suppressLoadMachine,
              objectName: '',
              dataField: 'objects',
              headers: ['Location', 'Login', 'Logout', 'Billed Time', 'Takt Time', 'Conforming Parts', 'N/C Parts', 'N/C %',
                'Personnel Productivity', 'Machine Productivity', 'Billed Productivity', ''],
              rowFields: ['location', 'login_time', 'logout_time', 'billed_time', 'takt_time', 'conforming_parts',
                'non_conforming_parts', '-', 'productivity', 'location_productivity', 'billed_productivity'],
              rowActions: [
                {text: 'Edit', icon: <Edit />, action: (id: number) => {openEditForm(id, true)}, permission: 'edit', condition: 'color', conditionValue: ''},
                {text: 'Delete', icon: <Delete />, action: (id: number) => {doDeleteLogin(id, true)}, permission: 'delete', condition: 'color', conditionValue: ''}
              ],
              url: '/productivity/login/',
              extraParams: {machine: true, start: startDate, end: endDate},
            }}
            // dataCallback={(data: any[]) => setMachineData(data)}
          />
        </BaseAccordion>
      </BaseContent>

      <PersonnelLoginForm id={loginEditId} onClose={closeEditForm} machine={loginEditMachine} doSubmit={submitEditForm}
                          errors={personnelFormErrors} />
    </>
  )
}

export default PersonnelLogins
