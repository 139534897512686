import React, {FC, useEffect} from 'react'
import {FormProvider, useForm} from "react-hook-form";
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import BaseModal from "../../modals/BaseModal";
import {FormSpacing} from "../../globals";
import FormInputText from "../../form/FormInputText";
import InfoText from "../InfoText";

interface OverrideFormModalProps {
  open: boolean
  onClose: () => void
}
const OverrideFormModal: FC<OverrideFormModalProps> = ({open, onClose}) => {

  const methods: any = useForm()
  const { handleSubmit } = methods

  useEffect(() => {

  }, [open])

  const doSubmit = (data: any) => {
    console.log('do submit override :', data)
  }

  return (
    <BaseModal
      title='Override Permissions'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Submit', action: handleSubmit(doSubmit)}
      ]}
      maxWidth='sm'
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          <Grid item sx={{width: '100%'}}>
            <FormInputText name='code' label='Override code' />
            <InfoText text='User code to temporarily set override permissions to.' mt={5} />
          </Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default OverrideFormModal
