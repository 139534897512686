import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useTheme, Tooltip, IconButton, Typography, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface ProjectedSales {
	[key: string]: {
		actual: number;
		projected: number;
	};
}

interface ProjectedSalesChartProps {
	projectedSales: ProjectedSales;
	machines: string[];
	height: number;
}

const lightenColor = (hex: string, percent: number) => {
	let r = parseInt(hex.slice(1, 3), 16);
	let g = parseInt(hex.slice(3, 5), 16);
	let b = parseInt(hex.slice(5, 7), 16);

	r = Math.min(255, Math.floor(r + (255 - r) * percent / 100));
	g = Math.min(255, Math.floor(g + (255 - g) * percent / 100));
	b = Math.min(255, Math.floor(b + (255 - b) * percent / 100));

	return `rgb(${r}, ${g}, ${b})`;
};

const ProjectedSalesChart: React.FC<ProjectedSalesChartProps> = ({ projectedSales, machines, height }) => {
	const theme = useTheme();

	const actualCosts = machines.map(machine => projectedSales[machine]?.actual ?? 0);
	const projectedCosts = machines.map(machine => projectedSales[machine]?.projected ?? 0);

	const formatDollarValue = (val: number) => {
		if (val === 0) return '';
		return `$${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
	};

	const chartOptions: ApexOptions = {
		chart: {
			type: 'bar',
			stacked: false,
			animations: {
				enabled: true,
				easing: 'easeinout',
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 150
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350
				}
			}
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '45%',
				dataLabels: {
					position: 'center', // Position data labels in the center of the bars
				}
			},
		},
		dataLabels: {
			enabled: true,
			formatter: formatDollarValue,
			style: {
				fontSize: '12px',
				colors: [
					lightenColor(theme.palette.primary.main, 60), // Lighten primary color
					lightenColor(theme.palette.success.light, 60)  // Lighten success color
				]
			},
			background: {
				enabled: true,
				foreColor: theme.palette.common.black,
				borderRadius: 2,
				padding: 4,
				opacity: 1,
				dropShadow: {
					enabled: true,
					top: 1,
					left: 1,
					blur: 1,
					opacity: 0.45
				}
			}
		},
		xaxis: {
			categories: machines,
			position: 'bottom',
			labels: {
				offsetY: 0,
				style: {
					fontSize: '12px',
					colors: theme.palette.text.primary // Use theme text color
				}
			},
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false
			}
		},
		yaxis: {
			title: {
				text: 'Sales'
			},
			labels: {
				formatter: (val: number) => {
					if (val === 0) return '';
					return `$${val.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
				},
				style: {
					colors: theme.palette.text.primary // Use theme text color
				}
			}
		},
		fill: {
			opacity: 1,
			colors: [theme.palette.primary.main, theme.palette.success.light],
		},
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: formatDollarValue
			},
			marker: {
				show: true,
				fillColors: [theme.palette.primary.main, theme.palette.success.light], // Match tooltip marker colors with bar colors
			}
		},
		legend: {
			position: 'top',
			horizontalAlign: 'left',
			offsetX: 40,
			labels: {
				colors: theme.palette.text.primary // Use theme text color
			},
			markers: {
				fillColors: [theme.palette.primary.main, theme.palette.success.light] // Match legend colors with bar colors
			}
		},
		responsive: [{
			breakpoint: 480,
			options: {
				plotOptions: {
					bar: {
						columnWidth: '100%'
					}
				},
				xaxis: {
					categories: machines,
					labels: {
						show: false
					}
				},
				yaxis: {
					show: false
				}
			}
		}]
	};

	const series = [
		{
			name: 'Actual',
			data: actualCosts
		},
		{
			name: 'Projected',
			data: projectedCosts
		}
	];

	return (
		<Box>
			<Box display="flex" justifyContent='center' alignItems="center" mb={2}>
				<Typography variant="h6" component="div" mr={1}>
					Actual vs Projected Sales
				</Typography>
				<Tooltip title="Actual vs Projected Sales displays the actual amount of sales revenue produced from machines versus the projected amount if machine productivity was at 100%">
					<IconButton size="small">
						<InfoIcon />
					</IconButton>
				</Tooltip>
			</Box>
			<ReactApexChart
				options={chartOptions}
				series={series}
				type="bar"
				height={height}
			/>
		</Box>
	);
};

export default ProjectedSalesChart;
