import React, {FC, useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
// mui imports
import Grid from '@mui/material/Grid'
// custom imports
import BaseModal from '../../modals/BaseModal'
import {FormSpacing} from '../../globals'
import FormInputText from '../../form/FormInputText'
import InfoText from '../InfoText'
import {doLogin} from "./LoginFormApi";

interface LoginFormModalProps {
  open: boolean
  onClose: () => void
  login: boolean
  machineId?: number
  lineplanId?: number
}
/**
 * @param open
 * @param onClose
 * @param login: logs the person in if true, otherwise logout
 * @param machineId?
 * @param lineplanId?
 */
const LoginFormModal: FC<LoginFormModalProps> = ({open, onClose, login, machineId,
                                                   lineplanId}) => {

  const methods: any = useForm()
  const { handleSubmit, setError } = methods

  useEffect(() => {

  }, [open])

  const doSubmit = (data: any) => {
    doLogin(login, {...data, lineplanId: lineplanId, machineId: machineId}, (errors: any[]) => {
      if (errors && errors.length > 0)
        for (const error of errors)
          setError(error.name, {type: 'server', message: error.message})
      else
        onClose()
    })
  }

  return (
    <BaseModal
      title={login ? 'Login' : 'Logout'}
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Submit', action: handleSubmit(doSubmit)}
      ]}
      maxWidth='sm'
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          <Grid item sx={{width: '100%'}}>
            <FormInputText name='code' label='Personnel Code' />
            <InfoText text={`Enter your personnel code then submit to ${login ? 'login' : 'logout'}.`} mt={15} noMb />
          </Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default LoginFormModal
