import {apiGet, apiPost} from "../../../../utils/api";


const URL: string = '/manufacturing/lineplan/'

// GET
const getFormData = (callBack: (data: any) => void) => {
  apiGet(URL, {formData: true}, (resp) => {
    callBack(resp.data);
  })
}
const getValidateData = (type: number, q: string, page: number, callBack: (data: any) => void) => {
  apiGet('/manufacturing/lineplan/validate/', {type: type, q: q, page: page}, (resp) => {
    callBack(resp.data);
  })
}
// get options for lineplan select dropdown
const getLineplanOptions = (type: number, callback: (data: any) => void) => {
  apiGet(URL, {type: type}, (resp) => {
    callback(resp.data)
  })
}
// get staged line plan items
const getStagedItems = (lineplanId: number, q: string, callback: (data: any) => void) => {
  apiGet(`/scheduling/lineplan-scheduling/${lineplanId}/`, {getData: true, q: q}, (resp: any) => {
    callback(resp.data)
  })
}

// POST
const stageLineplanItem = (orderId: number, lineplanId: number, callback: (data: any) => void) => {
  apiPost(`/scheduling/lineplan-scheduling/${lineplanId}/`, {orderId: orderId, stageLineplanItem: true},
    (resp: any) => callback(resp.data))
}

export {
  getFormData, getValidateData, getLineplanOptions, getStagedItems, stageLineplanItem
}