import { apiGet, apiPost } from '../../../../utils/api'

const MACHINE: number = 1
const ASSEMBLY: number = 2

const machineUrl: string = '/manufacturing/mrp/machine'
const assemblyUrl: string = '/manufacturing/mrp/assembly'

/**
 * @param type mrp type
 * @param callback
 */
const getBaseData = (type: number, callback: (data: any) => void) => {
  const url: string = type === MACHINE ? machineUrl : assemblyUrl
  apiGet(url, {getData: true}, (resp: any) => {
    callback(resp.data)
  })
}

/**
 * Gets arms for the machine, as well as loaded/up next items for the MRP form
 * @param id machine id
 * @param callback
 */
const getMachineArmsData = (id: number, callback: (data: any) => void) => {
  apiGet(`${machineUrl}/${id}/`, {armData: true}, (resp: any) => callback(resp.data))
}

/**
 * Gets the mrp table data if any exists for the machine or line plan id
 * @param id machine or assembly line plan id
 * @param type machine or assembly type
 * @param callback
 */
const getMRPData = (id: number, type: number, callback: (data: any) => void) => {
  const url: string = type === MACHINE ? machineUrl : assemblyUrl
  apiGet(`${url}/${id}/`, {mrpData: true}, (resp: any) => callback(resp.data))
}

/**
 * Export MRP data
 * @param type machine or assembly type
 * @param callback
 */
const exportMRPData = (type: number, machineType: number, callback: (resp: any) => void) => {
  const url: string = type === MACHINE ? machineUrl : assemblyUrl
  apiGet(`${url}/`, {'export': true, machineType: machineType, blob: true}, (resp: any) => callback(resp))
}

/**
 * Populates either a machine or assembly MRP
 * @param id machine or assembly line plan id
 * @param type either machine or assembly type
 * @param data mrp populate data
 * @param callback
 */
const populateMrp = (id: number, type: number, data: any, callback: () => void) => {
  const url: string = type === MACHINE ? machineUrl : assemblyUrl
  apiPost(`${url}/${id}/`, {...data, populate: true}, (resp: any) => callback())
}

/**
 * Clears either a machine or assembly MRP
 * @param id machine or assembly line plan id
 * @param type either machine or assembly type
 * @param callback
 */
const clearMrp = (id: number, type: number, callback: () => void) => {
  const url: string = type === MACHINE ? machineUrl : assemblyUrl
  apiPost(`${url}/${id}/`, {clear: true}, (resp: any) => callback())
}

export {
  MACHINE, ASSEMBLY, getBaseData, getMachineArmsData, getMRPData, exportMRPData, populateMrp, clearMrp
}
