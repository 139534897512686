import React, {FC, useEffect, useState} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// mui imports
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

interface FormInputAutocompleteProps {
  name: string
  label: string
  options: {title: string, id: number}[]
  dropdownMaxHeight?: number
  group?: boolean
  myOnChange?: (data: any) => void
  multiple?: boolean
  fullWidth?: boolean
  disabled?: boolean
}
/**
 * FormInputAutocomplete comp
 * @param name
 * @param label
 * @param options
 * @param myOnChange
 * @constructor
 */
const FormInputAutocomplete: FC<FormInputAutocompleteProps> = ({name, label, options, dropdownMaxHeight,
                                                                 group, myOnChange, multiple,
                                                                 fullWidth, disabled}) => {

  const [optionList, setOptionList] = useState<{title: string, id: number}[]>([{title: '', id: -1}])

  const { control } = useFormContext()

  useEffect(() => {
    setOptionList([{title: '', id: -1}].concat(options.sort()))
  }, [name, disabled, options])

  const getValue = (value: any) => {
    if (value) return value
    else if (multiple) return []
    else return ''
  }

  return (
    <Controller
      name={name as never}
      control={control}
      defaultValue={{title: '', id: -1} as never}
      render={(
        {
          field: {onChange, value},
          fieldState: {error},
          formState
        }: any) => (
      <Autocomplete
        sx={fullWidth ? {width: '100%'} : {}}
        multiple={multiple}
        disabled={disabled}
        size='small'
        disablePortal
        disableCloseOnSelect={multiple ? true : undefined}
        groupBy={group ? (opt) => {return opt.group_by} : undefined}
        value={getValue(value)}
        options={optionList}
        onChange={(_, data) => {if (data) {onChange(data); if (myOnChange) myOnChange(data)}}}
        getOptionLabel={(opt) => {return opt.title || ''}}
        isOptionEqualToValue={(opt, val) => {
          return (opt.id === val.id || opt.title === val)
        }}
        renderInput={params => <TextField {...params} label={label} size='small' error={!!error} helperText={error ? error.message : ''} />}
        ListboxProps={{style: {maxHeight: dropdownMaxHeight ? `${dropdownMaxHeight}px` : '175px'}}}
      />
    )} />
  )
}

export default FormInputAutocomplete
