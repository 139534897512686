import React, {FC, useEffect, useState} from 'react'
// mui imports
import Box from '@mui/material/Box'
// custom imports
import CircularProgress from '@mui/material/CircularProgress'
import BaseModal from '../../utils/modals/BaseModal'
// css
import './BaseForm.css'

interface BaseFormProps {
  title: string,
  open: boolean,
  closeForm: () => void,
  children: JSX.Element
  actions: any[]
  loading: boolean
  large?: boolean
  xl?: boolean
  doSubmit?: any
}

const BaseForm: FC<BaseFormProps> = ({title, open, closeForm, children,
                                       actions, loading, large, xl,
                                       doSubmit}) => {

  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {

    setIsLoading(loading)

  }, [open, loading])

  let contentSx: any
  if (large || xl)
    contentSx = {display:'grid', gridRowGap: '20px', padding: '20px'}
  else
    contentSx = {display:'grid', gridRowGap: '20px', padding: '20px', overflowY: 'visible'}

  const circularLoad =
    <Box sx={{display: 'flex'}}><CircularProgress sx={{margin: '0 auto 1rem'}} color='inherit' /></Box>

  const getWidth = () => {
    if (xl) return 'xl'
    else if (large) return 'lg'
    else return 'md'
  }

  return (
    <BaseModal
      title={title}
      open={open}
      closeHandler={closeForm}
      maxWidth={getWidth()}
      closeButton
      scroll='paper'
      dividers
      contentSx={contentSx}
      actions={actions}
      doSubmit={doSubmit}
    >
      {isLoading ? circularLoad : children}
    </BaseModal>
  )
}

export default BaseForm
