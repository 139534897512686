import React, { FC, useEffect, useState } from 'react';
// mui imports
import Grid from '@mui/material/Grid';
import { AxiosResponse } from "axios";
// custom imports
import FormInputText from '../../../../utils/form/FormInputText';
import FormInputNumber from '../../../../utils/form/FormInputNumber';
import FormInputSwitch from '../../../../utils/form/FormInputSwitch';
import FormInputDropdown from '../../../../utils/form/FormInputDropdown';
import ApiForm from '../../../../utils/form/ApiForm';
import { api, FormSpacing } from "../../../../utils/globals";

interface ArmFormProps {
  id: number | undefined;
  open: boolean;
  closeForm: (submit: boolean, data: any, create: boolean) => void;
  machines: any[];
  machine: number | undefined;
}
/**
 * ArmForm component
 * @param id
 * @param open
 * @param closeForm
 * @param machines
 * @param machine
 */
const ArmForm: FC<ArmFormProps> = ({id, open, closeForm, machines, machine}) => {

  const [name, setName] = useState<string>('');

  const fetchUrl: string = id ? `/inventory/arms/${id}/` : '';
  const submitUrl: string = '/inventory/arms/';

  // get arm name
  useEffect(() => {
    let isActive = true;
    api.get(`${submitUrl}?getName=${machine}`)
      .then((resp: AxiosResponse) => {
        setName(resp.data.name);
      })
    return () => {isActive = false}
  }, [machine, open])

  return (
    <ApiForm
      name='Arm Form'
      open={open}
      defaultValues={{machine_id: machine, name: name, capacity: '', shutdown: false, critical: false}}
      closeForm={closeForm}
      id={id}
      fetchUrl={fetchUrl}
      submitUrl={submitUrl}
      timeFields={[]}
    >
      <Grid container spacing={FormSpacing}>
        <Grid item xs={6}><FormInputDropdown name='machine_id' label='Machine' options={machines} multiple={false} /></Grid>
        <Grid item xs={6}><FormInputText name='name' label='Name' /></Grid>
        <Grid item xs={3}><FormInputNumber name='capacity' label='Capacity' /></Grid>
				<Grid item xs={3}><FormInputNumber name='travel_time' label='Travel Time' /></Grid>
        <Grid item xs={3}><FormInputSwitch name='shutdown' label='Shutdown' /></Grid>
        <Grid item xs={3}><FormInputSwitch name='critical' label='Critical' /></Grid>
      </Grid>
    </ApiForm>
  );
}

export default ArmForm;
