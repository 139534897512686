import React, { FC } from 'react'
import LineplanProcessing from "./LineplanProcessing";
import {FOAMING_LP} from "../../../utils/globals";

interface FoamingLineplanProps {
  id: number
}
/**
 * Wrapper for lineplan of type foaming
 * @param lineplan id
 */
const FoamingLineplan: FC<FoamingLineplanProps> = ({id}) => <LineplanProcessing type={FOAMING_LP} typeName={'Foaming'} id={id} />

export default FoamingLineplan
