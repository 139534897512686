import React, { FC, useState, useRef, useCallback } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import jsPDF, { jsPDF as JSpdf } from 'jspdf'
// mui imports
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
// mui icon imports
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import Edit from '@mui/icons-material/Edit'
import Delete from '@mui/icons-material/Delete'
import Add from '@mui/icons-material/Add'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import FileUpload from '@mui/icons-material/FileUpload'
import FileDownload from '@mui/icons-material/FileDownload'
import PreviewIcon from '@mui/icons-material/Preview'
import ClearIcon from '@mui/icons-material/Clear'
// custom imports
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import { doConfirm } from '../../../../utils/modals/Confirm'
import { colors } from '../../../../utils/colors'
import { apiGet, downloadExportFile, getExportFilename } from '../../../../utils/api'
import { api, refreshObjects, useEffectApi } from '../../../../utils/globals'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import SearchBar from '../../../../utils/globalComps/SearchBar'
import SetupsheetForm from './SetupsheetForm'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import CircularProgress from '@mui/material/CircularProgress'
import TableActions from '../../../../utils/table/TableActions'
import ImportModal from '../../../../utils/modals/ImportModal/ImportModal'
import SetupsheetView from './SetupsheetView'
import BaseTooltip from '../../../../utils/globalComps/BaseTooltip'
import VentMaterialModal from './VentMaterialModal'
import '../../../../utils/table/rowHighlight.css'
import html2canvas from 'html2canvas'
import { PDFDocument } from 'pdf-lib'
import { FormProvider, useForm } from 'react-hook-form'
import FormInputSwitch from '../../../../utils/form/FormInputSwitch'
import DefaultPdfView from './DefaultPdfView'
import DeleteDefaultPdfModal from './DeleteDefaultPdfModal'
import { throttle } from 'lodash'
import { grey } from '@mui/material/colors'
import LineplanPdfView from './LineplanPdfView'
import DeleteLineplanPdfModal from './DeleteLineplanPdfModal'


const url: string = '/manufacturing/setupsheets/'

interface setupsheetControlProps {
  create?: boolean
  doCreate?: () => void
  id?: number
  item: any
  location: any
  doView?: (id: number, item: any, location: any) => void
  doEdit?: (id: number, item: any, location: any) => void
  doDelete?: (id: number, item: any, locationName: string) => void
  permissions: any
}
const SetupsheetControl: FC<setupsheetControlProps> = ({create, doCreate, id,
                                                         item, location, doView, doEdit, doDelete, permissions}) => {
																									
  if (create && permissions.create)
    return (
      <BaseTooltip text='Create Setupsheet'>
        <IconButton color='primary' onClick={doCreate}>
          <Add />
        </IconButton>
      </BaseTooltip>
    )
  else
    return (
      <>
        {doEdit && id && permissions.edit ? <BaseTooltip text='Edit Setupsheet'>
          <IconButton size='small' color='primary' onClick={() => doEdit(id, item, location)}>
            <Edit />
          </IconButton>
        </BaseTooltip> : <></>}
        {doView && id ? <BaseTooltip text='View Setupsheet'>
          <IconButton size='small' color='primary' onClick={() => doView(id, item, location)}>
            <VisibilityOutlined />
          </IconButton>
        </BaseTooltip> : <></>}
        {doDelete && id && permissions.delete ? <BaseTooltip text='Delete Setupsheet'>
          <IconButton size='small' color='primary' onClick={() => doDelete(id, item, location.name)}>
            <Delete />
          </IconButton>
        </BaseTooltip> : <></>}
      </>
    )
}

interface defaultPdfControlProps {
  create?: boolean
  doCreate?: () => void
  id?: number
	index: number
  item: any
  doView?: (item: any, index: any) => void
  doEdit?: (item: any, index: any) => void
  doDelete?: (item: any, index: any) => void
  permissions: any
}
const DefaultPDFControl: FC<defaultPdfControlProps> = ({create, doCreate, id, index,
                                                         item, doView, doEdit, doDelete, permissions}) => {
  if (create && permissions.create)
    return (
      <BaseTooltip text='Add default PDF'>
        <IconButton color='primary'  onClick={doCreate}>
          <NoteAddIcon />
        </IconButton>
      </BaseTooltip>
    )
  else
    return (
      <>
        {doEdit && id && permissions.edit ? <BaseTooltip text='Upload New Default PDF'>
          <IconButton size='small' color='primary' onClick={() => doEdit(item, index)}>
            <FileUpload />
          </IconButton>
        </BaseTooltip> : <></>}
        {doView && id ? <BaseTooltip text='View Default PDF'>
          <IconButton size='small' color='primary' onClick={() => doView(item, index)}>
            <PreviewIcon />
          </IconButton>
        </BaseTooltip> : <></>}
        {doDelete && id && permissions.delete ? <BaseTooltip text='Clear Default PDF'>
          <IconButton size='small' color='primary' onClick={() => doDelete(item, index)}>
            <ClearIcon />
          </IconButton>
        </BaseTooltip> : <></>}
      </>
    )
}

interface lineplanPdfControlProps {
  create?: boolean
  doCreate?: () => void
  id?: number
	index: number
  item: any
  doView?: (item: any, index: any) => void
  doEdit?: (item: any, index: any) => void
  doDelete?: (item: any, index: any) => void
  permissions: any
}
const LineplanPdfControl: FC<lineplanPdfControlProps> = ({create, doCreate, id, index,
                                                         item, doView, doEdit, doDelete, permissions}) => {
  if (create && permissions.create)
    return (
      <BaseTooltip text='Add Lineplan PDF'>
        <IconButton color='primary' onClick={doCreate}>
					<NoteAddIcon />
        </IconButton>
      </BaseTooltip>
    )
  else
    return (
      <>
        {doEdit && id && permissions.edit ? <BaseTooltip text='Upload New Lineplan PDF'>
          <IconButton size='small' color='primary' onClick={() => doEdit(item, index)}>
            <FileUpload />
          </IconButton>
        </BaseTooltip> : <></>}
        {doView && id ? <BaseTooltip text='View Lineplan PDF'>
          <IconButton size='small' color='primary' onClick={() => doView(item, index)}>
            <PreviewIcon />
          </IconButton>
        </BaseTooltip> : <></>}
        {doDelete && id && permissions.delete ? <BaseTooltip text='Clear Lineplan PDF'>
          <IconButton size='small' color='primary' onClick={() => doDelete(item, index)}>
            <ClearIcon />
          </IconButton>
        </BaseTooltip> : <></>}
      </>
    )
}

interface SetupsheetsProps {

}
/**
 * Setupsheets Component
 * @constructor
 */
const Setupsheets: FC<SetupsheetsProps> = () => {
  const theme = useTheme()

	const [refresh, setRefresh] = useState<boolean>(false)
  const [itemList, setItemList] = useState<any[]>([])
  const itemListRef = useRef<any[]>([])
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0)
  const [selectedItemName, setSelectedItemName] = useState<string>('')
  const [selectedItemDescription, setSelectedItemDescription] = useState<string>('')
  const [selectedItemMold, setSelectedItemMold] = useState<string>('')
  const [selectedItemMoldLocation, setSelectedItemMoldLocation] = useState<string>('')
  const [selectedItemFirstArticle, setSelectedItemFirstArticle] = useState<boolean>(false)
  const [selectedItemBOM, setSelectedItemBOM] = useState<any[]>([])
  const [machineList, setMachineList] = useState<any[]>([])
  const [selectedMachineName, setSelectedMachineName] = useState<string>('')
  const [selectedMachineDualTimes, setSelectedMachineDualTimes] = useState<boolean>(false)
  const [selectedMachineCycleList, setSelectedMachineCycleList] = useState<any[]>([])
  const [setupsheetPdf, setSetupsheetPdf] = useState<boolean>(false)
  const [timeDiffTemp, setTimeDiffTemp] = useState<number>(0)
  const [tempType, setTempType] = useState<string>('')
  const [ventMaterialList, setVentMaterialList] = useState<string[]>([])
  const [q, setQ] = useState<string>('')
  const [page, setPage] = useState<number>(2)
  const pageRef = useRef<number>(2)
  const [totalPages, setTotalPages] = useState<number>(0)
  const totalPagesRef = useRef<number>(0)
  const [formOpen, setFormOpen] = useState<boolean>(false)
  const [objectId, setObjectId] = useState<number | undefined>(undefined)
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [bottomLoading, setBottomLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [setupsheetViewOpen, setSetupsheetViewOpen] = useState<boolean>(false)
  const [printLoading, setPrintLoading] = useState<boolean>(false)
  const [permissionList, setPermissionList] = useState<any>({})
  const [ventMaterialModalOpen, setVentMaterialModalOpen] = useState<boolean>(false)
	const [pdfInstructionsUrl, setPdfInstructionsUrl] = useState<string | undefined>(undefined)
	// DEFAULT PDF
	const [defaultSetupsheetUploadOpen, setDefaultSetupsheetUploadOpen] = useState<boolean>(false)
	const [defaultSetupsheetURL, setDefaultSetupsheetURL] = useState<string>('')
	const [defaultPdfViewOpen, setDefaultPdfViewOpen] = useState<boolean>(false)
	const [deleteDefaultPdfModalOpen, setDeleteDefaultPdfModalOpen] = useState<boolean>(false)
	// LINEPLAN PDF
	const [lineplanUploadOpen, setLineplanUploadOpen] = useState<boolean>(false)
	const [lineplanSetupsheetURL, setLineplanSetupsheetURL] = useState<string>('')
	const [lineplanPdfViewOpen, setLineplanPdfViewOpen] = useState<boolean>(false)
	const [deleteLineplanPdfModalOpen, setDeleteLineplanPdfModalOpen] = useState<boolean>(false)

	const methods = useForm()

  itemListRef.current = itemList
  totalPagesRef.current = totalPages
  pageRef.current = page

  const handleScroll = useCallback(throttle(() => {
		const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
		if (bottom && !bottomLoading) {
			loadMore();
		}
	}, 500), [bottomLoading])

  useEffectApi(() => {

		console.log('USE EFFECT API')

    document.title = 'Setupsheets | RotoEdgePro'

    // load more when hit bottom scrolling
    window.addEventListener('scroll', handleScroll, {passive: true})

    setLoading(true)


		if (q){
			search(q)
			return
		}

		// console.log('getUrl', getUrl)
    apiGet(url, {getData: true}, (resp: any) => {
      // console.log('setupsheet resp :', resp)
      setMachineList(resp.data.machines)
      setItemList(resp.data.items)
      setPage(resp.data.page)
      setTotalPages(resp.data.max_page)
      setVentMaterialList(resp.data.vent_materials)
      setTimeDiffTemp(resp.data.time_diff_temp)  // factory temp at which the oven time increases or decreases
      setTempType(resp.data.temp_type)  
      setPermissionList(resp.data.permissions)
      setLoading(false)
    }, (err: any) => setLoading(false))

		// Cleanup function
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};

  }, [refresh])

  const search = (newQ: string) => {
    let _page: number = 1
    setPage(_page)

    setQ(newQ)
    setLoading(true)
    getObjectsHelper((data: any[]) => {
      setItemList(data)
      setLoading(false)
    }, newQ, _page)
  }
  const loadMore = () => {
    if (pageRef.current === 1 && itemListRef.current.length > 0)
			console.log('setting page')
      setPage(pageRef.current + 1)

    console.log('load more :', pageRef.current, totalPagesRef.current)
    if (pageRef.current <= totalPagesRef.current) {
      setBottomLoading(true)
      getObjectsHelper((items: any[]) => {
        console.log('itemsList :', itemListRef.current)
        let newItemList: any[] = [...itemListRef.current].concat(items)
        if (items.length > 0) setItemList(newItemList)
        setPage(pageRef.current + 1)
        setBottomLoading(false)
      }, q, pageRef.current)
    }
  }
  const getObjectsHelper = (cb: (data: any[]) => void, q: string, page: number | string) => {
    api.get(`${url}?q=${q}&page=${page}`)
      .then((resp: AxiosResponse) => {
        setTotalPages(resp.data.max_page)
        cb(resp.data.items)
      })
      .catch((err: AxiosError) => {
        if (err.response) doAlert(err.response.data.message, 'error', true)
        cb([])
      })
  }

  const setFormParamsHelper = (item: any, machine: any) => {
    console.log('item :', item)
    setSelectedItemName(item.name)
    setSelectedItemDescription(item.description)
    setSelectedItemMold(item.mold)
    setSelectedItemMoldLocation(item.mold_location)
    setSelectedItemBOM(item.bom_items)
    setSelectedMachineName(machine.name)
    setSelectedMachineDualTimes(machine.dual_times)
    setSelectedMachineCycleList([{label: 'No Cycle', value: 'No Cycle'}, ...machine.cycle_list])
    setSetupsheetPdf(item[`${machine.name}_setupsheet_pdf`])
  }

  // table actions
  const exportObjects = () => {
    setExportLoading(true)
    api.get(url + `?export=${true}&q=${q}`, {responseType: 'blob'})
      .then((resp: AxiosResponse) => {
        downloadExportFile(resp.data, getExportFilename(resp.headers))
        setExportLoading(false)
      })
      .catch((err: AxiosError) => {
        if (err.response)
          doAlert(err.response.data.message, 'error', true)
        setExportLoading(false)
      })
  }
  const openObjectImportModal = () => setImportModalOpen(true)
  const openVentMaterialModal = () => setVentMaterialModalOpen(true)
  const closeVentMaterialModal = () => setVentMaterialModalOpen(false)

	// lineplan pdf row actions
	const addLineplanPdf = (item: any, index: number) => {
		setObjectId(item.setupsheet_id)
		setSelectedItemName(item.name)
		setSelectedItemIndex(index)
		setLineplanSetupsheetURL(`/manufacturing/setupsheets/`)
		openLineplanImportModal()
	}
	const deleteLineplanPdf = (item: any, index: number) => {
		setSelectedItemName(item.name)
		setDeleteLineplanPdfModalOpen(true)
		setSelectedItemIndex(index)
	}
	const editLineplanPdf = (item: any, index: number) => {
		setObjectId(item.setupsheet_id)
		setSelectedItemName(item.name)
		setSelectedItemIndex(index)
		setLineplanSetupsheetURL(`/manufacturing/setupsheets/`)
		openLineplanImportModal()
	}
	const viewLineplanPdf = (item: any, index: number) => {
		setSelectedItemName(item.name)
		setLineplanPdfViewOpen(true)
	}
	const closeLineplanViewHandler = () => setLineplanPdfViewOpen(false)

	// default pdf row actions
	const addDefaultPdf = (item: any, index: number) => {
		setObjectId(item.setupsheet_id)
		setSelectedItemName(item.name)
		setSelectedItemIndex(index)
		setDefaultSetupsheetURL(item.setupsheet_id ? `/manufacturing/setupsheets/${item.setupsheet_id}/` : `/manufacturing/setupsheets/`)
		openDefaultImportModal()
	}
	const deleteDefaultPdf = (item: any, index: number) => {
		setSelectedItemName(item.name)
		setDeleteDefaultPdfModalOpen(true)
		setSelectedItemIndex(index)
	}
	const editDefaultPdf = (item: any, index: number) => {
		setObjectId(item.setupsheet_id)
		setSelectedItemName(item.name)
		setSelectedItemIndex(index)
		setDefaultSetupsheetURL(item.setupsheet_id ? `/manufacturing/setupsheets/${item.setupsheet_id}/` : `/manufacturing/setupsheets/`)
		openDefaultImportModal()
	}
	const viewDefaultPdf = (item: any, index: number) => {
		setSelectedItemName(item.name)
		setDefaultPdfViewOpen(true)
	}
	const closeDefaultViewHandler = () => setDefaultPdfViewOpen(false)

  // row actions
  const createSetupsheet = (item: any, machine: any) => {
    setObjectId(undefined)
    setFormParamsHelper(item, machine)
    setFormOpen(true)
  }
  const viewSetupsheet = (id: number, item: any, machine: any) => {
    setObjectId(id)
    setSelectedItemBOM(item.bom_items)
    setSelectedItemFirstArticle(item.first_article)
    setSelectedMachineDualTimes(machine.dual_oven_times)
		getPdfInstructionUrls(id)
    setSetupsheetViewOpen(true)
  }
  const editSetupsheet = (id: number, item: any, machine: any) => {
    setObjectId(id)
    setFormParamsHelper(item, machine)
    setFormOpen(true)
  }
  const deleteSetupsheet = (id: number, item: any, machineName: string) => {
    doConfirm(`Delete ${item.name} ${machineName} Setupsheet?`, () => {
      api.delete(`${url}${id}/`)
        .then((resp: AxiosResponse) => {
          console.log('item list :', itemList)
          const newItemList = refreshObjects(resp.data.item, false, itemListRef.current)
          console.log('item list 2 :', newItemList)
          setItemList(newItemList)
          doAlert(resp.data.message, 'success', true)
        })
        .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
    })
  }

  // handlers
  const closeFormHandler = (submit: boolean, data: any, create: boolean) => {
    setFormOpen(false)
    setObjectId(undefined)
    if (data.item) {
      api.get(`${url}?getItem=${data.item}`)
        .then((resp: AxiosResponse) => {
          const newItemList = refreshObjects(resp.data.item, false, itemListRef.current)
          setItemList(newItemList)
        })
        .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
    }
  }
  const closeViewHandler = () => setSetupsheetViewOpen(false)

	// default setupsheet modal actions
	const openDefaultImportModal = () => setDefaultSetupsheetUploadOpen(true)
	const closeDefaultImportModal = (uploaded: boolean, file: any | undefined) => {
		setRefresh(true); setRefresh(false)
		setDefaultSetupsheetUploadOpen(false)
	}

	// lineplan pdf modal actions
	const openLineplanImportModal = () => setLineplanUploadOpen(true)
	const closeLineplanImportModal = (uploaded: boolean, file: any | undefined) => {
		setRefresh(true); setRefresh(false)
		setLineplanUploadOpen(false)
	}

	const getPdfInstructionUrls = async (id: number) => {
		// get pdf urls to pass into child setupsheetview
		apiGet(`${url}${id}/`, {getPdf: true, newTab: false, blob: true}, (resp: AxiosResponse) => {
			console.log('resp :', resp.data)
			if (resp.data.size > 32)
				setPdfInstructionsUrl(window.URL.createObjectURL(resp.data))
			else
				setPdfInstructionsUrl(undefined)
		})
	}

	const printHandler = async (cb: () => void) => {
		const content = document.getElementById('setupsheet-info');
	
		if (!content) {
			return;
		}
	
		setPrintLoading(true);
	
		try {
			// Assuming you have imgData from html2canvas
			const canvas = await html2canvas(content, { scale: 2 });
			const imgData = canvas.toDataURL('image/png');

			// Assuming imgData is the base64-encoded image data from html2canvas
			const imgBase64 = imgData.split(',')[1]; // Extract the base64 data from the data URL

			// Create a new PDF doc
			const mergedPdfDoc = await PDFDocument.create();

			// Embed the PNG image from the canvas data
			const image = await mergedPdfDoc.embedPng(imgBase64);

			// Define the size of the PDF page - for example, A4 at 72 DPI
			const pdfWidth = 612.5; // A4 width in points
			const pdfHeight = 750; // A4 height in points
			const margin = 45; // Margin in points

			// Calculate the scale to fit the image within the page while maintaining aspect ratio
			const scale = Math.min((pdfWidth - margin * 2) / image.width, (pdfHeight - margin * 2) / image.height);
			const imgWidth = image.width * scale;
			const imgHeight = image.height * scale;

			// Calculate position to center the image on the page
			const xPosition = (pdfWidth - imgWidth) / 2;
			const yPosition = (pdfHeight - imgHeight) - margin;

			// Add a page to the PDF that fits the A4 size
			const page = mergedPdfDoc.addPage([pdfWidth, pdfHeight]);

			// Draw the image on the page with margins and scaled size
			page.drawImage(image, {
				x: xPosition,
				y: yPosition,
				width: imgWidth,
				height: imgHeight,
			});
	
			// Fetch and merge pdfInstructionsUrl if it exists
			if (pdfInstructionsUrl) {
				const pdfInstructionsArrayBuffer = await fetchPDF(pdfInstructionsUrl);
				const pdfInstructionsDoc = await PDFDocument.load(pdfInstructionsArrayBuffer);
				const pdfInstructionsPages = await mergedPdfDoc.copyPages(pdfInstructionsDoc, pdfInstructionsDoc.getPageIndices());
				pdfInstructionsPages.forEach(page => mergedPdfDoc.addPage(page));
			}
	
			// Serialize the PDFDocument to bytes (a Uint8Array)
			const pdfBytes = await mergedPdfDoc.save();
	
			// Trigger download or open in new tab
			const blob = new Blob([pdfBytes], { type: 'application/pdf' });
			const blobUrl = URL.createObjectURL(blob);
			window.open(blobUrl, '_blank');
		} catch (error) {
			console.error("Error generating or merging PDFs: ", error);
			doAlert('Error generating or merging PDFs', 'error', true); // Use your existing alert system here
		}
	
		setPrintLoading(false);
		cb(); // Callback function after PDF creation
	};
	
	// Utility function to fetch PDF as ArrayBuffer
	const fetchPDF = async (url: string): Promise<ArrayBuffer> => {
		const response = await fetch(url);
		const blob = await response.blob();
		return blob.arrayBuffer();
	};


  const generateTableActions = () => {
    let actions: any[] = []

    actions.push({text: 'Vent Materials', action: openVentMaterialModal, tooltip: 'Manage vent materials'})

    if (permissionList?.create && permissionList?.edit && permissionList?.delete)
      actions.push({text: 'Import', icon: <FileUpload />, action: openObjectImportModal, outlined: true, right: true,
        tooltip: 'Import Setupsheets from excel'})

    if (permissionList?.view && permissionList?.edit)
      actions.push({text: 'Export', icon: <FileDownload />, action: exportObjects, outlined: true, right: true,
        tooltip: 'Export Setupsheets to excel', loadingButton: true, loading: exportLoading})

    return actions
  }

  const backgroundColor = theme.palette.mode === 'dark' ? colors.dark.header : colors.light.header

  return (
    <>
      <PageTitle title='Setup Sheets' />
      <BaseContent loading={loading}>
        <Grid container justifyContent='flex-end'>
          {permissionList.view ? <Grid item xs={3}>
            <Box display='flex' justifyContent='flex-end'>
              <SearchBar outlined={true} width={100} onSearch={search} q={q} defaultText='Search Items...' />
            </Box>
          </Grid> : <></>}
          <TableActions actions={generateTableActions()} />
          <TableContainer sx={{mt: '15px', mr: '20px', ml: '20px', mb: '15px'}}>
            <Table size='medium'>
              <TableHead sx={{
								backgroundColor: backgroundColor, 
							}}>
                <TableRow>
                  <TableCell>Item</TableCell>
									<TableCell align='center'>Lineplan PDF</TableCell>
                  <TableCell align='center'>Default PDF</TableCell>
                  {machineList.map((machine: any, key: number) => (
                    <TableCell key={key} align='center'>
                      {machine.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {itemList.map((item: any, key: number) => (
                  <TableRow key={key} className={theme.palette.mode === 'dark' ? 'dark' : 'light'}>
                    <TableCell sx={{fontWeight: 'bold'}}>{item.name}</TableCell>
										<TableCell 
											align='center'
											sx={{
												borderRight: '1px solid rgba(128, 128, 128, 0.5)',
											}}
										>
											<LineplanPdfControl
                          create={item[`has_process_instructions`] === false}
                          doCreate={() => addLineplanPdf(item, key)}
                          item={item}
													index={key}
													id={item.name}
                          doView={viewLineplanPdf}
                          doEdit={editLineplanPdf}
                          doDelete={deleteLineplanPdf}
                          permissions={permissionList}
                        />
										</TableCell>
                    <TableCell align='center'>
											{item.type == 'Moldable' ?
												<DefaultPDFControl 
													create={item['has_default_pdf'] === false}
													doCreate={() => addDefaultPdf(item, key)}
													item={item}
													index={key}
													id={item.name}
													doView={viewDefaultPdf}  // setupsheet id
													doEdit={editDefaultPdf}  // setupsheet id
													doDelete={deleteDefaultPdf}  // setupsheet id
													permissions={permissionList}											
												/>
												:
												<div></div>
											}
										</TableCell>
                    {machineList.map((machine: any, key: number) => (
                      <TableCell
												key={key}
												align='center'
											>
												{item.type == 'Moldable' ?
													<SetupsheetControl
														create={item[`${machine.name}_can_create`]}
														doCreate={() => createSetupsheet(item, machine)}
														item={item}
														location={machine}
														id={item[machine.name]}
														doView={viewSetupsheet}  // setupsheet id
														doEdit={editSetupsheet}  // setupsheet id
														doDelete={deleteSetupsheet}  // setupsheet id
														permissions={permissionList}
													/>
													:
													<div></div>
												}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </TableContainer>

        </Grid>
        {bottomLoading ?
          <Box sx={{display: 'flex', marginTop: '20px'}}>
            <CircularProgress sx={{margin: '0 auto 1rem'}} color='inherit' />
          </Box> : <></>}
      </BaseContent>
      <SetupsheetForm id={objectId} open={formOpen} closeForm={closeFormHandler} machineName={selectedMachineName}
                      itemName={selectedItemName} itemDescription={selectedItemDescription} moldName={selectedItemMold}
                      moldLocation={selectedItemMoldLocation} bomItems={selectedItemBOM} ventMaterials={ventMaterialList}
                      dualTimes={selectedMachineDualTimes} timeDiffTemp={timeDiffTemp} tempType={tempType}
                      cycleList={selectedMachineCycleList} hasPdf={setupsheetPdf} />
      {objectId ? <SetupsheetView open={setupsheetViewOpen} close={closeViewHandler} url={url} id={objectId}
                      bomItems={selectedItemBOM} dualTimes={selectedMachineDualTimes} timeDiffTemp={timeDiffTemp}
                      doPrint={printHandler} printLoading={printLoading} firstArticle={selectedItemFirstArticle}
                      hasPdf={setupsheetPdf} />  : <></>}
			

      <ImportModal 
				open={importModalOpen} 
				closeHandler={() => setImportModalOpen(false)} 
				url={url} 
				typeName='xlsx'
				title='Import Setupsheets XLSX' 
				excelImportId='setupsheets' 
			/>

			{/* Lineplan PDF */}
			<ImportModal 	
				open={lineplanUploadOpen} 
				closeHandler={closeLineplanImportModal}
				url={lineplanSetupsheetURL} 
				title={'Upload Lineplan Setupsheet'} 
				typeName='pdf'
				appendData={{
					type: 'lineplan_pdf', 
					item_name: selectedItemName
				}} 
				doCheck 
				maxWidth='sm'
				suppressAlert={false}
			/>
			<LineplanPdfView open={lineplanPdfViewOpen} close={closeLineplanViewHandler} 
											url={url} itemName={selectedItemName} bomItems={[]} printLoading={false}				
			/>
			<DeleteLineplanPdfModal 
				open={deleteLineplanPdfModalOpen}
				onClose={() => { 
					setRefresh(true); setRefresh(false)
					setDeleteLineplanPdfModalOpen(false) 
				}}
				item={selectedItemName}
				url={url} 
			/>
			{/* Lineplan PDF */}


			{/* DEFAULT PDF */}
			<ImportModal 	
				open={defaultSetupsheetUploadOpen} 
				closeHandler={closeDefaultImportModal}
				url={defaultSetupsheetURL} 
				title={'Upload Default Setupsheet'} 
				typeName='pdf'
				appendData={{
					type: 'default_pdf', 
					item_name: selectedItemName
				}} 
				doCheck 
				maxWidth='sm'
				suppressAlert={false}
				additionalInputs={[
					<FormInputSwitch name={'assign_to_related_items'} label={'Add this pdf to all items sharing this mold?'} />
				]}
			/>
			<DefaultPdfView open={defaultPdfViewOpen} close={closeDefaultViewHandler} 
											url={url} itemName={selectedItemName} bomItems={[]} printLoading={false}				
			/>
			<DeleteDefaultPdfModal 
				open={deleteDefaultPdfModalOpen}
				onClose={() => { 
					setRefresh(true); setRefresh(false)
					setDeleteDefaultPdfModalOpen(false) 
				}}
				item={selectedItemName}
				url={url} 
			/>
			{/* DEFAULT PDF */}

			
      <VentMaterialModal open={ventMaterialModalOpen} onClose={closeVentMaterialModal} />
  </>
  )
}

export default Setupsheets
