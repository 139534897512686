import { ResourceStore, ResourceModel } from "@bryntum/scheduler";

export default class RowStore extends ResourceStore {
  static get defaultConfig() {
    return {
      modelClass: ResourceModel,
    };
  }

  constructor(config) {
    super(config);
    this.lastServerData = config.data;
  }

  revertChanges() {
    this.removeAll();
    this.add(this.lastServerData);
  }

  onCommit() {
    console.log("this.allRecords", this.allRecords);
    console.log("this.lastServerData", this.lastServerData);
    this.lastServerData = this.allRecords.map((ord) => ord.data);
    super.commit();
  }

  // onBeforeAdd(event) {
  //   console.log(" onBeforeAdd event", event);
  // }

  // onUpdate(event) {
  //   console.log("onUpdate event", event);
  // }

  // onRemove(event) {
  //   console.log(" onRemove event", event);
  // }
}
