import React, { FC } from 'react'
import moment from 'moment'
import { Controller, useFormContext } from 'react-hook-form'
// mui imports
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { styled } from '@mui/material/styles'
// custom imports
import { DateFormat } from '../../utils/globals'

const StyledDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
  'input': {
    maxHeight: '8px'
  }
}))

interface FormInputDateRangeProps {
  name: string,
  label: any,
  disabled?: boolean | undefined
}
/**
 * DropdownActions comp
 * @param name
 * @param text
 * @param disabled
 * @constructor
 */
const FormInputDateRange: FC<FormInputDateRangeProps> = ({name, label, disabled}) => {

  const { control } = useFormContext()

  return (
    <Controller
      name={(name as never)}
      control={control}
      render={({
        field: {onChange, value},
        fieldState: {error},
      }: any) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledDateRangePicker
            localeText={label}
            onChange={onChange}
            format={DateFormat}
            value={value ? value.map((value: any) => moment(value)) : undefined}
          />
        </LocalizationProvider>
      )}
    />
  )
}

export default FormInputDateRange
