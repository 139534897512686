import React, {FC, useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
// mui icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// mui imports
// custom imports
import BaseAccordion from "../../../../utils/globalComps/BaseAccordion";
import {colors} from "../../../../utils/colors";
import InfoText from "../../../../utils/globalComps/InfoText";
import ApiTable from "../../../../utils/table/ApiTable";
import AssignToLineplanModal from "../../../../utils/modals/AssignToLineplanModal";
import {stageLineplanItem} from "./LinePlanApi";


interface LineplanValidateProps {
  type: number;
  light?: boolean | undefined
}
const LineplanValidate: FC<LineplanValidateProps> = ({type, light}) => {

  const theme: any = useTheme()

  const [data, setData] = useState<any[]>([])
  const [refresh, setRefresh] = useState<boolean>(false)
  const [suppressLoadDisplay, setSuppressLoadDisplay] = useState<boolean>(false)
  const [assignOrderItem, setAssignOrderItem] = useState<any | undefined>(undefined)

  const refreshTable = () => {
    setRefresh(!refresh);
    console.log('refresh')
  }

  useEffect(() => {
    let isActive = true;

    return () => {isActive = false}
  }, [type])

  const accordionChange = (e: any, expanded: boolean) => {
    if (expanded)
      refreshTable();
  }

  // helpers
  const generateNoteText = (type: number) => {
    let linePlanName: string = '';
    let previousLocationStatus: string = '';

    if (type === 1) {
      linePlanName = 'Secondary';
      previousLocationStatus = 'or is prioritized/arm scheduled, or is loaded to a Machine';
    } else if (type === 2) {
      linePlanName = 'Foaming';
      previousLocationStatus = 'or is on a Secondary Line Plan';
    } else if (type === 3) {
      linePlanName = 'Assembly';
      previousLocationStatus = 'or have children on the Secondary/Foaming Line Plan';
    }

    return `Items appear here that are not on the ${linePlanName} Line Plan, but have available count > 0, ${previousLocationStatus}`;
  }
  const getRowColor = (color: string) => colors[theme.palette.mode].table.row[color];
  const generateLegendItems = (type: number) => {
    if (type === 1) {
      return [
        {color: getRowColor('loaded'), text: 'Loaded'},
        {color: getRowColor('in_loadqueue'), text: 'Scheduled'},
        {color: getRowColor('scheduled'), text: 'Available'},
        {color: getRowColor('no_setupsheet'), text: 'Not Loaded & Not Scheduled & Not Available '},
      ];
    } else if (type === 2) {
      return [
        {color: getRowColor('loaded'), text: 'On Secondary & Available'},
        {color: getRowColor('in_loadqueue'), text: 'On Secondary & Not Available'},
        {color: getRowColor('scheduled'), text: 'Not on Secondary & Available'},
        {color: getRowColor('no_setupsheet'), text: 'Not on Secondary & Not Available'},
      ];
    } else if (type === 3) {
      return [
        {color: getRowColor('loaded'), text: 'Child on  Line & Available'},
        {color: getRowColor('in_loadqueue'), text: 'Child on Line & Available = 0'},
        {color: getRowColor('scheduled'), text: 'Child not on Line & Available > 0'},
        {color: getRowColor('no_setupsheet'), text: 'Child not on Line & Available = 0'},
      ];
    } else
      return []
  }
  const openAssignLineplanModal = (id: number) => {
    data.forEach((obj: any) => {
      if (obj.id === id) {
        console.log('openAssignLineplanModal ', `${obj.number} + ${obj.item}`)
        setAssignOrderItem({name: `${obj.number} ${obj.item}`, id: id})
      }
    })
  }
  const assignLineplanModalClose = () => {
    setAssignOrderItem(undefined)
  }
  const assignLineplan = (data: any) => {
    stageLineplanItem(data.order_id, data.lineplan, (data: any) => {
      assignLineplanModalClose()
      if (data.success) {
        setSuppressLoadDisplay(true)
        refreshTable()
      }
    })
  }

  return (
    <BaseAccordion title='Validate' onChange={accordionChange} light={light}>
      <>
        <InfoText text={generateNoteText(type)} />
        <ApiTable
          legend={generateLegendItems(type)}
          doLoad={true}
          objectName=''
          headers={['Order', 'Item', 'Description', 'Balance', 'Available', '']}
          rowFields={['number', 'item', 'description', 'balance', 'available']}
          rowActions={[
            {icon: <AddCircleOutlineIcon />, text: 'Assign To Lineplan', action: openAssignLineplanModal, permission: 'create'}
          ]}
          dataField='objects'
          url={`/manufacturing/lineplan/validate/?type=${type}`}
          searchable
          orderable
          refreshable
          loadMoreButton
          refresh={refresh}
          suppressLoadDisplay={suppressLoadDisplay}
          dataCallback={(data: any[]) => {setData(data)}}
        />
        <AssignToLineplanModal orderItem={assignOrderItem} type={type} doAssign={assignLineplan}
                               onClose={assignLineplanModalClose}  />
      </>
    </BaseAccordion>
  );
}

export default LineplanValidate