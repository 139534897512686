import React, { FC, useState } from 'react'
// mui imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
// custom imports
import { useEffectApi } from '../../globals'
import { getMachineLoggedProgress } from './ProductivityProgressApi'
import ProgressTickedBar from './ProgressTickedBar'
import BaseContent from '../BaseContent'

interface MachineLoggedProgressProps {
  id: number
  shiftId: number
  refresh: boolean | undefined
  extraRefresh: boolean | undefined
}
const MachineLoggedProgress: FC<MachineLoggedProgressProps> = ({id, shiftId, refresh, extraRefresh}) => {

  const [loading, setLoading] = useState<boolean>(false)
  const [loggedIns, setLoggedIns] = useState<string[]>([]) // names of logged in users
  const [ticks, setTicks] = useState<any[]>([]) // progress bar check (arm log) marks
  const [progress, setProgress] = useState<number>(0) // progress bar progress %
  const [loggedArms, setLoggedArms] = useState<number>(0) // # of logged arms for this machine on this shift
  const [skippedArms, setSkippedArms] = useState<number>(0) // # of skippged arms for this machine on this shift
  const [expectedArms, setExpectedArms] = useState<number>(0) // # of arms expected to be processed by current time of shift
  const [targetArms, setTargetArms] = useState<number>(0) // target # of arms that should be logged for this machine on this shift

  useEffectApi(() => {

    if (id) {
      getMachineLoggedProgress(id, shiftId, (data: any) => {
        setTargetArms(data.target)
        setExpectedArms(data.expected)
        setLoggedArms(data.logged)
        setSkippedArms(data.skipped)
        setTicks(data.ticks)
        setProgress(data.progress)
        setLoggedIns(data.logged_ins)
        setLoading(false)
      })
    } else
      setLoading(false)
  }, [id, shiftId, refresh, extraRefresh])

  return (
    <BaseContent loading={loading} sx={{width: '100%'}}>
      <Grid container item>
        <Grid item xs={12}>
          <ProgressTickedBar progress={progress} skipped={skippedArms} ticks={ticks}>
            <Typography variant='subtitle1'>Logged arms / Expected Arms / Shift target arms: <strong>{loggedArms} / {expectedArms} / {targetArms}</strong></Typography>
          </ProgressTickedBar>
        </Grid>
        <Grid item>
          <Typography variant='body1'>Currently logged in: {loggedIns.map((loggedIn: string, key: number) => (
            <strong key={key}>{loggedIn} {key < loggedIns.length-1 ? '| ' : ''}</strong>
          ))}</Typography>
        </Grid>
      </Grid>
    </BaseContent>
  )
}

export default MachineLoggedProgress
