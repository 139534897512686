// custom imports
import React from 'react';
import PageTitle from '../../../../utils/globalComps/PageTitle';
import BaseContent from '../../../../utils/globalComps/BaseContent';
import useProcessingReport from './hooks/useProcessingReport';
import ProductivityTable from './tables/ProcessingReportTable';
import ProductivityReportFilters from './ProcessingReportFilters';

interface ProductivityReportProps { }

const ProcessingReport: React.FC<ProductivityReportProps> = () => {
	document.title = 'Accounting | RotoEdgePro';

	const {
		loading, sendMessage, isReady,
		pageState, processing_report
	} = useProcessingReport();

	const {
		machines, lineplans, shifts,
		parts_produced, production_cost, sales_value
	} = processing_report;

	return (
		<>
			{/* <PageTitle title='Productivity Report' /> */}
			{/* <BaseContent loading={loading}> */}
				<ProductivityReportFilters
					shifts={shifts}
					isReady={isReady}
					sendMessage={sendMessage}
					pageState={pageState}
				/>
				<ProductivityTable title={'Parts Produced'} data={parts_produced} machines={machines} lineplans={lineplans} />
				<ProductivityTable sx={{ marginTop: 3 }} title={'Production Cost'} data={production_cost} machines={machines} lineplans={lineplans} />
				<ProductivityTable sx={{ marginTop: 3 }} title={'Sales Value'} data={sales_value} machines={machines} lineplans={lineplans} />
			{/* </BaseContent> */}
		</>
	);
};

export default ProcessingReport;
