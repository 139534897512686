import { apiGet, apiPost } from '../../api'

/**
 * @param id: order id
 * @param callback
 */
const getScheduleToLineplanData = (id: number, callback: (data: any) => void) => {
  apiGet('/scheduling/lineplan-scheduling/', {orderId: id}, (resp: any) => callback(resp.data))
}

/**
 * @param id: order id
 * @param data: stage data
 * @param confirm: if this is confirming the stage or executing it
 * @param callback
 */
const doScheduleToLineplan = (id: number, data: any, confirm: boolean, callback: (data: any) => void) => {
  apiPost('/scheduling/lineplan-scheduling/', {orderId: id, stageData: data, confirm: confirm},
    (resp: any) => callback(resp.data))
}

export {
  getScheduleToLineplanData, doScheduleToLineplan
}