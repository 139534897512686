import {apiGet} from "../../../../utils/api";

const URL: string = `/communication/scheduling-arm-note/`;

const getMachinePlanningData = (callBack: (data: any) => void) => {
  apiGet(URL, {machinePlanning: true}, (resp) => {
    callBack(resp.data);
  })
}

export {
  getMachinePlanningData,
}