import React, { FC, useState } from 'react'
import {useTheme} from "@mui/material/styles";
// mui imports
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import {TableCell} from "@mui/material";
import TableBody from "@mui/material/TableBody";
// custom imports
import {useEffectApi} from "../../../../utils/globals";
import {clearMrp, getMRPData, MACHINE} from "./MrpApi";
import BaseContent from "../../../../utils/globalComps/BaseContent";
import {colors} from "../../../../utils/colors";
import '../../../../utils/table/rowHighlight.css'
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {doConfirm} from "../../../../utils/modals/Confirm";


interface MachineTableProps {
  id: number
  name: string
  canEdit: boolean
  refresh: boolean
}
/**
 * Machine MRP table
 * @param id machine id
 * @param name machine name
 * @param refresh external refresh
 * @constructor
 */
const MachineTable: FC<MachineTableProps> = ({id, name, canEdit, refresh}) => {

  const theme: any = useTheme()

  const [internalRefresh, setInternalRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [shifts, setShifts] = useState<any[]>([])
  const [items, setItems] = useState<any[]>([])
  const [populated, setPopulated] = useState<boolean>(false)
  const [populatedAt, setPopulatedAt] = useState<string>('')

  const doRefresh = () => setInternalRefresh(!internalRefresh)

  useEffectApi(() => {
    getMRPData(id, MACHINE, (data: any) => {
      setItems(data.items)
      setShifts(data.shifts)
      setPopulated(data.populated)
      setPopulatedAt(data.populated_at)
      setLoading(false)
    })
  }, [id, internalRefresh, refresh])

  // actions
  const doClearMrp = () => {
    doConfirm(`Clear MRP for ${name}?`, () => clearMrp(id, MACHINE, () => doRefresh()))
  }

  return (
    <BaseContent loading={loading}>
      {!populated ?
        <Typography sx={{mr: '25px', pt: '5px', pb: '5px'}} component={Paper}>No Data</Typography>
        :
        <>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow sx={{backgroundColor: colors[theme.palette.mode].table.header}}>
                  <TableCell align='center' sx={{borderRadius: '10px 0 0 0 !important'}}>Powder Item</TableCell>
                  <TableCell align='center'>Description</TableCell>
                  {shifts.map((s: any, key: number) => (
                    <TableCell align='center' key={key}>
                      {s.name} Quantity
                    </TableCell>
                  ))}
                  <TableCell align='center' sx={{borderRadius: '0 10px 0 0 !important'}}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.filter((obj: any) => obj.type === 'powder').map((obj: any, key: number) => (
                  <TableRow key={key} className={theme.palette.mode}>
                    <TableCell align='center'>{obj.item}</TableCell>
                    <TableCell align='center'>{obj.description}</TableCell>
                    {shifts.map((s: any, key: number) => (
                      <TableCell align='center' key={key}>
                        {obj.shift_quantities[s.number] ? obj.shift_quantities[s.number] : 0}
                      </TableCell>
                    ))}
                    <TableCell align='center'>{obj.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow sx={{backgroundColor: colors[theme.palette.mode].table.header}}>
                  <TableCell align='center'>Order</TableCell>
                  <TableCell align='center'>Parent Item</TableCell>
                  <TableCell align='center'>Powder Item</TableCell>
                  <TableCell align='center'>Description</TableCell>
                  <TableCell align='center'>Quantity</TableCell>
                  <TableCell align='center'>Weight</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.filter((obj: any) => obj.type === 'parent-powder').map((obj: any, key: number) => (
                  <TableRow key={key} className={theme.palette.mode}>
                    <TableCell align='center'>{obj.order}</TableCell>
                    <TableCell align='center'>{obj.parent}</TableCell>
                    <TableCell align='center'>{obj.item}</TableCell>
                    <TableCell align='center'>{obj.description}</TableCell>
                    <TableCell align='center'>{obj.quantity}</TableCell>
                    <TableCell align='center'>{obj.weight}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow sx={{backgroundColor: colors[theme.palette.mode].table.header}}>
                  <TableCell align='center'>Component Item</TableCell>
                  <TableCell align='center'>Description</TableCell>
                  <TableCell align='center'>Bin Location</TableCell>
                  {shifts.map((s: any, key: number) => (
                    <TableCell align='center' key={key}>{s.name} Quantity</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.filter((obj: any) => obj.type === 'component').map((obj: any, key: number) => (
                  <TableRow key={key} className={theme.palette.mode}>
                    <TableCell align='center'>{obj.item}</TableCell>
                    <TableCell align='center'>{obj.description}</TableCell>
                    <TableCell align='center'>{obj.location}</TableCell>
                    {shifts.map((s: any, key: number) => (
                      <TableCell align='center' key={key}>
                        {obj.shift_quantities[s.number] ? obj.shift_quantities[s.number] : 0}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justifyContent='left' sx={{mt: '15px'}}>
            <Typography>Populated at: <strong>{populatedAt}</strong></Typography>
          </Grid>
          {canEdit ?
            <Grid container justifyContent='center'>
              <Button variant='contained' sx={{mr: '25px'}} onClick={doClearMrp}>Clear</Button>
            </Grid>
            :
            <></>
          }
        </>
      }
    </BaseContent>
  )
}

export default MachineTable
