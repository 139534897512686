import React, {FC, useState} from 'react'
import {AxiosResponse} from 'axios'
// mui icon imports
import Add from '@mui/icons-material/Add'
// mui imports
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
// custom imports
import { FormSpacing, useEffectApi } from '../../../../utils/globals'
import { apiGet, apiPost } from '../../../../utils/api'
import { colors } from '../../../../utils/colors'
import { useTheme } from '@mui/material/styles'
import FormInputText from '../../../../utils/form/FormInputText'
import ApiForm from '../../../../utils/form/ApiForm'
import FormInputAutocomplete from '../../../../utils/form/FormInputAutocomplete'
import FormInputSwitch from '../../../../utils/form/FormInputSwitch'
import FormInputDate from '../../../../utils/form/FormInputDate'
import FormInputNumber from '../../../../utils/form/FormInputNumber'
import ApiTable from '../../../../utils/table/ApiTable'
import ChildOrderForm from "./ChildOrderForm";

interface OrderFormProps {
  id: number | undefined
  open: boolean
  itemList: any[]
  closeForm: (submit: boolean) => void
}


const OrderForm: FC<OrderFormProps> = ({id, open, itemList, closeForm}) => {
  const theme = useTheme()

  const [parentOrderList, setParentOrderList] = useState<any[]>([])
  const [parentOrderQuantity, setParentOrderQuantity] = useState<number>(0)
  const [isAssemblyItem, setIsAssemblyItem] = useState<boolean>(true)
  const [childOrderDefaults, setChildOrderDefaults] = useState<any | undefined>(undefined)
  const [refreshTable, setRefreshTable] = useState<boolean>(false)

  const fetchUrl: string = id ? `/orders/orders/${id}/` : ''
  const submitUrl: string = '/orders/orders/'

  useEffectApi(() => {

    if (id && fetchUrl !== '') {
      apiGet(fetchUrl, {getOptions: true}, (resp: AxiosResponse) => {
        setParentOrderQuantity(resp.data.parentOrderQuantity)
        setParentOrderList(resp.data.parentOrderList)
        setIsAssemblyItem(resp.data.isAssembly)
      })
    }
  }, [id, fetchUrl])

  //
  const removeItemDescriptionFromName = (data: any) => {
    data.item.title = data.item.title.split(' ')[0]
    return data
  }

  // child order helpers
  const openChildOrderForm = (obj: any) => {
    setChildOrderDefaults({
      childBomId: obj.id,
      number: '',
      item: obj.child_item,
      description: obj.child_description,
      type: obj.child_item_type_name,
      quantity: obj.quantity
    })
  }
  const closeChildOrderForm = () => {
    setChildOrderDefaults(undefined)
  }
  const createChildOrder = (data: any) => {
    apiPost(fetchUrl, {createChildOrder: id, childBomId: data.id, ...data}, (resp: AxiosResponse) => {
      closeChildOrderForm()
      setRefreshTable(!refreshTable)
    })
  }

  return (
    <>
      <ApiForm
        name='Order Form'
        open={open}
        defaultValues={{due_date: new Date(), ship_date: new Date(), open_date: new Date()}}
        closeForm={closeForm}
        id={id}
        fetchUrl={fetchUrl}
        submitUrl={submitUrl}
        editFlag
        submitCallback={removeItemDescriptionFromName}
      >
        <Grid container spacing={FormSpacing}>
          <Grid item xs={6}><FormInputText name='full_number' label='Order Number' /></Grid>
					<Grid item xs={6}><FormInputAutocomplete name='parent' label='Parent Order' options={parentOrderList} disabled={isAssemblyItem} /></Grid>
          <Grid item xs={6}><FormInputAutocomplete name='item' label='Item' options={itemList} /></Grid>
          <Grid item xs={6}><FormInputNumber name='quantity' label='Quantity' /></Grid>
					<Grid item xs={6}><FormInputDate name='due_date' label='Due Date' placeholder='Due Date' /></Grid>
					<Grid item xs={6}><FormInputDate name='ship_date' label='Ship Date' placeholder='Ship Date' /></Grid>
          {/* <Grid item xs={4}><FormInputSwitch name='needs_post_molding' label='Needs Post Molding' helpText='Order Needs post molding' /></Grid> */}
          {/* <Grid item xs={4}><FormInputSwitch name='bom_pulled' label='BOM Pulled' helpText='BOM Pulled in Assembly' /></Grid> */}

          {/* <Grid container item xs={6} justifyContent='center'>
            

          </Grid>
          <Grid container item xs={6} justifyContent='center'>
            
          </Grid> */}
          {/* <Grid item xs={12}><Divider variant='middle' /></Grid> */}

          {/* <Grid item xs={3}><FormInputSwitch name='open' label='Open' helpText='Order open/closed status' disabled /></Grid>
          <Grid item xs={3}><FormInputSwitch name='service_synced' label='Service Synced' helpText='Order synced by accounting service' disabled /></Grid>
          <Grid item xs={3}><FormInputSwitch name='excel_synced' label='Excel Synced' helpText='Order synced by Excel' disabled /></Grid>
          <Grid item xs={3}><FormInputSwitch name='assembly_child_synced' label='Assembly Synced' helpText='Order synced by Assembly Child Sync action' disabled /></Grid> */}

          {/* <Grid container item xs={12} justifyContent='center'>
            <Grid item><FormInputDate name='open_date' label='Open Date' placeholder='Open Date' disabled /></Grid>
          </Grid> */}
          
					{fetchUrl !== '' && isAssemblyItem ?
						<Grid xs={12} sx={{paddingTop: 2}}>
							<Grid item xs={12}><Divider variant='middle' /></Grid>
							<Grid item xs={12} sx={{padding: 1}}>
								<ApiTable
									refresh={refreshTable}
									legend={[
										{color: colors[theme.palette.mode].table.row.secondary, text: 'Has Order'},
										{color: colors[theme.palette.mode].table.row.no_setupsheet, text: 'No Order'},
									]}
									tableName=''
									objectName='Order Bom'
									rowActions={[
										{icon: <Add />, text: 'Create Order', actionType: 'object', action: openChildOrderForm,
											autoFocus: true, condition: 'hasOrder', conditionValue: false}
									]}
									headers={['Order', 'Item', 'Description', 'Type', 'Quantity', '']}
									rowFields={['order', 'child_item', 'child_description', 'child_item_type_name', 'quantity']}
									dataField='objects'
									url={fetchUrl}
									hidePages
									extraParams={{getChildOrderInfo: true}}
								/>
							</Grid>
						</Grid>			
						: <></>}
        </Grid>
      </ApiForm>

      <ChildOrderForm orderDefaults={childOrderDefaults} onSubmit={createChildOrder} onClose={closeChildOrderForm} />
    </>
  )
}

export default OrderForm
