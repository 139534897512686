import React, { useState } from 'react';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, SxProps
} from '@mui/material';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';

interface RowData {
	[key: string]: number | string;
}

interface ProductivityTableProps {
	title: string;
	data: {
		[key: string]: RowData;
	};
	machines: string[];
	lineplans: string[];
	sx?: SxProps;
}

const ProductivityTable: React.FC<ProductivityTableProps> = ({ title, data, machines, lineplans, sx }) => {
	const [expandedColumns, setExpandedColumns] = useState<string[]>([]);

	const handleExpand = (column: string) => {
		if (expandedColumns.includes(column)) {
			setExpandedColumns(expandedColumns.filter(col => col !== column));
		} else {
			setExpandedColumns([...expandedColumns, column]);
		}
	};

	const renderSubColumns = (subColumns: string[], row: RowData) => {
		return subColumns.map((subCol, index) => (
			<TableCell
				key={subCol}
				align="center"
				sx={{
					borderRight: index === subColumns.length - 1
						? '1px solid rgba(128, 128, 128, 0.2)'
						: 'none'
				}}
			>
				{row[subCol]}
			</TableCell>
		));
	};

	const rows = Object.entries(data);

	const getCategory = (category: string) => {
		switch (category) {
			case 'conforming':
				return 'Conforming';
			case 'non_conforming':
				return 'Non Conforming';
			case 'non_conforming_percent':
				return 'Non Conforming %';
			default:
				return category;
		}
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden', ...sx }}>
			<TableContainer sx={{ maxWidth: '100%', overflowX: 'auto' }}>
				<Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650, maxWidth: '100%' }}>
					<TableHead>
						<TableRow>
							<TableCell align="left" sx={{ width: '190px', paddingLeft: '16px', fontWeight: 'bold', fontSize: 20 }}>{title}</TableCell>
							<TableCell align="center" colSpan={expandedColumns.includes('machines') ? machines.length + 2 : 1}>
								<Button onClick={() => handleExpand('machines')} sx={{ width: '100%' }}>
									<Box display="flex" alignItems="center" justifyContent="center">
										Machines {expandedColumns.includes('machines') ? <ChevronLeft /> : <ChevronRight />}
									</Box>
								</Button>
							</TableCell>
							{expandedColumns.includes('machines') && (
								<>
									{machines.map((machine, index) => (
										<TableCell key={machine} align="center">{machine}</TableCell>
									))}
									<TableCell align="center">Supv</TableCell>
								</>
							)}
							<TableCell align="center" colSpan={expandedColumns.includes('lineplans') ? lineplans.length + 1 : 1}>
								<Button onClick={() => handleExpand('lineplans')} sx={{ width: '100%' }}>
									<Box display="flex" alignItems="center" justifyContent="center">
										Lineplans {expandedColumns.includes('lineplans') ? <ChevronLeft /> : <ChevronRight />}
									</Box>
								</Button>
							</TableCell>
							{expandedColumns.includes('lineplans') && (
								lineplans.map((plan, index) => (
									<TableCell key={plan} align="center">{plan}</TableCell>
								))
							)}
							<TableCell align="center" sx={{ minWidth: '160px' }}>Admin Molding</TableCell>
							<TableCell align="center" sx={{ minWidth: '100px' }}>Total Molding</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map(([category, row], rowIndex) => (
							<TableRow key={rowIndex} sx={{ backgroundColor: rowIndex % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'transparent' }}>
								<TableCell align="left" sx={{ minWidth: '160px', fontWeight: 'bold', paddingLeft: '16px' }}>{getCategory(category)}</TableCell>
								<TableCell align="center" colSpan={expandedColumns.includes('machines') ? machines.length + 2 : 1} sx={{ borderRight: expandedColumns.includes('machines') ? '1px solid rgba(128, 128, 128, 0.2)' : 'none' }}>{row.machines}</TableCell>
								{expandedColumns.includes('machines') && (
									<>
										{renderSubColumns(machines, row)}
										<TableCell align="center" sx={{ borderRight: '1px solid rgba(128, 128, 128, 0.2)' }}>{row['machine_supervisor']}</TableCell>
									</>
								)}
								<TableCell align="center" colSpan={expandedColumns.includes('lineplans') ? lineplans.length + 1 : 1} sx={{ borderRight: expandedColumns.includes('lineplans') ? '1px solid rgba(128, 128, 128, 0.2)' : 'none' }}>{row.lineplans}</TableCell>
								{expandedColumns.includes('lineplans') && renderSubColumns(lineplans, row)}
								<TableCell align="center" sx={{ minWidth: '160px' }}>{row.admin}</TableCell>
								<TableCell align="center" sx={{ minWidth: '100px' }}>{row.total}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default ProductivityTable;
