import React, { FC, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// mui imports
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// custom imports
import FormInputNumber from './FormInputNumber';
import FormInputSwitch from './FormInputSwitch';
import FormInputDate from './FormInputDate';
import BaseTooltip from "../globalComps/BaseTooltip";
import FormInputDropdown from "./FormInputDropdown";


interface ApiFilterFormProps {
  filterMethods: any[];
  runFilter: (filterValues: any) => void;
  doResetFilter?: () => void;
}
const ApiFilterForm: FC<ApiFilterFormProps> = ({filterMethods, runFilter, doResetFilter}) => {
  const [rowXs, setRowXs] = useState<number>(3);

  useEffect(() => {
    setRowXs(Math.round(12 / filterMethods.length))

    // generate default values
    let defaults: any = {};
    for (const filterMethod of filterMethods)
      if (filterMethod.value)
        defaults[filterMethod.name] = filterMethod.value;
    reset(defaults);
  }, [filterMethods])

  // setup form
  const methods = useForm({defaultValues: {}});
  const { handleSubmit, reset } = methods;

  // form actions
  const onFilter = (data: any) => runFilter(data);
  const resetFilter = () => {
    reset({});
    if (doResetFilter) doResetFilter();
  }

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        {/* Date type filter fields */}
        {filterMethods.filter((fm: any) => fm.type.includes('date')).map((dFm: any, key: any) => (
          <BaseTooltip placement='top' text={dFm.tooltip} key={key}>
            <Grid item xs={dFm.xs ? dFm.xs : rowXs}>
              <FormInputDate name={dFm.name} label={dFm.label} placeholder={dFm.placeholder} />
            </Grid>
          </BaseTooltip>
        ))}
        {/* Number type filter fields */}
        {filterMethods.filter((fm: any) => fm.type.includes('number')).map((nFm: any, key: any) => (
          <BaseTooltip placement='top' text={nFm.tooltip} key={key}>
            <Grid item xs={nFm.xs ? nFm.xs : rowXs}>
              <FormInputNumber name={nFm.name} label={nFm.label} />
            </Grid>
          </BaseTooltip>
        ))}
        {/* Boolean type filter fields */}
        {filterMethods.filter((fm: any) => fm.type === 'boolean').map((bFm: any, key: any) => (
          <BaseTooltip text={bFm.tooltip} key={key}>
            <Grid item xs={bFm.xs ? bFm.xs : rowXs}>
              <FormInputSwitch name={bFm.name} label={bFm.label} />
            </Grid>
          </BaseTooltip>
        ))}
        {/* Select type filter fields */}
        {filterMethods.filter((fm: any) => fm.type === 'select').map((sFm: any, key: any) => (
          <BaseTooltip placement='top' text={sFm.tooltip} key={key}>
            <Grid item xs={sFm.xs ? sFm.xs : rowXs}>
              <FormInputDropdown name={sFm.name} label={sFm.label} options={sFm.options} multiple={sFm.multiple} />
            </Grid>
          </BaseTooltip>
        ))}
      </Grid>
      <Grid container spacing={2} sx={{mt: '5px'}}>
        <Grid item xs={6}>
          <BaseTooltip text='Run filter on table data'>
            <Button variant='contained' onClick={handleSubmit(onFilter)}>Run Filter</Button>
          </BaseTooltip>
        </Grid>
        <Grid item xs={6} display='flex' justifyContent='flex-end'>
          <BaseTooltip text='Reset filter values'>
            <Button variant='outlined' onClick={resetFilter}>Reset Filter</Button>
          </BaseTooltip>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ApiFilterForm;
