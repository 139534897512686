import React, {FC, useState} from "react";
import {useTheme} from "@mui/material/styles";
// mui imports
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
// icon imports
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
// custom imports
import {useEffectApi} from "../../../../utils/globals";
import BaseContent from "../../../../utils/globalComps/BaseContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {doConfirm} from "../../../../utils/modals/Confirm";
import {ASSEMBLY, clearMrp, getMRPData, populateMrp} from "./MrpApi";
import '../../../../utils/table/rowHighlight.css'
import {colors} from "../../../../utils/colors";
import AssemblyExpandRow from "./AssemblyExpandRow";


interface AssemblyTableProps {
  id: number
  name: string
  canEdit: boolean
}
const AssemblyTable: FC<AssemblyTableProps> = ({id, name, canEdit}) => {

  const theme: any = useTheme()

  const [loading, setLoading] = useState<boolean>(true)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const [subData, setSubData] = useState<any[]>([])
  const [populated, setPopulated] = useState<boolean>(false)
  const [populatedAt, setPopulatedAt] = useState<string>('')

  const doRefresh = () => setRefresh(!refresh)

  useEffectApi(() => {
    getMRPData(id, ASSEMBLY, (data: any) => {
      console.log('get mrp data :', data)
      setData(data.objects.map((object: any) => ({...object, open: false})))
      setSubData(data.sub_objects)
      setPopulated(data.populated_at !== '')
      setPopulatedAt(data.populated_at)
      setLoading(false)
    })
  }, [refresh])

  const doPopulate = () => {
    doConfirm(`Populate ${name} MRP?`, () => populateMrp(id, ASSEMBLY, {}, () => {
      doRefresh()
    }))
  }
  const doClear = () => {
    doConfirm(`Clear ${name} MRP?`, () => clearMrp(id, ASSEMBLY, () => {
      doRefresh()
    }))
  }

  const toggleExpand = (id: number) => {
    setData([...data.map((object: any) => {
      if (object.id === id)
        object.open = !object.open
      return object
    })])
  }

  return (
    <BaseContent loading={loading}>
      {!populated ?
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{mr: '25px', pt: '5px', pb: '5px', textAlign: 'center'}} component={Paper}>No Data</Typography>
          </Grid>
          {canEdit ?
            <Grid item xs={12} sx={{mr: '25px', textAlign: 'center'}}>
              <Button variant='contained' onClick={doPopulate}>Populate</Button>
            </Grid>
            : <></>
          }
        </Grid>
        :
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow sx={{backgroundColor: colors[theme.palette.mode].table.header}}>
                    <TableCell sx={{width: '5%'}} />
                    <TableCell align='center'>Item</TableCell>
                    <TableCell align='center'>Description</TableCell>
                    <TableCell align='center'>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((object: any, key: number) => (
                    <React.Fragment key={key}>
                      <TableRow className={theme.palette.mode}>
                        <TableCell>
                          <IconButton onClick={() => toggleExpand(object.id)}>
                            {object.open ? <KeyboardArrowUp sx={{fontSize: 15}} /> : <KeyboardArrowDown sx={{fontSize: 15}} />}
                          </IconButton>
                        </TableCell>
                        <TableCell align='center'>{object.item}</TableCell>
                        <TableCell align='center'>{object.description}</TableCell>
                        <TableCell align='center'>{object.quantity}</TableCell>
                      </TableRow>
                      <AssemblyExpandRow id={object.id} open={object.open}
                                         objects={subData.filter((subObj: any) => subObj.parent_id === object.id)} />
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item container justifyContent='left'>
            <Typography>Populated at: <strong>{populatedAt}</strong></Typography>
          </Grid>
          {canEdit ?
            <Grid item xs={12} sx={{textAlign: 'center', mr: '25px'}}>
              <Button variant='contained' onClick={doClear}>Clear</Button>
            </Grid>
            : <></>
          }
        </Grid>
      }
    </BaseContent>
  )
}

export default AssemblyTable
