const powderLabel = '<?xml version="1.0" encoding="utf-8"?>\
<DieCutLabel Version="8.0" Units="twips" MediaType="Default">\
    <PaperOrientation>Landscape</PaperOrientation>\
    <Id>LargeShipping</Id>\
    <IsOutlined>false</IsOutlined>\
    <PaperName>30256 Shipping</PaperName>\
    <DrawCommands>\
        <RoundRectangle X="0" Y="0" Width="3331" Height="5715" Rx="270" Ry="270" />\
    </DrawCommands>\
    <ObjectInfo>\
        <DateTimeObject>\
            <Name>DATE-TIME</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <DateTimeFormat>MonthDayYear</DateTimeFormat>\
            <Font Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
            <PreText />\
            <PostText />\
            <IncludeTime>True</IncludeTime>\
            <Use24HourFormat>False</Use24HourFormat>\
        </DateTimeObject>\
        <Bounds X="3915.00719424461" Y="3048.21709691071" Width="1653.11045281422" Height="200.782903089293" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>order</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">001234567</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="28" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="158.823529411764" Width="2915.29411764706" Height="748.235294117647" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>item</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">Item: 00-00000-00</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="876.79221328819" Width="1990.58823529412" Height="275.294117647059" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>powderDescription</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">Part description: This is a long item description, a very long description</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="10" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="1490.80829454084" Width="5338" Height="300" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>powderItem</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">Part: 00-00000</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="12" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="1175" Width="2500" Height="300" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>powderQuantity</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">SW: 0 Lbs</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="36" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="1912.09691070674" Width="4179.1790097334" Height="893.271265340669" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>machine</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">FSP-WA</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="3074.61151079137" Width="700" Height="174.388489208629" />\
    </ObjectInfo>\
</DieCutLabel>'

export default powderLabel