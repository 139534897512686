import React, { useState } from 'react';
import PageTitle from '../../../../utils/globalComps/PageTitle';
import BaseContent from '../../../../utils/globalComps/BaseContent';
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion';
import useStatistics from './hooks/useStatistics';
import OverallMachineStatistics from './tables/OverallMachineStatisticsTable';
import ArmLogBars from './ArmLogBars';
import OrdersProcessed from './tables/OrdersProccessedTable';
import { Grid, Typography, Button, Box, Tooltip } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MachineState, ProcessedOrder } from './hooks/Interfaces';

const Statistics = ({ }) => {
	document.title = 'Production Statistics | RotoEdgePro';
	const { loading, sendMessage, pageState, statistics } = useStatistics();
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	const handleDateChange = (date: Date | null) => {
		const dateString = date ? date.toISOString() : null;
		pageState.date = dateString;
		setSelectedDate(date);
		sendMessage('change_date', { date: dateString }, true);
	}

	const onAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean, machine: string) => {
		const machineState = pageState[machine];
		if (machineState && typeof machineState !== 'string') {
			(machineState as MachineState).active = isExpanded;
			sendMessage('update_page_state', { page_state: pageState });
		}
	};

	const handleExport = () => {
		const wb = XLSX.utils.book_new();

		const combinedOrders = combineProcessedOrders(statistics);
		const ordersArray = Object.values(combinedOrders);

		if (ordersArray.length > 0) {
			const ws = XLSX.utils.json_to_sheet(ordersArray, {
				header: [
					'order', 'item', 'ship_date', 'balance',
					'conforming_parts', 'non_conforming_parts', 'skipped_count'
				]
			});
			XLSX.utils.sheet_add_aoa(ws, [[
				'Order', 'Item', 'Ship Date', 'Balance',
				'Conforming Parts', 'Non Conforming Parts', 'Skipped Count'
			]], { origin: 'A1' });
			XLSX.utils.book_append_sheet(wb, ws, 'Combined Orders');
		}

		const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : 'no_date';
		const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `Processed_Orders_${formattedDate}.xlsx`);
	};

	const combineProcessedOrders = (statistics: any) => {
		let processedOrders: { [key: string]: ProcessedOrder } = {};
		console.log(Object.keys(statistics))
		Object.keys(statistics).forEach(machine => {
			if (!statistics[machine].processed_orders) return;
			const orders = statistics[machine].processed_orders;
			orders.forEach((order: ProcessedOrder) => {
				let orderNum = order.order;
				if (orderNum in processedOrders) {
					processedOrders[orderNum].conforming_parts += order.conforming_parts;
					processedOrders[orderNum].non_conforming_parts += order.non_conforming_parts;
					processedOrders[orderNum].skipped_count += order.skipped_count;
				} else {
					processedOrders[orderNum] = { ...order };
				}
			});
		});

		return processedOrders;
	};

	return (
		<>
			<PageTitle title='Production Statistics' />
			<BaseContent loading={loading}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label="Select Date"
							value={selectedDate}
							onChange={(date) => handleDateChange(date)}
						/>
					</LocalizationProvider>
					<Tooltip title='A date must be selected to export processed orders. Only machines that have been opened will be included in the export.'>
						<span>
							<Button
								variant="contained"
								color="primary"
								onClick={handleExport}
								disabled={!selectedDate}
								sx={{ height: 50 }}
							>
								Export Orders Processed
							</Button>
						</span>
					</Tooltip>
				</Box>
				<div style={{ marginTop: '20px' }}>
					{Object.keys(statistics).map((machine: any, key: number) => (
						<BaseAccordion title={machine} key={key} onChange={(event, isExpanded) => onAccordionChange(event, isExpanded, machine)}>
							<Grid>
								<Grid>
									<ArmLogBars armLogData={statistics[machine].arm_log_bars} />
								</Grid>
								<Grid>
									<Typography variant='h6' paddingLeft={1}>Overall</Typography>
									<OverallMachineStatistics machineData={statistics[machine].overall} />
								</Grid>
								<Grid sx={{ marginTop: 3 }}>
									<OrdersProcessed orderData={statistics[machine].processed_orders} />
								</Grid>
							</Grid>
						</BaseAccordion>
					))}
				</div>
			</BaseContent>
		</>
	);
};

export default Statistics;
