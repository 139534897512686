import React, { FC, useState, useEffect } from 'react'
// mui imports
// custom imports
import { getLineplanSchedulingData } from './LineplanSchedulingApi'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion'
import LineplanSchedulingTable from './LineplanSchedulingTable'
import LineplanLiveTable from '../../../../utils/globalComps/LineplanLiveTable/LineplanLiveTable'
import LineplanValidate from '../../services/LinePlan/LineplanValidate'
import {useEffectApi} from "../../../../utils/globals";

interface LineplanSchedulingProps {}
const LineplanScheduling: FC<LineplanSchedulingProps> = ({}) => {


  const [loading, setLoading] = useState<boolean>(false)
  const [lineplanTypes, setLineplanTypes] = useState<any[]>([])
  const [lineplans, setLineplans] = useState<any[]>([])
  const [permissions, setPermissions] = useState<any>(undefined)

  useEffectApi(() => {

    document.title = 'Lineplan Scheduling | RotoEdgePro'

    getLineplanSchedulingData(undefined, (data: any) => {
      setLineplanTypes(data.linePlanTypes)
      setLineplans(data.linePlans.map((lineplan: any) => ({...lineplan, refreshLive: false, refreshStaging: false, doLoadLive: false})))
      setPermissions(data.permissions)
      setLoading(false)
    })
  }, [])

  // helpers
  const refreshLineplan = (id: number, staging: boolean) => {
    setLineplans([...lineplans].map((lineplan: any) => {
      if (lineplan.id === id)
        lineplan[staging ? 'refreshStaging' : 'refreshLive'] = !lineplan[staging ? 'refreshStaging' : 'refreshLive']
      return lineplan
    }))
  }
  const setLineplanDoLoad = (id: number, live: boolean, doLoad: boolean) => {
    setLineplans([...lineplans].map((lineplan: any) => {
      if (lineplan.id === id)
        if (live)
          lineplan.doLoadLive = doLoad
        else
          lineplan.doLoadStaging = doLoad

      return lineplan
    }))
  }

  // dropdown actions
  const typeCollapse = (expanded: boolean) => {
    console.log('type collapse expanded :', expanded)
  }
  const lineplanStagingCollapse = (lpid: number, expanded: boolean) => {
    if (expanded) {
      refreshLineplan(lpid, true)
      setLineplanDoLoad(lpid, false, true)
    } else
      setLineplanDoLoad(lpid, false, false)
  }
  const lineplanLiveCollapse = (lpid: number, expanded: boolean) => {
    if (expanded) {
      setLineplanDoLoad(lpid, true, true)
      refreshLineplan(lpid, false)
    } else
      setLineplanDoLoad(lpid, true, false)
  }
  // refresh live line on post to live
  const postToLiveCallback = (lpid: number) => {
    refreshLineplan(lpid, false)
  }

  return (
    <>
      <PageTitle title='Lineplan Scheduling' />
      <BaseContent loading={loading}>
        {lineplanTypes.map((lineplanType: any, key: number) => (
          <BaseAccordion title={lineplanType.name} onChange={(e, expanded: boolean) => typeCollapse(expanded)}
                         key={key}>
            <>
              {lineplans.filter((lineplan: any) => lineplan.type === lineplanType.type).map((lineplan: any, key: number) => (
                <BaseAccordion title={lineplan.name} key={key} light
                               onChange={(e, expanded: boolean) => lineplanStagingCollapse(lineplan.id, expanded)}>

                  <BaseAccordion title='Live Line' onChange={(e, expanded: boolean) => lineplanLiveCollapse(lineplan.id, expanded)}>
                    <LineplanLiveTable id={lineplan.id} refresh={lineplan.refreshLive} doLoad={lineplan.doLoadLive} />
                  </BaseAccordion>

                  <LineplanSchedulingTable type={lineplan.type} id={lineplan.id} postToLiveCallback={postToLiveCallback} doLoad={lineplan.doLoadStaging} />

                </BaseAccordion>
              ))}

              {permissions?.edit ?
                <LineplanValidate type={lineplanType.type} light />
                :
                <></>
              }

            </>
          </BaseAccordion>
        ))}
      </BaseContent>
    </>
  )
}

export default LineplanScheduling