import React, { FC } from 'react'
// mui imports
import Grid from '@mui/material/Grid'
// custom imports
import { FormSpacing } from '../../../../utils/globals'
import ApiForm from '../../../../utils/form/ApiForm'
import FormInputDropdown from '../../../../utils/form/FormInputDropdown'
import FormInputText from '../../../../utils/form/FormInputText'
import FormInputTime from '../../../../utils/form/FormInputTime'
import FormInputNumber from '../../../../utils/form/FormInputNumber'
import FormInputSwitch from '../../../../utils/form/FormInputSwitch'

interface LinePlanFormProps {
  id: number | undefined,
  open: boolean
  closeForm: (submit: boolean, data: any, create: boolean, created_id: number | undefined) => void
  factories: any[]
  types: any[]
  type: any
  shifts: any[]
}
const LinePlanForm: FC<LinePlanFormProps> = ({id, open, closeForm, factories, types,
                                               shifts, type}) => {

  const fetchUrl: string = id ? `/manufacturing/lineplan/${id}/` : ''
  const submitUrl: string = '/manufacturing/lineplan/'

  return (
    <ApiForm
      name='Line Plan Form'
      open={open}
      defaultValues={{type: type}}
      closeForm={closeForm}
      id={id}
      fetchUrl={fetchUrl}
      submitUrl={submitUrl}
      timeFields={['post_to_live_time']}
      popFields={['manual_break_point_place']}
    >
      <Grid container spacing={FormSpacing}>
        <Grid item md={5}><FormInputText name='name' label='Name' /></Grid>
        <Grid item md={2}><FormInputText name='code' label='Code' /></Grid>
        <Grid item md={5}><FormInputDropdown name='factory_id' label='Factory' options={factories}/></Grid>
        <Grid item md={4}><FormInputDropdown name='type' label='Type' options={types} disabled /></Grid>
        <Grid item md={4}><FormInputDropdown name='shift_id' label='Shift' options={shifts}/></Grid>
        <Grid item md={4}><FormInputTime name='post_to_live_time' label='Post To Live' /></Grid>
        <Grid item md={3}><FormInputNumber name='expected_takt_per_shift' label='Expected Takt per Shift' /></Grid>
        <Grid item md={3}><FormInputNumber name='worker_count' label='Worker Count' /></Grid>
        <Grid item md={3}><FormInputNumber name='tiks_per_shift' label='Tiks Per Shift' /></Grid>
        <Grid item md={3}><FormInputSwitch name='auto_post_to_live' label='Auto Post to Live' /></Grid>
      </Grid>
    </ApiForm>
  )
}

export default LinePlanForm
