import React, {FC, useEffect} from "react";
import {useTheme} from "@mui/material/styles";
// mui imports
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
// custom imports
import '../../../../utils/table/rowHighlight.css'
import BaseContent from "../../../../utils/globalComps/BaseContent";
import {colors} from "../../../../utils/colors";


interface AssemblyExpandRowProps {
  id: number
  open: boolean
  objects: any[]
}
/**
 * @param id: assembly mrp item id
 * @param open
 * @param objects
 */
const AssemblyExpandRow: FC<AssemblyExpandRowProps> = ({id, open, objects}) => {

  const theme: any = useTheme()

  useEffect(() => {}, [id, open])

  return (
    <TableRow>
      <TableCell colSpan={4} sx={{borderBottom: '0 !important', padding: 0}}>
        <Collapse in={open} timeout='auto' unmountOnExit>

          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead sx={{backgroundColor: colors[theme.palette.mode].table.header}}>
                <TableRow>
                  <TableCell align='center'>Component</TableCell>
                  <TableCell align='center'>Description</TableCell>
                  <TableCell align='center'>Bin Location</TableCell>
                  <TableCell align='center'>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objects.map((object: any, key: number) => (
                  <TableRow className={theme.palette.mode} key={key}>
                    <TableCell align='center'>{object.item}</TableCell>
                    <TableCell align='center'>{object.description}</TableCell>
                    <TableCell align='center'>{object.location}</TableCell>
                    <TableCell align='center'>{object.quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default AssemblyExpandRow
