import React, { FC, useState, useEffect, useRef } from 'react'
import { AxiosError } from 'axios'
import moment from 'moment/moment'
// mui imports
import { useTheme } from '@mui/material/styles'
// mui icon imports
import Edit from '@mui/icons-material/Edit'
import Delete from '@mui/icons-material/Delete'
import Add from '@mui/icons-material/Add'
import FileDownload from '@mui/icons-material/FileDownload'
import FileUpload from '@mui/icons-material/FileUpload'
import Refresh from '@mui/icons-material/Refresh'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
// custom imports
import { api, DateFormat, refreshObjects } from '../globals'
import { apiGet, downloadExportFile, getExportFilename } from '../api'
import { doAlert } from '../globalComps/PopupAlert/PopupAlert'
import { doConfirm } from '../modals/Confirm'
import { colors } from '../colors'
import BaseTable from './BaseTable'
import BaseContent from '../globalComps/BaseContent'
import TrueFalseIcon from '../globalComps/TrueFalseIcon'
import ImportModal from '../modals/ImportModal/ImportModal'
import ApiFilterForm from '../form/ApiFilterForm'
import BaseTooltip from '../globalComps/BaseTooltip'

interface ApiTableProps {
  doLoad?: boolean,
  tableName?: string,
  objectName: string,
  titleVariant?: string,
  headers: any[]
  rowFields: any[]
  rowActions?: any[]
  rowClickActions?: any[]
  tableActions?: any[]
  dataField: string
  refreshCallback?: (dataList: any[]) => void
  url: string
  orderable?: boolean
  sortable?: boolean
  sortableUrl?: any
  searchable?: boolean
  setParentQ?: (q: string | undefined) => void
  preQ?: string
  childSearchable?: boolean
  preChildQ?: string
  searchPlaceholder?: string
  childSearchPlaceholder?: string
  editable?: boolean
  deletable?: boolean
  refreshable?: boolean
  deleteFuncOverride?: (id: number) => void
  deleteUrlOverride?: string
  creatable?: boolean
  exportable?: boolean
  expandable?: boolean
  setExpandedId?: (id: number | undefined) => void
  expandableParams?: any
  showLoadAll?: boolean
  filterable?: boolean
  filters?: any[]
  noAllFilter?: boolean
  FormComponent?: any
  overrideFormParams?: boolean
  formParams?: any
  id?: number
  importable?: boolean
  excelImportId?: string
  importUrl?: string
  refresh?: boolean
  clickToToggleFields?: any[]
  orderableBlacklist?: string[]
  expandableCondition?: any
  expandableParentCondition?: any
  dateFilters?: any[]
  filterMethods?: any[]
  legend?: any[]
  suppressLoadDisplay?: boolean
  preRefreshHook?: () => void
  forceFilter?: string
  extraParams?: any
  dataCallback?: (data: any[]) => void
  idField?: string | undefined
  loadMoreButton?: boolean | undefined
  nameField?: string | undefined
  hidePages?: boolean | undefined
  permissionsCallback?: (permissionData: any) => void
  tableHeadText?: string | undefined
}

/**
 * ApiTable component
 * @param doLoad: TODO: Fill
 * @param tableName?: table title
 * @param objectName: object name
 * @param titleVariant?: table title variant
 * @param headers: field header names
 * @param rowFields: fields to display for each data item
 * @param rowActions?: individual row actions
 * @param tableActions?: overall table actions
 * @param dataField: get resp data field
 * @param refreshCallback: TODO: Fill
 * @param url: api url
 * @param orderable?: table orderable? Makes headers able to order asc/desc
 * @param sortable?: table sortable? Allows table rows to be sorted by user (order should be saved in backend with api call)
 * @param sortableUrl?: Url to post the new sort order to
 * @param searchable?: table searchable
 * @param preQ?: Load page with this query in search bar
 * @param childSearchable?: Allows table to searched by child elements (should only be used with dropdown tables)
 * @param setParentQ: Load page with this parent query in search bar
 * @param preChildQ?: Load page with this child query in search bar
 * @param searchPlaceholder?: table search placeholder
 * @param childSearchPlaceholder?:
 * @param editable?: object edit action
 * @param deletable?: object delete action
 * @param refreshable: TODO: Fill
 * @param deleteFuncOverride?: TODO: Fill
 * @param deleteUrlOverride?: TODO: Fill
 * @param creatable?: object create action
 * @param exportable?: table xlsx exportable
 * @param importable?: table xlsx importable
 * @param expandable?: table expandable
 * @param setExpandedId?: set expanded id
 * @param expandableParams?: expandable params
 * @param filterable?: table is filterable?
 * @param filters?: table filter options
 * @param noAllFilter: TODO: Fill
 * @param FormComponent?: object form
 * @param overrideFormParams?: parent component will handle form id/open states
 * @param formParams?: form list data, i.e. dropdown options etc...
 * @param id?: For expandable sub table get method
 * @param refresh?: Used to refresh the api table
 * @param clickToToggleFields: TODO: Fill
 * @param orderableBlacklist: TODO: Fill
 * @param excelImportId: TODO: Fill
 * @param expandableCondition: TODO: Fill
 * @param filterMethods: TODO: Fill
 * @param legend: TODO: Fill
 * @param suppressLoadDisplay: TODO: Fill
 * @param preRefreshHook: TODO: Fill
 * @param rowClickActions: TODO: Fill
 * @param forceFilter: TODO: Fill
 * @param extraParams: TODO: Fill
 * @param dataCallback: TODO: Fill
 * @param idField: TODO: Fill
 * @param loadMoreButton: Turns off scrolling to bottom of page to load more and replaces with a load more button
 * @param nameField: name field of the object, assumed to be 'name', but can be overridden with this value
 * @param hidePages
 * @param permissionsCallback: callback to pass permissions to parent component
 * @param tableHeadText: text to display above the table
 */
const ApiTable: FC<ApiTableProps> = ({doLoad, tableName, objectName, titleVariant, headers,
                                       rowFields, rowActions, tableActions, dataField,
                                       refreshCallback, url, orderable,
                                       sortable, sortableUrl, searchable, preQ,
                                       childSearchable, setParentQ, preChildQ, searchPlaceholder,
                                       childSearchPlaceholder, editable, deletable, refreshable,
                                       deleteFuncOverride, deleteUrlOverride,
                                       creatable, exportable, expandable,
                                       setExpandedId, expandableParams, filterable,
                                       filters, noAllFilter, FormComponent, overrideFormParams,
                                       formParams, id, importable, refresh,
                                       clickToToggleFields, orderableBlacklist, excelImportId,
                                       expandableCondition, expandableParentCondition, filterMethods, legend,
                                       suppressLoadDisplay, preRefreshHook, rowClickActions,
                                       forceFilter, extraParams, dataCallback, idField,
                                       loadMoreButton, nameField, hidePages,
                                       permissionsCallback, tableHeadText}) => {

  const [data, setData] = useState<any[]>([])
  const dataRef = useRef<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [bottomLoading, setBottomLoading] = useState<boolean>(false)
  const [q, setQ] = useState<string>('')
  const qRef = useRef<string>('')
  const [childQ, setChildQ] = useState<string>('')
  const childQRef = useRef<string>('')
  const [filterList, setFilterList] = useState<any[]>([])
  const [filterText, setFilterText] = useState<string>(noAllFilter && filters ? filters[0].text : 'All')  // displayed filter text
  const [filter, setFilter] = useState<string>('')  // actual filter
  const filterRef = useRef<string>('')
  const [totalPages, setTotalPages] = useState<number>(0)
  const totalPagesRef = useRef<number>(0)
  const [page, setPage] = useState<number>(2)
  const pageRef = useRef<number>(2)
  const [formOpen, setFormOpen] = useState<boolean>(false)
  const [objectId, setObjectId] = useState<number | undefined>(undefined)
  const [importOpen, setImportOpen] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [orderStatus, setOrderStatus] = useState<any | undefined>({headerText: '', ascending: true})
  const [orderValue, setOrderValue] = useState<string | undefined>(undefined)
  const orderValueRef = useRef<string | undefined>(undefined)
  const [refreshTable, setRefreshTable] = useState<boolean | undefined>(undefined)
  const [permissionList, setPermissionList] = useState<any | undefined>(
      {view: false, create: false, edit: false, delete: false, export: false, import: false}
  )
  const [totalItemCount, setTotalItemCount] = useState<number | undefined>(undefined)
  const [extraFilterValues, setExtraFilterValues] = useState<any>({})
  const extraFilterValuesRef = useRef<any>({})
  dataRef.current = data
  qRef.current = q
  filterRef.current = filter
  totalPagesRef.current = totalPages
  pageRef.current = page
  childQRef.current = childQ
  orderValueRef.current = orderValue
  extraFilterValuesRef.current = extraFilterValues

  const highlightColor = useTheme().palette.mode === 'dark' ? colors.dark.table.rowHighlight : colors.light.table.rowHighlight

  // refresh edited item helper
  const refreshObject = (updatedData: any, create: boolean) => {
    console.log('refresh object :', updatedData, create)
    const newObjects = refreshObjects(updatedData, create, dataRef.current)
    if (refreshCallback && dataRef.current.length !== newObjects.length) refreshCallback(newObjects)
    console.log('set data :', newObjects)
    setData(newObjects)
  }

  // general helpers
  const closeFormHandler = (submit: boolean, updatedData: any, create: boolean=false, createdId: number | undefined=undefined) => {
    // update object in data list

    if (create)
      updatedData['id'] = createdId

    for (const key in updatedData) {
      // patch date objects back to string
      if (updatedData[key] instanceof Date)
        updatedData[key] = moment(updatedData[key]).format(DateFormat)

      // patch object types back to string
      if (typeof updatedData[key] === 'object' && updatedData[key] !== null)
        updatedData[key] = updatedData[key].title || updatedData[key].value
    }


    if (updatedData && Object.entries(updatedData).length > 0)
      refreshObject(updatedData, create)
    setObjectId(undefined)
    setFormOpen(false)
  }
  // toggle helper for click to toggle fields
  const toggle = (entry: any, field: string) => {entry[field] = !entry[field]; refreshObject(entry, false)}
  const buildRows = (data: any[]) => {
    let hasPopped: boolean | undefined = undefined
    let rows: any[] = data.map((entry: any) => {

      if (entry === 'break')
        return 'break'

      let actions: any[] = []
      if (editable && permissionList?.edit)
        actions.push({text: 'Edit', icon: <Edit />, action: editObject})
      if (deletable && permissionList?.delete)
        actions.push({text: 'Delete', icon: <Delete />, action: deleteObject, divideAfter: true})

      let _rowActions: any
      if (rowActions) {
        rowActions = rowActions.filter((a: any) => (a.permission !== undefined && permissionList && permissionList[a.permission]) || a.permission === undefined)
        _rowActions = rowActions.filter((a: any) => {

          if (a.condition) {
            if (typeof a.condition === 'string')
              return a.noCondition || (a.condition && entry[a.condition] === a.conditionValue)
            else if (a.condition.length > 0) {
              let pass = true
              for (let i = 0; i < a.condition.length; i++) {
                if (entry[a.condition[i]] !== a.conditionValue[i]) {
                  pass = false
                  break
                }
              }

              return pass
            }
          } else
            return true
        })
      }

      // console.log('actions :', actions.length)
      // console.log('row actions :', _rowActions)
      // fix header length based on if there is an action list or not
      if (_rowActions && _rowActions.length === 0 && actions.length === 0 && headers[headers.length-1] === ''
          && !permissionList?.edit && hasPopped === undefined) {
        console.log('headers pop')
        headers.pop()
        hasPopped = true
      } else if (hasPopped) {
        console.log('headers push')
        headers.push('')
        hasPopped = false
      }

      // make this a loose object to append dropdown below if expandable table
      let row: {[k: string]: any} = {
        id: idField ? entry[idField] : entry.id,
        object: entry,
        orderBy: entry.number !== undefined ? entry.number : entry.name,
        tooltip: entry.tooltip,
        highlight: entry.highlight ? {background: highlightColor} : {},
        color: entry.color,
        actions: actions.concat(_rowActions ? _rowActions : []),
        data: rowFields.map((rowField: any) => {
          let clickableRowFieldNames = clickToToggleFields ? [...clickToToggleFields].map((field: any) => field.field) : undefined

          if (clickToToggleFields && clickableRowFieldNames && clickableRowFieldNames.includes(rowField) && permissionList?.edit) {
            const clickableField = clickToToggleFields[clickableRowFieldNames.indexOf(rowField)]

            return (
              <BaseTooltip text={`Click to toggle ${clickableField.tooltip} value`}>
                <IconButton onClick={() => {clickableField.action(entry.id); toggle(entry, rowField)}} sx={{fontSize: '1em'}}>
                  <TrueFalseIcon isTrue={entry[rowField]} />
                </IconButton>
              </BaseTooltip>
            )
          } else if (rowClickActions && rowClickActions.filter((rca: any) => rca.field === rowField).length > 0) {
            let condition: boolean = true

            // if condition exists on the entry, check it, otherwise allow the action
            if (rowClickActions.filter((rca: any) => rca.field === rowField)[0].condition !== undefined)
              condition = entry[rowClickActions.filter((rca: any) => rca.field === rowField)[0].condition]

            if (rowClickActions.filter((rca: any) => rca.field === rowField)[0].permission !== undefined)
              condition = permissionList[rowClickActions.filter((rca: any) => rca.field === rowField)[0].permission]

            const tooltip: string = rowClickActions.filter((rca: any) => rca.field === rowField)[0].tooltip
            let action: any = rowClickActions.filter((rca: any) => rca.field === rowField)[0].action
            if (condition) {
              return <BaseTooltip text={tooltip}>
                <Button sx={{maxHeight: 20}} onClick={() => action(entry)}>{entry[rowField]}</Button>
              </BaseTooltip>
            }
            else
              return entry[rowField]
          } else if (Array.isArray(rowField)) {
            for (const rF of rowField)
              if (entry[rF] !== undefined)
                return entry[rF]
          } else if (entry[rowField] === true || entry[rowField] === false)
            return <TrueFalseIcon isTrue={entry[rowField]} sx={{fontSize: '1.5em'}} />
          else
            return entry[rowField] || entry[rowField] === 0 ? entry[rowField] : '-'
        }),
      }

      return row
    })

    return rows
  }
  const nameFromId = (id: number) => {
    const fieldName: string = nameField ? nameField : 'name'

    for (const item of data) {
      if (item.id === id)
        return item[fieldName] || item.parent_item
    }
  }

  useEffect(() => {

    const handleScrollWindow = () => {
      const bottom: boolean = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

      // console.log('handle scroll window bottom :', bottom, ' bottom loading :', bottomLoading)

      if (bottom && !bottomLoading)
        loadMore()
    }
    const handleScroll = (e: any) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      const bottom = (scrollHeight - scrollTop - clientHeight) === 0

      // console.log('handle scroll bottom :', bottom, ' bottom loading :', bottomLoading)

      if (bottom && !bottomLoading) {
        console.log('use callback handle scroll load more')
        loadMore()
      }
    }

    // console.log(`api table use effect suppress=${suppressLoadDisplay} load=${doLoad} refresh=${refresh} table=${refreshTable}`, extraParams)

    let isActive = true

    if (forceFilter) {
      setFilter(forceFilter)
      // console.log('setting force filter :', forceFilter)
    }

    if (!suppressLoadDisplay) {
      setLoading(true)
    }

    // refresh table
    if (refreshTable !== refresh) {
      setRefreshTable(refreshTable)
      setOrderValue('')
      setOrderStatus({headerText: '', ascending: true})
      if (!suppressLoadDisplay) {
        // console.log('suppress load display :', suppressLoadDisplay)
        setQ('')
      }
    }

    // build filter list
    let fList: any[] = []
    if (filters && filters.length > 0)
      for (const filter of filters)
        fList.push({text: filter.text, action: onFilter})
    setFilterList(fList)

    // handle child dropdown get id
    if (setExpandedId && id)
      setExpandedId(id)

    if (doLoad || doLoad === undefined) {
      getObjectsHelper((resp: any) => {
        if (isActive) {
          let _data: any[] = resp.data[dataField]

          if (idField)
            _data = _data.map((object: any) => {if (!object[idField]) object[idField] = object.id; return object})
          setData(_data)

          setPermissionList(resp.data.permissions)
          if (permissionsCallback)
            permissionsCallback(resp.data.permissions)
          setLoading(false)

          if (preQ && preQ !== 'none') onSearch(preQ)
          if (preChildQ && preChildQ !== 'none') onChildSearch(preChildQ)
          if (refreshCallback) refreshCallback(resp.data[dataField])

          // add window listener to track if at bottom of page
          if (!loadMoreButton) {
            for (const contentRoot of Array.from(document.getElementsByClassName('MuiDialogContent-root')))
              contentRoot.addEventListener('scroll', handleScroll, {passive: true})

            window.addEventListener('scroll', handleScrollWindow, {passive: true})
          }

          if (dataCallback) dataCallback(_data)
        }
      }, forceFilter ? forceFilter : filter, q, childQ, 1, orderValue, {}, true)
    }

    return () => {
      for (const contentRoot of Array.from(document.getElementsByClassName('MuiDialogContent-root')))
        contentRoot.removeEventListener('scroll', handleScroll)
      window.removeEventListener('scroll', handleScrollWindow)

      isActive = false
    }
  }, [setData, childQ, setChildQ, refresh, refreshTable, suppressLoadDisplay, doLoad, extraParams])

  // row actions
  const editObject = (id: number) => {
    setObjectId(id)
    setFormOpen(true)
  }
  const deleteObject = (id: number) => {

    let deleteUrl: string = url
    if (deleteFuncOverride)
      return deleteFuncOverride(id)
    else if (/\d+/.test(deleteUrl) && !deleteUrlOverride)
      deleteUrl = deleteUrl.replace('?parentId=', '').replace(/\d+/, '')
    else if (deleteUrlOverride)
      deleteUrl = deleteUrlOverride

    doConfirm(`Delete ${objectName} ${nameFromId(id)}? Are you sure?`, () => {

      api.delete(deleteUrl + `${id}/`)
        .then(() => {
          setData(dataRef.current.filter((item: any) => item.id !== id))
        })
        .catch((err: AxiosError) => {
          if (err.response) doAlert(err.response.data.message, 'error', true)
        })
    })
  }

  // table actions
  const newObject = () => {
    setObjectId(undefined)
    setFormOpen(true)
  }
  const exportObjects = () => {
    setExportLoading(true)

    apiGet(url, {...extraParams, export: true, q: qRef.current, filter: filterRef.current, blob: true}, (resp: any) => {
      console.log('resp :', resp)
      downloadExportFile(resp.data, getExportFilename(resp.headers))
      setExportLoading(false)
      console.log('setExportLoading(false)')
    })

    // api.get(url + `?export=${true}&q=${qRef.current}&filter=${filterRef.current}`, {responseType: 'blob'})
    //   .then((resp: AxiosResponse) => {
    //     downloadExportFile(resp.data, getExportFilename(resp.headers))
    //     setExportLoading(false)
    //   })
    //   .catch((err: AxiosError) => {
    //     if (err.response)
    //       doAlert(err.response.data.message, 'error', true)
    //     setExportLoading(false)
    //   })
  }
  const openImportModal = () => {
    setImportOpen(true)
  }
  const doRefresh = () => {
    if (preRefreshHook) preRefreshHook()

    setPage(1)
    setOrderValue(undefined)
    setOrderStatus(undefined)

    console.log('set loading refresh')
    setLoading(true)
    setRefreshTable(!refreshTable)
  }
  const getTableActions = () => {
    let _tableActions: any[] = []

    if (creatable && permissionList?.create)
      _tableActions.push({text: `New ${objectName}`, icon: <Add />, action: newObject})
    if (refreshable && permissionList?.view)
      _tableActions.push({text: 'Refresh', icon: <Refresh />, action: doRefresh, tooltip: 'Refresh Table', outlined: true, right: true})
    if (importable && (permissionList?.create && permissionList?.edit && permissionList?.delete))
      _tableActions.push({text: 'Import', icon: <FileUpload />, action: openImportModal, outlined: true,
      right: true, tooltip: `Import ${objectName}s from excel`})
    if (exportable && (permissionList?.view))
      _tableActions.push({text: 'Export', icon: <FileDownload />, action: exportObjects, outlined: true,
      right: true, tooltip: `Export ${objectName}s to excel`, loadingButton: true, loading: exportLoading})

    return _tableActions.concat(tableActions ? tableActions : [])
  }

  // data filtering
  const onFilter = (newFilter: string) => {
    let _page: number = page

    if (filter !== newFilter) {
      _page = 1
      setPage(_page)
    }

    setFilter(newFilter)
    console.log('set loading filter')
    setLoading(true)
    getObjectsHelper((data: any[]) => {
      setFilterText(newFilter)
      setData(data)
      setLoading(false)

      if (dataCallback) dataCallback(data)
    }, newFilter, qRef.current, childQRef.current, _page, orderValueRef.current, extraFilterValuesRef.current)
  }
  const onSearch = (newQ: string) => {
    let _page: number = page

    // search, switch to page 1
    _page = 1
    setPage(_page)

    if (setParentQ) setParentQ(newQ)

    setQ(newQ)
    console.log('set loading search')
    setLoading(true)
    getObjectsHelper((data: any[]) => {
      setData(data)
      setLoading(false)

      if (dataCallback) dataCallback(data)
    }, filterRef.current, newQ, childQRef.current, _page, orderValueRef.current, extraFilterValuesRef.current)
  }
  const onChildSearch = (newChildQ: string) => {
    let _page: number = page
    // new search, switch to page 1
    if (childQ !== newChildQ) {
      _page = 1
      setPage(_page)
    }

    setChildQ(newChildQ)
    console.log('set loading child search')
    setLoading(true)
    getObjectsHelper((data: any[]) => {
      setData(data)
      setLoading(false)

      if (dataCallback) dataCallback(data)
    }, filterRef.current, qRef.current, newChildQ, _page, orderValueRef.current, extraFilterValuesRef.current)
  }
  let loadingMore: boolean = false
  const loadMore = () => {
    // set to end of the event loop for bottom Loading flag
    console.log('loading :', loadingMore)
    if (!loadingMore) {
      console.log('load more! cur page :', pageRef.current, 'total :', totalPagesRef.current)

      if (pageRef.current === 1 && dataRef.current.length > 0)
        setPage(pageRef.current + 1)

      if (pageRef.current <= totalPagesRef.current) {
        setBottomLoading(true)
        loadingMore = true
        getObjectsHelper((_data: any[]) => {
          let newData: any[] = [...dataRef.current].concat(_data)
          if (_data.length > 0) setData(newData)
          setPage(pageRef.current + 1)
          setBottomLoading(false)

          loadingMore = false
          if (dataCallback) dataCallback(_data)
        }, filterRef.current, qRef.current, childQRef.current, pageRef.current, orderValueRef.current, extraFilterValuesRef.current)
      }
    }
  }
  const getObjectsHelper = (cb: (data: any[]) => void, filter: string, q: string, childQ: string, page: number | string,
                            order: string | undefined, extraFilters?: any, allData?: boolean) => {
    // console.log(`getObjectsHelper(filter=${filter}, q=${q}, childQ=${childQ}, page=${page}, order=${order})`)

    let objectsUrl: string
    if (url.includes('?'))
      objectsUrl = `${url}&q=${q}&page=${page}&filter=${filter}&childQ=${childQ}&order=${order}`
    else
      objectsUrl = `${url}?q=${q}&page=${page}&filter=${filter}&childQ=${childQ}&order=${order}`

    for (const field in extraFilters)
      objectsUrl += `&${field}=${extraFilters[field]}`

    if (extraParams)
      for (const param in extraParams)
        objectsUrl += `&${param}=${extraParams[param]}`

    // console.log('extra params :', extraParams)
    apiGet(objectsUrl, {}, (resp: any) => {
      setTotalPages(resp.data.max_page)
      if (allData)
        cb(resp)
      else
        cb(resp.data[dataField])
    })
  }

  // update data order
  const updateDataOrder = (_data: any[]) => {
    const newData: any = []

    for (const _item of _data)
      for (const item of data)
        if (_item.id === item.id)
          newData.push(item)

    // console.log('set data :', newData)
    setData(newData)
  }

  let formComp: any
  if (FormComponent && !overrideFormParams) {
    // console.log('form comp id:', objectId)
    formComp = <FormComponent {...formParams} id={objectId} open={formOpen} closeForm={closeFormHandler} />
  }
  else
    formComp = FormComponent

  const closeImportModal = (uploaded: boolean) => {
    console.log('UPLOADED :', uploaded)
    if (uploaded) {
      console.log(`setRefreshTable(${!refresh})`)
      setRefreshTable(!refreshTable)
    }
    setImportOpen(false)
  }

  const importComp = () => {
    let comp: any = <></>
    if (importable)
      comp = <ImportModal open={importOpen} closeHandler={closeImportModal}
                          url={url} typeName='xlsx' title={`Import ${objectName}s XLSX`} excelImportId={excelImportId} />
    return comp
  }

  // table ordering
  const doOrder = (headerText: string, ascending: boolean) => {
    setLoading(true)
    const newOrderStatus: any = {headerText: headerText, ascending: ascending}
    let newOrderValue: string = ''
    let i = 0
    for (const header of headers) {

      let index: number = i
      if (expandableParams)
        index -= 1

      if (typeof header === 'object') {
        if (header.text === headerText) {
          newOrderValue = rowFields[index]
          break
        }
      } else if (header === headerText) {
        newOrderValue = rowFields[index]
        break
      }
      i++
    }
    if (newOrderValue)
      newOrderValue = ascending ? newOrderValue :  '-' + newOrderValue
    else
      newOrderValue = ''

    if (newOrderStatus !== orderStatus) {
      let _page = 1
      setPage(_page)
      setLoading(true)
      getObjectsHelper((data: any[]) => {
        setData(data)
        setLoading(false)

        if (dataCallback) dataCallback(data)
      }, filterRef.current, qRef.current, childQRef.current, 1, newOrderValue, extraFilterValuesRef.current)
    }

    setOrderValue(newOrderValue)
    setOrderStatus(newOrderStatus)
  }

  // extra table filtering
  const doRunDataFilter = (filterValues: any) => {
    setExtraFilterValues(filterValues)

    let _page = pageRef.current
    if (filterValues !== extraFilterValues) {
      _page = 1
      setPage(_page)
    }

    getObjectsHelper((data: any) => {
      console.log('data filter data :', data)
      setData(data)

      if (dataCallback) dataCallback(data)
    }, filterRef.current, qRef.current, childQRef.current, _page, orderValueRef.current, filterValues)
  }

  return (
    <>
      <BaseContent loading={loading}>
        {!doLoad || permissionList?.view ?
          <BaseTable
            name={tableName && permissionList?.view ? tableName : ''}
            titleVariant={titleVariant ? titleVariant : 'h5'}
            sortable={permissionList?.edit ? sortable : undefined}
            sortableUrl={sortableUrl}
            headers={permissionList?.view ? headers : []}
            rows={permissionList?.view ? buildRows(dataRef.current) : []}
            actions={getTableActions()}
            clickActions={rowClickActions}
            q={q}
            childQ={childQ}
            search={searchable && permissionList?.view ? onSearch : undefined}
            childSearch={childSearchable && permissionList?.view ? onChildSearch : undefined}
            searchPlaceholder={searchPlaceholder}
            childSearchPlaceholder={childSearchPlaceholder}
            orderable={orderable}
            orderFunc={doOrder}
            orderStatus={orderStatus}
            filterable={permissionList?.view ? filterable : undefined}
            filterButtonText={permissionList?.view ? filterText : undefined}
            filterOptions={permissionList?.view ? filterList : undefined}
            bottomLoading={bottomLoading}
            expandable={expandable}
            expandableParams={expandableParams}
            setExpandedId={setExpandedId}
            refreshObject={refreshObject}
            updateDataOrder={updateDataOrder}
            orderableBlacklist={orderableBlacklist}
            expandableCondition={expandableCondition}
            expandableParentCondition={expandableParentCondition}
            legend={permissionList?.view ? legend : []}
            totalItemCount={totalItemCount}
            filterForm={filterMethods ?
              <ApiFilterForm
                filterMethods={filterMethods}
                runFilter={doRunDataFilter}
              /> : <></>}
            currentPage={pageRef.current}
            totalPages={totalPagesRef.current}
            hidePages={permissionList?.view ? hidePages : true}
            tableHeadText={tableHeadText}
          />
          :
          <Typography>Insufficient permission</Typography>
        }
      </BaseContent>
      {loadMoreButton && pageRef.current <= totalPagesRef.current ?
        <Grid container justifyContent='center'>
          <LoadingButton onClick={() => loadMore()} loading={loading} variant='outlined'>
            Load More
          </LoadingButton>
        </Grid>
        :
        <></>}
      {formComp}
      {importComp()}
    </>
  )
}

export default ApiTable
