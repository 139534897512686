import React, { FC, useEffect } from 'react'
// custom imports
import { toggleNcField } from './NonconformingCodesApi'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import ApiTable from '../../../../utils/table/ApiTable'
import NonconformingCodeForm from './NonconformingCodeForm'


const QualityAssuranceCodes: FC = () => {

  useEffect(() => {
    document.title = 'QA Codes | RotoEdgePro'
  }, [])

  const toggleItemValue = (id: number, fieldName: string) => toggleNcField(id, fieldName)
  const toggleMoldingEnabled = (id: number) => toggleItemValue(id, 'molding_enabled')
  const toggleSecondaryEnabled = (id: number) => toggleItemValue(id, 'secondary_enabled')
  const toggleFoamingEnabled = (id: number) => toggleItemValue(id, 'foaming_enabled')
  const toggleAssemblyEnabled = (id: number) => toggleItemValue(id, 'assembly_enabled')
  const toggleCountedEnabled = (id: number) => toggleItemValue(id, 'counted')

  return (
    <>
      <PageTitle title='Quality Assurance Codes' />
      <BaseContent loading={false}>
        <ApiTable
          tableName=''
          objectName='Non-conforming Code'
          headers={['Name', 'Description', 'Molding Enabled', 'Secondary Enabled', 'Foaming Enabled', 'Assembly Enabled', 'Counted', '']}
          rowFields={['code_name', 'description', 'molding_enabled', 'secondary_enabled', 'foaming_enabled',
            'assembly_enabled', 'counted']}
          clickToToggleFields={[
            {field: 'molding_enabled', action: toggleMoldingEnabled, tooltip: 'can log N/C on Molded product'},
            {field: 'secondary_enabled', action: toggleSecondaryEnabled, tooltip: 'can log N/C on Secondary product'},
            {field: 'foaming_enabled', action: toggleFoamingEnabled, tooltip: 'can log N/C on Foamed product'},
            {field: 'assembly_enabled', action: toggleAssemblyEnabled, tooltip: 'can log N/C on Assembled product'},
            {field: 'counted', action: toggleCountedEnabled, tooltip: 'N/C counted in production logs'}
          ]}
          dataField='objects'
          url='/inventory/qa-codes/'
          nameField='code_name'
          editable
          deletable
          creatable
          importable
          exportable
          refreshable
          excelImportId='qa codes'
          FormComponent={NonconformingCodeForm}
        />
      </BaseContent>
    </>
  )
}

export default QualityAssuranceCodes
