import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// mui imports
import Grid from '@mui/material/Grid';
import { AxiosError, AxiosResponse} from "axios";
// custom imports
import BaseForm from '../../../../utils/form/BaseForm';
import FormInputPassword from '../../../../utils/form/FormInputPassword';
import { api, FormSpacing } from '../../../../utils/globals';
import {apiPost} from "../../../../utils/api";

interface PasswordFormProps {
  open: boolean;
  closeForm: (success: boolean) => void;
  id: number | undefined;
}

/**
 * PasswordForm Comp
 * @param open
 * @param closeForm
 * @param id
 */
const PasswordForm: FC<PasswordFormProps> = ({open, closeForm, id}) => {

  // setup form
  const defaultVals = {password: '', 'confirm_password': ''};
  const methods = useForm({defaultValues: defaultVals});
  const { handleSubmit, setError, reset } = methods;

  // on form submit
  const onSubmit = (data: any) => {
    data['update_password'] = true
    apiPost(`/main/accounts/${id}/`, data, (resp: any) => {
      console.log('errors :', resp)
      if (resp.data.errors)
        for (const err of resp.data.errors)
          setError(err.name, {type: 'server', message: err.message})
      else
        closeForm(true)
    })
  }

  // form actions
  const actions = [
    {text: 'Reset', action: () => reset(defaultVals), outlined: true},
    {text: 'Submit', action: handleSubmit(onSubmit)},
  ];

  return (
    <BaseForm title='Reset Password Form' open={open} closeForm={() => closeForm(false)} actions={actions} loading={false}>
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          <Grid item md={6}><FormInputPassword name='password' label='Password' /></Grid>
          <Grid item md={6}><FormInputPassword name='confirm_password' label='Confirm Password' /></Grid>
        </Grid>
      </FormProvider>
    </BaseForm>
  );
}

export default PasswordForm;
