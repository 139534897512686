import React, { FC, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
// mui imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
// mui icons
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
// custom imports
import { DateFormat, startAndEndOfWeek, useEffectApi } from '../../../../utils/globals'
import { approveUnit, exportLogs, getFormOptions, setUnitNotFound, toggleUnitApproved, toggleUnitNotFound } from './NonconformingLogApi'
import { colors } from '../../../../utils/colors'
import { doConfirm } from '../../../../utils/modals/Confirm'
import { downloadExportFile, getExportFilename } from '../../../../utils/api'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion'
import ApiTable from '../../../../utils/table/ApiTable'
import FormInputDate from '../../../../utils/form/FormInputDate'
import ConfirmForm from './ConfirmForm'
import FormInputDateRange from '../../../../utils/form/FormInputDateRange'
import QAExportForm from './QAExportForm'


interface QualityAssuranceProps {
}
const QualityAssurance: FC<QualityAssuranceProps> = ({}) => {

  const theme: any = useTheme()

  const [refresh, setRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [permissions, setPermissions] = useState<any>(undefined)
  const [searchDate, setSearchDate] = useState<string>('')
  const [refreshTable1, setRefreshTable1] = useState<boolean>(false)
  const [refreshTable1Silent, setRefreshTable1Silent] = useState<boolean>(false)
  const [refreshTable2, setRefreshTable2] = useState<boolean>(false)
  const [refreshTable2Silent, setRefreshTable2Silent] = useState<boolean>(false)
  const [refreshTable3, setRefreshTable3] = useState<boolean>(false)
  const [refreshTable3Silent, setRefreshTable3Silent] = useState<boolean>(false)
  const [shiftOptions, setShiftOptions] = useState<any[]>([])
  const [machineOptions, setMachineOptions] = useState<any[]>([])
  const [codeOptions, setCodeOptions] = useState<any[]>([])
  const [confirmUnitId, setConfirmUnitId] = useState<string | undefined>(undefined)
	const [startDate, setStartDate] = useState<string>('')
	const [endDate, setEndDate] = useState<string>('')
	const [exporting, setExporting] = useState<boolean>(false)

  const searchMethods = useForm({defaultValues: {'search-date': new Date()}})
  const exportMethods = useForm({defaultValues: {'export-range': startAndEndOfWeek(new Date())}})

  const refreshTable = (tableNum: number, silent?: boolean) => {
    if (tableNum === 1) {
      if (silent)
        setRefreshTable1Silent(true)
      setRefreshTable1(!refreshTable1)
    } else if (tableNum === 2) {
      if (silent)
        setRefreshTable2Silent(true)
      setRefreshTable2(!refreshTable2)
    } else {
      if (silent)
        setRefreshTable3Silent(true)
      setRefreshTable3(!refreshTable3)
    }
  }

  useEffectApi(() => {

    document.title = 'Quality Assurance | RotoEdgePro'

    getFormOptions((data: any) => {
      setCodeOptions(data.codeOptions)
      setShiftOptions(data.shiftOptions)
      setMachineOptions(data.machineOptions)
      setLoading(false)
    })
  }, [refresh])

  // search/export filters
  const submitSearch = (data: any) => {
    setSearchDate(moment(data['search-date']).format(DateFormat))
    refreshTable(1)
    refreshTable(2)
  }
  const resetSearch = () => {
    searchMethods.reset({'search-date': new Date()})
    setRefresh(!refresh)
  }
  const submitExport = (data: any) => {
    setStartDate(moment(data['export-range'][0]).format(DateFormat))
    setEndDate(moment(data['export-range'][1]).format(DateFormat))
  }
  const resetExport = () => exportMethods.reset({'export-range': startAndEndOfWeek(new Date())})

  // row actions
  const confirmUnit = (id: string) => setConfirmUnitId(id)
  const unitNotFound = (id: string) => {
    doConfirm('Set unit to not found?', () => setUnitNotFound(id, () => refreshTable(1, true)))
  }
  const doToggleUnitNotFound = (id: string) => toggleUnitNotFound(id)
  const doToggleUnitApproved = (id: string) => toggleUnitApproved(id)
  const doApproveUnit = (id: string) => {
    approveUnit(id, () => {
      refreshTable(2, true)
    })
  }

  // helpers
  const getRowClickActions = () => {
    let actions: any[] = [{field: 'not_found', action: doToggleUnitNotFound, tooltip: 'Toggle not found'}]

    if (permissions?.edit)
      actions.push({field: 'confirmed', tooltip: 'Confirm this unit', action: confirmUnit})

    return actions
  }
  const getRowColor = (name: string) => colors[theme.palette.mode].table.row[name]
  const handleExpand = (e: any, expanded: boolean, n: number) => {
    if (expanded)
      refreshTable(n)
  }
  const closeForm = () => {
    setConfirmUnitId(undefined)
    refreshTable(1, true)
    refreshTable(2, true)
  }

  const handlePermissions = (permissions: any) => setPermissions(permissions)

  return (
    <>
      <PageTitle title='Quality Assurance' />
      <BaseContent loading={loading}>

        <Grid container spacing={2}>

          {/*search form*/}
          <Grid container item xs={12}>
            <FormProvider {...searchMethods}>
              <Grid container item xs={6}>
                <Grid item xs={4}>
                  <FormInputDate name='search-date' label='Search Day' placeholder='Search Day' />
                </Grid>
                <Grid item>
                  <Button variant='contained' sx={{ml: '5px'}} onClick={searchMethods.handleSubmit(submitSearch)}>Submit</Button>
                  <Button variant='outlined' sx={{ml: '5px'}} onClick={resetSearch}>Reset</Button>
                </Grid>
              </Grid>
            </FormProvider>
            {permissions?.edit ?
              <FormProvider {...exportMethods}>
                <Grid container item xs={6}>
                  <Grid item sx={{mr: '5px'}}>
                    <FormInputDateRange name='export-range' label={{start: 'Export Start', end: 'Export End'}} />
                  </Grid>
                  <Button variant='contained' sx={{mr: '5px'}} disabled={exporting} onClick={exportMethods.handleSubmit(submitExport)}>{exporting ? 'Exporting...' : 'Export'}</Button>
                  {!exporting ? <Button variant='outlined' onClick={resetExport}>Reset</Button> : <></>}
                </Grid>
              </FormProvider>
              :
              <></>
            }
          </Grid>

          <Grid item xs={12}>
            <ApiTable
              refresh={refreshTable1}
              suppressLoadDisplay={refreshTable1Silent}
              legend={[
                {color: getRowColor('molded'), text: 'Molded'},
                {color: getRowColor('secondary'), text: 'Secondary'},
                {color: getRowColor('foamed'), text: 'Foamed'},
                {color: getRowColor('assembly'), text: 'Assembled'},
              ]}
              objectName=''
              tableName=''
              headers={['Order', 'Item', 'Description', 'Location', 'Order Quantity', 'Non-Conforming (Total NC / Part)', 'Shift',
                'User', 'Confirmed', 'Not Found', 'Created', 'Edited', '']}
              rowFields={['order', 'item', 'description', 'location', 'quantity', 'non_conforming', 'shift', 'user',
                'confirmed', 'not_found', 'created', 'updated']}
              rowActions={[
                {text: 'Edit', tooltip: 'Confirm this unit', icon: <Check />, action: confirmUnit, permission: 'edit'},
                {text: 'Not Found', tooltip: 'This unit cannot be found', icon: <Close />, action: unitNotFound, permission: 'edit'},
              ]}
              clickToToggleFields={getRowClickActions()}
              dataField='objects'
              searchable
              refreshable
              url={`/manufacturing/nonconforming/logs/?date=${searchDate}`}
              loadMoreButton
              permissionsCallback={handlePermissions}
            />
          </Grid>

          <Grid item xs={12}>
            <BaseAccordion title='Productivity Review' onChange={(e: any, expanded: boolean) => handleExpand(e, expanded, 2)}>
              <ApiTable
                refresh={refreshTable2}
                suppressLoadDisplay={refreshTable2Silent}
                objectName=''
                tableName=''
                headers={['Order', 'Item', 'Description', 'Location', 'Order Quantity', 'Non-Conforming (Total NC / Part)', 'Shift',
                  'User', 'Approved', 'Created', 'Edited', '']}
                rowFields={['order', 'item', 'description', 'location', 'quantity', 'non_conforming', 'shift', 'user',
                  'approved', 'created', 'updated']}
                rowActions={[
                  {text: 'Approve', tooltip: 'Approve this unit', icon: <Check />, action: doApproveUnit, permission: 'edit'},
                ]}
                clickToToggleFields={[
                  {field: 'approved', action: doToggleUnitApproved, tooltip: 'Toggle approved'}
                ]}
                dataField='objects'
                searchable
                refreshable
                url={`/manufacturing/nonconforming/logs/?date=${searchDate}&review=${true}`}
              />
            </BaseAccordion>
          </Grid>

          <Grid item xs={12}>
            <BaseAccordion title='Not Found Units' onChange={(e: any, expanded: boolean) => handleExpand(e, expanded, 3)}>
              <ApiTable
                refresh={refreshTable3}
                suppressLoadDisplay={refreshTable3Silent}
                objectName=''
                tableName=''
                headers={['Order', 'Item', 'Description', 'Location', 'Order Quantity', 'Non-Conforming (Total NC / Part)', 'Shift',
                  'User', 'Not Found', 'Created', 'Edited', '']}
                rowFields={['order', 'item', 'description', 'location', 'quantity', 'non_conforming', 'shift', 'user',
                  'not_found', 'created', 'updated']}
                clickToToggleFields={permissions?.edit ? [{field: 'not_found', action: doToggleUnitNotFound, tooltip: 'Toggle not found'}] : []}
                dataField='objects'
                searchable
                refreshable
                url={`/manufacturing/nonconforming/logs/?date=${searchDate}&notFound=${true}`}
              />
            </BaseAccordion>
          </Grid>

        </Grid>

      </BaseContent>

      <ConfirmForm id={confirmUnitId} onClose={closeForm} codeOptions={codeOptions} shiftOptions={shiftOptions}
                   machineOptions={machineOptions} />

			<QAExportForm 
				onClose={() => {setStartDate(''); setEndDate('')}} 
				onCancel={() => {setStartDate(''); setEndDate('')}}
				startDate={startDate} endDate={endDate} 
				setExporting={setExporting}
			/>
			
    </>
  )
}

export default QualityAssurance
