import React, { FC, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import {Queue} from "@mui/icons-material";
import {getLoadAfterOptions, getName} from "../../components/pages/scheduling/MachineScheduling/MachineSchedulingApi";
import FormInputText from "../form/FormInputText";
import FormInputAutocomplete from "../form/FormInputAutocomplete";
import InfoText from "../globalComps/InfoText";

interface AssignLoadAfterOrderProps {
  orderId: number | undefined;
  onClose?: () => void;
  doAssign?: (orderId: number | undefined, assignToId: number | undefined, assignToType: string | undefined) => void;
}
const AssignLoadAfterOrder: FC<AssignLoadAfterOrderProps> = ({orderId, doAssign, onClose}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [orderName, setOrderName] = useState<string>('');
  const [selectFromList, setSelectFromList] = useState<any[]>([]);
  const [selectedObjectId, setSelectedObjectId] = useState<number | undefined>(undefined);
  const [selectedObjectType, setSelectedObjectType] = useState<string | undefined>(undefined);

  useEffect(() => {
    setOpen(orderId !== undefined);

    if (orderId) {
      // get name of the order we are assigning to load after
      getName(orderId, 'loadQueue', (name: string) => {
        setOrderName(name)
        reset({order_item: name});
        console.log('reset :', {order_item: name})
      })

      // get list of orders we can assign this order to after
      getLoadAfterOptions(orderId, (loadAfterList) => {
        console.log('load after list :', loadAfterList)
        setSelectFromList(loadAfterList)
      })
    }
  }, [orderId])

  // form setup
  const methods = useForm({defaultValues: {arm: '', order_item: orderName}});
  const { reset } = methods;

  // helpers
  const assignHandler = () => {
    if (doAssign) doAssign(orderId, selectedObjectId, selectedObjectType);
  }
  const onChangeHandler = (data: any) => {
    console.log('onChangeHandler data :', data)
    setSelectedObjectId(data.id);
    setSelectedObjectType(data.group_by === 'Load Queue' ? 'loadQueue' : 'machineLoad')
  }

  return (
    <BaseModal title='Assign Load After Order' closeButton dividers open={open} closeHandler={onClose ? onClose : () => {}} actions={[
      {text: 'Assign', action: assignHandler, icon: <Queue />}
    ]}>
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <InfoText mt={10} text='Choose a Machine Loaded or Load Queue Order on this Arm to load this Order on after.' />
          <InfoText mt={10} text={`When the chosen Order is unloaded, ${orderName} is then suggested to be loaded next.`} />
          <Grid item xs={12}><FormInputText name='order_item' label='Order Item' disabled={true}/></Grid>
          <Grid item xs={12}>
            <FormInputAutocomplete name='orders' label='Load After this Order' myOnChange={onChangeHandler}
                                   options={selectFromList} group />
          </Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  );
}

export default AssignLoadAfterOrder;