import {apiGet, apiPost} from "../../api";
import {doAlert} from "../PopupAlert/PopupAlert";

/**
 * gets shift list for the progress bars display
 */
const getShiftList = (callback: (data: any) => void) => {
  apiGet('/main/shifts/', {}, (resp: any) => callback(resp.data))
}

/**
 * gets an order name from order id
 * @param id order id
 * @param type
 * @param callback
 */
const getOrderName = (id: number, type: number, callback: (name: string) => void) => {
  apiGet('/manufacturing/production/logs/', {orderId: id, type: type, orderName: true}, (resp: any) => {
    callback(resp.data.name)
  })
}

/**
 * gets production log data for edit modal
 * @param id
 * @param callback
 */
const getEditData = (id: string, callback: (data: any) => void) => {
  apiGet(`/manufacturing/production/logs/${id}/`, {editData: true}, (resp: any) => callback(resp.data))
}

/**
 * gets production log data to put on a reprinted log label
 * @param id
 * @param callback
 */
const getPrintData = (id: string, callback: (data: any) => void) => {
  apiGet(`/manufacturing/production/logs/${id}/`, {printData: true}, (resp: any) => callback(resp.data))
}

/**
 * gets shift and scrap code data for the production log edit modal
 * @param callback
 */
const getShiftsAndScrapCodes = (callback: (data: any) => void) => {
  apiGet('/manufacturing/production/logs/', {}, (resp: any) => {
    callback(resp.data)
  })
}

/**
 * Archives a processed unit
 * @param id processed unit id
 * @param callback
 */
const archive = (id: string, callback: () => void) => {
  apiPost(`/manufacturing/production/logs/${id}/`, {archive: true}, (resp: any) => {
    callback()
  })
}

/**
 * Restores a processed unit
 * @param id processed unit id
 * @param callback
 */
const unArchive = (id: string, callback: () => void) => {
  apiPost(`/manufacturing/production/logs/${id}/`, {unarchive: true}, (resp: any) => {
    callback()
  })
}

/**
 * Posts edit log data from the edit modal
 * @param id unit id
 * @param data edit data
 * @param callback form error handling
 */
const editLog = (id: string, data: any, callback: (errors: any[]) => void) => {
  apiPost(`/manufacturing/production/logs/${id}/`, {...data, edit: true}, (resp: any) => {
    if (resp.data.status === 'success') {
      doAlert(resp.data.message, 'success', true)
      callback([])
    }
    else
      callback(resp.data)
  })
}

export {
  getShiftList, getOrderName, getEditData, getPrintData, getShiftsAndScrapCodes, archive, unArchive, editLog
}
