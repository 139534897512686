import React, { FC } from 'react'
// mui imports
import Grid from '@mui/material/Grid'
// custom imports
import { FormSpacing } from '../../../../utils/globals'
import ApiForm from '../../../../utils/form/ApiForm'
import FormInputText from '../../../../utils/form/FormInputText'
import FormInputAutocomplete from '../../../../utils/form/FormInputAutocomplete'
import FormInputNumber from '../../../../utils/form/FormInputNumber'
import FormInputSwitch from '../../../../utils/form/FormInputSwitch'

const URL: string = '/productivity/personnel/'

interface PersonnelFormProps {
  id: number | undefined
  open: boolean
  factories: any[]
  closeForm: (submit: boolean, data: any, create: boolean, createdId: number | undefined) => void
}
const PersonnelForm: FC<PersonnelFormProps> = ({id, open, factories, closeForm}) => {

  return (
    <ApiForm
      name='Personnel Form'
      open={open}
      defaultValues={{}}
      closeForm={closeForm}
      id={id}
      fetchUrl={id ? `${URL}${id}/` : ''}
      submitUrl={URL}
    >
      <Grid container spacing={FormSpacing}>
        <Grid item md={3}><FormInputText name='name' label='Name' /></Grid>
        <Grid item md={3}><FormInputText name='personnel_id' label='Accounting ID' /></Grid>
        <Grid item md={3}><FormInputText name='login_code' label='Login Code' /></Grid>
        <Grid item md={3}><FormInputNumber name='adjustment' label='Adjustment %' /></Grid>
        <Grid item md={6}><FormInputAutocomplete name='factory' label='Factory' options={factories} /></Grid>
        <Grid item md={6}><FormInputSwitch name='archived' label='Archived' /></Grid>
      </Grid>
    </ApiForm>
  )
}

export default PersonnelForm
