import {apiPost} from "../../../../utils/api";

/**
 * toggles where the nc code is enabled
 * @param id
 * @param fieldName
 */
const toggleNcField = (id: number, fieldName: string) => {
  apiPost(`/inventory/qa-codes/${id}/`, {toggleField: fieldName})
}

export {
  toggleNcField
}
