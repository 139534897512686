import React, {FC, useEffect} from 'react';
// mui imports
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
// custom imports
import BaseModal from "./BaseModal";
import {TableCell} from "@mui/material";
import TableRow from "@mui/material/TableRow";

interface LoadQueueModalProps {
  open: boolean;
  armName: string;
  items: any[];
  onClose: () => void;
}

const LoadQueueModal: FC<LoadQueueModalProps> = ({open, armName, items, onClose}) => {

  return (
    <BaseModal
      title={`Load Queue: ${armName}`}
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[]}
      maxWidth='lg'
    >
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                Order, Item
              </TableCell>
              <TableCell>
                Description
              </TableCell>
              <TableCell>
                # Molds to Load
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item: any, key: number) => (
              <TableRow key={key}>
                <TableCell>
                  {item.number}, {item.item}
                </TableCell>
                <TableCell>
                  {item.description}
                </TableCell>
                <TableCell>
                  {item.mold_load_quantity}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </BaseModal>
  );
}

export default LoadQueueModal;
