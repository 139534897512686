import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
// custom imports
import FormInputText from '../../../../utils/form/FormInputText'
import FormInputNumber from '../../../../utils/form/FormInputNumber'
import FormInputAutocomplete from '../../../../utils/form/FormInputAutocomplete'
import FormInputTime from '../../../../utils/form/FormInputTime'
import ApiForm from '../../../../utils/form/ApiForm'
import { FormSpacing } from '../../../../utils/globals'
import { Box } from '@mui/material'


interface FactoryFormProps {
  id: number | undefined
  open: boolean
  closeForm: (submit: boolean) => void
  states: any[]
  timezones: any[]
}

/**
 * FactoryForm component
 * @param closeForm
 * @param open
 * @param id
 * @param states: State choices
 * @param timezones: Timezone choices
 * @constructor
 */
const FactoryForm: FC<FactoryFormProps> = ({id, open, closeForm, states, timezones}) => {

  const fetchUrl: string = id ? `/main/factories/${id}/` : ''
  const submitUrl: string = '/main/factories/'

  console.log('states :', states)
  console.log('timezones :', timezones)

  return (
    <ApiForm
      name='Factory Form'
      open={open}
      defaultValues={{name: '', code: '', address: '', city: '', state: '', timezone: '', phone: '', zip: '',
        shift_count: '', time_diff_temp: '', shift_start: ''}}
      closeForm={closeForm}
      id={id}
      fetchUrl={fetchUrl}
      submitUrl={submitUrl}
      timeFields={['shift_start']}
    >
      <Grid container spacing={FormSpacing}>
        <Grid item md={8}><FormInputText name='name' label='Name' /></Grid>
        <Grid item md={4}><FormInputText name='code' label='Code' /></Grid>
        <Grid item md={12}><FormInputText name='address' label='Address' /></Grid>
        <Grid item md={4}><FormInputText name='city' label='City' /></Grid>
        <Grid item md={4}><FormInputAutocomplete name='state' label='State' options={states} /></Grid>
        <Grid item md={4}><FormInputAutocomplete name='timezone' label='Timezone' options={timezones} /></Grid>
        <Grid item md={4}><FormInputText name='phone' label='Phone' /></Grid>
        <Grid item md={4}><FormInputNumber name='zip' label='Zip' /></Grid>
        <Grid item md={4}><FormInputNumber name='shift_count' label='Shift Count' /></Grid>
      </Grid>
			<Grid>
				<Box sx={{ my: 1 }}>
					<small>Temperature (°F) at which the time molds spend in the oven raises or lowers</small>
				</Box>
				<Box>
					<FormInputNumber name='time_diff_temp' label='Time difference temperature °F' />
				</Box>
			</Grid>
			<Grid sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
				<Box>
					<small>Setting first shift start time, dynamically sets the rest of the shift start/end times</small>
				</Box>
				<Box sx={{ ml: 1, mt: 1 }}>
					<FormInputTime name='shift_start' label='First Shift Start Time' />
				</Box>
			</Grid>
    </ApiForm>
  )
}

export default FactoryForm
