// React imports
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// Custom imports
import Main from './components/main/main';
import "./App.css"
import { LicenseInfo } from '@mui/x-date-pickers-pro';

LicenseInfo.setLicenseKey('aa142e5f7defdb40606bde600e17eb03Tz05MzQwOCxFPTE3NTEyMzQzNTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

ReactDOM.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
