// ProductivityReportFilters.tsx
import React, { useState, useEffect } from 'react';
import { DateRangePicker, LocalizationProvider, DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { startOfMonth, endOfToday, formatISO, startOfWeek, startOfYear } from 'date-fns';
import { PageState, Shift } from './hooks/Interfaces';
import ExcelImportDialog from '../../../../websocket/utils/ExcelImportDialog';

interface ProcessingReportFiltersProps {
	isReady: Boolean
	pageState: PageState;
	sendMessage: (message: string, data?: { [key: string]: any }, refresh?: boolean) => void;
	shifts: Shift[];
}

const ProcessingReportFilters: React.FC<ProcessingReportFiltersProps> = ({ pageState, sendMessage, shifts, isReady }) => {
	const [dateRange, setDateRange] = useState<DateRange<Date>>([
		startOfMonth(new Date()),
		endOfToday()
	]);

	const [selectedShift, setSelectedShift] = useState<Shift | null>(() => {
		const shift = pageState.filters.shift;
		return shift ? shift : { id: 'all', name: 'All' };
	});

	const [period, setPeriod] = useState<string>('monthToDate');

	useEffect(() => {
		if (dateRange[0] && dateRange[1] && isReady) {
			const start = formatISO(dateRange[0], { representation: 'complete' });
			const end = formatISO(dateRange[1], { representation: 'complete' });
			sendMessage('change_dates', { start, end }, true);
			pageState.filters.start = start;
			pageState.filters.end = end;
		}
	}, [dateRange]);

	useEffect(() => {
		// Initialize selectedShift with pageState's shift value
		const shift = pageState.filters.shift;
		setSelectedShift(shift ? shift : { id: 'all', name: 'All' });
	}, [pageState.filters.shift]);

	const handleShiftChange = (event: SelectChangeEvent<string>) => {
		const shiftId = event.target.value;
		const shift = shiftId === 'all' ? { id: 'all', name: 'All' } : shifts.find(s => s.id === shiftId) || null;
		setSelectedShift(shift);
		sendMessage('change_shift', { shift }, true);
		pageState.filters.shift = shift;
	};

	const handlePeriodChange = (event: SelectChangeEvent<string>) => {
		const selectedPeriod = event.target.value;
		setPeriod(selectedPeriod);

		let newStart: Date;
		const today = endOfToday();

		switch (selectedPeriod) {
			case 'weekToDate':
				newStart = startOfWeek(today, { weekStartsOn: 1 }); // Assuming week starts on Monday
				break;
			case 'yearToDate':
				newStart = startOfYear(today);
				break;
			case 'monthToDate':
			default:
				newStart = startOfMonth(today);
				break;
		}

		setDateRange([newStart, today]);
	};

	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 3 }}>
			<Box>
				<ExcelImportDialog requiredHeaders={['Item Name', 'Unit Cost', 'Sales Price']} sendMessage={sendMessage} buttonSx={{ height: 56, mr: 2 }} buttonText='Import Financial Data' />
				<FormControl sx={{ minWidth: 120, marginRight: 2}}>
					<InputLabel id="shift-select-label">Shift</InputLabel>
					<Select
						labelId="shift-select-label"
						value={selectedShift?.id || 'all'}
						label="Shift"
						onChange={handleShiftChange}
					>
						<MenuItem value="all">All</MenuItem>
						{shifts.map((shift) => (
							<MenuItem key={shift.id} value={shift.id}>
								{shift.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			<Box sx={{ display: 'flex' }}>
				<FormControl sx={{ minWidth: 160, marginRight: 2 }}>
					<InputLabel id="period-select-label">Period</InputLabel>
					<Select
						labelId="period-select-label"
						value={period}
						label="Period"
						onChange={handlePeriodChange}
					>
						<MenuItem value="weekToDate">Week to Date</MenuItem>
						<MenuItem value="monthToDate">Month to Date</MenuItem>
						<MenuItem value="yearToDate">Year to Date</MenuItem>
					</Select>
				</FormControl>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateRangePicker
						value={dateRange}
						onChange={(newValue) => setDateRange(newValue)}
					/>
				</LocalizationProvider>
			</Box>
		</Box>
	);
};

export default ProcessingReportFilters;
