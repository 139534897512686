import {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import {FormSpacing, useEffectApi} from "../../../../utils/globals";
import BaseModal from "../../../../utils/modals/BaseModal";
import { exportLogs, getFormData } from "./NonconformingLogApi";
import { downloadExportFile, getExportFilename } from "../../../../utils/api";
import FormInputRadioGroup from "../../../../utils/form/FormInputRadioGroup";


interface QAExportFormProps {
  onClose: () => void, 
  onCancel: () => void, 
	startDate: string, 
	endDate: string, 
	setExporting: Dispatch<SetStateAction<boolean>>
}
const QAExportForm: FC<QAExportFormProps> = ({onClose, onCancel, startDate, endDate, setExporting}) => {

  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const [showConforming, setShowConforming] = useState<boolean>(false)
	
	interface Option {
		value: string;
		label: string;
	}
	const [logOptions, setLogOptions] = useState<Option[]>([
		{ value: 'non_conforming', label: 'Non Conforming Logs'},
		{ value: 'conforming', label: 'Conforming Logs'},
		{ value: 'all', label: 'All Logs'}
	])

  const methods = useForm({
		defaultValues: {
			log_type: logOptions[0].value
		}
	});
  const { handleSubmit, reset, setError } = methods

	useEffect(() => {
		if (startDate && endDate) {
			setOpen(true)
		} else {
			setOpen(false)
		}	
	}, [startDate, endDate])

  const submit = (formData: any) => {
		const data = {
			startDate, 
			endDate, 
			...formData
		}
		setExporting(true)
		exportLogs(data, (resp: any) => {
      downloadExportFile(resp.data, getExportFilename(resp.headers))
			setExporting(false)
    })
		onClose()
  }

  return (
    <BaseModal
      title='Export Quality Assurance Data'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Cancel', action: onCancel, outlined: true},
        {text: 'Export', action: handleSubmit(submit)},
      ]}
      maxWidth='sm'
    >
      <FormProvider {...methods}>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={FormSpacing}>
          <Grid item style={{ marginTop: '15px' }}>
            <FormInputRadioGroup row name='log_type' label='Log Selection' options={logOptions} />
          </Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default QAExportForm
