import React, { FC, useState } from 'react'
import {useTheme} from '@mui/material/styles'
// mui imports
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from "@mui/material/Paper";
// mui icon imports
import ArrowUpward from '@mui/icons-material/ArrowUpward'
// custom imports
import {useEffectApi} from '../../../../utils/globals'
import BaseModal from '../../../../utils/modals/BaseModal'
import {getAssemblyChildLookupData, pushItemToTop} from './LineplanSchedulingApi'
import {LineplanSchedulingRow} from './LineplanSchedulingTable'
import {patchPerGroupOverall} from '../../../../utils/helpers'
import {colors} from '../../../../utils/colors'

interface AssemblyChildLookupModalProps {
  id?: number | undefined
  onClose: () => void
}
const AssemblyChildLookupModal: FC<AssemblyChildLookupModalProps> = ({id, onClose}) => {

  const theme: any = useTheme()

  const [parentName, setParentName] = useState<string | undefined>(undefined)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])

  const loadData = () => {
    getAssemblyChildLookupData(id, (data: any) => {
      setParentName(data.parentName)
      setData(data.items)
    })
  }

  useEffectApi(() => {

    if (id) {
      setOpen(true)
      loadData()
    } else
      setOpen(false)

  }, [id, refresh])

  /**
   * @param id: lineplan item id
   */
  const pushToTop = (id: number) => {
    pushItemToTop(id, () => {
      console.log('pushed to top!')
      setRefresh(!refresh)
    })
  }

  const getPushToTopAction = (obj: any) => {
    const actions: any[] = []
    if (obj.child)
      actions.push({
        icon: <ArrowUpward />,
        text: 'Push to Top',
        action: pushToTop,
        highlight: 'Pushes this item to the top of its staged lineplan'
      })

    return actions
  }

  return (
    <BaseModal
      title={`Assembly Child Lookup: ${parentName}`}
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[]}
      maxWidth='xl'
    >
      <Grid container>
        {data.map((childOrder: any, key: number) => (
          <Grid item xs={12} sx={key > 0 ? {mt: '15px'} : {}} key={key}>
            <Typography variant='h6'>{childOrder.name}</Typography>
            {childOrder.data.length > 0 ?
              <TableContainer sx={{mt: '10px'}} key={key} component={Paper}>
                <Table size='small'>
                  <TableHead sx={{backgroundColor: colors[theme.palette.mode].table.header}}>
                    <TableRow>
                      <TableCell align='center'>Priority</TableCell>
                      <TableCell align='center'>Order</TableCell>
                      <TableCell align='center'>Item</TableCell>
                      <TableCell align='center'>Description</TableCell>
                      <TableCell align='center'>Balance</TableCell>
                      <TableCell align='center'>Available</TableCell>
                      <TableCell align='center'>Quantity</TableCell>
                      <TableCell align='center'>Takt Time</TableCell>
                      <TableCell align='center'>Per Group</TableCell>
                      <TableCell align='center'>Overall</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patchPerGroupOverall(childOrder.data).map((childLineplanItem: any, key: number) => (
                      <React.Fragment key={key}>
                        <LineplanSchedulingRow
                          object={childLineplanItem}
                          actions={getPushToTopAction(childLineplanItem)}
                          autoBreakpoint={false}
                          manualBreakpoint={false}
                          showNumber
                        />
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <>
                {!childOrder.live ?
                  <Typography variant='subtitle2'>No line plan item exists</Typography>
                  : <></>}
              </>
            }
          </Grid>
        ))}
      </Grid>
    </BaseModal>
  )
}

export default AssemblyChildLookupModal
