import React, {FC, useEffect, useState} from 'react';
import { AxiosError, AxiosResponse } from "axios";
// custom imports
import PageTitle from "../../../../utils/globalComps/PageTitle";
import ApiTable from "../../../../utils/table/ApiTable";
import PermissionForm from "./PermissionForm";
import {api, useEffectApi} from "../../../../utils/globals";
import { doAlert } from "../../../../utils/globalComps/PopupAlert/PopupAlert";
import {getPermissionData} from "./PermissionApi";

const url: string = '/main/permissions/'

const Permissions: FC = () => {

  const [viewList, setViewList] = useState<any[]>([])
  const [groupList, setGroupList] = useState<any[]>([])
  const [permissionList, setPermissionList] = useState<any[]>([])

  const [extraInfo, setExtraInfo] = useState<any[]>([]);

  useEffectApi(() => {

    getPermissionData((data: any) => {
      setViewList(data.views)
      setGroupList(data.groups)
      console.log('getPermissionData :', data)
    })

  }, [])

  return (
    <>
      <PageTitle title='Permission Groups' />
      <ApiTable
        objectName='Group'
        headers={['Name', 'Description', '']}
        rowFields={['name', 'description']}
        dataField='objects'
        url={url}
        refreshable
        creatable
        editable
        deletable
        excelImportId={'permissions'}
        expandableParams={{}}
        FormComponent={PermissionForm}
        formParams={{permissionList: permissionList, extraInfo: extraInfo, views: viewList, groups: groupList}}
      />
    </>
  );
}

export default Permissions;
