import React, { FC, useState, useEffect } from 'react'
// mui icon imports
import Autorenew from '@mui/icons-material/Autorenew'
// custom imports
import { getFormData, toggleAutoShiftLogout } from './PersonnelApi'
import ApiTable from '../../../../utils/table/ApiTable'
import PersonnelForm from './PersonnelForm'
import PageTitle from '../../../../utils/globalComps/PageTitle'

interface PersonnelProps {}
const Personnel: FC<PersonnelProps> = () => {

  const [autoShiftLogoutOnText, setAutoShiftLogoutOnText] = useState<string>('Off')
  const [infoText, setInfoText] = useState<string>('')
  const [factories, setFactories] = useState<any[]>([])

  const text: string = 'When auto shift logout is on, every logged in personnel is logged out at the end of every shift.'

  useEffect(() => {
    document.title = 'Personnel | RotoEdgePro'
    getFormData((data) => {
      setFactories(data.factories)
      setAutoShiftLogoutOnText(data.autoShiftLogoutOn ? 'Off' : 'On')
      setInfoText(data.autoShiftLogoutOn ? text : '')
    })
  }, [setFactories])

  const doToggleAutoShiftLogout = () => {
    toggleAutoShiftLogout((data: any) => {
      setAutoShiftLogoutOnText(data.autoShiftLogoutOn ? 'Off' : 'On')
      setInfoText(data.autoShiftLogoutOn ? text : '')
    })
  }

  return (
    <>
      <PageTitle title='Personnel Management'/>
      <ApiTable
        tableName=''
        objectName='Personnel'
        tableHeadText={infoText}
        titleVariant=''
        tableActions={[
          {icon: <Autorenew />, text: `Turn ${autoShiftLogoutOnText} Auto Shift Logout`,
            action: doToggleAutoShiftLogout, left: true}
        ]}
        headers={['Personnel ID', 'Name', 'Factory', 'Login Code', 'Adjustment', '']}
        rowFields={['personnel_id', 'name', 'factory_name', 'login_code', 'adjustment_display']}
        dataField='objects'
        url='/productivity/personnel/'
        editable
        deletable
        creatable
        orderable
        importable
        exportable
        refreshable
        searchable
        excelImportId='personnel'
        FormComponent={PersonnelForm}
        formParams={{factories: factories}}
      />
    </>
  )
}

export default Personnel
