import React, { FC } from 'react';
import BaseModal from "../../../utils/modals/BaseModal";
import Typography from "@mui/material/Typography";
import InfoText from "../../../utils/globalComps/InfoText";

interface LoadAfterModalProps {
  open: boolean;
  onClose: () => void;
  doLoadAfter: () => void;
  doLoadNothing: () => void;
  items: any[];
}
const LoadAfterModal: FC<LoadAfterModalProps> = ({open, onClose, doLoadAfter, doLoadNothing, items}) => {
  return (
    <BaseModal
      title='Load After Items'
      open={open}
      dividers
      closeHandler={onClose}
      actions={[
        {text: 'Load Nothing', action: doLoadNothing, color: 'warning'},
        {text: 'Load these Items', action: doLoadAfter, disabled: items.length === 0}
      ]}
			preventClose={true}
    >
      <>
        <InfoText text='These molds will be loaded on after the current mold is removed' />
        {items.map((item: any, key: number) => (
          <Typography key={key}>
            {item.order} - {item.description}
          </Typography>
        ))}
      </>
    </BaseModal>
  );
}

export default LoadAfterModal;
