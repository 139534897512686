import React, {FC, useEffect, useState} from 'react';
// mui imports
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// custom imports
import { colors } from "../colors";
import {acknowledgeMessage, getLatestMessage, hideMessage} from "../modals/ChannelModal/ChannelModalApi";
import Typography from "@mui/material/Typography";
import ChannelModal from "../modals/ChannelModal/ChannelModal";

interface ChannelActionProps {
  text: string;
  action?: () => void;
}
const ChannelAction: FC<ChannelActionProps> = ({text, action}) => {
  return (
    <Button onClick={action ? action : () => {}}>
      {text}
    </Button>
  );
}

interface ChannelProps {
  armId?: number;
  linePlanId?: number;
  shipping?: boolean | undefined
  forceRefresh?: boolean | undefined
}
const Channel: FC<ChannelProps> = ({armId, linePlanId, shipping,
                                     forceRefresh}) => {
  const theme: any = useTheme();
  const [latestChannelMessage, setLatestChannelMessage] = useState<string>('');
  const [latestChannelMessageId, setLatestChannelMessageId] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [messageUser, setMessageUser] = useState<string>('');
  const [messageDatetime, setMessageDatetime] = useState<string>('');
  const [channelModalArmId, setChannelModalArmId] = useState<number | undefined>(undefined);
  const [channelModalLinePlanId, setChannelModalLinePlanId] = useState<number | undefined>(undefined);
  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const refreshChannel = () => setRefresh(!refresh);

  useEffect(() => {
    let isActive: boolean = true;

    if ((armId !== undefined || linePlanId !== undefined || shipping) && isActive)
      getLatestChannelMessage();

    // console.log('channel use effect')

    return () => {isActive = false};
  }, [armId, refresh, forceRefresh])

  const channelBorderColor: string = colors[theme.palette.mode].channel.outline;
  const channelBackgroundColor: string = colors[theme.palette.mode].channel.background;

  // helpers
  const getLatestChannelMessage = () => {
    getLatestMessage(armId, linePlanId, shipping, (message: string, user: string, datetime: string, id: number, acknowledged: boolean) => {
      if (message && message !== '') {
        // console.log('has latest message')
        setVisible(true)
        setLatestChannelMessage(message);
        setMessageUser(user);
        setLatestChannelMessageId(id);
        setMessageDatetime(datetime);
        setAcknowledged(acknowledged)
      } else {
        console.log('no latest message')
        setVisible(false);
      }
    });
  }
  const doAcknowledgeMessage = () => {
    if (latestChannelMessageId) acknowledgeMessage(latestChannelMessageId, () => refreshChannel());
  }

  // channel modal
  const openChannel = () => {
    setChannelModalArmId(armId);
    setChannelModalLinePlanId(linePlanId);
  }
  const closeChannel = () => {
    setChannelModalArmId(undefined);
    setChannelModalLinePlanId(undefined);
  }
  const doHideMessage = () => {
    if (latestChannelMessageId)
      hideMessage(latestChannelMessageId, () => {
        refreshChannel();
      })
  }

  return (
    <>
      <Grid container justifyContent='center' sx={{outline: `1px solid ${channelBorderColor}`,
        background: channelBackgroundColor, ml: '10px', mb: '10px', mt: '10px', width: '90%', display: `${!visible ? 'none' : 'flex'}`}}>
        <Grid item xs={12} sx={{margin: '5px'}}>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant='subtitle2'>
                {latestChannelMessage}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle2'>
                {messageUser} - {messageDatetime}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ChannelAction text='Open Channel' action={openChannel} /> |
          {!acknowledged ? <><ChannelAction text='Acknowledge Message' action={doAcknowledgeMessage} /> |</> : <></>}
          <ChannelAction text='Hide Message' action={doHideMessage} />
        </Grid>
      </Grid>
      <ChannelModal armId={channelModalArmId} linePlanId={channelModalLinePlanId} channelClose={closeChannel} />
    </>
  );
}

export default Channel;