import React, {FC, useState} from "react";
import Grid from "@mui/material/Grid";
import ProgressTickedBar from "./ProgressTickedBar";
import Typography from "@mui/material/Typography";
import {useEffectApi} from "../../globals";
import {getLineplanLoggedProgress} from "./ProductivityProgressApi";


interface LineplanLoggedProgressProps {
  id: number
  shiftId: number
  refresh: boolean | undefined
  extraRefresh: boolean | undefined
}
const LineplanLoggedProgress: FC<LineplanLoggedProgressProps> = ({id, shiftId, refresh, extraRefresh}) => {

  const [loggedIns, setLoggedIns] = useState<any[]>([])
  const [ticks, setTicks] = useState<any[]>([])
  const [progress, setProgress] = useState<number>(0)
  const [loggedTakt, setLoggedTakt] = useState<number>(0)
  const [targetTakt, setTargetTakt] = useState<number>()
  const [armLogs, setArmLogs] = useState<number>()
  const [shift, setShift] = useState<number>()

  useEffectApi(() => {
    getLineplanLoggedProgress(id, shiftId, (data: any) => {
      // console.log('data :', data)
      setTicks(data.ticks)
      setLoggedIns(data.logged_ins)
      setProgress(data.logged_progress)
      setTargetTakt(data.target)
      setLoggedTakt(data.logged)
			setArmLogs(data.arm_logs)
			setShift(data.shift)
    })
  }, [id, shiftId, refresh, extraRefresh])

  return (
    <Grid container item>
      <Grid item xs={12}>
        <ProgressTickedBar progress={progress} ticks={ticks} flatEnd={true}>
          <Typography variant='subtitle1'>
            <strong>Total</strong> Logged takt / Target takt: <strong>{loggedTakt} / {targetTakt}</strong></Typography>
        </ProgressTickedBar>
      </Grid>
      <Grid container item xs={12} justifyContent='center' sx={{mb: '20px'}}>
        {loggedIns.map((loggedIn: any, key: number) => (
          <Grid item xs={10} key={key}>
            <ProgressTickedBar progress={loggedIn.logged_progress} ticks={ticks} spacing={1}>
              <Typography variant='subtitle1'>
                <strong>{loggedIn.name}</strong> Logged takt / Target takt: <strong>{loggedIn.logged} / {loggedIn.target}</strong>
              </Typography>
            </ProgressTickedBar>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default LineplanLoggedProgress
