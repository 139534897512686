import {FC, useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import {FormSpacing, useEffectApi} from "../../../../utils/globals";
import BaseModal from "../../../../utils/modals/BaseModal";
import FormInputText from "../../../../utils/form/FormInputText";
import FormInputNumber from "../../../../utils/form/FormInputNumber";
import FormInputDropdown from "../../../../utils/form/FormInputDropdown";
import {confirmUnit, getFormData} from "./NonconformingLogApi";


interface ConfirmFormProps {
  id?: string | undefined
  onClose: () => void
  shiftOptions: any[]
  machineOptions: any[]
  codeOptions: any[]
}
const ConfirmForm: FC<ConfirmFormProps> = ({id, onClose, codeOptions, shiftOptions, machineOptions}) => {

  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const [showConforming, setShowConforming] = useState<boolean>(false)

  const methods = useForm()
  const { handleSubmit, reset, setError } = methods

  useEffectApi(() => {
    if (id) {
      getFormData(id, (data: any) => {
        console.log('getting:', data)
        setData(data)
        setShowConforming(data.apply_machine !== -1)
        reset(data)
      })
      setOpen(true)
    } else
      setOpen(false)
  }, [id])

  const submit = (data: any) => {
    if (id)
      confirmUnit(id, data, (errors: any[]) => {
				console.log('posting', data)
        if (errors.length > 0)
          for (const error of errors)
            setError(error.name, {type: 'server', message: error.message})
        else
          onClose()
      })
  }
  const doReset = () => reset(data)

  return (
    <BaseModal
      title='Confirm Non-Conforming Log'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Reset', action: doReset, outlined: true},
        {text: 'Submit', action: handleSubmit(submit)},
      ]}
      maxWidth='md'
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          <Grid item xs={4}><FormInputText name='order' label='Order' disabled /></Grid>
          <Grid item xs={4}><FormInputText name='location' label='Location' disabled /></Grid>
          <Grid item xs={4}><FormInputText name='shift' label='Shift' disabled /></Grid>
          <Grid item xs={showConforming ? 4 : 3}>
            <FormInputDropdown name='code' label='Non-Conforming Code' options={codeOptions} />
          </Grid>
					<Grid item xs={showConforming ? 4 : 3}>
            <FormInputDropdown name='apply_machine' label='Apply To Machine' options={machineOptions} />
          </Grid>
          <Grid item xs={showConforming ? 4 : 3}>
            <FormInputDropdown name='apply_shift' label='Apply To Shift' options={shiftOptions} />
          </Grid>
          <Grid item xs={showConforming ? 6 : 3}><FormInputNumber name='non_conforming' label='Non Conforming' min={0} /></Grid>
          {showConforming ?
            <Grid item xs={6}>
              <FormInputNumber name='conforming' label='Conforming' min={0} />
            </Grid>
            :
            <></>
          }
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default ConfirmForm
