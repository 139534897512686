import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import moment from 'moment'
// mui imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
// custom imports
import { FormSpacing, useEffectApi } from '../../../../utils/globals'
import { getFormData } from './PersonnelLoginApi'
import BaseModal from '../../../../utils/modals/BaseModal'
import FormInputText from '../../../../utils/form/FormInputText'
import FormInputNumber from '../../../../utils/form/FormInputNumber'
import FormInputDatetime from '../../../../utils/form/FormInputDatetime'

interface PersonnelLoginFormProps {
  id?: number | undefined
  machine: boolean
  onClose: () => void
  doSubmit: (data: any) => void
  errors: any[]
}

const PersonnelLoginForm: FC<PersonnelLoginFormProps> = ({id, machine, onClose, doSubmit,
                                                           errors}) => {

  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<any>({})

  const methods = useForm({defaultValues: data})
  const { reset, handleSubmit, setValue, setError } = methods

  useEffectApi(() => {

    if (errors && errors.length > 0)
      for (const error of errors)
        setError(error.name, {type: 'server', message: error.message})

    if (id) {
      setOpen(true)
      getFormData(id, (data: any) => {
        console.log('object :', data.object[0])
        const object: any = {
          ...data.object[0],
          login_time: moment(data.object[0].login_time).toDate(),
          logout_time: moment(data.object[0].logout_time).toDate()
        }
        console.log('object :', object)
        setData(object)
        reset(object)
      })
      // get data
    } else
      setOpen(false)

  }, [id])

  const doReset = () => reset(data)

  return (
    <BaseModal
      title='Edit Login Data'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={data.logout_time !== null ? [
        {text: 'Reset', action: doReset, outlined: true},
        {text: 'Submit', action: handleSubmit(doSubmit)}
      ] : []}
      maxWidth='lg'
    >
      <>
        <FormProvider {...methods}>
          <Grid container spacing={FormSpacing}>
            <Grid item xs={6}><FormInputText name='personnel' label='Personnel' disabled /></Grid>
            <Grid item xs={6}><FormInputText name='location' label='Location' disabled /></Grid>
            <Grid item xs={3}><FormInputNumber name='billed_time' label='Billed Time' min={0} disabled={data.logout_time === null} float /></Grid>
            <Grid item xs={3}><FormInputNumber name='takt_time' label='Takt Time' min={0} disabled={data.logout_time === null} float /></Grid>
            <Grid item xs={3}><FormInputNumber name='conforming_parts' label='Conforming Parts' min={0} disabled={data.logout_time === null} float /></Grid>
            <Grid item xs={3}><FormInputNumber name='non_conforming_parts' label='Non Conforming Parts' min={0} disabled={data.logout_time === null} float /></Grid>
            {machine ? <Grid item xs={4}><FormInputNumber name='arms_logged' label='Arms Logged' min={0} disabled={data.logout_time === null} float /></Grid> : <></>}
            <Grid item xs={machine ? 4 : 6}><FormInputDatetime name='login_time' label='Login Time' disabled={data.logout_time === null} /></Grid>
            <Grid item xs={machine ? 4 : 6}><FormInputDatetime name='logout_time' label='Logout Time' disabled={data.logout_time === null} /></Grid>
            {data.logout_time === null ?
              <Grid item xs={12} sx={{textAlign: 'center'}}>
                <Typography variant='subtitle1' sx={{fontWeight: 'bold'}}>Currently logged in</Typography>
              </Grid>
              : <></>}
          </Grid>
        </FormProvider>
      </>
    </BaseModal>
  )
}

export default PersonnelLoginForm
