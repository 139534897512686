import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
// custom imports
import { doScheduleToLineplan, getScheduleToLineplanData } from './ScheduleToLineplanApi'
import { FormSpacing, useEffectApi } from '../../globals'
import { doConfirm } from '../../modals/Confirm'
import BaseModal from '../../modals/BaseModal'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormInputDropdown from '../../form/FormInputDropdown'
import InfoText from '../InfoText'


interface ScheduleToLineplanProps {
  id: number | undefined
  closeHandler: () => void
}

/**
 * An order can be scheduled to only one lineplan/shift.
 *    if there are three shifts, and a lineplan assigned on each of those shifts, the order can be scheduled 3 times
 *    to each different lineplan/shift. If there are three shifts, but all lineplans are assigned to 1 shift then the
 *    order can only be scheduled to one line plan. This is to make sure that factory employees are not fighting over
 *    available product on the floor.
 *
 * @param id: order id to schedule/reschedule
 * @param closeHandler
 */
const ScheduleToLineplan: FC<ScheduleToLineplanProps> = ({id, closeHandler}) => {

  const [orderName, setOrderName] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState<boolean>(false)
  const [types, setTypes] = useState<any[]>([])
  const [shifts, setShifts] = useState<any[]>([])
  const [options, setOptions] = useState<any[]>([])

  const [alreadyLive, setAlreadyLive] = useState<any[]>([])
  const [alreadyStaged, setAlreadyStaged] = useState<any[]>([])

  const methods: any = useForm()
  const { reset, setError, handleSubmit } = methods

  const resetSchedule = (types: any[], shifts: any[], alreadyStaged: any[]) => {
    let resetData: any = {}
    for (const shift of shifts)
      for (const type of types) {
        console.log(`lineplans_${type.number}_${shift.id} =`, filterDataByTypeAndShift(type.number, shift.id, alreadyStaged).map((lineplan: any) => lineplan.value))
        resetData[`lineplans_${type.number}_${shift.id}`] = filterDataByTypeAndShift(type.number, shift.id, alreadyStaged).map((lineplan: any) => lineplan.value)
      }

    console.log('reset data :', resetData)
    reset(resetData)
  }

  useEffectApi(() => {
    setOpen(id !== undefined)
    if (id) {
      getScheduleToLineplanData(id, (data: any) => {
        console.log('data :', data)
        setOrderName(data.orderName)
        setTypes(data.types)
        setShifts(data.shifts)
        setOptions(data.options)
        setAlreadyStaged(data.alreadyStaged)
        setAlreadyLive(data.alreadyLive)

        resetSchedule(data.types, data.shifts, data.alreadyStaged)
      })
    }
  }, [id])

  const submitSchedule = (data: any) => {
    console.log('submit schedule :', data)
    if (id) {
      doScheduleToLineplan(id, data, true, (cbData: any) => {
        // do confirm data
        let messages: string[] = []
        console.log('cbData :', cbData)
        if (cbData.add.length > 0)
          messages.push('Adding to: ' + cbData.add.join(', '))
        if (cbData.exists.length > 0)
          messages.push('No changes to: ' + cbData.exists.join(', '))
        if (cbData.remove.length > 0)
          messages.push('Removing from: ' + cbData.remove.join(', '))

        doConfirm(messages, () => {
          doScheduleToLineplan(id, data, false, () => closeHandler())
        })
      })
    }
  }

  const filterOptionsByTypeAndShift = (type: number, shift: number) => options.filter((option: any) =>
    option.type === type && option.shift === shift)
  const filterDataByTypeAndShift = (type: number, shift: number, data: any[]) => data.filter((obj: any) =>
    obj.type === type && obj.shift === shift)

  return (
    <BaseModal
      title={`Schedule to Lineplan ${orderName}`}
      open={open}
      closeHandler={closeHandler}
      dividers
      closeButton
      actions={[
        {text: 'Reset', action: () => resetSchedule(types, shifts, alreadyStaged), outlined: true, left: true},
        {text: 'Submit', action: handleSubmit(submitSchedule)}
      ]}
      maxWidth='md'
      doSubmit={handleSubmit(submitSchedule)}
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>

          <Grid item xs={12}>
            <InfoText text='Lineplans this order is already scheduled to will be automatically selected. Items will remain
            scheduled if they are left selected.' />
          </Grid>

        {types.map((type: any, key: number) => (
          <React.Fragment key={key}>
            {shifts.map((shift: any, key: number) => (
              <React.Fragment key={key}>
                {filterOptionsByTypeAndShift(type.number, shift.id).length > 0 ?
                  <Grid key={key} container item spacing={FormSpacing}>
                    <Grid item xs={12}><Typography variant='h6'>{type.name} - {shift.min_name} Shift</Typography></Grid>
                    <Grid item xs={12}><FormInputDropdown name={`lineplans_${type.number}_${shift.id}`} label='Stage to these Lineplans'
                                                          options={[{label: 'Unstage', value: -1}, ...filterOptionsByTypeAndShift(type.number, shift.id)]} /></Grid>
                    {/*<InfoText text={''} />*/}
                    {filterDataByTypeAndShift(type.number, shift.id, alreadyLive).length > 0 ?
                      <>
                        <Grid item xs={12}>
                          <Typography variant='subtitle1'>On Live Lines:</Typography>
                        </Grid>
                        {filterDataByTypeAndShift(type.number, shift.id, alreadyLive).map((lineplan: any, key: number) => (
                          <Grid item xs={12} key={key}>
                            <Typography variant='subtitle2'>{lineplan.label}</Typography>
                          </Grid>
                        ))}
                      </>
                      :
                      <></>
                    }
                  </Grid>
                  : <></>}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default ScheduleToLineplan
