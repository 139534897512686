import { DependenciesConfig, DateHelper, EventDragCreate, EventTooltipConfig, SchedulerConfig, TimeAxisHeaderMenu, TimeRangesConfig } from "@bryntum/scheduler";
// import { DateHelper } from "@bryntum/scheduler";

/****** Global Variables *******/

// The heigh of each scheduler bar + its margin
const ROW_HEIGHT: number = 45;
const ROW_MARGIN: number = 5;

// Determine the current date and adjust if it's Saturday or Sunday
// const getAdjustedStartDate = () => {
// 	const today = new Date();
// 	const dayOfWeek = today.getDay();

// 	// If today is Saturday (6), add 2 days to make it Monday
// 	// If today is Sunday (0), add 1 day to make it Monday
// 	if (dayOfWeek === 6) {
// 			today.setDate(today.getDate() + 2);
// 	} else if (dayOfWeek === 0) {
// 			today.setDate(today.getDate() + 1);
// 	}
// 	console.log(today)
// 	return today;
// }
// // configured start date and end date
// const START_DATE = getAdjustedStartDate()

// configured start date and end date
const START_DATE = new Date();

// if we should only include working hours (configured with vars below). Note:
// this should always be true- but can be useful to turn off for debugging
const ONLY_WORKING_HOURS = false;

// variables configuring the boundaries for the work week
const WORKING_START_DAY = 1; // Monday
const WORKING_END_DAY = 6; // Saturday
const WORKING_START_HOUR = 8; // 8:00 AM
const WORKING_END_HOUR = 17; // 5:00 PM

// Static configuration for dependencies
const dependencyConfig: Partial<DependenciesConfig> = {
  allowCreate: false
}

// Static configuration for the time ranges feature 
const timeRangesConfig: Partial<TimeRangesConfig> = {
    // The green line that shows the current date 
    showCurrentTimeLine: {
      name: "Now",
    },
    // How often the 'Now' line updates. Default is 10000 ms or 10 sec- but we override this to 24 hours
    currentTimeLineUpdateInterval: 86400000, // Update in one day (in ms)
    showHeaderElements: true,
    enableResizing: true,
    showTooltip: true,
    callOnFunctions: true,
}

// Config for orders when they are hovered 
const eventTooltipConfig: Partial<EventTooltipConfig> = {
  template: (data: any) => {

    console.log('data :', data.eventRecord)

    if (data.eventRecord.work_order_number) {
      return `
        <dl style="margin-top: 5px; margin-bottom: 5px; border-radius: 50px">
          <dt>
            <strong>Order: ${data.eventRecord.work_order_number} | ${data.eventRecord.balance} Parts</strong>
          </dt>
          <dt><strong>Item: ${data.eventRecord.data.item} | ${data.eventRecord.mold_load_quantity} Mold(s)</strong></dt>
          <dt style="margin-bottom: 5px">${data.eventRecord.data.description.trim()}</dt>
          <dd>
            Start: ${DateHelper.format(data.eventRecord.startDate, "hh:mm A MM/DD")}
          </dd>
          <dd>
            End: ${DateHelper.format(data.eventRecord.endDate, "hh:mm A MM/DD")}
          </dd>
        </dl>`.trim()
    } else {
      return `<dl style="margin-top: 5px; margin-bottom: 5px">
      <dt style="margin-bottom: 5px">
        <strong>Counter Weight</strong>
      </dt>
      <dd>
        Start: ${DateHelper.format(data.eventRecord.startDate, "hh:mm A MM/DD")}
      </dd>
      <dd>
        End: ${DateHelper.format(data.eventRecord.endDate, "hh:mm A MM/DD")}
      </dd>
      </dl>`
    }
  }
}



// Baseline configurations for the scheduler.
const schedulerConfig: Partial<SchedulerConfig> = {
  startDate: START_DATE,
  // Commnenting this out. This could be used to configure working times into the scheduler (IE. A molding 
  // factory only molds during the week days or only molds from 9:00AM - 9:00PM, etc)
  // https://www.bryntum.com/docs/scheduler/api/Scheduler/view/TimelineBase#config-workingTime
  // workingTime: {
  //   fromDay: ONLY_WORKING_HOURS ? WORKING_START_DAY : undefined,
  //   toDay: ONLY_WORKING_HOURS ? WORKING_END_DAY : undefined,
  //   fromHour: ONLY_WORKING_HOURS ? WORKING_START_HOUR : undefined,
  //   toHour: ONLY_WORKING_HOURS ? WORKING_END_HOUR : undefined,
  // },
  rowHeight: ROW_HEIGHT,
  barMargin: ROW_MARGIN,
  multiEventSelect: true,
  autoHeight: true,
  eventStyle: undefined,
  createEventOnDblClick: false, // disable default behavior [that adds events] when double clicking scheduler
  zoomOnTimeAxisDoubleClick: false, // disable default behavior that zooms in on the time axis when double clicking the header
};

  // IMPORTANT: You can find a full list of of features for the bryntum scheduler here: 
  // https://bryntum.com/products/scheduler/docs/guide/Scheduler/integration/react/guide#features 
  // It's also important to distinguish that these features could be defined directly on the scheduler in Scheduler.tsx- But I include 
  // the static features in this config to reduce the logic in that file. See the Bryntum doc link above to find the corresponding React
  // Feature name and the Bryntum API feature it references [if you want to add or migrate a feature to be defined on the Scheduler]. 
const schedulerFeatures = {
  dependencies: dependencyConfig,
  timeRanges: timeRangesConfig,
  eventTooltip: eventTooltipConfig,
}



export {
  schedulerConfig,
  schedulerFeatures,
  ROW_HEIGHT,
  ROW_MARGIN,
  ONLY_WORKING_HOURS,
  WORKING_START_DAY,
  WORKING_END_DAY,
  WORKING_START_HOUR,
  WORKING_END_HOUR,
  START_DATE,
};
