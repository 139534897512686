// Base content file for pages, sets page to armloading then loads content
import React, { FC } from 'react'
// mui imports
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface BaseContentProps {
  loading: boolean,
  children: JSX.Element | JSX.Element[]
  sx?: any | undefined
  permission?: boolean | undefined
}

const BaseContent: FC<BaseContentProps> = ({loading, children, sx, permission}) => {
  let content: any
  if (permission !== undefined && !permission)
    content = <Box sx={{...sx, m: '1rem', textAlign: 'center'}}><Typography>Insufficient Permissions</Typography></Box>
  else if (loading)
    content = <Box sx={{...sx, display: 'flex'}}><CircularProgress sx={{margin: '4rem auto 4rem'}} color='inherit' /></Box>
  else
    content = <Box sx={{...sx, m: '1rem 1rem'}}>{children}</Box>

  // const content: any = loading ? circularLoad :

  return content
}

export default BaseContent
