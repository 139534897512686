import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { TimeFormat } from '../globals'
import { styled } from '@mui/material/styles'

const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
  'input': {
    maxHeight: '8px',
    width: '100%',
    maxWidth: '100%',
  }
}))

interface FormInputTimeProps {
  name: string,
  label: string,
}
/**
 * DropdownActions comp
 * @param name
 * @param label
 * @constructor
 */
const FormInputTime: FC<FormInputTimeProps> = ({name, label}) => {
  const { control } = useFormContext()

  return (
    <Controller
        name={name as never}
        control={control}
        render={
        ({
             field: {onChange, value},
             fieldState: {error},
        }: any) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledTimePicker
            label={label}
            onChange={onChange}
            value={value}
            format={TimeFormat}
          />
        </LocalizationProvider>
      )} />
  )
}

export default FormInputTime
