import {apiGet} from "../../../../../utils/api";

/**
 * @param orderIdList
 * @param machineId
 * @param callback
 * @param type
 */
const getFindCompatibleItemData = (orderIdList: number[], machineId: number, callback: (data: any) => void, type?: string) => {
  apiGet(
    '/scheduling/find-compatible-orders/',
    {orders: orderIdList, type: type ? type : 'machineLoad', machineId: machineId, itemData: true},
    (resp: any) => callback(resp.data)
  )
}

export {
  getFindCompatibleItemData
}