import {apiPost} from "../../api";
import {doAlert} from "../PopupAlert/PopupAlert";

/**
 * Logs a personnel in or out
 * @param login: login if true otherwise logout
 * @param data
 * @param callback
 */
const doLogin = (login: boolean, data: any, callback: (errors: any[]) => void) => {
  apiPost('/productivity/login/', {...data, login: login}, (resp: any) => callback(resp.data.errors),
    (err: any) => {
      if (err.response) doAlert(err.response.data.message, 'error', true)
      callback([])
    })
}

export {
  doLogin
}
