import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';

interface FormInputTextProps {
  name: string;
  label: string;
  disabled?: boolean;
  multiline?: boolean;
  hidden?: boolean;
  id?: string | undefined
}
/**
 * FormInputText comp
 * @param name
 * @param label
 * @param disabled?
 * @param multiline
 * @param hidden?
 * @param id?
 * @constructor
 */
const FormInputText: FC<FormInputTextProps> = ({name, label, disabled,
                                                multiline, hidden, id}) => {
  const { control } = useFormContext();

  return (
    <Controller name={name} control={control} render={({field: {onChange, value}, fieldState: {error}, formState, }) => (
      <TextField
        id={id ? id : undefined}
        helperText={error ? error.message: null}
        size='small'
        error={!!error}
        onChange={onChange}
        value={value ? value : ''}
        fullWidth
        label={label}
        variant='outlined'
        disabled={!!disabled}
        multiline={!!multiline}
        rows={!!multiline ? 4 : 1}
        sx={hidden ? {display: 'none'} : {}}
       />
    )} />
  );
}

export default FormInputText;
