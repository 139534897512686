import React, { FC } from 'react'
import LineplanProcessing from "./LineplanProcessing";
import {SECONDARY_LP} from "../../../utils/globals";

interface SecondaryLineplanProps {
  id: number
}
/**
 * Wrapper for lineplan of type secondary
 * @param lineplan id
 */
const SecondaryLineplan: FC<SecondaryLineplanProps> = ({id}) => <LineplanProcessing type={SECONDARY_LP} typeName='Secondary' id={id} />

export default SecondaryLineplan
