import React, {FC, useEffect, useState} from 'react'
import BaseModal from "../../../../utils/modals/BaseModal";
import ApiTable from "../../../../utils/table/ApiTable";
import VentMaterialForm from "./VentMaterialForm";
import { FormProvider, useForm } from 'react-hook-form';
import { Clear, Delete } from '@mui/icons-material';
import { api } from '../../../../utils/globals';
import { AxiosResponse } from 'axios';
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert';
import FormInputSwitch from '../../../../utils/form/FormInputSwitch';

interface DeleteDefaultPdfModalProps {
  open: boolean
  onClose: () => void
	item: string, 
	url: string
}
/**
 * Vent material management modal
 * @constructor
 */
const DeleteDefaultPdfModal: FC<DeleteDefaultPdfModalProps> = ({open, onClose, item, url}) => {

  const [doLoad, setDoLoad] = useState<boolean>(false)

	const methods = useForm()

  useEffect(() => {
    setDoLoad(open)
  }, [open])

	const handleDelete = () => {
		const formData = methods.getValues()
		api.post(url, {
			deletePdf: true, 
			type: 'default_pdf',
			delete_from_related_items: formData['delete_from_related_items'], 
			item_name: item
		})
			.then((resp: AxiosResponse) => {
				onClose()
				doAlert(resp.data.message, 'success', true)
			})
	}

  return (
    <BaseModal
      title='Delete Default PDF'
      open={open}
      closeHandler={onClose}
      maxWidth='sm'
      closeButton
      dividers
			actions={[
				{text: 'Delete', color: 'error', outlined: false, icon: <Delete />, action: handleDelete}
			]}
    >
			<FormProvider {...methods} >
				<FormInputSwitch name={'delete_from_related_items'} label={'Remove this PDF from all items sharing this mold?'} />
			</FormProvider>
    </BaseModal>
  )
}

export default DeleteDefaultPdfModal
