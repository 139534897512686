import React, { FC, useState, useEffect } from 'react';
import BaseModal from "../BaseModal";
import {getAcknowledgedByList} from "./ChannelModalApi";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";

interface AcknowledgedUsersListProps {
  messageId: number | undefined;
  closeHandler: () => void;
}
const AcknowledgedUsersList: FC<AcknowledgedUsersListProps> = ({messageId, closeHandler}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);

  useEffect(() => {
    console.log('ID :', messageId)
    if (messageId) {
      getAcknowledgedByList(messageId, (userList: any[]) => {
        if (userList.length > 0) {
          setUserList(userList);
          setOpen(true);
        } else
          setOpen(false)
      })
    } else
      setOpen(false)
  }, [messageId]);

  const doClose = () => {
    setOpen(false);
    closeHandler();
  }

  return (
    <BaseModal
      title='Acknowledged Users'
      open={open}
      closeHandler={doClose}
      closeButton
      dividers
      maxWidth='sm'
    >
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((user: string, key: number) => (
              <TableRow key={key}>
                <TableCell>{user}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </BaseModal>
  );
}

export default AcknowledgedUsersList;