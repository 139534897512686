import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

interface InfoTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
  children: React.ReactElement;
  tooltip: { [key: string]: any };
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const InfoTooltip: React.FC<InfoTooltipProps> = ({ children, tooltip, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl({
      ...anchorEl,
      getBoundingClientRect: () => ({
        top: event.clientY,
        left: event.clientX,
        right: event.clientX,
        bottom: event.clientY,
        width: 0,
        height: 0,
      }),
    } as HTMLElement);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const { title, ...dataItems } = tooltip;  // Destructure to separate title from data items

  return (
    <div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      <StyledTooltip
        {...props}
        PopperProps={{
          open: Boolean(anchorEl),
          anchorEl: anchorEl,
          placement: 'top',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ],
        }}
        title={
          <React.Fragment>
            <Typography variant='h6'>{title}</Typography>
            {Object.entries(dataItems).map(([key, { label, value }]) => (
              <Typography key={key}>{label}: {value}</Typography>
            ))}
          </React.Fragment>
        }
      >
        {children}
      </StyledTooltip>
    </div>
  );
};

export default InfoTooltip;
