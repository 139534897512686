import {apiGet, apiPost} from "../../../../utils/api";

/**
 * gets base data for the scrap log page
 * @param callback
 */
const getFormOptions = (callback: (data: any) => void) => {
  apiGet('/manufacturing/nonconforming/logs/', {formOptions: true}, (resp: any) => callback(resp.data))
}

/**
 * @param id: unit id
 * @param callback
 */
const getFormData = (id: string, callback: (data: any) => void) => {
  apiGet(`/manufacturing/nonconforming/logs/${id}/`, {formData: true}, (resp: any) => callback(resp.data))
}

interface ExportLogData {
	startDate: string, 
	endDate: string, 
	log_type: string
}
const exportLogs = (data: ExportLogData, callback: (resp: any) => void) => {
  apiGet('/manufacturing/nonconforming/logs/', {export: true, log_type: data.log_type, start: data.startDate, end: data.endDate, blob: true},
    (resp: any) => callback(resp))
}

/**
 * Sets the unit to not found (main table)
 * @param id: unit id
 * @param callback
 */
const setUnitNotFound = (id: string, callback: () => void) => {
  apiPost(`/manufacturing/nonconforming/logs/${id}/`, {notFound: true}, (resp: any) => callback())
}

const toggleUnitNotFound = (id: string) => {
  apiPost(`/manufacturing/nonconforming/logs/${id}/`, {toggleField: 'not_found'})
}

const toggleUnitApproved = (id: string) => {
  apiPost(`/manufacturing/nonconforming/logs/${id}/`, {toggleField: 'approved'})
}

/**
 * submits the confirm unit form (main table)
 * @param id: unit id
 * @param data
 * @param callback
 */
const confirmUnit = (id: string, data: any, callback: (errors: any[]) => void) => {
  apiPost(`/manufacturing/nonconforming/logs/${id}/`, {...data, confirm: true}, (resp: any) => {
    if (resp.data.status === 'success')
      callback([])
    else
      callback(resp.data)
  })
}

/**
 * approves the unit (productivity review table)
 * @param id: unit id
 * @param callback
 */
const approveUnit = (id: string, callback: () => void) => {
  apiPost(`/manufacturing/nonconforming/logs/${id}/`, {approved: true}, (resp: any) => callback())
}

export {
  getFormOptions, getFormData, exportLogs, setUnitNotFound, toggleUnitNotFound, toggleUnitApproved, confirmUnit, approveUnit
}