// MaintenanceHistoryDialog.tsx

import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	CircularProgress,
	Box,
	Chip,
	Typography,
	IconButton
} from '@mui/material';
import { format, parseISO } from 'date-fns'; // Import format and parseISO from date-fns
import useMaintenanceHistory from './hooks/useMaintenanceHistory'; // Adjust the import path as necessary
import { Archive } from '@mui/icons-material';

interface MaintenanceHistoryDialogProps {
	open: boolean;
	onClose: () => void;
	id: number;
	type: 'Mold' | 'Machine';
	name: string;
}

const MaintenanceHistoryDialog: React.FC<MaintenanceHistoryDialogProps> = ({ open, onClose, id, type, name }) => {
	const { history, loading, sendMessage } = useMaintenanceHistory(id, type);

	const handleArchive = (id: number) => {
		sendMessage('archive_record', { id })
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="md"
			PaperProps={{
				sx: {
					borderRadius: 5, // Rounded borders for the Dialog
				},
			}}
		>
			<DialogTitle
				sx={{
					textAlign: 'center', // Centered title text
					fontWeight: 'bold',
					fontSize: '1.25rem',
				}}
			>
				{`${name} Maintenance History`}
			</DialogTitle>
			<DialogContent
				dividers
				sx={{
					padding: 2,
				}}
			>
				{loading ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '200px',
						}}
					>
						<CircularProgress />
					</Box>
				) : history.length === 0 ? (
					<Box sx={{ padding: 2 }}>
						<Typography variant="body1">No maintenance records found.</Typography>
					</Box>
				) : (
					<TableContainer
						component={Paper}
						sx={{
							borderRadius: 3, 
							maxHeight: 600, 
							overflowY: 'auto', // Enable vertical scrolling
						}}
					>
						<Table
							aria-label="maintenance history table"
							stickyHeader // Keeps the header visible during scroll
						>
							<TableHead>
								<TableRow>
									<TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Date</TableCell>
									<TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Maintenance Type</TableCell>
									<TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Description</TableCell>
									<TableCell sx={{ fontWeight: 'bold', textAlign: 'center', whiteSpace: 'nowrap' }}>Performed By</TableCell>
									<TableCell sx={{ fontWeight: 'bold', textAlign: 'center', whiteSpace: 'nowrap' }}>Archive</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{history.map((record) => (
									<TableRow key={record.id} hover>
										<TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
											{record.date
												? format(parseISO(record.date), 'MM-dd-yyyy hh:mm:ss a') // Format to 12-hour with AM/PM
												: 'N/A'}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{Array.isArray(record.type) && record.type.length > 0 ? (
												record.type.map((mt, index) => (
													<Chip
														key={index}
														label={mt}
														size="small"
														color="primary"
														sx={{ marginRight: 0.5, marginBottom: 0.5 }}
													/>
												))
											) : (
												'N/A'
											)}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>{record.description || 'N/A'}</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>{record.performed_by || 'N/A'}</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											<IconButton
												onClick={() => handleArchive(record.id)}
												sx={{ marginLeft: 1, backgroundColor: 'secondary.light', color: 'white', '&:hover': { backgroundColor: 'secondary.dark' } }}
											>
												<Archive />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</DialogContent>
			<DialogActions sx={{ p: 2 }}>
				<Button
					onClick={onClose}
					variant="contained"
					color="primary"
					sx={{
						borderRadius: 3
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default MaintenanceHistoryDialog;
