import React, { FC, useState, useRef } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
// mui imports
import Grid from "@mui/material/Grid"
import FileUpload from "@mui/icons-material/FileUpload"
// custom imports
import { apiGet, apiPost } from "../../../../utils/api"
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import { useEffectApi } from '../../../../utils/globals'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import ApiTable from "../../../../utils/table/ApiTable"
import DataProgressBar from "../../../../utils/globalComps/DataProgressBar"
import ImportModal from '../../../../utils/modals/ImportModal/ImportModal'

const url: string = '/main/order-sync/'
const syncCheckTime: number = 250 // ms

const OrderSync: FC = () => {

	const [excelImport, setExcelImport] = useState(false)
	const [syncing, setSyncing] = useState<boolean>(false)
	const syncingRef = useRef<boolean>(false)
	const [pageTitle, setPageTitle] = useState<string>('Order Sync')
	const [progressPercent, setProgressPercent] = useState<number>(0)
	const [progressLabel, setProgressLabel] = useState<string>('')
	const [refreshTable, setRefreshTable] = useState<boolean>(false)
	const [importModalOpen, setImportModalOpen] = useState<boolean>(false)
	const [canImport, setCanImport] = useState<boolean>(false)

	syncingRef.current = syncing

	useEffectApi(() => {
		document.title = 'Order Sync | RotoEdgePro'

		apiGet(url, {}, (resp: any) => {
			setExcelImport(resp.data.excel_import)
			setPageTitle(resp.data.excel_import ? 'Order Sync - Excel' : 'Order Sync - Service')
			setCanImport(resp.data.permissions?.create)
		})
	}, [])

	const closeImportModal = () => { setRefreshTable(!refreshTable); setImportModalOpen(false) }

	const initProgressCheck = () => doProgressCheck()
	const doProgressCheck = () => {
		const checkUrl = `${url}?checkSync=${true}`

		apiGet(url, { checkSync: true }, (resp: AxiosResponse) => {
			if (syncingRef.current && !resp.data.done) {
				setProgressLabel(`${resp.data.current}/${resp.data.total}`)
				setProgressPercent(resp.data.percent)

				setTimeout(doProgressCheck, syncCheckTime)
			}
		})
	}

	const doImport = () => {
		setSyncing(true)

		apiPost(url, { runSync: true }, (resp: AxiosResponse) => {
			setProgressLabel(`${resp.data.total}/${resp.data.total}`)
			setProgressPercent(100)
			doAlert(resp.data.message, 'success', true)
			setTimeout(() => {
				setSyncing(false)
				setProgressLabel('')
				setProgressPercent(0)
				setRefreshTable(!refreshTable)
			}, 250)
		}, (err: AxiosError) => {
			if (err.response)
				doAlert(err.response.data.message, 'error', true)
			setSyncing(false)
		})

		setTimeout(initProgressCheck, 1500)
	}

	const generateTableActions = () => {
		let actions: any = []

		if (canImport)
			actions.push({ text: 'Import Orders', icon: <FileUpload />, action: importAction, permission: 'create' })

		return actions
	}

	// conditional comps
	const loadBar = () => {
		if (syncing)
			return (
				<Grid sx={{ mt: 5, mr: 5, ml: 5 }}>
					<DataProgressBar labelText={progressLabel} value={progressPercent} />
				</Grid>
			)
		else
			return <></>
	}

	const importAction = () => {
		if (excelImport)
			setImportModalOpen(true)
		else
			doImport()
	}

	const tableHeaders = [
		['Orders'],
		['Factory', 'Number', 'Item', 'Description', 'Quantity', 'Parent Order', 'Order Date', 'Due Date', 'Ship Date', 'Closed Date', 'Order Status', 'Special Instructions']
	]

	return (
		<>
			<PageTitle title={pageTitle} />
			{loadBar()}
			<ApiTable
				tableName=''
				objectName='OrderSync'
				titleVariant=''
				headers={['Type', 'Orders Created', 'Orders Updated', 'Items Created', 'Items Updated', 'When', '']}
				rowFields={['type_name', 'orders_created', 'orders_updated', 'items_created', 'items_updated', 'created']}
				dataField='objects'
				url={url}
				tableActions={generateTableActions()}
				refresh={refreshTable}
			/>
			<ImportModal title='Import Orders' url={url} open={importModalOpen} closeHandler={closeImportModal}
				extraInfo={['You can export orders from the Order Management page.']} typeName='xlsx' tableHeaders={tableHeaders} />
		</>
	)
}

export default OrderSync
