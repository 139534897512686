import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Grid from "@mui/material/Grid";

interface FormInputNumberProps {
  name: string
  label: string
  min?: number
  max?: number
  step?: number
  float?: boolean
  onChange?: (e: any) => void
  id?: string | undefined
  onKeyDown?: ((e: any) => void) | undefined
  selectOnFocus?: boolean | undefined
  disabled?: boolean | undefined
  sx?: any | undefined
	error?: any | undefined
}
/**
 * FormInputText comp
 * @param name
 * @param label
 * @param min
 * @param max
 * @param step
 * @param float: allows decimal point to be included in number only filter
 * @param onChange
 * @param id
 * @param onKeyDown
 * @param selectOnFocus
 * @param disabled
 * @param sx
 * @constructor
 */
const FormInputNumber: FC<FormInputNumberProps> = ({
  name, label, min, max, step, float, onChange, id,
  onKeyDown, selectOnFocus, disabled, sx, error
}) => {
  const { control } = useFormContext();

  const handleNumberOnly = (e: any) => {
    if (onChange) onChange(e);
    if (min !== undefined && parseFloat(e.target.value) < min) e.target.value = min.toString();
    if (max !== undefined && parseFloat(e.target.value) > max) e.target.value = max.toString();
    if (float) e.target.value = e.target.value.replace(/[^0-9.]/, '');
    else e.target.value = e.target.value.replace(/[^0-9]/g, '');
    return e;
  }

  const handleOnFocus = (e: any) => {
    if (selectOnFocus) e.currentTarget.select();
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id={id}
              name={name}
              helperText={fieldError ? fieldError.message : null}
              size='small'
              error={error || !!fieldError}
              onChange={(e: any) => onChange(handleNumberOnly(e))}
              value={value ? value : ''}
              fullWidth
              label={label}
              variant='outlined'
              type='number'
              onKeyDown={onKeyDown}
              onFocus={handleOnFocus}
              disabled={disabled}
              sx={{
                ...sx,
                '& .MuiOutlinedInput-root.Mui-error': {
                  borderColor: 'red', // makes border red
                }
              }}
              inputProps={{
                step: step || (float ? 0.1 : 1),
              }}
            />
          </Grid>
        </Grid>
      )}
    />
  );
}

export default FormInputNumber
