import React, { useState, useMemo } from 'react';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Paper, TablePagination, Box, CircularProgress, TableSortLabel
} from '@mui/material';
import { Inventory } from '../hooks/Interfaces';
import { SendMessageFunction } from '../../../../../websocket/hooks/types';

interface InventoryTableProps {
	inventory: Inventory[];
	sendMessage: SendMessageFunction;
	searchQuery: string;
}

const InventoryTable: React.FC<InventoryTableProps> = ({ inventory, sendMessage, searchQuery }) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [sortBy, setSortBy] = useState<{ field: keyof Inventory, order: 'asc' | 'desc' }>({ field: 'name', order: 'asc' });

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSort = (field: keyof Inventory) => {
		const isAsc = sortBy.field === field && sortBy.order === 'asc';
		setSortBy({ field, order: isAsc ? 'desc' : 'asc' });
	};

	const sortedInventory = useMemo(() => {
		const stabilizedThis = [...inventory];
		stabilizedThis.sort((a, b) => {
			const fieldA = a[sortBy.field] ?? '';
			const fieldB = b[sortBy.field] ?? '';
			if (fieldA < fieldB) {
				return sortBy.order === 'asc' ? -1 : 1;
			}
			if (fieldA > fieldB) {
				return sortBy.order === 'asc' ? 1 : -1;
			}
			return 0;
		});
		return stabilizedThis;
	}, [inventory, sortBy]);

	const filteredInventory = useMemo(() => {
		return sortedInventory.filter((item) =>
			item.name.toLowerCase().includes(searchQuery.toLowerCase())
		);
	}, [sortedInventory, searchQuery]);

	const formatNumber = (num: number | null | undefined) => {
		if (num === null || num === undefined || isNaN(num)) {
			return '';
		}
		return new Intl.NumberFormat('en-US').format(num);
	};

	const truncateString = (str: string, maxLength: number) => {
		
		if (str && str.length > maxLength) {
			return str.substring(0, maxLength) + '...';
		}
		return str;
	};

	return (
		<>
			{inventory.length === 0 ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '400px' }}>
					<CircularProgress />
				</Box>
			) : (
				<>
					<TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'hidden', borderRadius: 3 }}>
						<Table stickyHeader aria-label={`Inventory table`}>
							<TableHead>
								<TableRow>
									<TableCell sx={{ pl: 5, width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center', fontWeight: 'bold' }}>
										<TableSortLabel
											active={sortBy.field === 'name'}
											direction={sortBy.field === 'name' ? sortBy.order : 'asc'}
											onClick={() => handleSort('name')}
										>
											Name
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center', fontWeight: 'bold' }}>
										Description
									</TableCell>
									<TableCell sx={{ pl: 5, width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										<TableSortLabel
											active={sortBy.field === 'inventory_count'}
											direction={sortBy.field === 'inventory_count' ? sortBy.order : 'asc'}
											onClick={() => handleSort('inventory_count')}
										>
											In Stock
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ pl: 5, width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										<TableSortLabel
											active={sortBy.field === 'order_demand'}
											direction={sortBy.field === 'order_demand' ? sortBy.order : 'asc'}
											onClick={() => handleSort('order_demand')}
										>
											Order Demand
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ pl: 5, width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										<TableSortLabel
											active={sortBy.field === 'projected_stock'}
											direction={sortBy.field === 'projected_stock' ? sortBy.order : 'asc'}
											onClick={() => handleSort('projected_stock')}
										>
											Projected Stock
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ pl: 5, width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										<TableSortLabel
											active={sortBy.field === 'scrap'}
											direction={sortBy.field === 'scrap' ? sortBy.order : 'asc'}
											onClick={() => handleSort('scrap')}
										>
											Scrap
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ pl: 5, width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										<TableSortLabel
											active={sortBy.field === 'wip'}
											direction={sortBy.field === 'wip' ? sortBy.order : 'asc'}
											onClick={() => handleSort('wip')}
										>
											WIP
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ pl: 5, width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										<TableSortLabel
											active={sortBy.field === 'units_consumed'}
											direction={sortBy.field === 'units_consumed' ? sortBy.order : 'asc'}
											onClick={() => handleSort('units_consumed')}
										>
											Units Consumed
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredInventory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
									<TableRow key={index} sx={{ bgcolor: item.order_demand > item.inventory_count ? 'rgba(255, 0, 0, 0.3)' : 'inherit' }}>
										<TableCell
											sx={{
												width: {
													xs: '100px',
													sm: '150px',
													md: '200px'
												},
												textAlign: 'center',
												fontWeight: 'bold'
											}}>{item.name}
										</TableCell>
										<TableCell
											sx={{
												width: {
													xs: '100px',
													sm: '150px',
													md: '200px'
												},
												textAlign: 'center'
											}}>{truncateString(item.description, 25)}
										</TableCell>
										<TableCell
											sx={{
												width: {
													xs: '100px',
													sm: '150px',
													md: '200px'
												},
												textAlign: 'center'
											}}>{formatNumber(item.inventory_count)}
										</TableCell>
										<TableCell
											sx={{
												width: {
													xs: '100px',
													sm: '150px',
													md: '200px'
												},
												textAlign: 'center'
											}}>{formatNumber(item.order_demand)}
										</TableCell>
										<TableCell
											sx={{
												width: {
													xs: '100px',
													sm: '150px',
													md: '200px'
												}, textAlign: 'center'
											}}>{formatNumber(item.projected_stock)}
										</TableCell>
										<TableCell
											sx={{
												width: {
													xs: '100px',
													sm: '150px',
													md: '200px'
												},
												textAlign: 'center'
											}}>{formatNumber(item.scrap)}
										</TableCell>
										<TableCell
											sx={{
												width: {
													xs: '100px',
													sm: '150px',
													md: '200px'
												},
												textAlign: 'center'
											}}>{formatNumber(item.wip)}
										</TableCell>
										<TableCell
											sx={{
												width: {
													xs: '100px',
													sm: '150px',
													md: '200px'
												},
												textAlign: 'center'
											}}>{formatNumber(item.units_consumed)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<TablePagination
						component="div"
						count={filteredInventory.length}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}
		</>
	);
};

export default InventoryTable;
