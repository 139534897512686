const nonConformingLabel = '<?xml version="1.0" encoding="utf-8"?>\
<DieCutLabel Version="8.0" Units="twips" MediaType="Default">\
    <PaperOrientation>Landscape</PaperOrientation>\
    <Id>LargeShipping</Id>\
    <IsOutlined>false</IsOutlined>\
    <PaperName>30256 Shipping</PaperName>\
    <DrawCommands>\
        <RoundRectangle X="0" Y="0" Width="3331" Height="5715" Rx="270" Ry="270" />\
    </DrawCommands>\
    <ObjectInfo>\
        <DateTimeObject>\
            <Name>DATE-TIME</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <DateTimeFormat>MonthDayYear</DateTimeFormat>\
            <Font Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
            <PreText />\
            <PostText />\
            <IncludeTime>True</IncludeTime>\
            <Use24HourFormat>False</Use24HourFormat>\
        </DateTimeObject>\
        <Bounds X="4004.79136690648" Y="3048.21709691071" Width="1563.32628015235" Height="200.782903089293" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>machine</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">FSP-WA</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="3041.55184088024" Width="700" Height="158.036394413878" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <BarcodeObject>\
            <Name>barcode</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>True</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <Text>12345</Text>\
            <Type>Code39</Type>\
            <Size>Medium</Size>\
            <TextPosition>Bottom</TextPosition>\
            <TextFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
            <CheckSumFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
            <TextEmbedding>None</TextEmbedding>\
            <ECLevel>0</ECLevel>\
            <HorizontalAlignment>Center</HorizontalAlignment>\
            <QuietZonesPadding Left="0" Top="0" Right="0" Bottom="0" />\
        </BarcodeObject>\
        <Bounds X="529.381294964031" Y="2250" Width="4043.44223444773" Height="800" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>order</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">001234567</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="28" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="158.823529411764" Width="2915.29411764706" Height="748.235294117647" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>item</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">Item: 00-00000-00</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="842.43243243243" Width="1990.58823529412" Height="275.294117647059" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>description</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">Description: This is a long item description, a very long description</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="1186.55007949125" Width="5338" Height="225.882352941176" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <ShapeObject Stroke="SolidLine">\
            <Name>SHAPE</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <ShapeType>Rectangle</ShapeType>\
            <LineWidth>30</LineWidth>\
            <LineAlignment>Center</LineAlignment>\
            <FillColor Alpha="0" Red="255" Green="255" Blue="255" />\
        </ShapeObject>\
        <Bounds X="3650.59459459463" Y="290" Width="441.081081081062" Height="402.16216216216" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <ShapeObject Stroke="SolidLine">\
            <Name>SHAPE_1</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <ShapeType>Rectangle</ShapeType>\
            <LineWidth>30</LineWidth>\
            <LineAlignment>Center</LineAlignment>\
            <FillColor Alpha="0" Red="255" Green="255" Blue="255" />\
        </ShapeObject>\
        <Bounds X="4160.18918918922" Y="290" Width="441.081081081062" Height="402.16216216216" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <ShapeObject Stroke="SolidLine">\
            <Name>SHAPE__1</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <ShapeType>Rectangle</ShapeType>\
            <LineWidth>30</LineWidth>\
            <LineAlignment>Center</LineAlignment>\
            <FillColor Alpha="0" Red="255" Green="255" Blue="255" />\
        </ShapeObject>\
        <Bounds X="4669.78378378382" Y="290" Width="441.081081081062" Height="402.16216216216" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>TEXT__1</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">NON CONFORMING</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="26" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="1700" Width="5217.99682034978" Height="475" />\
    </ObjectInfo>\
    <ObjectInfo>\
        <TextObject>\
            <Name>nonConformingCode</Name>\
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
            <LinkedObjectName />\
            <Rotation>Rotation0</Rotation>\
            <IsMirrored>False</IsMirrored>\
            <IsVariable>False</IsVariable>\
            <GroupID>-1</GroupID>\
            <IsOutlined>False</IsOutlined>\
            <HorizontalAlignment>Left</HorizontalAlignment>\
            <VerticalAlignment>Top</VerticalAlignment>\
            <TextFitMode>ShrinkToFit</TextFitMode>\
            <UseFullFontHeight>True</UseFullFontHeight>\
            <Verticalized>False</Verticalized>\
            <StyledText>\
                <Element>\
                    <String xml:space="preserve">Scrap code: Admin App Adjustment</String>\
                    <Attributes>\
                        <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
                    </Attributes>\
                </Element>\
            </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="1462.2972972973" Width="5162.48012718604" Height="275.294117647059" />\
    </ObjectInfo>\
</DieCutLabel>'

export default nonConformingLabel