import { jsPDF as JSpdf } from 'jspdf'
import { saveAs } from 'file-saver'

// helper class to print to pdf
class tableToPDF {
  title: string
  headers: {text: string, width: number}[]
  rows: any[][]
  doc: any = new JSpdf('p', 'px', 'letter')
  timestampFontSize: number = 8
  titleFontSize: number = 15
  headerFontSize: number = 11
  docFontSize: number = 10
  width: number = 1
  height: number = 1
  headerPadding: number = 20
  padding: number = 5
  font: any = {}
  currentYPos: number = 0
  currentXPos: number = 0
  firstRowBold: boolean = false
  dateTime: string
  filename: string = ''

  constructor(title: string, headers: {text: string, width: number}[], rows: string[][], fontSize: number,
              firstRowBold: boolean) {
    // pdf params
    this.title = title
    this.headers = headers
    this.rows = rows
    this.docFontSize = fontSize
    this.headerFontSize = fontSize + 1
    this.width = this.doc.internal.pageSize.getWidth()
    this.height = this.doc.internal.pageSize.getHeight()
    this.font = this.doc.getFont()
    this.firstRowBold = firstRowBold
    this.dateTime = new Date().toLocaleString()

    // set filename and doc title
    this.filename = this.generateFilename()
    this.doc.setProperties({title: this.title})
  }

  setBold = () => this.doc.setFont(undefined, 'bold')
  setNormal = () => this.doc.setFont(undefined, 'normal')

  generateTimestamp = () => {
    const dateTime = new Date().toLocaleString()

    const oldFontSize: number = this.doc.getFontSize()

    // timestamp will be smaller font than the rest of the doc
    this.doc.setFontSize(this.timestampFontSize)
    this.doc.text(dateTime, this.width / 2, this.height - this.padding, {align: 'center'})
    this.doc.setFontSize(oldFontSize)
  }
  generateFilename = () => {
    let dateTime: string = this.dateTime
    let filename: string = `${this.title}-${dateTime}`
      .replace(/\s/g, '_')
      .replace(/:/g, '_')
      .replace(/\//g, '_')
      .replace(/__/g, '_')
    return filename
  }

  generateTitle = () => {
    // set pdf title
    this.setBold()
    this.doc.setFontSize(this.titleFontSize)

    this.currentYPos = this.headerPadding + this.titleFontSize
    this.doc.text(this.title, this.width / 2, this.currentYPos, {align: 'center'})
    this.currentYPos += this.headerPadding + this.headerFontSize

    this.setNormal()
    this.doc.setFontSize(this.headerFontSize)
  }

  generateHeaders = () => {
    this.setBold()

    for (const header of this.headers) {
      this.currentXPos += this.padding + header.width / 2
      this.doc.text(header.text, this.currentXPos, this.currentYPos, {align: 'center'})
    }

    this.setNormal()
  }

  generateRows = () => {
    let i: number = 0
    for (const row of this.rows) {
      this.currentYPos += this.padding + this.docFontSize
      this.currentXPos = 0

      if (this.currentYPos >= this.height - this.padding + this.timestampFontSize) {
        this.doc.addPage()
        this.currentYPos = this.padding + this.docFontSize
      }

      if (i === 0 && this.firstRowBold)
        this.setBold()

      let j: number = 0
      for (let field of row) {

        if (field === undefined || field === null)
          field = '-'
        else if (typeof field !== 'string')
          field = field.toString().toUpperCase()

        this.currentXPos += this.padding + this.headers[j].width / 2
        this.doc.text(field, this.currentXPos, this.currentYPos, {align: 'center'})

        j++
      }

      if (i === 0 && this.firstRowBold)
        this.setNormal()
      i++
    }
  }

  generatePDF = () => {
    // generate pdf document
    this.generateTitle()
    this.generateHeaders()
    this.generateRows()
    this.generateTimestamp()

    window.open(this.doc.output('bloburl', {filename: this.filename}), '_blank')
  }

}

export default tableToPDF
