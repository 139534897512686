import React, {FC} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// mui imports
import Typography from "@mui/material/Typography";
// custom imports
import BaseModal from "../../../utils/modals/BaseModal";
import FormInputSwitch from "../../../utils/form/FormInputSwitch";
import InfoText from "../../../utils/globalComps/InfoText";

interface RemoveOrderConfirmModalProps {
  open: boolean
  doClose: (doUnload: boolean, status: any) => void
  orderItem: string
  arm: string
  showRemoveAll: boolean
}
const RemoveOrderConfirmModal: FC<RemoveOrderConfirmModalProps> = ({open, doClose, orderItem, arm,
                                                                     showRemoveAll}) => {

  // setup form
  const methods = useForm({defaultValues: {}})
  const { handleSubmit } = methods

  const doConfirm = (data: any) => doClose(true, {...data})

  return (
    <BaseModal
      title='Unload'
      open={open}
      closeHandler={() => doClose(false, {})}
      closeButton
      dividers
      actions={[
        {text: 'Cancel', action: () => doClose(false, {}), outlined: true, left: true},
        {text: 'Confirm', action: handleSubmit(doConfirm)}
      ]}
    >
      <Typography variant='subtitle1'>{`Unload ${orderItem} from ${arm}?`}</Typography>
      <FormProvider {...methods}>
        <FormInputSwitch label='Needs Maintenance?' name='needsMaintenance' />
        <InfoText text='Flags this mold for maintenance.' />
        {showRemoveAll ?
          <>
            <FormInputSwitch label='Remove all molds?' name='removeAll' />
            <InfoText text='Removes all instances of this mold loaded to this arm, otherwise just removes one.' />
          </>
          :
          <></>
        }
        {/* <FormInputSwitch label='Load to different arm after unloading?' name='loadToArmAfterUnload' />
        <InfoText text='Will prompt you to load this mold (or molds) to a new arm after unloading.' /> */}
      </FormProvider>

    </BaseModal>
  )
}

export default RemoveOrderConfirmModal