import React, {FC, useEffect, useState} from "react"
// mui imports
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
// custom imports
import BaseModal from "../modals/BaseModal"
import {
  checkDymoSoftware, CONFORMING, CONFORMING_REPRINT,
  getDymoPrinterData,
  NON_CONFORMING,
  NON_CONFORMING_REPRINT,
  POWDER,
  printLabel
} from "./labelPrinting"
import BaseContent from "../globalComps/BaseContent";
import TrueFalseIcon from "../globalComps/TrueFalseIcon";

interface LabelPrinterSettingsModalProps {
  open: boolean
  onClose: () => void
}
const LabelPrinterSettingsModal: FC<LabelPrinterSettingsModalProps> = ({open, onClose}) => {

  const [softwareDataLoading, setSoftwareDataLoading] = useState<boolean>(true)
  const [printerDataLoading, setPrinterDataLoading] = useState<boolean>(true)
  const [dymoPrinters, setDymoPrinters] = useState<any[]>([])
  const [dymoData, setDymoData] = useState<any | undefined>(undefined)

  const getDymoData = () => {
    setSoftwareDataLoading(true)
    const data: any = checkDymoSoftware(() => {
      setSoftwareDataLoading(false)
    })

    setDymoData(data)
  }

  const getDymoPrinters = () => {
    setPrinterDataLoading(true)
    const printers: any[] = getDymoPrinterData(() => {
      setPrinterDataLoading(false)
    })

    setDymoPrinters(printers)
  }

  useEffect(() => {

    getDymoData()
    getDymoPrinters()

  }, [open])

  // test prints
  const testPrintPowder = () => {
    printLabel(POWDER, {
      order: '001234567',
      parentItem: '00-0000-00',
      item: '00-00000',
      description: 'powder description',
      quantity: '15 Lbs',
      machine: 'FSP-WA'
    })
  }
  const testPrintMoldedUnitHelper = (type: number) => {
    const labelData: any = {
      order: '001234567',
      item: '00-0000-00',
      description:'molded unit description',
      machine: 'FSP-WA'
    }

    if ([NON_CONFORMING, NON_CONFORMING_REPRINT].includes(type))
      labelData['ncCode'] = 'AD - Admin App Adjustment'

    printLabel(type, labelData)
  }
  const testPrintConforming = () => testPrintMoldedUnitHelper(CONFORMING)
  const testPrintConformingReprint = () => testPrintMoldedUnitHelper(CONFORMING_REPRINT)
  const testPrintNonConforming = () => testPrintMoldedUnitHelper(NON_CONFORMING)
  const testPrintNonConformingReprint = () => testPrintMoldedUnitHelper(NON_CONFORMING_REPRINT)

  return (
    <BaseModal
      title='Label Printer Management'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      maxWidth='lg'
    >
      <Grid container spacing={2}>

        <Grid item xs={12}><Typography variant='h6'>Test Printing</Typography></Grid>
        <Grid item container xs={12} justifyContent='space-around' spacing={2}>
          <Grid item><Button variant='outlined' onClick={testPrintPowder}>Print Powder Label</Button></Grid>
          <Grid item><Button variant='outlined' onClick={testPrintConforming}>Print Conforming Label</Button></Grid>
          <Grid item><Button variant='outlined' onClick={testPrintConformingReprint}>Print Conforming Reprint Label</Button></Grid>
          <Grid item><Button variant='outlined' onClick={testPrintNonConforming}>Print Non-conforming Label</Button></Grid>
          <Grid item><Button variant='outlined' onClick={testPrintNonConformingReprint}>Print Non-conforming Reprint Label</Button></Grid>
        </Grid>

        <Grid item xs={12}>

          {/* dymo software information */}
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={9}>
              <Typography variant='h6'>Dymo Software</Typography>
              <BaseContent loading={softwareDataLoading}>
                {dymoData ?
                  <>
                    <Grid></Grid>
                    <Typography>
                      <strong>Browser Supported: </strong>
                      <TrueFalseIcon isTrue={dymoData.isBrowserSupported} />
                    </Typography>

                    <Typography>
                      <strong>Framework Installed: </strong>
                      <TrueFalseIcon isTrue={dymoData.isFrameworkInstalled} />
                    </Typography>

                    <Typography>
                      <strong>Web Service Present: </strong>
                      <TrueFalseIcon isTrue={dymoData.isWebServicePresent} />
                    </Typography>

                    {dymoData.errorDetails ?
                      <Typography><strong>Error Details:</strong> {dymoData.errorDetails}</Typography>
                      :
                      <></>
                    }
                  </>
                  :
                  <>
                    <Typography>Dymo not found!</Typography>
                  </>
                }
              </BaseContent>
            </Grid>
            <Grid container item xs={3} justifyContent='right'>
              <Grid item>
                <Button variant='contained' onClick={getDymoData} sx={{mr: 'auto'}}>Check software</Button>
              </Grid>
            </Grid>
          </Grid>

          {/* dymo printer information */}
          <Grid container item xs={12}>

              <Grid item xs={9}>
                <Typography variant='h6'>Dymo Printers</Typography>
                <BaseContent loading={printerDataLoading}>
                  <>
                    {dymoPrinters.length > 0 ?
                      <Grid item xs={12} container spacing={2}>
                        {dymoPrinters.map((printer: any, key: number) => (
                          <Grid item xs={12} key={key}>
                            <Typography><strong>Name:</strong> {printer.modelName}</Typography>

                            <Typography>
                              &emsp;<strong>Connected: </strong>
                              <TrueFalseIcon isTrue={printer.isConnected} />
                            </Typography>

                            <Typography>&emsp;<strong>Type:</strong> {printer.printerType}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                      :
                      <Typography>No printers found</Typography>
                    }
                  </>
                </BaseContent>
              </Grid>

              <Grid container item xs={3} justifyContent='right'>
                <Grid item>
                  <Button variant='contained' onClick={getDymoPrinters} sx={{mr: 'auto'}}>Check printers</Button>
                </Grid>
              </Grid>

            </Grid>

        </Grid>
      </Grid>
    </BaseModal>
  )

}

export default LabelPrinterSettingsModal
