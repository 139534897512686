import React, { FC, useState } from 'react'
// mui imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
// custom imports
import { ASSEMBLY, exportMRPData, getBaseData } from './MrpApi'
import { useEffectApi } from '../../../../utils/globals'
import { doConfirm } from '../../../../utils/modals/Confirm'
import { downloadExportFile, getExportFilename } from '../../../../utils/api'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion'
import AssemblyTable from './AssemblyTable'
import InfoText from '../../../../utils/globalComps/InfoText'

interface AssemblyProps {
}
const Assembly: FC<AssemblyProps> = ({}) => {

  const [lineplans, setLineplans] = useState<any[]>([])
  const [loading, setLoading]= useState<boolean>(false)
  const [permissions, setPermissions] = useState<any>(undefined)

  useEffectApi(() => {

    document.title = 'Assembly MRP | RotoEdgePro'

    getBaseData(ASSEMBLY, (data: any) => {
      setLineplans(data.lineplans)
      setPermissions(data.permissions)
      setLoading(false)
    })
  }, [])

  const doExport = () => {
    doConfirm('Export assembly MRPs?', () => {
      exportMRPData(ASSEMBLY, 1, (resp: any) => {
        downloadExportFile(resp.data, getExportFilename(resp.headers))
      })
    })
  }

  return (
    <>
      <PageTitle title='Assembly MRP' />
      <BaseContent loading={loading}>
        <Grid container spacing={2} sx={{width: '100%'}}>
          <Grid item container justifyContent='right'>
            <Button variant='contained' onClick={doExport}>Export</Button>
          </Grid>
          <Grid item sx={{width: '100%'}}>
            <InfoText text='Reports will automatically clear 24hrs after they have been generated.' />
            {lineplans.map((lineplan: any, key: number) => (
              <BaseAccordion title={lineplan.name} key={key}>
                <AssemblyTable id={lineplan.id} name={lineplan.name} canEdit={permissions?.edit} />
              </BaseAccordion>
            ))}
          </Grid>
        </Grid>
      </BaseContent>
    </>
  )
}

export default Assembly
