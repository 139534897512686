/**
 * File: auth.tsx
 * Desc: Holds auth token for the app
 */
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { api, ApiUrl } from './globals';
import {doAlert} from "./globalComps/PopupAlert/PopupAlert";

const responseText404 = 'Not found.';
const responseText403 = 'Forbidden, insufficient permissions.';
const responseText401 = 'Unauthorized action';

// This inserts the auth token into every axios request if it exists
api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const authService = new AuthService();

    if (config.headers === undefined)
      config.headers = {};

    if (config.baseURL === ApiUrl && !config.headers.Authorization) {
      const token: string | null = authService.getToken();

      if (token)
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
);
// This parses errors on responses to axios
api.interceptors.response.use((resp: AxiosResponse) => {
  return resp;
}, (error: AxiosError) => {
  if (error.response) {
    console.log('error.response :', error.response)
    if (401 === error.response.status)
      error.response['data'] = {message: error.response.data?.message};
    else if (403 === error.response.status)
      error.response['data'] = {message: responseText403};
    else if (404 === error.response.status)
      error.response['data'] = {message: responseText404};

    return Promise.reject(error)
  } else
    return Promise.reject(error)
});

class AuthService {

  login(user: string, pass: string) {
  	return api.post('/auth/token/obtain', {
      username: user,
      password: pass
  	}).then((resp: AxiosResponse) => {
      console.log('resp :', resp)
      if (resp.data.status === 'success') {
        this.setToken(resp.data.access)
        this.setRefreshToken(resp.data.refresh)
        this.setAccountID(resp.data.accountId);
        this.setAccountName(user);
        return Promise.resolve(resp);
      } else {
        doAlert(resp.data.message, resp.data.status, true)
        this.logout()
      }
    })
  }

  logout() {
    this.setToken('');
    this.setRefreshToken('');
    this.setAccountID('');
    this.setAccountName('');
  }

  isAuthed(): boolean {
    return !!(this.getToken() && this.getToken() !== '' && this.getRefreshToken() && this.getRefreshToken() !== '');
  }

  setAccountName(name: string) {
    localStorage.setItem('account_name', name);
  }

  setAccountID(id: string) {
    localStorage.setItem('account_id', id);
  }

  setToken(token: string) {
  	localStorage.setItem('id_token', token)
  }

  getToken(): string | null {
    return localStorage.getItem('id_token')
  }

  setRefreshToken(token: string) {
  	localStorage.setItem('id_token_refresh', token)
  }

  getRefreshToken(): string | null {
  	return localStorage.getItem('id_token_refresh')
  }

  refresh() {
    if (this.getRefreshToken() === null) return Promise.reject('No refresh token in memory')

    return api.post('/auth/token/refresh', {
      refresh: this.getRefreshToken()
    }).then((resp: AxiosResponse) => {
      this.setToken(resp.data.access);
      this.setRefreshToken(resp.data.access);
      return Promise.resolve(resp);
    }).catch((err: AxiosError) => {
      this.logout();
      return err;
    })
  }
}

const getAccountId = (): string | null => localStorage.getItem('account_id');
const getAccountName = (): string | null => localStorage.getItem('account_name');

export {
  AuthService,
  getAccountId,
  getAccountName
}
