import React, { FC, useState } from 'react'
// mui icon imports
import { AccessTime, LibraryBooks, Message } from '@mui/icons-material'
// custom imports
import { useEffectApi } from '../../../utils/globals'
import { SHIPPING } from '../../../utils/globalComps/ProductionLog/ProductionLogHelpers'
import { getData, logProduction } from './ShippingApi'
import PageTitle from '../../../utils/globalComps/PageTitle'
import BaseContent from '../../../utils/globalComps/BaseContent'
import LogProduction from '../../../utils/globalComps/LogProduction/LogProduction'
import ChannelModal from '../../../utils/modals/ChannelModal/ChannelModal'
import ProductionLogModal from '../../../utils/globalComps/ProductionLog/ProductionLogModal'
import ApiTable from '../../../utils/table/ApiTable'
import Channel from '../../../utils/globalComps/Channel'

interface ShippingProps {

}
const Shipping: FC<ShippingProps> = ({}) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [silentLoad, setSilentLoad] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const [canViewChannel, setCanViewChannel] = useState<boolean>(false)
  const [canViewProduction, setCanViewProduction] = useState<boolean>(false)

  const [channelOpen, setChannelOpen] = useState<boolean>(false)
  const [reloadChannel, setReloadChannel] = useState<boolean>(false)

  const [logProductionItem, setLogProductionItem] = useState<any[]>([])
  const [logProductionErrors, setLogProductionErrors] = useState<any[]>([])

  const [productionLogsOrderId, setProductionLogsOrderId] = useState<number | undefined>(undefined)
  const [productionLogsOpen, setProductionLogsOpen] = useState<boolean>(false)

  useEffectApi(() => {

    document.title = 'Shipping | RotoEdgePro'

    getData((data: any) => {
      console.log('shipping data :', data)
      setCanViewChannel(data.canViewChannel)
      setCanViewProduction(data.canViewProduction)

      setLoading(false)
    })
  }, [setData])

  // helpers
  const doRefresh = () => {
    setSilentLoad(true)
    setRefresh(!refresh)
  }
  const generateRowActions = () => {
    const actions: any = [{icon: <AccessTime />, actionType: 'object', action: logProductionModal, text: 'Log Production', noCondition: true, permission: 'create'}]

    if (canViewProduction)
      actions.push({icon: <LibraryBooks />, action: viewProduction, text: 'Production Logs', condition: 'has_production', conditionValue: true})

    return actions
  }
  const generateTableActions = () => {
    const actions: any = []

    if (canViewChannel)
      actions.push({text: 'Open Channel', action: openChannel, icon: <Message />, left: true})

    if (canViewProduction)
      actions.push({text: 'Production Logs', action: openProductionLogs, icon: <LibraryBooks />, left: true})

    return actions
  }

  // log production
  const logProductionModal = (object: any | undefined) => {
    console.log('open shipping log production :', object)
    if (object)
      setLogProductionItem([object])
  }
  const doLogProduction = (data: any) => {
    logProduction(data, (data: any) => {
      if (data.errors)
        setLogProductionErrors(data.errors)
      else {
        closeLogProduction()
        doRefresh()
      }
    })
  }
  const closeLogProduction = () => {
    setLogProductionItem([])
    setLogProductionErrors([])
  }

  // view production logs
  const openProductionLogs = (id: number) => {
    // 24 hr prod logs
    setProductionLogsOrderId(undefined)
    setProductionLogsOpen(true)
  }
  const viewProduction = (id: number | undefined) => {
    // individual order prod logs
    setProductionLogsOrderId(id)
    setProductionLogsOpen(true)
  }
  const closeProductionLogs = () => {
    setProductionLogsOrderId(undefined)
    setProductionLogsOpen(false)
  }

  // channel
  const openChannel = () => setChannelOpen(true)
  const closeChannel = () => setChannelOpen(false)
  const refreshChannel = () => setReloadChannel(!reloadChannel)


  return (
    <>
      <PageTitle title='Shipping' />
      <BaseContent loading={loading}>

        {canViewChannel ? <Channel shipping forceRefresh={reloadChannel} /> : <></>}

        <ApiTable
          suppressLoadDisplay={silentLoad}
          refresh={refresh}
          objectName=''
          headers={['Order', 'Item', 'Description', 'Available', '']}
          rowFields={['number', 'item', 'description', 'available']}
          tableActions={generateTableActions()}
          rowActions={generateRowActions()}
          rowClickActions={[
            {field: 'number', action: (order: any | undefined) => logProductionModal(order),
              condition: 'can_log', 'tooltip': 'Log Production', permission: 'create'}
          ]}
          dataField='objects'
          url='/processing/shipping'
          searchable
          refreshable
          dataCallback={(newData: any) => {setData([...data, ...newData]); console.log('data :', data, 'newData :', newData)}}
        />

      </BaseContent>

      <LogProduction data={logProductionItem} onClose={closeLogProduction} onLog={doLogProduction}
                     errors={logProductionErrors} scrapCodeList={[]} nonConforming={false} />
      {canViewChannel ? <ChannelModal channelClose={closeChannel} shipping={channelOpen} reloadChannelDisplay={refreshChannel} /> : <></>}
      {canViewProduction ?
        <ProductionLogModal open={productionLogsOpen} type={SHIPPING} onClose={closeProductionLogs}
                            orderId={productionLogsOrderId} /> : <></>}
    </>
  )
}

export default Shipping
