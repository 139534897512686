import React, { FC, useState } from 'react'
import { AxiosResponse } from 'axios'
import { useTheme } from "@mui/material/styles"
// mui icon imports imports
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import Flag from '@mui/icons-material/Flag'
// custom imports
import { useEffectApi } from '../../../../utils/globals'
import { doConfirm } from '../../../../utils/modals/Confirm'
import { colors } from "../../../../utils/colors"
import { apiGet, apiPost } from '../../../../utils/api'
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import ApiTable from '../../../../utils/table/ApiTable'
import MoldForm from './MoldForm'
import PdfViewer from '../../../../utils/modals/PdfViewer'

const url: string = '/inventory/molds/'

const Molds: FC = () => {
  const theme = useTheme()

  const [machineList, setMachineList] = useState<string[]>([])
  const [setupsheetPdf, setSetupsheetPdf] = useState<any | undefined>(undefined)
  const [pdfViewerOpen, setPdfViewerOpen] = useState<boolean>(false)
  const [setupsheetMoldId, setSetupsheetMoldId] = useState<number | undefined>(undefined)
  const [tableQ, setTableQ] = useState<string | undefined>(undefined)
  const [tableRefresh, setTableRefresh] = useState<boolean>(false)

  useEffectApi(() => {

    document.title = 'Molds | RotoEdgePro'

    apiGet(url, {}, (resp: AxiosResponse) => setMachineList(resp.data.machines))

  }, [setMachineList, tableRefresh, setupsheetPdf])

  // row actions (default edit/delete)
  // table actions (default create/export)
  const viewSetupsheet = () => setPdfViewerOpen(true)
  const toggleNeedsMaintenance = (id: number) => {
    apiPost(`${url}${id}/`, {toggleField: 'needs_maintenance'}, (_: AxiosResponse) => setTableRefresh(!tableRefresh))
  }

  const generateRowActions = () => {

    const actions: any[] = [
      {text: 'Clear Maintenance Flag', icon: <Flag />, action: toggleNeedsMaintenance, condition: 'needs_maintenance', conditionValue: true, permission: 'edit'},
      {text: 'Needs Maintenance', icon: <Flag />, action: toggleNeedsMaintenance, condition: 'needs_maintenance', conditionValue: false, permission: 'edit'},
      {text: 'View Items', icon: <VisibilityOutlined />, action: 'location', location: '/management/items', condition: 'has_item', conditionValue: true, divideAfter: true}
    ]

    actions.push({text: 'View PDF Instructions', icon: <VisibilityOutlined />, action: viewSetupsheet, condition: 'has_pdf', conditionValue: true, tooltip: 'View Setupsheet page two PDF'})

    return actions
  }

  // handlers
  const closePdfViewer = () => setPdfViewerOpen(false)
  const setSelectedMoldId = (id: any) => setSetupsheetMoldId(id)
  const deletePdfInstructions = (id: number) => {
    doConfirm('Delete setupsheet PDF instructions?', () => {
      apiPost(`${url}${id}/`, {deleteSetupsheet: true}, (resp: AxiosResponse) => {
        setPdfViewerOpen(false)
        setTableRefresh(!tableRefresh)
        setSetupsheetPdf(undefined)
      })
    })
  }
  const setNewPdfInstructions = (file: any) => {
    console.log('file :', file)
    const reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      console.log('result :', reader.result)
      setSetupsheetPdf(reader.result)
      doAlert('Uploaded successfully', 'success', true)
    }
  }

  const getRowColor = (color: string) => colors[theme.palette.mode].table.row[color];

  return (
    <>
      <PageTitle title='Molds' legend={[
        {color: getRowColor('no_setupsheet'), text: 'Needs Maintenance'}
      ]} />
      <ApiTable
        tableName=''
        objectName='Mold'
        titleVariant=''
        headers={['Mold #', 'Description', 'Stock', 'In Use', 'Mold Yield', 'Takt Time', 'X', 'Y', 'Z', 'Location', 'Primary Machines', '']}
        rowFields={['name', 'description', 'in_stock', 'in_use', 'mold_yield', 'takt_time', 'x', 'y', 'z', 'location', 'primary_machines']}
        rowActions={generateRowActions()}
        tableActions={[]}
        dataField='objects'
        url={url}
        setParentQ={(q: string | undefined) => {if (q) setTableQ(q)}}
        preQ={tableQ}
        refresh={tableRefresh}
        orderableBlacklist={['Description']}
        orderable
        refreshable
        searchable
        editable
        deletable
        creatable
        exportable
        importable
        showLoadAll
        excelImportId='molds'
        setExpandedId={setSelectedMoldId}
        FormComponent={MoldForm}
        formParams={{machines: machineList, deletePdfInstructions: deletePdfInstructions, hasPdf: !!setupsheetPdf,
          setNewPdfInstructions: setNewPdfInstructions}}
      />
      <PdfViewer file={setupsheetPdf} open={pdfViewerOpen} closeHandler={closePdfViewer}
                 actions={[]} itemId={setupsheetMoldId} />
    </>
  )
}

export default Molds
