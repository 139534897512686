import React, { FC, useEffect, useState } from 'react'
// icon imports
import Edit from '@mui/icons-material/Edit'
import Print from '@mui/icons-material/Print'
import Archive from '@mui/icons-material/Archive'
import Restore from '@mui/icons-material/Restore'
// custom imports
import { getHeadersFromType, getRowsFromType } from './ProductionLogHelpers'
import ApiTable from '../../table/ApiTable'
import {useTheme} from '@mui/material/styles'
import {colors} from '../../colors'
import {archive, getPrintData, unArchive} from './ProductionLogApi'
import EditProductionLogModal from './EditProductionLogModal'
import {printLabel} from '../../labelPrinting/labelPrinting'

interface ProductionLogTableProps {
  params: any
  type: number
  doLoad: boolean
}
const ProductionLogTable: FC<ProductionLogTableProps> = ({params, type, doLoad}) => {

  const theme: any = useTheme()

  const [refresh, setRefresh] = useState<boolean>(false)
  const [silentLoad, setSilentLoad] = useState<boolean>(false)

  const [editId, setEditId] = useState<string | undefined>(undefined)

  const doRefresh = () => {
    setSilentLoad(true)
    setRefresh(!refresh)
  }

  useEffect(() => {

    if ((params.date && params.logType) || (params.orderId && params.logType)) {
      console.log('production log table use effect params :', params)
      setRefresh(!refresh)
    }

  }, [params, type])

  // archive
  const doArchive = (id: string) => {
    archive(id, () => {
      console.log('do refresh!')
      doRefresh()
    })
  }
  const doUnArchive = (id: string) => {
    unArchive(id, () => {
      console.log('do refresh!')
      doRefresh()
    })
  }

  // edit
  const openEditModal = (id: string) => {
    setSilentLoad(true)
    setEditId(id)
  }
  const closeEditModal = () => {
    setEditId(undefined)
  }

  // reprint
  const reprintProductionLog = (id: string) => {
    getPrintData(id, (data: any) => printLabel(data.type, data.printData))
  }

  const getRowColor = (name: string) => colors[theme.palette.mode].table.row[name]

  return (
    <>
      <ApiTable
        refresh={refresh}
        doLoad={doLoad}
        suppressLoadDisplay={silentLoad}
        objectName=''
        legend={[
          {color: getRowColor('archived'), text: 'Archived'},
          {color: getRowColor('molded'), text: 'Molded'},
          {color: getRowColor('secondary'), text: 'Secondary'},
          {color: getRowColor('foamed'), text: 'Foamed'},
          {color: getRowColor('assembly'), text: 'Assembly'},
          {color: getRowColor('kit'), text: 'Kit'},
          {color: getRowColor('shipped'), text: 'Shipping'},
        ]}
        headers={getHeadersFromType(type)}
        rowFields={getRowsFromType(type)}
        rowActions={[
          {text: 'Edit Log', icon: <Edit />, action: openEditModal, permission: 'edit', condition: 'skipped', conditionValue: false},
          {text: 'Archive Log', icon: <Archive />, action: doArchive, condition: 'is_archived', conditionValue: false, permission: 'delete'},
          {text: 'Restore Log', icon: <Restore />, action: doUnArchive, condition: 'is_archived', conditionValue: true, permission: 'delete'},
          {text: 'Reprint Log', icon: <Print />, action: reprintProductionLog, condition: 'can_reprint', conditionValue: true},
        ]}
        url={'/manufacturing/production/logs'}
        extraParams={{...params, getLogs: true}}
        dataField='objects'
        searchable
        refreshable
        exportable
      />

      <EditProductionLogModal id={editId} onClose={closeEditModal} />
    </>
  )
}

export default ProductionLogTable
