import React, { FC } from 'react';
// mui imports
import Grid from '@mui/material/Grid';
import { button } from '../globals';


interface TableActionsProps {
  actions: any[];
  marginBottom?: boolean;
}
/**
 * Table actions component
 * @param actions
 */
const TableActions: FC <TableActionsProps> = ({actions, marginBottom}) => {

  const leftActions = actions.filter((act: any) => act.right === undefined);
  const rightActions = actions.filter((act: any) => act.right);

  let style: any = {marginTop: '15px'}
  if (marginBottom)
    style = {marginTop: '15px', marginBottom: '15px'}

  return (
    <Grid container sx={style}>
      <Grid item xs={6}>
        {leftActions.map((act: any, key: number) => button(act, key))}
      </Grid>
      <Grid item xs={6} display='flex' justifyContent='flex-end'>
        {rightActions.map((act: any, key: number) => button(act, key))}
      </Grid>
    </Grid>
  );
}

export default TableActions;
