import React, {FC, useState} from 'react'
import {useForm, FormProvider} from "react-hook-form";
import {useTheme} from "@mui/material/styles";
// mui imports
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
// custom imports
import {useEffectApi} from "../../../../utils/globals";
import {getMachineArmsData, MACHINE, populateMrp} from "./MrpApi";
import {colors} from "../../../../utils/colors";
import FormInputDropdown from "../../../../utils/form/FormInputDropdown";
import BaseAccordion from "../../../../utils/globalComps/BaseAccordion";
import BaseContent from "../../../../utils/globalComps/BaseContent";
import {doConfirm} from "../../../../utils/modals/Confirm";

interface ItemCellProps {
  item: any
}
const ItemCell: FC<ItemCellProps> = ({item}) => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{textAlign: 'center'}}>{item.item}</Grid>
      <Grid item xs={12} sx={{fontWeight: 'bold', textAlign: 'center'}}>{item.description}</Grid>
      <Grid item xs={12} sx={{textAlign: 'center'}}>{item.order}</Grid>
      <Grid item xs={12} sx={{textAlign: 'center'}}>Quantity: {item.balance}</Grid>
    </Grid>
  )
}

interface MachineDisplayProps {
  id: number
  name: string
  refreshTable: () => void
}
interface FormFields {
  [key: string]: any; 
}
const MachineForm: FC<MachineDisplayProps> = ({id, name, refreshTable}) => {

  const theme = useTheme()

  const [refresh, setRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [shiftOptions, setShiftOptions] = useState<any[]>([])
  const [arms, setArms] = useState<any[]>([])
  const [items, setItems] = useState<any[]>([])
	const [formData, setFormData] = useState<any>({})

  const methods = useForm<FormFields>({defaultValues: formData});
  const { reset, handleSubmit, setValue, getValues, getFieldState } = methods

  const borderColor: string = theme.palette.mode === 'dark' ? 'rgba(81, 81, 81, 1)' : 'rgba(224, 224, 224, 1)'

  useEffectApi(() => {
    getMachineArmsData(id, (data: any) => {
      setArms(data.arms)
      setItems(data.items)
      setShiftOptions(data.shift_options)
      setLoading(false)
      setDefaultData(data.items)
      // console.log('machine mrp arms data :', data)
    })
  }, [id, refresh])

  // form helpers
  const submitForm = (data: any) => {
		console.log(data)
    doConfirm(`Populate ${name} MRP?`, () => populateMrp(id, MACHINE, data, () => {
      refreshTable()
    }))
  }
  const setDefaultData = (items: any[]) => {
    let defaultData: any = {}
    for (const item of items) {
      const notLoaded: boolean = item.color !== 'loaded'

      defaultData[`count_${item.order_id}`] = notLoaded ? 0 : item.molds_available
      defaultData[`shift_on_${item.order_id}`] = notLoaded ? 0 : shiftOptions[0].value
      defaultData[`shift_off_${item.order_id}`] = notLoaded ? 0 : shiftOptions[shiftOptions.length - 1].value
    }

    reset(defaultData)
  }

  // helpers
  const doRefresh = () => setRefresh(!refresh)
  const handleExpand = (e: any, expanded: boolean) => {
    if (expanded)
      doRefresh()
  }
  const getColor = (itemColor: string) => {
    if (itemColor === 'loaded')
      return colors[theme.palette.mode].machineLoadDisplay.itemCellColor
    else
      return colors[theme.palette.mode].table.row[itemColor]
  }
  const filterItemsByArm = (armId: number) => items.filter((item: any) => item.arm_id === armId)
  const getShiftOptions = (isPriority: boolean) => {
    const options: any = [...shiftOptions]

    if (isPriority)
      options.unshift({label: 'Off', value: 0})

    // console.log('options :', options)

    return options
  }

	// Form auto update logic when selecting shifts for items that have 0 count/no shifts 
	// => updates count to 1 automatically after a shift is slected

	const [shiftValue, setShiftValue] = useState<number>()

	const handleShiftFocus = (value: any) => {
		// Stores the data of the currently focused dropdown shift menu
		setShiftValue(value)
	}

	// handles the automatic count population if shiftValue (original value of drp down when it was focused) is null 
	const handleShiftChangeFromNull = (order_id: String, e: Event, onOff: String) => {
		
		// Don't do anything is shiftValue exists
		if (shiftValue) return; 

		// Set related count value to 1
		setValue(`count_${order_id}`, 1, { shouldValidate: true})
	}

  return (
    <BaseAccordion title='Populate' onChange={handleExpand}>
      <BaseContent loading={loading}>
        <FormProvider {...methods}>
          <Grid container justifyContent='center' component={Paper}>
            <Grid item xs={12}>
              <Table size='small'>
                <TableBody>
                  {arms.map((arm: any, key: number) => (
                    <TableRow key={key}>
                      <TableCell sx={{borderBottom: '0px !important'}}>

                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={4} sx={{textAlign: 'center', borderBottom: '!important'}}>
                                {arm.name}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filterItemsByArm(arm.id).map((item: any, key: any) => (
                              <TableRow key={key}>
                                <TableCell sx={{width: '25%', backgroundColor: getColor(item.color), borderRight: `1px solid ${borderColor}`}}>
                                  <ItemCell item={item} />
                                </TableCell>
                                <TableCell sx={{width: '25%', backgroundColor: getColor(item.color), borderRight: `1px solid ${borderColor}`}}>
                                  <Grid container sx={{backgroundColor: undefined}}>
                                    <Grid item xs><FormInputDropdown name={`count_${item.order_id}`} label='Count' options={item.count_options}
                                                                     disabled={item.color === 'loaded'} /></Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell sx={{width: '25%', backgroundColor: getColor('secondary'), borderRight: `1px solid ${borderColor}`}}>
                                  <FormInputDropdown name={`shift_on_${item.order_id}`} label='Shift On'
                                                     options={getShiftOptions(item.color !== 'loaded')}
                                                     disabled={item.color === 'loaded'} 
																										 myOnFocus={(e, value) => handleShiftFocus(value)}
																										 myOnChange={(e) => handleShiftChangeFromNull(item.order_id, e, 'on')}/>
                                </TableCell>
                                <TableCell sx={{width: '25%', backgroundColor: getColor('foamed')}}>
                                  <FormInputDropdown name={`shift_off_${item.order_id}`} label='Shift Off'
                                                     options={getShiftOptions(item.color !== 'loaded')}
																										 myOnFocus={(e, value) => handleShiftFocus(value)}
																										 myOnChange={(e) => handleShiftChangeFromNull(item.order_id, e, 'off')} />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center', mt: '10px', mb: '15px'}}>
              <Button variant='contained' onClick={handleSubmit(submitForm)}>Submit</Button>
            </Grid>
          </Grid>
        </FormProvider>
      </BaseContent>
    </BaseAccordion>
  )
}

export default MachineForm
