// Global colors file

// table colors
const colors: any = {
  dark: {
    table: {
      header: '#0c0c0c',
      rowHighlight: '#111111',
      row: {
        scheduled: '#003d50',
        in_loadqueue: '#65603d',
        arm_scheduled: '#675d00',
        loaded: '#2f2f2f',
        no_setupsheet: '#863434',
        selected_item: '#b4a244',
        break: '#000000',
        almostDone: '#626b04',
        breakpoint: '#000000',

        archived: '#833232',
        molded: '#0e465d',
        secondary: '#306c11',
        foamed: '#755610',
        assembly: '#63597a',
        kit: '#466253',
        shipped: '#777439',
      }
    },
    channel: {
      messageHeadline: '#000000',
      outline: '#121212',
      background: '#212121'
    },
    machineLoadDisplay: {
      itemCellColor: '#24709b',
      armLeftOverCellColor: '#059185',
      leftColumnColor: '#1a8550',
      headerCellColor: '#49b449',
      outlineColor: '#4f4f4f',
      loadQueueOutline: '#a10000',
      overLoaded: '#f35a5a',
      inCycle: '#49b449',
      shutdown: '#929292',
      almostDone: '#a7b606'
    },
    lineplan: {
      completed: '#2b5639',
      inProgress: '#7e7740',
    }
  },
  light: {
    table: {
      header: '#bbbbbb',
      rowHighlight: '#cbcbcb',
      row: {
        scheduled: '#96d2ea',
        in_loadqueue: '#eadc96',
        arm_scheduled: '#eadc96',
        loaded: '#c5c5c5',
        no_setupsheet: '#ff5f5f',
        selected_item: '#b4a244',
        break: '#000000',
        almostDone: '#ecf57e',
        breakpoint: '#8c8c8c',

        archived: '#ff5f5f',
        molded: '#96d2ea',
        secondary: '#95da71',
        foamed: '#e0a527',
        assembly: '#b4a3da',
        kit: '#8dbba3',
        shipped: '#c7c25e',
        scrap_confirmed: '#6bcb3a',
        scrap_not_found: '#ff5f5f',

        logged_in: '#95da71',
      }
    },
    channel: {
      name: '#65A0D0',
      messageHeadline: 'rgba(166,166,166,0.3)',
      outline: '#ff9800',
      background: '#fff7b3'
    },
    machineLoadDisplay: {
      itemCellColor: '#3598dc',
      armLeftOverCellColor: '#36D7B7',
      leftColumnColor: '#36D787',
      headerCellColor: '#66ff66',
      outlineColor: '#e7ecf1',
      loadQueueOutline: '#ff6969',
      overLoaded: '#f35a5a',
      inCycle: '#66ff66',
      shutdown: '#929292',
      almostDone: '#ecf57e'
    },
    lineplan: {
      completed: '#63c383',
      inProgress: '#fff373',
    }
  }
}

export {
  colors,
}
