import React, { useState, useEffect } from 'react';
import PageTitle from '../../../../utils/globalComps/PageTitle';
import BaseContent from '../../../../utils/globalComps/BaseContent';
import useAccounting from './hooks/useAccounting';
import AccountingFilters from './AccountingFilters';
import AccountingTable from './AccountingTable';
import ProcessingReport from '../processing/ProcessingReport';
import { Box } from '@mui/material';

interface AccountingProps { }

const Accounting: React.FC<AccountingProps> = () => {
	document.title = 'Accounting | RotoEdgePro';

	const {
		loading, sendMessage,
		pageState, accounting
	} = useAccounting();

	const {
		shifts, machines, personnel, item_data
	} = accounting;

	
	const [tablePersonnel, setTablePersonnel] = useState<string[]>([]); // New state for filtered personnel

	const handleFilterSubmit = (filters: any) => {
		sendMessage('update_filters', filters, true); 
	};

	return (
		<>
			<PageTitle title='Accounting' />
			<BaseContent loading={false}>
				<Box sx={{ mb: 5 }}>
					<ProcessingReport />
				</Box>
				<hr />
				<Box sx={{ mt: 5 }}>
					<AccountingFilters
						shifts={shifts}
						machines={machines}
						personnel={personnel}
						sendMessage={handleFilterSubmit} // Use handleFilterSubmit
						pageState={pageState}
						itemData={item_data}
					/>
					<AccountingTable
						data={item_data}
					/>
				</Box>
			</BaseContent>
		</>
	);
};

export default Accounting;
