/**
 * patchPerGroupOverall - Used by line plan table displays
 * @param data: list of line plan items in order
 * Patches in per group and overall values, per group = quantity * takt, overall is per group summed each iteration
 */
const patchPerGroupOverall = (data: any) => {
  let overall: number = 0

  return data.map((object: any) => {
    let perGroup: number = 0
    if (parseInt(object.staged_quantity) && parseInt(object.takt)) {
      perGroup = parseInt(object.staged_quantity) * parseInt(object.takt)
      overall += perGroup
    }

    return {...object, perGroup: perGroup, overall: overall}
  })
}

export {
  patchPerGroupOverall
}