import React, { FC, useState } from 'react'
import { AxiosResponse } from 'axios'
// mui imports
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
// mui icon imports
import Print from '@mui/icons-material/Print'
// custom imports
import { useEffectApi } from '../../../../utils/globals'
import { apiGet } from '../../../../utils/api'
import BomItemList from './BomItemList'
import InfoText from '../../../../utils/globalComps/InfoText'
import BaseModal from '../../../../utils/modals/BaseModal'
import BaseContent from '../../../../utils/globalComps/BaseContent'

interface SectionTitleProps {
  text: string
}
const SectionTitle: FC<SectionTitleProps> = ({text}) => {
  const theme = useTheme()

  const bgColor = theme.palette.mode === 'dark' ? '#1e1e1e' : '#cccccc'

  return (
    <Box justifyContent='center' display='flex' sx={{backgroundColor: bgColor, borderTop: '2px solid red'}}>
      <Typography sx={{mt: '15px', mb: '15px'}} variant='h5'>{text.toUpperCase()}</Typography>
    </Box>
  )
}


interface DefaultPdfViewProps {
  open: boolean
  close: () => void
  url: string
  id?: number | undefined
	itemName: string
  refresh?: boolean | undefined
  bomItems: any[]
  dualTimes?: boolean
  timeDiffTemp?: number
  firstArticle?: boolean
  doPrint?: ((cb: () => void) => void) | undefined
  printLoading: boolean
  hasPdf?: boolean
  machineList?: any[]
  selectedMachine?: string | undefined
  doSelectMachine?: ((machineName: string) => void) | undefined
  newTab?: boolean | undefined
}
const DefaultPdfView: FC<DefaultPdfViewProps> = ({open, close, url, id, refresh, itemName,
																									doPrint, printLoading, newTab}) => {

  const theme = useTheme()
  const lightTheme = createTheme({palette: {mode: 'light', background: {default: '#f4f9ff'}, primary: {main: '#178de0'}}})

  const [loading, setLoading] = useState<boolean>(false)

  const [setupsheet, setSetupsheet] = useState<any>({})
  const [pdfInstructionsUrl, setPdfInstructionsUrl] = useState<string | undefined>(undefined)
  const [currentTheme, setCurrentTheme] = useState<any>({})

  useEffectApi(() => {

    if (itemName) {
      setCurrentTheme(theme)
      setLoading(true)

			apiGet(`${url}`, {getDefaultPdf: true, newTab: newTab, blob: true, itemName}, (resp: AxiosResponse) => {
				if (resp.data.size > 32)
					setPdfInstructionsUrl(window.URL.createObjectURL(resp.data))
				else
					setPdfInstructionsUrl(undefined)
				setLoading(false)
			})

    }

  }, [id, url, open, theme, refresh])

  const doPrintWrapper = () => {
    setCurrentTheme(lightTheme)
    if (doPrint)
      doPrint(() => setCurrentTheme(theme))
  }

  const Content = () => {
    return (
      <BaseContent loading={loading}>
          <div className='setupsheet-pdf'>
            <SectionTitle text='Default PDF' />
            <iframe title='pdf viewer' src={pdfInstructionsUrl} frameBorder='0' style={{width: '100%', height: '80vh',
              marginTop: '20px'}} />
          </div> 
      </BaseContent>
    )
  }

  return (
    <>
      {itemName ?
        <ThemeProvider theme={currentTheme}>
          {newTab ?
            <>
              <Content />
            </> :
            <BaseModal
              title='Default PDF View'
              open={open}
              closeHandler={close}
              closeButton
              dividers
              actions={doPrint ? [
                {text: 'Print', icon: <Print />, loading: printLoading, action: doPrintWrapper}
              ] : []}
              maxWidth='lg'
            >
              <Content />
            </BaseModal>
          }
        </ThemeProvider>
        :
        <></>
      }
    </>
  )
}

export default DefaultPdfView
