import React, { FC, useState, useEffect } from 'react'
// custom imports
import { getFormData } from './LinePlanApi'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import ApiTable from '../../../../utils/table/ApiTable'
import LinePlanForm from './LinePlanForm'
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion'
import BaseContent from '../../../../utils/globalComps/BaseContent'

interface LinePlanProps {

}

/**
 * LinePlan Management, not to be confused with lineplan processing
 */
const LinePlan: FC<LinePlanProps> = ({}) => {
  const [factoryOptions, setFactoryOptions] = useState<any[]>([])
  const [typeOptions, setTypeOptions] = useState<any[]>([])
  const [shiftOptions, setShiftOptions] = useState<any[]>([])
  const [secondaryRefresh, setSecondaryRefresh] = useState<boolean>(false)
  const [assemblyRefresh, setAssemblyRefresh] = useState<boolean>(false)
  const [foamingRefresh, setFoamingRefresh] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Lineplans | RotoEdgePro'

    getFormData((formData: any) => {
      setFactoryOptions(formData.factories)
      setTypeOptions(formData.types)
      setShiftOptions(formData.shifts)
    })
  }, [])

  // helpers
  const foamingEnabled = () => {
    for (const type of typeOptions)
      if (type.label === 'Foaming')
        return true
  }
  const refreshSecondary = (e: any, expanded: boolean) => {
    if (expanded)
      setSecondaryRefresh(!secondaryRefresh)
  }
  const refreshAssembly = (e: any, expanded: boolean) => {
    if (expanded)
      setAssemblyRefresh(!assemblyRefresh)
  }
  const refreshFoaming = (e: any, expanded: boolean) => {
    if (expanded)
      setFoamingRefresh(!foamingRefresh)
  }

  return (
    <>
      <PageTitle title='Line Plan Management' />
      <BaseContent loading={false}>
        <BaseAccordion title='Secondary' onChange={refreshSecondary}>
          <ApiTable
            tableName=''
            objectName='LinePlan'
            titleVariant=''
            headers={['Name', 'Code', 'Workers', 'Post To Live', 'Auto Clear', 'Auto Post', 'Shift', '']}
            rowFields={['name', 'code', 'worker_count', 'post_to_live_time', 'auto_clear', 'auto_post', 'shift_name']}
            tableActions={[]}
            dataField='objects'
            url='/manufacturing/lineplan/'
            editable
            deletable
            creatable
            orderable
            orderableBlacklist={['Shift', 'Auto Post']}
            refresh={secondaryRefresh}
            forceFilter='secondary'
            FormComponent={LinePlanForm}
            formParams={{factories: factoryOptions, types: typeOptions, type: 1, shifts: shiftOptions}}
          />

          {/*<LineplanValidate type={1} />*/}
        </BaseAccordion>

        {foamingEnabled() ? <BaseAccordion title='Foaming' onChange={refreshFoaming}>
          <ApiTable
            tableName=''
            objectName='LinePlan'
            titleVariant=''
            headers={['Name', 'Code', 'Workers', 'Post To Live', 'Auto Clear', 'Auto Post', 'Shift', '']}
            rowFields={['name', 'code', 'worker_count', 'post_to_live_time', 'auto_clear', 'auto_post', 'shift_name']}
            tableActions={[]}
            dataField='objects'
            url='/manufacturing/lineplan/'
            editable
            deletable
            creatable
            orderable
            orderableBlacklist={['Shift', 'Auto Post']}
            refresh={foamingRefresh}
            forceFilter='foaming'
            FormComponent={LinePlanForm}
            formParams={{factories: factoryOptions, types: typeOptions, type: 2, shifts: shiftOptions}}
              />

            {/*<LineplanValidate type={2} />*/}
          </BaseAccordion> : <></>}

        <BaseAccordion title='Assembly' onChange={refreshAssembly}>
          <ApiTable
            tableName=''
            objectName='LinePlan'
            titleVariant=''
            headers={['Name', 'Code', 'Workers', 'Post To Live', 'Auto Clear', 'Auto Post', 'Shift', '']}
            rowFields={['name', 'code', 'worker_count', 'post_to_live_time', 'auto_clear', 'auto_post', 'shift_name']}
            tableActions={[]}
            dataField='objects'
            url='/manufacturing/lineplan/'
            editable
            deletable
            creatable
            orderable
            orderableBlacklist={['Shift', 'Auto Post']}
            refresh={assemblyRefresh}
            forceFilter='assembly'
            FormComponent={LinePlanForm}
            formParams={{factories: factoryOptions, types: typeOptions, type: 3, shifts: shiftOptions}}
          />

          {/*<LineplanValidate type={3} />*/}
        </BaseAccordion>
      </BaseContent>
    </>
  )
}

export default LinePlan
