import {apiGet, apiPost} from "../../../../utils/api";

const URL: string = '/scheduling/lineplan-scheduling/';

/**
 * Gets the base data for the line plan scheduling page
 * @param id lineplan id
 * @param callback
 */
const getLineplanSchedulingData = (id: number | undefined, callback: (data: any) => void) => {
  const url: string = id ? `${URL}${id}/` : URL
  const params: any = id ? {} : {getData: true}
  apiGet(url, params, (resp: any) => callback(resp.data))
}
/**
 * Checks assembly children line plan information
 * @param id assembly line plan item id
 * @param callback success callback
 */
const getAssemblyChildLookupData = (id: number | undefined, callback: (data: any) => void) => {
  apiGet(URL, {parentId: id}, (resp) => callback(resp.data))
}

// post
const updateItemQuantity = (id: number, quantity: number, callback: () => void) => {
  apiPost(URL, {updateQuantity: true, id: id, quantity: quantity}, () => callback())
}
/**
 * Reorders the lineplan staging table
 * @param id lineplan id
 * @param items list of staged line plan item ids in order
 * @param callback
 */
const reorderLineplanItems = (id: number, items: number[], callback: (data: any) => void) => {
  apiPost(`${URL}${id}/`, {reorderStagedLineplanItems: true, items: items},
    (resp: any) => callback(resp.data))
}
/**
 * Handles the manual breakpoint, can enable/disable and update the breakpoint place with this api call
 * @param id lineplan id
 * @param params enable/disable/update params
 * @param callback
 */
const handleManualBreakpoint = (id: number, params: any, callback: (data: any) => void) => {
  apiPost(`${URL}${id}/`, {...params, manualBreakpoint: true}, (resp: any) => callback(resp.data))
}
/**
 * Updates the line plan worker count
 * @param id lineplan id
 * @param count worker count
 * @param callback
 */
const updateWorkerCount = (id: number, count: number, callback: () => void) => {
  apiPost(`${URL}${id}/`, {workerCount: count}, () => callback())
}
/**
 * moves a staged lineplan item from one lineplan to another
 * @param data form data
 * @param callback
 */
const moveStagedLineplanItem = (data: any, callback: (success: boolean) => void) => {
  apiPost(URL, {...data, moveItem: true}, (resp: any) => callback(resp.data.status !== 'warning'),
    () => callback(false))
}
/**
 * Removes a lineplan item
 * @param id lineplan item id
 * @param callback
 */
const removeLineplanItem = (id: number, callback: () => void) => {
  apiPost(URL, {removeItem: true, id: id}, () => {
    callback()
  })
}
/**
 * Updates the live line with any new values from the planning line
 * @param id
 */
const updateToLive = (id: number) => {
  apiPost(`${URL}${id}/`, {updateToLive: true})
}
/**
 * Either posts the staging line items to the live line, overwriting the current live line, or schedules a post to live.
 * The post to live time is set on the line plan model in line plan management
 * @param id lineplan id
 * @param data post to live data
 * @param callback success callback
 */
const postToLive = (id: number, data: any, callback: () => void) => {
  apiPost(`${URL}${id}/`, { ...data, postToLive: true}, () => callback())
}
/**
 * clears the live line of all items
 * @param id lineplan id
 * @param callback success callback
 */
const clearLiveLine = (id: number, callback: () => void) => {
  apiPost(`${URL}${id}/`, {clearLiveLine: true, id: id}, () => callback())
}
/**
 * Cancels a post to live later
 * @param id lineplan id
 * @param callback success callback
 */
const cancelPostToLiveLater = (id: number, callback: () => void) => {
  apiPost(`${URL}${id}/`, {cancelPostToLiveLater: true}, () => callback())
}
/**
 * Pushes the passed lineplan item to the top of its staged lineplan
 * @param id: lineplan item id
 * @param callback
 */
const pushItemToTop = (id: number, callback: () => void) => {
  apiPost(`${URL}${id}/`, {pushToTop: true}, () => callback())
}
/**
 * Sorts red items to bottom for the passed lineplan, this is done automatically for secondary lines but is an action
 * for foaming/assembly lines
 * @param id: lineplan id
 * @param callback
 */
const sortRedToBottom = (id: number, callback: () => void) => {
  apiPost(`${URL}${id}/`, {redToBottom: true}, () => callback())
}


export {
  getLineplanSchedulingData, updateItemQuantity, reorderLineplanItems, handleManualBreakpoint, updateWorkerCount,
  moveStagedLineplanItem, removeLineplanItem, updateToLive, postToLive, clearLiveLine, cancelPostToLiveLater,
  getAssemblyChildLookupData, pushItemToTop, sortRedToBottom
}