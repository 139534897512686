import React, {FC, useEffect} from 'react';
// mui imports
import Grid from '@mui/material/Grid';
// custom imports
import ApiForm from '../../../../utils/form/ApiForm';
import FormInputText from '../../../../utils/form/FormInputText';
import FormInputAutocomplete from '../../../../utils/form/FormInputAutocomplete';
import { FormSpacing, capitialize } from '../../../../utils/globals';
import InfoText from "../../../../utils/globalComps/InfoText";
import BaseTooltip from "../../../../utils/globalComps/BaseTooltip";
import FormInputDropdown from "../../../../utils/form/FormInputDropdown";
import Typography from "@mui/material/Typography";

interface PermissionFormProps {
  id: number | undefined;
  open: boolean;
  closeForm: (submit: boolean, data: any, create: boolean) => void;
  extraInfo: any[];
  permissionList: any[];
  views: any[]
  groups: any[]
}
const PermissionForm: FC<PermissionFormProps> = ({id, open, closeForm, views, groups}) => {

  useEffect(() => {
    console.log('permission form :', id)
  }, [id])

  const overrideCloseForm = (submit: boolean, data: any, create: boolean) => {
    closeForm(submit, data, create);
  }

  return (
    <ApiForm
      name='Group Form'
      open={open}
      defaultValues={{}}
      closeForm={overrideCloseForm}
      id={id}
      tableActions={[]}
      fetchUrl={`/main/permissions/${id}/`}
      submitUrl={'/main/permissions/'}
      xl
    >
      <Grid container spacing={FormSpacing}>
        <Grid item xs={3}><FormInputText name='name' label='Name' /></Grid>
        <Grid item xs={9}><FormInputText name='description' label='Description' /></Grid>
      </Grid>
      <Grid container spacing={4}>

        {groups ?
          <>
            {groups.map((group: any, key: number) => (

              <Grid item container xs={12} spacing={FormSpacing} key={key}>
                <Grid item xs={12}><Typography variant='h6'>{group.name}</Typography></Grid>
                {views.filter((view: any) => view.group_number === group.number).map((view: any, key: number) => (
                  <BaseTooltip key={key}>
                    <Grid item xs={4}>
                      <FormInputDropdown name={view.number.toString()} label={view.name} multiple helper={view.note}
                                         options={view.permissions.map((perm: any) => ({value: perm.id, label: perm.name}))} />
                    </Grid>
                  </BaseTooltip>
                ))}
              </Grid>

            ))}
          </>
          :
          <Grid item xs={12}><Typography>No groups found</Typography></Grid>
        }

      </Grid>
    </ApiForm>
  )
}

export default PermissionForm;
