import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface HeaderExampleDialogProps {
	open: boolean;
	onClose: () => void;
	headers: string[];
}

const HeaderExampleDialog: React.FC<HeaderExampleDialogProps> = ({ open, onClose, headers }) => {
	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>
				Example Excel Headers
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TableContainer component={Paper} sx={{ borderRadius: 2 }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								{headers.map((header, index) => (
									<TableCell
										key={index}
										sx={{
											border: '1px solid #ccc',
											borderTopLeftRadius: index === 0 ? 8 : 0,
											borderTopRightRadius: index === headers.length - 1 ? 8 : 0,
										}}
									>
										{header}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								{headers.map((header, index) => (
									<TableCell
										key={index}
										sx={{
											border: '1px solid #ccc',
											borderBottomLeftRadius: index === 0 ? 8 : 0,
											borderBottomRightRadius: index === headers.length - 1 ? 8 : 0,
										}}
									>
										Example Data {index + 1}
									</TableCell>
								))}
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
};

export default HeaderExampleDialog;
