import React, { FC } from 'react';
// mui imports
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// mui icon imports
import CircleIcon from "@mui/icons-material/Circle";
import BaseTooltip from "./BaseTooltip";

interface LegendItemProps {
  color: string;
  text: string;
  tooltipText?: string;
}
const LegendItem: FC<LegendItemProps> = ({color, text, tooltipText}) => {
  return (
    <BaseTooltip text={tooltipText ? tooltipText : ''}>
      <Grid item sx={{mr: '8px'}}>
        <Grid container>
          <CircleIcon style={{color: color, fontSize: '20px', marginTop: '2px'}} />
          <Typography variant='subtitle2' sx={{marginTop: '2px', marginLeft: '5px'}} >{text}</Typography>
        </Grid>
      </Grid>
    </BaseTooltip>
  );
}

interface LegendProps {
  legendItems: any[];
}
const Legend: FC<LegendProps> = ({legendItems}) => {
  return (
    <Grid container>
      {legendItems.map((legendItem: any, key: number) => (
        <LegendItem color={legendItem.color} text={legendItem.text} tooltipText={legendItem.tooltipText} key={key} />
      ))}
    </Grid>
  );
}

export default Legend;
