import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";

export interface StandaloneScheduledOrder {
	number: string
	item: string
	arm: string
	quantity: number
	balance: number
}

export interface Machine {
	id: number;
	name: string;
	capacity: number
	productivity: number;
	standalone_scheduled_orders: StandaloneScheduledOrder[]
}

interface MachineScheduling {
	page_state: {}
	machines: Machine[]
	permissions: {}
}

const useMachineScheduling = () => {
	const [data, setData] = useState<MachineScheduling>({
		page_state: { },
		permissions: {
			is_admin: false,
			is_superuser: false,
			view: false,
			create: false,
			edit: false,
			delete: false,
		},
		machines: [],
	});

	const { loading, sendMessage, isVisible, setPageState, isReady } = useWebsocket('/scheduling/machine/', setData);

	// let pageState: PageState = data.page_state;
	// const permissions = useMemo(() => data.permissions ? data.permissions : {
	// 	is_admin: false,
	// 	is_superuser: false,
	// 	view: false,
	// 	create: false,
	// 	edit: false,
	// 	delete: false,
	// }, [data]);

	const machines = useMemo(() => data.machines ? data.machines : [], [data])
	

	// Log and potentially re-set state when visibility changes
	useEffect(() => {
		if (!isVisible) {
			// setPageState(pageState);
		}
	}, [isVisible]);

	return {
		loading, sendMessage, isVisible, setPageState,
		// pageState, permissions
		machines
	};
};

export default useMachineScheduling;
