import React, { FunctionComponent } from 'react';
import { Box, Grid, ToggleButtonGroup, ToggleButton, useTheme } from '@mui/material/';
import BaseContent from "../../../utils/globalComps/BaseContent";
import { DepartmentProductivity, ScrapSummary, FinancialSummary } from './dashboardComponents';
import { TimePeriodProvider, useTimePeriod } from './TimePeriodContext';
import { grey } from '@mui/material/colors';

const TimePeriodToggle: FunctionComponent = () => {

	
	const { timePeriod, setTimePeriod } = useTimePeriod();

	const handleTimePeriodChange = (
		event: React.MouseEvent<HTMLElement>,
		newTimePeriod: 'shift' | 'month'
	) => {
		if (newTimePeriod !== null) {
			setTimePeriod(newTimePeriod);
		}
	};

	return (
		<Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
			<ToggleButtonGroup
				value={timePeriod}
				exclusive
				onChange={handleTimePeriodChange}
				aria-label="time period"
			>
				<ToggleButton value="shift" aria-label="today">
					Shift
				</ToggleButton>
				<ToggleButton value="month" aria-label="month-to-date">
					Month to Date
				</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	);
};

const Dashboard: FunctionComponent = () => {
	
	document.title = 'Dashboard | RotoEdgePro';
	const theme = useTheme();

	return (
		<BaseContent loading={false}>
			<TimePeriodProvider>
				<TimePeriodToggle />
				<Box sx={{ maxHeight: 'calc(100vh - 240px)', overflow: 'auto', borderBottom: 2, borderTop: 2, borderRadius: 0, borderColor: grey[500], py: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6} sx={{ display: 'flex' }}>
							<DepartmentProductivity />
						</Grid>
						<Grid item xs={12} md={6}>
							<ScrapSummary />
						</Grid>
						<Grid item xs={12}>
							<FinancialSummary />
						</Grid>
					</Grid>
				</Box>
			</TimePeriodProvider>
		</BaseContent>
	);
};

export default Dashboard;
