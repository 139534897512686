import {AxiosError, AxiosResponse} from "axios";
// custom imports
import {apiGet, apiPost} from "../../../utils/api";
import {doAlert} from "../../../utils/globalComps/PopupAlert/PopupAlert";
import {api} from "../../../utils/globals";
import {doConfirm} from "../../../utils/modals/Confirm";


// get
const getMachine = (machineId: number, callback: (machine: any) => void) => {

  apiGet(`/inventory/machines/${machineId}/`, {},
    (resp: any) => callback(resp.data.object[0]))
}
const getArms = (machineId: number, callback: (arms: any) => void) => {
  apiGet(`/inventory/arms`, {machineId: machineId},
    (resp: any) => callback(resp.data.objects))
}
const getArmLoadingData = (callback: (data: any) => void) => {
  apiGet('/scheduling/arm-loading/', {getData: true},
  (resp: any) => {callback(resp.data)})
}
/**
 * Gets powder label data to print
 * @param id: order id
 * @param machineId
 * @param callback
 */
const getPowderLabelData = (id: number, machineId: number, callback: (data: any) => void) => {
  apiGet(`scheduling/arm-loading/${id}/`, {powderLabel: true, machineId: machineId}, (resp: any) => {
    callback(resp.data)
  })
}

// post
const unloadAllMolds = (machineName: string, orderId: number | undefined, machineId: number, successCallback: () => void) => {
  const url: string = `/scheduling/arm-loading/${orderId}/`

  apiGet(url, {moldName: true, type: 'machineLoad'}, ({data}) => {
    doConfirm(`Unload all ${data.name} molds from ${machineName}?`, () => {
      apiPost(url, {machineId: machineId, removeAllMolds: true}, (resp: any) => {
        console.log('unloadAllMolds :', resp)
        successCallback()
      })
    })
  })
}
const unloadMold = (orderName: string, armName: string, orderId: number | undefined, status: any, successCallback: (loadAfterItems: any[]) => void) => {
  doConfirm(`Unload ${orderName} from ${armName}?`, () => {
    api.post(`/scheduling/arm-loading/${orderId}/`, {...status, armName: armName, quantity: 1, unloadFromMachine: true})
      .then((resp: AxiosResponse) => {
        successCallback(resp.data)
      })
      .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
  })
}
const loadAfter = (loadAfterIds: number[], loadNothing: boolean, callback: () => void) => {
  api.post(`/scheduling/arm-loading/`, {loadAfterIds: loadAfterIds, loadNothing: loadNothing})
    .then(() => callback())
    .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
}

const removeLoadQueue = (orderId: number, callback: () => void) => {
  apiGet(`/scheduling/arm-loading/${orderId}/`, {getOrderName: true, type: 'loadQueue'},
    (resp: AxiosResponse) => {
      doConfirm(`Remove ${resp.data.name} from Load Queue? It will still be in the machine schedule table.`, () => {
        apiPost(`/scheduling/arm-loading/${orderId}/`, {removeLoadQueue: true, loadQueueId: true},
          () => callback())
      })
    })
}
const unloadAllMoldsFromArm = (armId: number, successCallback: () => void) => {
  apiPost('/scheduling/arm-loading/', {armId: armId, removeAllMoldsArm: true}, () => successCallback())
}

export {
  getMachine, getArms, getArmLoadingData, getPowderLabelData, unloadAllMolds, unloadMold, loadAfter,
  removeLoadQueue, unloadAllMoldsFromArm
}