import React, { FC, useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form';
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import BaseModal from "../../modals/BaseModal";
import {FormSpacing} from "../../globals";
import FormInputNumber from "../../form/FormInputNumber";
import FormInputText from "../../form/FormInputText";
import FormInputDropdown from "../../form/FormInputDropdown";
import {editLog, getEditData} from "./ProductionLogApi";
import {splitIdType} from "./ProductionLogHelpers";

interface EditProductionLogModalProps {
  id?: string | undefined
  onClose: () => void
}
/**
 * modal for editing production logs
 * @param id
 * @param onClose
 */
const EditProductionLogModal: FC<EditProductionLogModalProps> = ({id, onClose}) => {

  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const [ncEnabled, setNcEnabled] = useState<boolean>(false)
  const [shifts, setShifts] = useState<any[]>([])
  const [codes, setCodes] = useState<any[]>([])
  const [codeDisabled, setCodeDisabled] = useState<boolean>(true)

  const methods = useForm({defaultValues: data});
  const { reset, handleSubmit, setValue, setError } = methods;

  useEffect(() => {

    if (id) {
      setOpen(true)

      getEditData(id, (data: any) => {
        setData(data.object)
        setShifts(data.shifts)
        setCodes(data.codes)
        setNcEnabled(data.nc_enabled)
        reset(data.object)
      })
    } else
      setOpen(false)

  }, [id])

  const doReset = () => {
    reset(data)
  }
  const submit = (submitData: any) => {
    console.log('id :', id)
    if (id) {
      editLog(id, submitData, (errors: any[]) => {
        if (errors.length > 0)
          for (const error of errors)
            setError(error.name, {type: 'server', message: error.message})
        else
          onClose()
      })
    }
  }

  // disable nc code selection when nc parts are 0 or none, enable otherwise
  const validateNonConforming = (e: any) => {
    if (e.target.value === '' || parseInt(e.target.value) === 0) {
      setCodeDisabled(true)
      setValue('scrap_code', undefined)
    } else
      setCodeDisabled(false)
  }

  return (
    <BaseModal
      title='Edit Production Log'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Reset', action: doReset, outlined: true},
        {text: 'Submit', action: handleSubmit(submit)},
      ]}
      maxWidth='md'
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing} sx={{mb: '8px'}}>
          <Grid item xs={6}><FormInputText name='order' label='Order' disabled /></Grid>
          <Grid item xs={6}><FormInputText name='item' label='Item' disabled /></Grid>
          <Grid item xs={12}><FormInputNumber name='conforming_parts' label='Conforming Parts' /></Grid>
          {ncEnabled ? <Grid item xs={12}>
            <FormInputNumber name='non_conforming_parts' label='Non Conforming Parts' onChange={validateNonConforming} />
          </Grid> : <></>}
          {ncEnabled ? <Grid item xs={6}>
            <FormInputDropdown name='scrap_code' label='Non Conforming Code' options={codes} disabled={codeDisabled} />
          </Grid> : <></>}
          <Grid item xs={ncEnabled ? 6 : 12}><FormInputDropdown name='shift' label='Shift' options={shifts} /></Grid>
        </Grid>
        {data.updated ? <small style={{marginBottom: 5}}>Last updated at: <strong>{data.updated}</strong></small> : <></>}
      </FormProvider>
    </BaseModal>
  )
}

export default EditProductionLogModal