import React, { FC, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
// mui icon imports
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import Delete from '@mui/icons-material/Delete'
// custom imports
import { useEffectApi } from '../../../../utils/globals'
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import { doConfirm } from '../../../../utils/modals/Confirm'
import { apiGet, apiPost } from '../../../../utils/api'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import ApiTable from '../../../../utils/table/ApiTable'
import ItemForm from './ItemForm'
import PdfViewer from '../../../../utils/modals/PdfViewer'

const url: string = '/inventory/items/'

const Items: FC = () => {
  const params = useParams()

  const [moldList, setMoldList] = useState<string[]>([])
  const [foamingEnabled, setFoamingEnabled] = useState<boolean>(false)
  const [typeList, setTypeList] = useState<string[]>([])
  const [selectedType, setSelectedType] = useState<number>(1)
  const [tableQ, setTableQ] = useState<string | undefined>(undefined)
  const [tableRefresh, setTableRefresh] = useState<boolean>(false)
  const [processInstructionsPdf, setProcessInstructionsPdf] = useState<any | undefined>(undefined)
  const [pdfViewerOpen, setPdfViewerOpen] = useState<boolean>(false)
  const [processInstructionsItemId, setProcessInstructionsItemId] = useState<number | undefined>(undefined)
  const [itemId, setItemId] = useState<number | undefined>(undefined)
  const [costSalesPermission, setCostSalesPermissions] = useState<boolean>(false)

  const selectedIdHandler = (id: number | undefined) => {
    // set selected type for item edit form
    setSelectedItemId(id)
    if (id)
      apiGet(url, {getItemType: id}, (resp: any) => setSelectedType(resp.data.type))
  }

  useEffectApi(() => {

    document.title = 'Items | RotoEdgePro'

    apiGet(url, {getData: true}, (resp: any) => {
      setMoldList(resp.data.molds)
      setTypeList(resp.data.types)
      setFoamingEnabled(resp.data.foamingEnabled)
      setCostSalesPermissions(resp.data.costSalesPermission)
    })

  }, [url, setTypeList, processInstructionsPdf, itemId])

  const filterOptions = [
    {text: 'All'},
    {text: 'Assembly'},
    {text: 'Moldable'},
    {text: 'Kit'},
    {text: 'Powder'},
    {text: 'Component'},
    {text: 'Missing Type'}
  ]

  let headers = [{text: 'Item', tooltip: ''}, {text: 'Description', tooltip: ''}, {text: 'Mold', tooltip: ''},
    {text: 'Type', tooltip: ''}, {text: 'Bin Location', tooltip: ''}, {text: 'Line Plan Takt Time' , tooltip: ''}, {text: 'Skip Molding', tooltip: ''},
    {text: 'Skip Secondary', tooltip: ''}, {text: 'Skip Assembly', tooltip: ''},
    {text: 'Printing Enabled', tooltip: ''}, {text: 'First Article', tooltip: ''}, {text: ''}]
  let fields = ['name', 'description', 'mold', 'type_name', 'bin_location', 'takt_time', 'skip_molding', 'skip_secondary',
    'skip_assembly', 'printing_enabled', 'first_article']

  if (foamingEnabled) {
    headers.splice(5, 0, {text: 'Foam Takt Time', tooltip: 'foobar'})
    headers.splice(6, 0, {text: 'Is Foam', tooltip: ''})
    fields.splice(5, 0, 'foam_takt_time')
    fields.splice(6, 0, 'is_foam')
  }

  const generateRowActions = () => {
    const actions: any[] = [
      {text: 'View Item\'s BOM', icon: <VisibilityOutlined />, action: 'location', location: '/management/bomitems', condition: 'has_child', conditionValue: true},
      {text: 'View Parent BOM', icon: <VisibilityOutlined />, action: 'location', location: '/management/bomitems', condition: 'has_parent', conditionValue: true},
    ]

    if (!!processInstructionsPdf)
      actions.push({text: 'View Process Instructions', icon: <VisibilityOutlined />, action: viewProcessInstructions, condition: 'has_process_instructions', conditionValue: true, divideAfter: true})

    return actions
  }

  // row actions
  const toggleItemValue = (id: number, fieldName: string) => {
    apiPost(`${url}${id}/`, {toggleField: fieldName}, (resp: AxiosResponse) => {})
  }
  const toggleSkipMolding = (id: number) => toggleItemValue(id, 'skip_molding')
  const toggleSkipSecondary = (id: number) => toggleItemValue(id, 'skip_secondary')
  const toggleSkipAssembly = (id: number) => toggleItemValue(id, 'skip_assembly')
  const togglePrintingEnabled = (id: number) => toggleItemValue(id, 'printing_enabled')
  // view process instructions
  const viewProcessInstructions = (id: number) => {
    apiGet(`${url}${id}/`, {processInstructions: true, blob: true}, (resp: AxiosResponse) => {
      setProcessInstructionsItemId(id)
      setProcessInstructionsPdf(resp.data)
      setPdfViewerOpen(true)
    })
  }
  const closePdfViewer = () => setPdfViewerOpen(false)

  // process instructions pdf upload modal
  const setSelectedItemId = (id: any) => {
    setItemId(id)
    apiGet(`${url}${id}/`, {'blob': true}, (resp: AxiosResponse) => {
      setProcessInstructionsItemId(id)
      setProcessInstructionsPdf(resp.data)
    }, (_: AxiosError) => {
      setProcessInstructionsPdf(undefined)
    }, true)
  }
  const deleteProcessInstructions = (id: number) => {
    doConfirm('Delete setupsheet PDF instructions?', () => {
      apiPost(`${url}${id}`, {deleteProcessInstructions: true}, (resp: AxiosResponse) => {
        setPdfViewerOpen(false)
        setTableRefresh(!tableRefresh)
        setProcessInstructionsPdf(undefined)
        if (resp.data.message) doAlert(resp.data.message, 'success', true)
      })
    })
  }
  const setNewProcessInstructions = (file: any) => {
    console.log('file :', file)
    const reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {console.log('result :', reader.result); setProcessInstructionsPdf(reader.result)}
  }

  return (
    <>
      <PageTitle title='Items Management'/>
      <ApiTable
        tableName=''
        objectName='Item'
        titleVariant=''
        headers={headers}
        rowFields={fields}
        clickToToggleFields={[
          {field: 'skip_molding', action: toggleSkipMolding, tooltip: 'Skip Molding'},
          {field: 'skip_secondary', action: toggleSkipSecondary, tooltip: 'Skip Secondary'},
          {field: 'skip_assembly', action: toggleSkipAssembly, tooltip: 'Skip Assembly'},
          {field: 'printing_enabled', action: togglePrintingEnabled, tooltip: 'Printing Enabled'},
        ]}
        rowActions={generateRowActions()}
        dataField='objects'
        url={url}
        refreshable
        filterable
        filters={filterOptions}
        orderable
        searchable
        setParentQ={(q: string | undefined) => setTableQ(q)}
        preQ={params.q || tableQ}
        editable
        deletable
        creatable
        exportable
        importable
        excelImportId='items'
        setExpandedId={selectedIdHandler}
        FormComponent={ItemForm}
        formParams={{types: typeList.filter((type: any) => type.label !== 'All'), foamingEnabled: foamingEnabled,
          type: selectedType, molds: moldList, hasPdf: !!processInstructionsPdf,
          deleteProcessInstructions: deleteProcessInstructions, setNewProcessInstructions: setNewProcessInstructions,
          costSalesPermission: costSalesPermission}}
      />
      <PdfViewer file={processInstructionsPdf} open={pdfViewerOpen} closeHandler={closePdfViewer}
                 actions={[{text: 'Delete', action: deleteProcessInstructions, color: 'error', icon: <Delete />}]}
                 itemId={processInstructionsItemId} />
    </>
  )
}

export default Items
