import React, { FC } from "react"
import BaseModal from "../../utils/modals/BaseModal";


interface ConfigSettingsModalProps {
  open: boolean
  onClose: () => void
}
const ConfigSettingsModal: FC<ConfigSettingsModalProps> = ({open, onClose}) => {

  return (
    <BaseModal
      title='Config Settings'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[

      ]}
    >
      <p>foobar</p>
    </BaseModal>
  )

}

export default ConfigSettingsModal
