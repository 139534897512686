import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

interface ProgressTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
  children: React.ReactElement;
  tooltip: any;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
		borderRadius: '10px'
  },
}));

const ProgressTooltip: React.FC<ProgressTooltipProps> = ({ children, tooltip, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl({
      ...anchorEl,
      getBoundingClientRect: () => ({
        top: event.clientY,
        left: event.clientX,
        right: event.clientX,
        bottom: event.clientY,
        width: 0,
        height: 0,
      }),
    } as HTMLElement);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

	const title = tooltip.title

  return (
    <div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      <StyledTooltip
        {...props}
				sx={{paddingX: 0}}
        PopperProps={{
          open: Boolean(anchorEl),
          anchorEl: anchorEl,
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
        }}
        title={
          <React.Fragment>
						<Typography variant='h6' sx={{paddingY: 1}}>{title}</Typography>
						{tooltip.skipped_arm_data.map((skippedArm: any, key: number) => (
								<Grid key={key} container direction="column" sx={{borderTop: 1, borderColor: 'lightgray', paddingY: 1}}>
										<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography sx={{ fontWeight: 'bold', marginRight: 1 }}>Order:</Typography>
												<Typography>{skippedArm.order}</Typography>
										</Grid>
										<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography sx={{ fontWeight: 'bold', marginX: 1 }}>Item:</Typography>
												<Typography>{skippedArm.item}</Typography>
										</Grid>
								</Grid>
								
						))}
					</React.Fragment>
        }
      >
        {children}
      </StyledTooltip>
    </div>
  );
};

export default ProgressTooltip;
