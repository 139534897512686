import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// MUI imports
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import BaseTooltip from '../globalComps/BaseTooltip'

interface FormInputRadioGroupProps {
  name: string
  label: string
  options: { label: string; value: string }[]
  helpText?: string
  disabled?: boolean
  row?: boolean
}

const FormInputRadioGroup: FC<FormInputRadioGroupProps> = ({ name, label, options, helpText, disabled, row = false }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name as never}
      control={control}
      render={({ field }) => (
        <Grid container spacing={2} alignItems='center' justifyContent='center'>
          <BaseTooltip text={helpText}>
            <FormControl component='fieldset' disabled={disabled}>
              <FormLabel component='legend'>{label}</FormLabel>
              <RadioGroup
                {...field}
                row={row}
                value={field.value} 
  							onChange={(e) => field.onChange(e.target.value)}
              >
                {options.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    disabled={disabled}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </BaseTooltip>
        </Grid>
      )}
    />
  )
}

export default FormInputRadioGroup
