// Maintenance.tsx

import React, { useState, useCallback } from 'react';
import PageTitle from '../../../../utils/globalComps/PageTitle';
import BaseContent from '../../../../utils/globalComps/BaseContent';
import useMaintenance from './hooks/useMaintenance';
import { Tabs, Tab, Box, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MaintenanceTable from './tables/MaintenanceTable';
import debounce from 'lodash/debounce';

interface MaintenanceProps { }

const Maintenance: React.FC<MaintenanceProps> = () => {
	document.title = 'Maintenance | RotoEdgePro';
	const { loading, maintenance, sendMessage, pageState } = useMaintenance(); // Ensure useMaintenance provides pageState
	const [activeTab, setActiveTab] = useState(0);
	const [searchQuery, setSearchQuery] = useState(pageState.filters.search || '');

	// Debounced function to update pageState with the search query
	const debouncedSendMessage = useCallback(
		debounce((query: string) => {
			pageState.filters.search = query
			sendMessage('update_page_state', { page_state: pageState });
		}, 500), // Adjust the debounce delay as needed
		[pageState, sendMessage]
	);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
		// Optionally, reset search when switching tabs
		setSearchQuery('');
		debouncedSendMessage('');
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const query = event.target.value;
		setSearchQuery(query);
		debouncedSendMessage(query);
	};

	const handleClearSearch = () => {
		setSearchQuery('');
		debouncedSendMessage('');
	};

	return (
		<>
			<PageTitle title='Maintenance' />
			<BaseContent loading={loading}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingX: 3, marginBottom: 2 }}>
					<Tabs value={activeTab} onChange={handleChange} aria-label="maintenance tabs">
						<Tab label="Molds" />
						<Tab label="Machines" />
					</Tabs>
					<TextField
						placeholder={`Search...`}
						variant="outlined"
						size="small"
						value={searchQuery}
						onChange={handleSearchChange}
						sx={{ borderRadius: '20px', '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{searchQuery ? (
										<IconButton sx={{ p: 0 }} onClick={handleClearSearch}>
											<ClearIcon />
										</IconButton>
									) : (
										<SearchIcon />
									)}
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<Box sx={{ overflow: 'auto' }}>
					<TabPanel value={activeTab} index={0}>
						<MaintenanceTable
							type='Mold'
							maintenance={maintenance.molds}
							sendMessage={sendMessage}
							maintenanceTypes={maintenance.maintenance_types}
							pageState={pageState}
						/>
					</TabPanel>
					<TabPanel value={activeTab} index={1}>
						<MaintenanceTable
							type='Machine'
							maintenance={maintenance.machines}
							sendMessage={sendMessage}
							maintenanceTypes={maintenance.maintenance_types}
							pageState={pageState}
						/>
					</TabPanel>
				</Box>
			</BaseContent>
		</>
	);
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ height: '100%', overflow: 'hidden' }}>
					{children}
				</Box>
			)}
		</div>
	);
}

export default Maintenance;
