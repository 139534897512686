import React, { FC, useState, useEffect } from 'react'
// mui icons
import AccessTime from '@mui/icons-material/AccessTime'
import PriorityHigh from '@mui/icons-material/PriorityHigh'
// mui imports
// custom imports
import BaseModal from '../../../../utils/modals/BaseModal'
import Grid from "@mui/material/Grid";
import FormInputText from "../../../../utils/form/FormInputText";
import FormInputDropdown from "../../../../utils/form/FormInputDropdown";
import {button, FormSpacing} from "../../../../utils/globals";
import {doConfirm} from "../../../../utils/modals/Confirm";
import Button from "@mui/material/Button";
import InfoText from "../../../../utils/globalComps/InfoText";

interface PostToLiveFormModalProps {
  id: number
  name: string
  laterTime: string
  onClose: () => void
  onSubmit: (now: boolean) => void
}
const PostToLiveFormModal: FC<PostToLiveFormModalProps> = ({id, name, laterTime, onClose, onSubmit}) => {

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setOpen(id > 0)
  }, [id])

  return (
    <BaseModal
      title={`Post to Live ${name}`}
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
    >
      <Grid container>

        <Grid item xs={12}>
          <InfoText text='Post now will overwrite the live table with all items above the highest breakpoint.' />
          <InfoText text={`Post later will will overwrite the live table with all items above the highest breakpoint today at ${laterTime}.`} />
        </Grid>

        <Grid item xs={6}>
          <Grid container justifyContent='center'>
            <Button variant='contained' startIcon={<PriorityHigh />} onClick={() => onSubmit(true)}>Now</Button>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent='center'>
            <Button variant='contained' startIcon={<AccessTime />} onClick={() => onSubmit(false)}>Later</Button>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default PostToLiveFormModal