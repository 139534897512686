import { apiGet } from '../../../../utils/api'

/**
 * Gets permissions view and general permissions data for permission group form
 * @param callback
 */
const getPermissionData = (callback: (data: any) => void) => {
  apiGet('/main/permissions/', {data: true}, (resp: any) => {
    callback(resp.data)
  })
}

export {
  getPermissionData
}