import React, { FC, useState, useEffect } from 'react'
import { arrayMoveImmutable } from 'array-move'
import {useTheme} from '@mui/material/styles'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
// mui icons
import Delete from '@mui/icons-material/Delete'
import ArrowCircleRightOutlined from '@mui/icons-material/ArrowCircleRightOutlined'
import Person from '@mui/icons-material/Person'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import PriorityHigh from '@mui/icons-material/PriorityHigh'
import Print from '@mui/icons-material/Print'
import Update from '@mui/icons-material/Update'
import Edit from '@mui/icons-material/Edit'
import ClearAll from '@mui/icons-material/ClearAll'
import Cancel from '@mui/icons-material/Cancel'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
// mui imports
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
// custom imports
import {getStagedItems} from '../../services/LinePlan/LinePlanApi'
import SearchBar from '../../../../utils/globalComps/SearchBar'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import DropdownActions, {DropdownAction} from '../../../../utils/globalComps/DropdownActions'
import {colors} from '../../../../utils/colors'
import BaseTooltip from '../../../../utils/globalComps/BaseTooltip'
import {Actions} from '../../../../utils/table/BaseRow'
import {patchPerGroupOverall} from '../../../../utils/helpers'
import Legend from '../../../../utils/globalComps/Legend'
import WorkerCountFormModal from './WorkerCountFormModal'
import PostToLiveFormModal from './PostToLiveFormModal'
import {doConfirm} from '../../../../utils/modals/Confirm'
import AssignToLineplanModal from '../../../../utils/modals/AssignToLineplanModal'
import {
  cancelPostToLiveLater,
  clearLiveLine,
  getLineplanSchedulingData,
  handleManualBreakpoint,
  moveStagedLineplanItem, postToLive,
  removeLineplanItem,
  reorderLineplanItems, sortRedToBottom, updateItemQuantity,
  updateToLive,
  updateWorkerCount
} from './LineplanSchedulingApi'
import LineplanItemQuantityModal from './LineplanItemQuantityModal'
import {ASSEMBLY_LP, FOAMING_LP, useEffectApi} from '../../../../utils/globals'
import TrueFalseIcon from '../../../../utils/globalComps/TrueFalseIcon'
import Button from '@mui/material/Button'
import AssemblyChildLookupModal from './AssemblyChildLookupModal'
// css import
import './LineplanSchedulingTable.css'
import InfoText from "../../../../utils/globalComps/InfoText";

// staging table sorting
const SortableSchedulingRow = SortableElement(({object, actions, autoBreakpoint, manualBreakpoint,
                                                 updateItemQuantityId, itemQuantityUpdateQuantity, setAssemblyLookupId,
                                                 canEdit}: any) => {
  return (
    <LineplanSchedulingRow object={object} actions={actions} autoBreakpoint={autoBreakpoint} manualBreakpoint={manualBreakpoint}
                           updateItemQuantityId={updateItemQuantityId} editable itemQuantityUpdateQuantity={itemQuantityUpdateQuantity}
                           setAssemblyLookupId={setAssemblyLookupId} canEdit={canEdit} />
  )
})
const SortableManualBreakpoint = SortableElement(({canEdit}: any) => {
  return <LineplanSchedulingBreakpoint manual={true} canEdit={canEdit} />
})
const SortableLineplanSchedulingBody = SortableContainer(({objects, actions, autoBreakpoint,
                                                            manualBreakpoint, refreshTable, permissions}: any) => {

  const [itemQuantityUpdateId, setItemQuantityUpdateId] = useState<number | undefined>(undefined)
  const [itemQuantityUpdateQuantity, setItemQuantityUpdateQuantity] = useState<number>(0)
  const [assemblyLookupId, setAssemblyLookupId] = useState<number | undefined>(undefined)  // TODO: add assembly child lookup modal
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    setData(objects)
  }, [autoBreakpoint, objects])

  const submitItemQuantityModal = (_data: any) => {
    console.log('submitItemQuantityModal data :', _data)
    if (itemQuantityUpdateId)
      updateItemQuantity(itemQuantityUpdateId, _data.quantity, () => refreshTable(true))

    closeItemQuantityModal()
  }
  const closeItemQuantityModal = () => {
    setItemQuantityUpdateId(undefined)
    setItemQuantityUpdateQuantity(0)
  }

  const closeAssemblyLookupModal = () => setAssemblyLookupId(undefined)

  return (
    <>
      <TableBody>
        <>
          {data.map((object: any, key: any) => (
            <React.Fragment key={key} >
              {object.manual_breakpoint ?
                <SortableManualBreakpoint {...{key: `breakpoint-${key}`, index: key, canEdit: permissions?.edit}} />
                :
                <SortableSchedulingRow {...{key: `item-${key}`, index: key, object: object, actions: actions, canEdit: permissions?.edit,
                  autoBreakpoint: object.staged_number === autoBreakpoint, manualBreakpoint: object.staged_number === manualBreakpoint,
                  updateItemQuantityId: setItemQuantityUpdateId, setAssemblyLookupId: setAssemblyLookupId,
                  itemQuantityUpdateQuantity: setItemQuantityUpdateQuantity}} />
              }
            </React.Fragment>
          ))}
        </>
      </TableBody>

      <LineplanItemQuantityModal id={itemQuantityUpdateId} quantity={itemQuantityUpdateQuantity} onClose={closeItemQuantityModal}
                                 onSubmit={submitItemQuantityModal} />
      <AssemblyChildLookupModal id={assemblyLookupId} onClose={closeAssemblyLookupModal} />
    </>
  )
})

interface LineplanSchedulingRowProps {
  object: any
  actions: any[]
  autoBreakpoint: boolean
  manualBreakpoint: boolean
  editable?: boolean
  showNumber?: boolean
  updateItemQuantityId?: ((id: number | undefined) => void) | undefined
  itemQuantityUpdateQuantity?: ((id: number | undefined) => void) | undefined
  setAssemblyLookupId?: ((id: number | undefined) => void) | undefined
  canEdit?: boolean | undefined
}
const LineplanSchedulingRow: FC<LineplanSchedulingRowProps> = ({object, actions, autoBreakpoint,
                                                                 manualBreakpoint, editable,
                                                                 updateItemQuantityId, itemQuantityUpdateQuantity,
                                                                 setAssemblyLookupId, showNumber, canEdit}) => {
  const theme = useTheme()

  const getRowColor = (color: string) => colors[theme.palette.mode].table.row[color]

  const OrderNumberCell = () => {
    if (object.type === ASSEMBLY_LP && object.can_child_lookup)
      return (
        <TableCell align='center'>
          <BaseTooltip text='Lookup children line plan information'>
            <Button sx={{maxHeight: '20px'}} onClick={() => {
              console.log(`handleAssemblyChildLookup(${object.id})`)
              handleAssemblyChildLookup(object.id)
            }}>
              {object.order_number}
            </Button>
          </BaseTooltip>
        </TableCell>
      )
    else
      return <TableCell align='center'>{object.order_number}</TableCell>
  }

  const handleUpdateQuantity = (object: any) => {
    if (updateItemQuantityId && itemQuantityUpdateQuantity) {
      updateItemQuantityId(object.id)
      itemQuantityUpdateQuantity(object.quantity)
    }
  }

  const handleAssemblyChildLookup = (id: number) => {
    console.log(setAssemblyLookupId, `setAssemblyLookupId(${id})`)

    return setAssemblyLookupId ? setAssemblyLookupId(id) : null
  }

  const getClassname = () => {
    let className: string = theme.palette.mode === 'dark' ? 'dark' : 'light'

    if (object.child)
      className += ' child'

    return className
  }

  // if auto and manual overlap, only show manual
  return (
    <>
      <TableRow sx={{backgroundColor: getRowColor(object.staging_color), userSelect: 'none'}} className={getClassname()}>
        {showNumber ?
          <TableCell align='center'>{object.staged_number}</TableCell>
          :
        <></>}
        <OrderNumberCell />
        <TableCell align='center'>{object.item}</TableCell>
        <TableCell align='center'>{object.description}</TableCell>
        <TableCell align='center'>{object.balance}</TableCell>
        <TableCell align='center'>{object.available}</TableCell>
        <TableCell align='center' className='quantity' onClick={() => handleUpdateQuantity(object)} sx={{cursor: 'pointer'}}>
          {object.staged_quantity}
          {editable ?
            <BaseTooltip text='Edit quantity value' placement='right'>
              <IconButton size='small'><Edit sx={{fontSize: '15px', ml: '5px'}} /></IconButton>
            </BaseTooltip>
            :
            <></>}
        </TableCell>
        <TableCell align='center'>{object.takt}</TableCell>
        <TableCell align='center'>{object.perGroup}</TableCell>
        <TableCell align='center'>{object.overall}</TableCell>
        {actions.length > 0 ?
          <Actions
            id={object.id}
            actions={actions}
          />
          :
          <TableCell> </TableCell>}
      </TableRow>
      {autoBreakpoint && !manualBreakpoint ? <LineplanSchedulingBreakpoint manual={false} /> : <></>}
    </>
  )
}

interface LineplanSchedulingBreakpointProps {
  manual: boolean
  canEdit?: boolean | undefined
}
const LineplanSchedulingBreakpoint: FC<LineplanSchedulingBreakpointProps> = ({manual, canEdit}) => {

  const theme = useTheme()

  return (
    <TableRow sx={{backgroundColor: colors[theme.palette.mode].table.row.breakpoint}}>
      <TableCell colSpan={10}>
        <Typography sx={{fontWeight: 'bold', textAlign: 'center'}} variant='subtitle2'>
          {manual ? 'MANUAL' : 'AUTO'} BREAKPOINT
        </Typography>
      </TableCell>
    </TableRow>
  )
}

interface LineplanSchedulingTableProps {
  id: number
	type: number
  doLoad?: boolean | undefined
  postToLiveCallback: (id: number) => void
}
/**
 * @param id lineplan id
 * @param doLoad
 * @param postToLiveCallback
 */
const LineplanSchedulingTable: FC<LineplanSchedulingTableProps> = ({id, type, doLoad, postToLiveCallback}) => {

  const theme: any = useTheme()

  const [loading, setLoading] = useState<boolean>(true)
  const [lineplan, setLineplan] = useState<any>(undefined)
  const [suppressLoadDisplay, setSuppressLoadDisplay] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const [q, setQ] = useState<string>('')
  const [permissions, setPermissions] = useState<any>(undefined)
  const [redHidden, setRedHidden] = useState<boolean>(type == 1 ? true : false)

  // form state
  const [workerCountFormId, setWorkerCountFormId] = useState<number>(0)
  const [postToLiveFormId, setPostToLiveFormId] = useState<number>(0)
  const [moveLineplanOrderItem, setMoveLineplanOrderItem] = useState<any>(undefined)
  const [moveLineplanOrderType, setMoveLineplanOrderType] = useState<number>(-1)

  const refreshTable = (suppress: boolean) => {
    setSuppressLoadDisplay(suppress)
    setRefresh(!refresh)
  }
  const getLineplanData = () => {
    getLineplanSchedulingData(id, (data: any) => {
      setLineplan(data)
    })
  }
  const getStagingTableData = (overrideQ?: string) => {
    getStagedItems(id, overrideQ ? overrideQ : q, (data: any) => {
      setData(patchPerGroupOverall(data.objects))
      setPermissions(data.permissions)
      setLoading(false)
    })
  }

  useEffectApi(() => {
    if (doLoad) {
      setLoading(true)

      getLineplanData()
      getStagingTableData()
    }
  }, [refresh, doLoad])

  // helpers
  const doSearch = (newQ: string) => {
    setQ(newQ)
    setLoading(true)
    getStagingTableData(newQ)
  }
  const generateActions = () => {
    const actions: any[] = []

    if (permissions?.edit) {
      actions.push({text: 'Set Worker Count', action: openWorkerCountForm, icon: <Person />,
        tooltip: 'Adjusts the automatic breakpoint'})
      actions.push({text: 'Update To Live', action: doUpdateToLive, icon: <Update />,
        tooltip: 'Updates live values with values from the staging table'})
      actions.push({text: 'Post To Live', action: openPostToLiveForm, icon: <PriorityHigh />,
        tooltip: 'Overwrites the live table with the staging table'})

      if (lineplan && lineplan.scheduled)
        actions.push({text: 'Cancel Post To Live Later', action: doCancelPostToLiveLater, icon: <Cancel />,
          tooltip: 'Cancels the post to live later'})

      if (lineplan && lineplan.manual_break_point_place)
        actions.push({text: 'Disable Manual Breakpoint', action: disableBreakpoint, icon: <Close />,
          tooltip: 'Disables the movable breakpoint'})
      else
        actions.push({text: 'Enable Manual Breakpoint', action: enableBreakpoint, icon: <Check />,
          tooltip: 'Enables the movable breakpoint'})

      if (lineplan && [FOAMING_LP, ASSEMBLY_LP].includes(lineplan.type))
        actions.push({text: 'Sort Red Items to Bottom', action: doSortRedToBottom, icon: <ArrowDownward />,
          tooltip: 'Sorts all red items to the bottom of the table.'})
    }

    if (permissions?.delete)
      actions.push({text: 'Clear Live Line', action: doClearLiveLine, icon: <ClearAll />,
        tooltip: 'Clears the live table of all items'})

    if (permissions?.view)
      actions.push({text: 'Print Staging Table', action: printPlanningTable, icon: <Print />,
        tooltip: 'Prints the planning table'})

    return actions
  }
  const getRowColor = (color: string) => colors[theme.palette.mode].table.row[color]
  const generateLegendItems = () => {
    if (lineplan && lineplan.type === 1) {
      return [
        {color: getRowColor('loaded'), text: 'Loaded',
          tooltipText: 'Loaded to a Machine and available > 0'},
        {color: getRowColor('in_loadqueue'), text: 'Scheduled',
          tooltipText: 'Scheduled to a machine arm and available > 0'},
        {color: getRowColor('scheduled'), text: 'Available',
          tooltipText: 'Not loaded or scheduled but available > 0'},
        {color: getRowColor('no_setupsheet'), text: 'Not Loaded & Not Scheduled & Not Available',
          tooltipText: 'Not loaded or scheduled and available = 0'},
      ]
    } else if (lineplan && lineplan.type === 2) {
      return [
        {color: getRowColor('loaded'), text: 'On Secondary & Available'},
        {color: getRowColor('in_loadqueue'), text: 'On Secondary & Not Available'},
        {color: getRowColor('scheduled'), text: 'Not on Secondary & Available'},
        {color: getRowColor('no_setupsheet'), text: 'Not on Secondary & Not Available'},
      ]
    } else if (lineplan && lineplan.type === 3) {
      return [
        {color: getRowColor('loaded'), text: 'Child on  Line & Available'},
        {color: getRowColor('in_loadqueue'), text: 'Child on Line & Available = 0'},
        {color: getRowColor('scheduled'), text: 'Child not on Line & Available > 0'},
        {color: getRowColor('no_setupsheet'), text: 'Child not on Line & Available = 0'},
      ]
    } else
      return []
  }
  const getLineplanItemName = (id: number) => {
    for (const object of data)
      if (object.id === id)
        return `${object.order_number} ${object.item}`
  }
  const getOrderTypeFromLineplanId = (id: number) => {
    for (const object of data)
      if (object.id === id)
        return object.type
  }
  const toggleRedHidden = () => {
    setRedHidden(!redHidden)
  }
  const getData = () => {
    const red: string = 'no_setupsheet'
    return redHidden ? data.filter((obj: any) => obj.staging_color !== red) : data
  }
  const redToShow = () => {
    const red: string = 'no_setupsheet'
    return data.filter((obj: any) => obj.staging_color === red).length > 0
  }

  // lineplan schedule actions
  const openWorkerCountForm = () => setWorkerCountFormId(id)
  const closeWorkerCountForm = () => setWorkerCountFormId(0)
  const submitWorkerCountForm = (data: any) => {
    updateWorkerCount(id, data.worker_count, () => {
      refreshTable(true)
      closeWorkerCountForm()
    })
  }
  const doUpdateToLive = () => {
    // TODO: add number of items being updated
    doConfirm('Update live line with values from planning line?', () => updateToLive(id))
  }
  const openPostToLiveForm = () => setPostToLiveFormId(id)
  const closePostToLiveForm = () => setPostToLiveFormId(0)
  const submitPostToLiveForm = (now: boolean) => {
    const msg: string = now ? `Post ${lineplan.name} to live now? This action is irreversible.` :
      `Post ${lineplan.name} to live at ${lineplan.post_to_live_time}?`

    doConfirm(msg, () => {

      postToLive(id, {now: now}, () => {
        getLineplanData()
      })

      postToLiveCallback(id)
      closePostToLiveForm()
    })
  }
  const doClearLiveLine = () => {
    doConfirm(`Clear ${lineplan.name} live line?`, () => {
      clearLiveLine(id, () => {
        getLineplanData()
      })
    })
  }
  const enableBreakpoint = () => {
    doConfirm(`Enable staging manual break point for ${lineplan.name}?`, () => {
      handleManualBreakpoint(id, {enable: true}, () => {
        // add breakpoint to table data
        getStagingTableData()
        getLineplanData()
      })
    })
  }
  const disableBreakpoint = () => {
    doConfirm('Disable staging manual break point?', () => {
      handleManualBreakpoint(id, {disable: true}, () => {
        // remove breakpoint from table data
        getStagingTableData()
        getLineplanData()
      })
    })
  }
  const updateBreakpoint = ({oldIndex, newIndex}: any) => {
    const _data: any[] = arrayMoveImmutable(data, oldIndex, newIndex)  // re-order data
    const sortedIds: number[] = _data.map((object: any) => object.id)  // sort ids for api post
    setData(patchPerGroupOverall(_data.map((object: any, i: number) => ({...object, number: i+1}))))  // update data order

    reorderLineplanItems(id, sortedIds, (data: any) => {
      getLineplanData()
    })
  }
  const printPlanningTable = () => {
    doConfirm(`Print ${lineplan.name} staging line items above the breakpoint?`, () => {
      console.log('printPlanningTable :', lineplan.name, lineplan.id)
    })
  }
  const doCancelPostToLiveLater = () => {
    doConfirm(`Cancel post to live later for ${lineplan.name}?`, () => {
      cancelPostToLiveLater(lineplan.id, () => {
        getLineplanData()
      })
    })
  }
  const doSortRedToBottom = () => sortRedToBottom(lineplan.id, () => refreshTable(true))

  // row actions
  const openMoveStagedLineplanItemForm = (id: number) => {
    setMoveLineplanOrderItem({id: id, name: getLineplanItemName(id)})
    setMoveLineplanOrderType(getOrderTypeFromLineplanId(id))
  }
  const closeMoveStagedLineplanItemForm = () => {
    setMoveLineplanOrderItem(undefined)
    setMoveLineplanOrderType(-1)
  }
  const submitMoveStagedLineplanItemForm = (_data: any) => {
    moveStagedLineplanItem(_data, (success: boolean) => {
      if (success) {
        setData([...data].filter((object: any) => object.id !== _data.lineplan_item))  // update table data
        closeMoveStagedLineplanItemForm()
      }
    })
  }
  const deleteStagedLineplanItem = (id: number) => {
    // doConfirm
    doConfirm((`Remove staged line plan item ${getLineplanItemName(id)}?`), () => {
      removeLineplanItem(id, () => {
        setData([...data].filter((object: any) => object.id !== id))
      })
    })
  }
  const generateRowActions = () => {
    const actions: any[] = []

    if (permissions?.edit)
      actions.push({text: 'Move Item', icon: <ArrowCircleRightOutlined/>, action: openMoveStagedLineplanItemForm})

    if (permissions?.delete)
      actions.push({text: 'Remove Item', icon: <Delete/>, action: deleteStagedLineplanItem})

    return actions
  }

  return (
    <BaseContent loading={loading && !suppressLoadDisplay}>
      <Grid container justifyContent='right'>
        <Grid item><Legend legendItems={generateLegendItems()} /></Grid>
      </Grid>
      <Grid container sx={{mt: '10px'}}>
        <Grid item xs={6}>
          <DropdownActions name='Actions' id={id} noMin>
            {generateActions().map((action: any, key: number) => (
              <DropdownAction key={key} {...action} />
            ))}
          </DropdownActions>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent='right'>
            <SearchBar
              outlined={true}
              width={40}
              onSearch={doSearch}
              q={q}
            />
          </Grid>
        </Grid>
      </Grid>

      {lineplan ?
        <Grid container>
          <Typography variant='body1' sx={{ display: 'flex', gap: 1, marginY: 'auto'}}>
            <strong>Worker Count:</strong> {lineplan.worker_count} |
            <strong> Staged items:</strong> {lineplan.staged_items} |
            <strong> Live items:</strong> {lineplan.live_items} |
            <strong> Scheduled to Post to Live:</strong> <TrueFalseIcon isTrue={lineplan.scheduled} sx={{fontSize: 20}} />
          </Typography>
        </Grid>
        :
        <></>}

      <InfoText text='This table is sortable, drag and drop rows to re-order the data.' />
      <TableContainer sx={{mt: '10px'}} component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow sx={{backgroundColor: colors[theme.palette.mode].table.header}}>
              <TableCell align='center'>Order</TableCell>
              <TableCell align='center'>Item</TableCell>
              <TableCell align='center'>Description</TableCell>
              <TableCell align='center'>Balance</TableCell>
              <TableCell align='center'>Available</TableCell>
              <TableCell align='center'>Quantity</TableCell>
              <TableCell align='center'>Takt Time</TableCell>
              <TableCell align='center'>Per Group</TableCell>
              <TableCell align='center'>Overall</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>

          {lineplan ?
            <SortableLineplanSchedulingBody
              onSortEnd={updateBreakpoint}
              distance={1}
              useWindowAsScrollContainer
              {...{objects: getData(),
								autoBreakpoint: lineplan.auto_break_point_place,
              manualBreakpoint: lineplan.manual_break_point_place, 
							refreshTable: refreshTable,
              actions: generateRowActions(), 
							permissions: permissions}}
            />
            : <></>}

        </Table>
      </TableContainer>
      {redToShow() ?
        <Grid container justifyContent='center' sx={{mt: '10px'}}>
          <LoadingButton onClick={() => toggleRedHidden()} loading={loading} variant='outlined'>
            {redHidden ? 'Show Red' : 'Hide Red'}
          </LoadingButton>
        </Grid>
        :
        <></>
      }

      {lineplan ?
        <>
          <WorkerCountFormModal id={workerCountFormId} name={lineplan.name} currentWorkerCount={lineplan.worker_count}
                                onClose={closeWorkerCountForm} onSubmit={submitWorkerCountForm} />
          <PostToLiveFormModal id={postToLiveFormId} name={lineplan.name} laterTime={lineplan.post_to_live_time}
                               onClose={closePostToLiveForm} onSubmit={submitPostToLiveForm} />
        </>
        : <></>}
      <AssignToLineplanModal orderItem={moveLineplanOrderItem} type={moveLineplanOrderType} onClose={closeMoveStagedLineplanItemForm}
                             doAssign={submitMoveStagedLineplanItemForm} move />
    </BaseContent>
  )
}

export default LineplanSchedulingTable
export {
  LineplanSchedulingRow
}