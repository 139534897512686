import { apiGet } from '../../../../utils/api'

/**
 * Fetches cycle info for the setupsheet form
 * @param cycleName
 * @param callback
 */
const fetchCycleInfo = (setupsheetId: number | undefined, cycleName: string, callback: (data: any) => void) => {
  apiGet('/manufacturing/setupsheets', {cycleName: cycleName, ssId: setupsheetId},
    (resp: any) => callback(resp.data))
}

const getSetupsheetViewData = () => {

}

export { fetchCycleInfo }