import React, { FC, useState, useEffect } from 'react';
// mui imports
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// custom imports
import PageTitle from "../../../../utils/globalComps/PageTitle";
import BaseContent from "../../../../utils/globalComps/BaseContent";
import { getMachinePlanningData } from "./MachinePlanningApi";
import BaseAccordion from "../../../../utils/globalComps/BaseAccordion";

interface MachinePlanningProps {

}

const MachinePlanning: FC<MachinePlanningProps> = ({}) => {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);

  const doRefresh = () => setRefresh(!refresh);


  useEffect(() => {
    getMachinePlanningData((data: any) => {
      setData(data);

      setLoading(false);
    })
  }, [refresh])

  // helpers
  const getNote = (week: number, day: number, armNotes: any[]) => {
    if (armNotes.length > 0)
      for (const note of armNotes)
        if (note.week === week && note.day === day)
          return note;
  }
  const getNoteInfo = (week: number, day: number, armNotes: any[]) => {
    const note = getNote(week, day, armNotes);

    if (note)
      return `${note.user ? note.username : 'None'} - ${note.day_name} - ${note.week_name}`;
  }
  const getNoteItems = (week: number, day: number, armNotes: any[], loaded: boolean) => {
    const note = getNote(week, day, armNotes);

    if (note && (note.selected_load.length > 0 || note.selected_unload.length > 0))
      if (loaded)
        return note.selected_load.map((load: any) => load.title).join(', ')
      else
        return note.selected_unload.map((load: any) => load.title).join(', ')
    else
      return 'None'
  }
  const hasNote = (week: number, day: number, armNotes: any[]) => {
    const note = getNote(week, day, armNotes);

    return note !== undefined && (note.selected_load.length > 0 || note.selected_unload.length > 0);
  }

  // helpers
  return (
    <>
      <PageTitle title='Machine Planning' />
      <BaseContent loading={loading}>
        <Grid container>

          {data.map((machine: any, key: number) => (
            <BaseAccordion title={machine.name} key={key}>
              <TableContainer key={key} sx={key === 0 ? {} : {mt: 10}} component={Paper}>
                {machine.arms.map((arm: any, key: number) => (
                  <Table key={key}>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={5} align='center'>
                          {arm.name}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[1, 2].map((week: number, key: number) => (  // two weeks, we can loop 1 -> 2
                        <TableRow key={key}>
                          {[1, 2, 3, 4, 5].map((day: number, key: number) => (
                            <TableCell key={key}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant='body1' sx={{fontWeight: 'bold'}}>Items to Load:</Typography>
                                  <Typography variant='subtitle2'>
                                    {getNoteItems(week, day, arm.notes, true)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant='body1' sx={{fontWeight: 'bold'}}>Items to Unload:</Typography>
                                  <Typography variant='subtitle2'>
                                    {getNoteItems(week, day, arm.notes, false)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant='body1' sx={{fontWeight: 'bold'}}>
                                    {getNoteInfo(week, day, arm.notes)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ))}
              </TableContainer>
            </BaseAccordion>
          ))}

        </Grid>
      </BaseContent>
    </>
  )
}

export default MachinePlanning;