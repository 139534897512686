import React, { FC } from 'react';
// mui imports
import Grid from '@mui/material/Grid';
// custom imports
import ApiForm from '../../form/ApiForm';
import FormInputText from '../../form/FormInputText';

interface ChannelMessageFormProps {
  channelId: number | undefined;
  id: number | undefined; // this is the message id
  armId: number | undefined;
  linePlanId: number | undefined;
  open: boolean;
  onClose: () => void;
  reloadChannel: () => void;
}
const ChannelMessageForm: FC<ChannelMessageFormProps> = ({channelId, id, armId,
                                                           linePlanId, open, onClose, reloadChannel}) => {

  return (
    <ApiForm
      name={'Channel Message Form'}
      open={open}
      defaultValues={{message: '', channel: channelId}}
      closeForm={onClose}
      id={id}
      fetchUrl={`/communication/channel-messaging/${id}/?form=${true}&armId=${armId}&linePlanId=${linePlanId}`}
      submitUrl={'/communication/channel-messaging/'}
    >
      <Grid container sx={{mt: '15px', mr: '5px', mb: '5px'}}>
        <FormInputText name='message' label='Enter new Channel Message' multiline />
      </Grid>
    </ApiForm>
  );
}

export default ChannelMessageForm;
