import React, {FC, useState} from 'react'
// mui imports
// custom imports
import {useEffectApi} from "../../globals";
import {getOverallProgress} from "./ProductivityProgressApi";
import ProgressTickedBar from "./ProgressTickedBar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BaseContent from "../BaseContent";

interface OverallProgressBarProps {
  machineId?: number | undefined
  lineplanId?: number | undefined
  shiftId: number
  refresh: boolean | undefined
}
const OverallProgressBar: FC<OverallProgressBarProps> = ({machineId, lineplanId,
                                                           shiftId, refresh}) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [productivity, setProductivity] = useState<number>(0) // current productivity % at this moment
  const [progress, setProgress] = useState<number>(0)
  const [logged, setLogged] = useState<number>(0) // current logged arms at this moment
  const [adjustedTarget, setAdjustedTarget] = useState<number>(0) // target arms expected at this moment

  useEffectApi(() => {
    if (machineId !== undefined || lineplanId !== undefined) {
      getOverallProgress({machineId: machineId, lineplanId: lineplanId, shiftId: shiftId}, (data: any) => {
        console.log(' getOverallProgress data :', data)
        setProductivity(data.productivity)
        setProgress(data.progress)
        setLogged(data.logged_arms ? data.logged_arms : 0)
        setAdjustedTarget(data.adjusted_target ? data.adjusted_target : 0)
        setLoading(false)
      })
    }
  }, [refresh])

  const ticks: any[] = [
    {position: (100/125) * 50, text: '50%', length: '50%'.length},
    {position: (100/125) * 100, text: '100%', length: '100%'.length},
    {position: 100, text: '125%', length: '125%'.length},
  ]

  return (
    <BaseContent loading={loading} sx={{width: '100%'}}>
      <ProgressTickedBar progress={progress} ticks={ticks} flatEnd={true}>
        <Typography variant='subtitle1'>
          Productivity: <strong>{productivity}%</strong>
          {machineId !== undefined ? <>| Logged arms / Adjusted Target Arms: <strong>{logged} / {adjustedTarget}</strong></> : <></>}
        </Typography>
      </ProgressTickedBar>
    </BaseContent>
  )
}

export default OverallProgressBar
