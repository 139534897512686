import { useState, useEffect } from "react";

function usePageState<T>(key: string, defaultValue: T, storage: Storage = sessionStorage): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [state, setState] = useState<T>(() => {
        const storedValue = storage.getItem(key);
        return storedValue !== null ? JSON.parse(storedValue) as T : defaultValue;
    });

    useEffect(() => {
        storage.setItem(key, JSON.stringify(state));
    }, [key, state, storage]);

    return [state, setState];
}

export default usePageState;
