import React, {FC, useEffect, useState} from 'react'
import BaseModal from "../../../../utils/modals/BaseModal";
import ApiTable from "../../../../utils/table/ApiTable";
import VentMaterialForm from "./VentMaterialForm";
import { FormProvider, useForm } from 'react-hook-form';
import { Clear, Delete } from '@mui/icons-material';
import { api } from '../../../../utils/globals';
import { AxiosResponse } from 'axios';
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert';
import FormInputSwitch from '../../../../utils/form/FormInputSwitch';
import { Typography } from '@mui/material';

interface DeleteDefaultPdfModalProps {
  open: boolean
  onClose: () => void
	item: string, 
	url: string
}
/**
 * Vent material management modal
 * @constructor
 */
const DeleteLineplanPdfModal: FC<DeleteDefaultPdfModalProps> = ({open, onClose, item, url}) => {

  const [doLoad, setDoLoad] = useState<boolean>(false)

	const methods = useForm()

  useEffect(() => {
    setDoLoad(open)
  }, [open])

	const handleDelete = () => {
		api.post(url, {
			deletePdf: true, 
			type: 'lineplan_pdf',
			item_name: item
		})
			.then((resp: AxiosResponse) => {
				onClose()
				doAlert(resp.data.message, 'success', true)
			})
	}

  return (
    <BaseModal
      title={`Delete ${item} Lineplan PDF?`}
      open={open}
      closeHandler={onClose}
      maxWidth='xs'
      closeButton
      // dividers
			actions={[
				{text: 'Delete', color: 'error', outlined: false, icon: <Delete />, action: handleDelete}
			]}
    >
    </BaseModal>
  )
}

export default DeleteLineplanPdfModal
