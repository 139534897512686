import React, {FC, useEffect, useState} from 'react';
import {useForm, FormProvider} from "react-hook-form";
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import BaseModal from "./BaseModal";
import FormInputText from "../form/FormInputText";
import {getUpdateMoldQuantityData} from "../../components/pages/scheduling/MachineScheduling/MachineSchedulingApi";
import FormInputDropdown from "../form/FormInputDropdown";
import InfoText from "../globalComps/InfoText";

interface UpdateMoldLoadQuantityModalProps {
  orderItemId: number | undefined;
  onClose: () => void;
  doUpdate: (id: number, quantity: number) => void;
}
const UpdateMoldLoadQuantityModal: FC<UpdateMoldLoadQuantityModalProps> = ({orderItemId, onClose, doUpdate}) => {

  const [open, setOpen] = useState<boolean>(false);
  const [orderItemName, setOrderItemName] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const [maxQuantity, setMaxQuantity] = useState<number>(1);

  useEffect(() => {
    let isActive = true;
    setOpen(!!orderItemId);

    if (orderItemId) {
      getUpdateMoldQuantityData(orderItemId, (data: any) => {
        setOrderItemName(data.orderItemName)
        setQuantity(data.quantity)
        setMaxQuantity(data.maxQuantity)
        reset({quantity: data.quantity, order_item: data.orderItemName})
      })
    }

    return () => {isActive = false};
  }, [orderItemId])

  const methods = useForm({defaultValues: {quantity: 1, order_item: orderItemName}});
  const { reset } = methods;

  // helpers
  const getQuantityOptions = () => {
    const options: any[] = [];

    for (let i=1; i < maxQuantity+1; i++) {
      options.push({label: i, value: i})
    }

    return options
  }

  // handlers
  const updateHandler = () => {
    console.log('update :', orderItemId, quantity)
    if (orderItemId)
      doUpdate(orderItemId, quantity);
  }
  const handleOnChange = (e: any) => {
    setQuantity(e.target.value);
  }

  return (
    <BaseModal title='Update Mold Load Quantity' open={open} closeHandler={onClose} closeButton dividers actions={[
      {text: 'Update', action: updateHandler }
    ]}>

      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <InfoText mt={10} text='Choose how many molds are to be loaded onto the machine' />
          <Grid item xs={12}><FormInputText name='order_item' label='Order Item' disabled={true} /></Grid>
          <Grid item xs={12}><FormInputDropdown name='quantity' label='Quantity' options={getQuantityOptions()}
            myOnChange={handleOnChange} /></Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default UpdateMoldLoadQuantityModal;
