import React, { FC, useState } from 'react'
import { AxiosResponse } from 'axios'
// mui imports
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
// mui icon imports
import Print from '@mui/icons-material/Print'
// custom imports
import { useEffectApi } from '../../../../utils/globals'
import { apiGet } from '../../../../utils/api'
import BomItemList from './BomItemList'
import InfoText from '../../../../utils/globalComps/InfoText'
import BaseModal from '../../../../utils/modals/BaseModal'
import BaseContent from '../../../../utils/globalComps/BaseContent'

interface FieldValueDisplayProps {
  field: string
  value?: string
  xs: number
  overrideValue?: any
  size?: string
  textColor?: string
}
const FieldValueDisplay: FC<FieldValueDisplayProps> = ({field, value, xs, overrideValue,
                                                         size, textColor}) => {

  const fontHeaderSize: string = size && size === 'lg' ? '20px' : '15px'
  const fontSize: string = size && size === 'lg' ? '23px' : '18px'

  return (
    <Grid item xs={xs} justifyContent='center' display='flex' sx={{mb: '20px'}}>
      <Grid container justifyContent='center' display='flex'>
        <Grid item xs={12} justifyContent='center' display='flex'>
          <Typography sx={{fontWeight: 'bold', fontSize: fontHeaderSize, color: textColor}}>{field.toUpperCase()}</Typography>
        </Grid>
        <Grid item xs={12} justifyContent='center' display='flex'>
          {overrideValue ? overrideValue : <Typography sx={{fontSize: fontSize, color: textColor}}>{value}</Typography>}
        </Grid>
      </Grid>
    </Grid>
  )
}

interface SectionTitleProps {
  text: string
}
const SectionTitle: FC<SectionTitleProps> = ({text}) => {
  const theme = useTheme()

  const bgColor = theme.palette.mode === 'dark' ? '#1e1e1e' : '#cccccc'

  return (
    <Box justifyContent='center' display='flex' sx={{backgroundColor: bgColor, borderTop: '2px solid red'}}>
      <Typography sx={{mt: '15px', mb: '15px'}} variant='h5'>{text.toUpperCase()}</Typography>
    </Box>
  )
}


interface SetupsheetViewProps {
  open: boolean
  close: () => void
  url: string
  id?: number | undefined
  refresh?: boolean | undefined
  bomItems: any[]
  dualTimes?: boolean
  timeDiffTemp?: number
  firstArticle?: boolean
  doPrint?: ((cb: () => void) => void) | undefined
  printLoading: boolean
  hasPdf?: boolean
  machineList?: any[]
  selectedMachine?: string | undefined
  doSelectMachine?: ((machineName: string) => void) | undefined
  newTab?: boolean | undefined
}
const SetupsheetView: FC<SetupsheetViewProps> = ({open, close, url, id, refresh,
                                                   bomItems, dualTimes, timeDiffTemp,
                                                   firstArticle, doPrint, printLoading,
                                                   hasPdf, machineList, selectedMachine,
                                                   doSelectMachine, newTab}) => {

  const theme = useTheme()
  const lightTheme = createTheme({palette: {mode: 'light', background: {default: '#f4f9ff'}, primary: {main: '#178de0'}}})

  const [loading, setLoading] = useState<boolean>(false)

  const [setupsheet, setSetupsheet] = useState<any>({})
  const [pdfInstructionsUrl, setPdfInstructionsUrl] = useState<string | undefined>(undefined)
  const [currentTheme, setCurrentTheme] = useState<any>({})

  useEffectApi(() => {

    if (id) {
      setCurrentTheme(theme)
      setLoading(true)

      apiGet(`${url}${id}/`, {newTab: newTab}, (resp: AxiosResponse) => {
        setSetupsheet(resp.data.object[0])
				
        apiGet(`${url}${id}/`, {getPdf: true, newTab: newTab, blob: true}, (resp: AxiosResponse) => {
          
					if (resp.data.size > 32) {
            setPdfInstructionsUrl(window.URL.createObjectURL(resp.data))
						console.log(pdfInstructionsUrl)
					} else {
            setPdfInstructionsUrl(undefined)
					}
          setLoading(false)
        })

      })
    }

  }, [id, url, open, theme, refresh])

  const ovenTimeDisplay = (textColor: string | undefined) => {
    if (dualTimes) {
      const coolColor: string = '#5897dc'
      const warmColor: string = '#f32334'

      const dualTimeDisplay =
        <Grid container>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12} justifyContent='center' display='flex' sx={{border: `2px solid ${warmColor}`, ml: '50px', mr: '20px'}}>
                <Typography sx={{mt: '2px', mb: '2px', color: textColor}}>{setupsheet.oven_warm_factory_time}</Typography>
              </Grid>
              <Grid item xs={12} justifyContent='center' display='flex' >
                <Typography sx={{color: warmColor}}>{`Plant >= ${timeDiffTemp}°F`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12} justifyContent='center' display='flex' sx={{border: `2px solid ${coolColor}`, ml: '20px', mr: '50px'}}>
                <Typography sx={{mt: '2px', mb: '2px', color: textColor}}>{setupsheet.oven_cold_factory_time}</Typography>
              </Grid>
              <Grid item xs={12} justifyContent='center' display='flex' >
                <Typography sx={{color: coolColor}}>{`Plant < ${timeDiffTemp}°F`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      return <FieldValueDisplay textColor={textColor} xs={3} field='Oven Time' overrideValue={dualTimeDisplay} />
    } else {
      return <FieldValueDisplay textColor={textColor} xs={3} field='Oven Time' value={setupsheet.oven_cold_factory_time} />
    }
  }

  const validate = (value: any) => {
    return value !== undefined && value !== null ? value : '-'
  }
  const doPrintWrapper = () => {
    setCurrentTheme(lightTheme)
    if (doPrint)
      doPrint(() => setCurrentTheme(theme))
  }

  const selectMachine = (machineName: string) => {
    if (doSelectMachine)
      doSelectMachine(machineName)
  }

  const textColor: string | undefined = firstArticle ? 'red' : undefined
  const actionInfoText: string | undefined = theme.palette.mode === 'dark' ? 'All printing is done in light mode' : undefined

  const Content = () => {
    return (
      <BaseContent loading={loading}>
        {machineList && machineList.length > 0
          ?
          <Grid container justifyContent='space-around'>
            {machineList?.sort((a: any, b: any) => a.number - b.number).map((machine: any, key: number) => (
              <Grid item sx={{}} key={key}>
                <Button
                  variant={selectedMachine && selectedMachine === machine.name ? 'outlined' : 'contained'}
                  onClick={() => selectMachine(machine.name)}
                >
                  {machine.name}
                </Button>
              </Grid>
            ))}
            <Grid item xs={12} sx={{mt: 2, mb: 2}}><Divider /></Grid>
          </Grid>
          :
          <></>}
        <div id='setupsheet-info'>
          {firstArticle ?
            <Grid container justifyContent='center' sx={{mb: '20px'}}>
              <Typography variant='h4' sx={{color: textColor}}>First Article Setupsheet</Typography>
            </Grid>
            : <></>}
          <Grid container>
            <FieldValueDisplay textColor={textColor} xs={3} field='Machine' value={setupsheet.machine} size={'lg'} />
            <FieldValueDisplay textColor={textColor} xs={3} field='Item' value={setupsheet.item} />
            <FieldValueDisplay textColor={textColor} xs={3} field='Mold' value={setupsheet.mold} />
            <FieldValueDisplay textColor={textColor} xs={3} field='Mold Location' value={validate(setupsheet.mold_location)} />
          </Grid>
          <Grid container>
            <FieldValueDisplay textColor={textColor} xs={4} field='Customer Name' value={setupsheet.customer_name} />
            <FieldValueDisplay textColor={textColor} xs={4} field='Item Description' value={setupsheet.item_description} />
            <FieldValueDisplay textColor={textColor} xs={4} field='Cooling Fixture' value={setupsheet.cooling_fixture ? 'Yes' : 'No'} />
          </Grid>
          <SectionTitle text='Bill Of Materials' />
          <BomItemList bomItems={bomItems} textColor={textColor} />
          <SectionTitle text='Machine Cycle Information' />
          {setupsheet.cycle ?
            <Grid container>
              <FieldValueDisplay xs={12} textColor={textColor} field='Cycle' value={setupsheet.cycle_number} />
            </Grid>
            :
            <></>}
          <Grid container sx={{mt: '20px'}}>
            <FieldValueDisplay xs={3} textColor={textColor} field='Oven Temperature' value={setupsheet.oven_temperature} />
            {ovenTimeDisplay(textColor)}
            <FieldValueDisplay xs={3} textColor={textColor} field='Vent Material' value={validate(setupsheet.vent_material)} />
            <FieldValueDisplay xs={3} textColor={textColor} field='Arm/Plate Speed'
                               value={`${validate(setupsheet.arm_speed)}/${validate(setupsheet.plate_speed)}`} />
          </Grid>
          <Grid container>
            <FieldValueDisplay textColor={textColor} xs={3} field='Oven Reverse Time' value={validate(setupsheet.oven_reverse_time)} />
            <FieldValueDisplay textColor={textColor} xs={3} field='Oven Air Time' value={validate(setupsheet.oven_air_time)} />
            <FieldValueDisplay textColor={textColor} xs={3} field='PSI' value={validate(setupsheet.psi)} />
            <FieldValueDisplay textColor={textColor} xs={3} field='Wall Thickness' value={validate(setupsheet.wall_thickness)} />
          </Grid>
          <Grid container>
            <FieldValueDisplay textColor={textColor} xs={3} field='1st Cooling Delay' value={validate(setupsheet.cooling_delay_1)} />
            <FieldValueDisplay textColor={textColor} xs={3} field='1st Cooling Station Time' value={validate(setupsheet.cooling_station_time_1)} />
            <FieldValueDisplay textColor={textColor} xs={3} field='2nd Cooling Delay' value={validate(setupsheet.cooling_delay_2)} />
            <FieldValueDisplay textColor={textColor} xs={3} field='2nd Cooling Station Time' value={validate(setupsheet.cooling_station_time_2)} />
          </Grid>
          <SectionTitle text='Additional Instructions' />
          <Grid container sx={{mt: '20px'}}>
            <FieldValueDisplay textColor={textColor} xs={12} field='Molding/Demolding Instructions' value={validate(setupsheet.molding_demolding_instructions)} />
            <FieldValueDisplay textColor={textColor} xs={12} field='Cooling Instructions' value={validate(setupsheet.cooling_instructions)} />
          </Grid>
          {setupsheet.updated ? <small className='MuiTypography-body1' style={{marginBottom: 5}}>Last updated at: <strong>{setupsheet.updated}</strong></small> : <></>}
        </div>
        {pdfInstructionsUrl ?
          <div className='setupsheet-pdf'>
            <SectionTitle text='PDF Instructions' />
            <iframe title='pdf viewer' src={pdfInstructionsUrl} frameBorder='0' style={{width: '100%', height: '80vh',
              marginTop: '20px'}} />
          </div> : <></>}
      </BaseContent>
    )
  }

  return (
    <>
      {id ?
        <ThemeProvider theme={currentTheme}>
          {newTab ?
            <>
              <Content />
            </> :
            <BaseModal
              title='Setupsheet View'
              open={open}
              closeHandler={close}
              closeButton
              dividers
              actions={doPrint ? [
                {text: 'Print', icon: <Print />, loading: printLoading, action: doPrintWrapper}
              ] : []}
              maxWidth='lg'
              actionInfoText={actionInfoText}
            >
              <Content />
            </BaseModal>
          }
        </ThemeProvider>
        :
        <></>
      }
    </>
  )
}

export default SetupsheetView
