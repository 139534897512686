import { useEffect, useMemo, useState } from "react";
import { Orders, PageState } from './Interfaces';
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";

const useOrders = () => {
	const [data, setData] = useState<Orders>({
		page_state: {
			filters: {
				type: 0,
				search: null,
				machines: [],
				loaded: [],
				lineplans: []
			},
			sort_by: {
				item_type: null
			}
		},
		permissions: {
			is_admin: false,
			is_superuser: false,
			view: false,
			create: false,
			edit: false,
			delete: false,
		},
		orders: [], 
		machines: [],
		arms: [],
		lineplans: [],
		items: [], 
		scrap_codes: [], 
		sync_enabled: false
	});

	const { loading, sendMessage, isVisible, setPageState, isReady } = useWebsocket('/orders/management/', setData);

	let pageState: PageState = data.page_state;
	const permissions = useMemo(() => data.permissions ? data.permissions : {
		is_admin: false,
		is_superuser: false,
		view: false,
		create: false,
		edit: false,
		delete: false,
	}, [data]);
	const orders = useMemo(() => data.orders ? data.orders : [], [data]);
	const machines = useMemo(() => data.machines ? data.machines : [], [data]);
	const arms = useMemo(() => data.arms ? data.arms : [], [data]);
	const lineplans = useMemo(() => data.lineplans ? data.lineplans : [], [data]);
	const items = useMemo(() => data.items ? data.items : [], [data]);
	const scrapCodes = useMemo(() => data.scrap_codes ? data.scrap_codes : [], [data]);
	const syncEnabled = useMemo(() => data.sync_enabled ? data.sync_enabled : false, [data]);

	// Log and potentially re-set state when visibility changes
	useEffect(() => {
		if (!isVisible) {
			setPageState(pageState);
		}
	}, [isVisible]);

	return {
		loading, sendMessage, isVisible, setPageState,
		pageState, permissions, orders, machines, arms, lineplans, items, scrapCodes, isReady, syncEnabled
	};
};

export default useOrders;
