import React, { FC } from 'react';
// mui icons
// react mui
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// custom
import Legend from '../globalComps/Legend';

interface PageTitleProps {
  title: string;
  legend?: any[];
}
/**
 * PageTitle component
 * @param title
 * @constructor
 */
const PageTitle: FC<PageTitleProps> = ({title, legend}) => {

  return (
    <Grid container>
      <Grid item xs={6}><Typography sx={{margin: '1rem 0 0 1rem'}} variant='h4'>{title}</Typography></Grid>
      <Grid item xs={6} sx={{marginTop: '20px'}}>
        <Grid container>
          {legend ? <Legend legendItems={legend} /> : <></>}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PageTitle;
