import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../../websocket/hooks/useWebsocket";

interface Sync {
	created: string
	orders_created: number
	id: number
}

export interface TokenField {
	label: string
	field: string
}

interface OrderSync {
	page_state: any
	syncs: Sync[]
	token_form: TokenField[]
}

const useOrderSync = () => {
	const [data, setData] = useState<OrderSync>({
		page_state: {},
		syncs: [],
		token_form: [], 
	});

	const { loading, sendMessage, isVisible, setPageState, isReady } = useWebsocket('/orders/sync/', setData);

	let pageState: any = data.page_state;
	const syncs = useMemo(() => data.syncs ? data.syncs : [], [data]);
	const tokenForm = useMemo(() => data.token_form ? data.token_form : [], [data]);

	// Log and potentially re-set state when visibility changes
	// useEffect(() => {
	// 	if (!isVisible) {
	// 		setPageState(pageState);
	// 		console.log('pageState', pageState)
	// 	}
	// }, [isVisible]);

	return {
		loading, sendMessage, isVisible, setPageState,
		pageState, isReady, 
		syncs, tokenForm
	};
};

export default useOrderSync;
