import React, { useRef } from 'react';
import { Box, Card, CardHeader, Typography, IconButton, Badge, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Machine } from './hooks/useMachineScheduling';

interface MachineListProps {
	machines: Machine[];
	selectedMachine: Machine | null;
	onSelectMachine: (machine: Machine) => void;
}

const MachineList: React.FC<MachineListProps> = ({
	machines,
	selectedMachine,
	onSelectMachine
}) => {

	const theme = useTheme()

	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const scroll = (scrollOffset: number) => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
		}
	};

	return (
		<Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
			<IconButton
				onClick={() => scroll(-800)}
				sx={{ position: 'absolute', left: 0, top: 25, zIndex: 3, bgcolor: 'background.paper', '&:hover': { bgcolor: 'action.hover' } }}
			>
				<ChevronLeftIcon />
			</IconButton>
			<Box
				sx={{
					position: 'relative',
					width: '100%',
					'&::before, &::after': {
						content: '""',
						position: 'absolute',
						top: 0,
						bottom: 0,
						width: '10px',
						zIndex: 2,
						pointerEvents: 'none',
					},
					'&::before': {
						left: 48,
						background: `linear-gradient(to right, ${theme.palette.background.paper}, ${theme.palette.background.paper}00)`,
					},
					'&::after': {
						right: 48,
						background: `linear-gradient(to left, ${theme.palette.background.paper}, ${theme.palette.background.paper}00)`,
					},
				}}
			>
				<Box
					ref={scrollContainerRef}
					sx={{
						display: 'flex',
						overflowX: 'auto',
						scrollBehavior: 'smooth',
						pb: 1,
						mx: 6,
						'&::-webkit-scrollbar': {
							height: '8px',
							px: 10
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'rgba(0,0,0,.2)',
							borderRadius: '4px',
						}
					}}
				>
					{machines.map((machine, index) => (
						<Box
							key={index}
							sx={{
								position: 'relative',
								m: 1,
								flexShrink: 0,
								minWidth: 150,  // Set a reasonable minimum width
								maxWidth: 300,  // Optionally, set a max width
							}}
						>
							<Card
								sx={{
									width: 'auto', // Allow the width to adjust dynamically
									cursor: 'pointer',
									borderRadius: 3,
									boxShadow: selectedMachine?.id === machine.id ? 5 : 3,
									transition: 'box-shadow 0.3s, border 0.3s',
									position: 'relative',
									zIndex: 1,
									backgroundColor: 'background.paper',
									border: selectedMachine?.id === machine.id ? '2px solid' : '2px solid transparent',
									borderColor: selectedMachine?.id === machine.id ? 'primary.main' : 'transparent',
								}}
								onClick={() => onSelectMachine(machine)}
							>
								<CardHeader
									title={
										<Typography noWrap variant="h6">{machine.name}</Typography>
									}
									action={
										<Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
											{machine.standalone_scheduled_orders.length > 0 && (
												<Badge
													badgeContent={machine.standalone_scheduled_orders.length}
													color="secondary"
													sx={{
														'& .MuiBadge-badge': {
															fontSize: '1rem',  // Increase font size
															width: '30px',      // Set width for larger badge
															height: '30px',     // Set height for larger badge
															borderRadius: '50%', // Keep it circular
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															border: '2px solid',
															borderColor: 'background.paper',
														},
														mr: 2, // Increase margin to add more space between the badge and text
													}}
												/>
											)}
										</Box>
									}
									sx={{
										p: 2,
										'& .MuiCardHeader-content': {
											flex: '1 1 auto',
										},
										'& .MuiCardHeader-action': {
											flex: '0 0 auto',
											alignSelf: 'center',
											marginTop: 0,
											marginBottom: 0,
										},
									}}
								/>
							</Card>
						</Box>
					))}
				</Box>
			</Box>
			<IconButton
				onClick={() => scroll(800)}
				sx={{ position: 'absolute', right: 0, top: 25, zIndex: 3, bgcolor: 'background.paper', '&:hover': { bgcolor: 'action.hover' } }}
			>
				<ChevronRightIcon />
			</IconButton>
		</Box>
	);
};

export default MachineList;
