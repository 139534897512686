import {apiGet, apiPost} from "../../api";
import { doAlert } from "../PopupAlert/PopupAlert";
import {api} from "../../globals";
import {AxiosError, AxiosResponse} from "axios";

const url: string = '/scheduling/arm-scheduling/';

const getSubScheduledToArm = (armId: number, callBack: (data: any) => void) => {
  apiGet(url, {armId: armId}, (resp: any) => {

    callBack(resp.data);

  }, (err: any) => {
    if (err.response) doAlert(err.response.data.message, 'error', true);
  })
}

const saveRotoSchedule = (armId: number, data: any) => {
  api.post(url, {...data, armId: armId, saveSchedule: true})
    .then()
    .catch((err: AxiosError) => {if (err.response) {doAlert(err.response.data.message, 'error', true)}});
}

const unscheduleFromArm = (armScheduledOrderId: number, callback: () => void) => {
  console.log('unscheduleSubScheduledFromArm :', armScheduledOrderId)

  api.post(url, {unscheduleFromArmId: armScheduledOrderId})
    .then((resp: any) => {
      doAlert(resp.data.message, 'success', true)
      if (callback) callback();
    })
    .catch((err: AxiosError) => {if (err.response) {doAlert(err.response.data.message, 'error', true)}});
}

/**
 * Schedules an order scheduled on an arm to another arm on the machine
 * @param armScheduledOrderId
 * @param armId
 * @param callback
 * @returns
 */
const scheduleToAdditionalArm = (armScheduledOrderId: number, armId: number, callback: () => void) => {
  api.post(url, {scheduleToAdditionalArmId: armId, armScheduldOrderId: armScheduledOrderId})
    .then((resp: any) => {
      console.log('scheduleToAdditionalArm resp :', resp)
      console.log('message :', resp.data.message, resp.data.message_type)
      doAlert(resp.data.message, resp.data.message_type, true)
      if (resp.data.message_type === 'success' && callback)
        callback()
    })
    .catch((err: AxiosError) => {if (err.response) {doAlert(err.response.data.message, 'error', true)}});
}
const getMaxMolds = (id: number, rotoScheduled: boolean, loadingMore: boolean, callback: (data: any) => void) => {
  apiGet(`${url}${id}/`, {getMaxMolds: true, rotoScheduler: rotoScheduled, loadingMore: loadingMore},
    (resp: any) => callback(resp.data))
}
const setMoldLoadCount = (data: any, callback: () => void) => {
  console.log('set mold count :', data)
  apiPost(`${url}${data.id}/`, {...data, updateMoldCount: true}, () => {
    callback()
  })
}

export {
  getSubScheduledToArm,
  saveRotoSchedule,
  unscheduleFromArm,
  scheduleToAdditionalArm,
  getMaxMolds,
  setMoldLoadCount
}
