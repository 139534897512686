// React imports
import React, { FC, useState } from 'react'
import { AxiosResponse } from 'axios'
// Custom imports
import { useEffectApi } from '../../../../utils/globals'
import { apiGet } from "../../../../utils/api";
import PageTitle from '../../../../utils/globalComps/PageTitle'
import FactoryForm from './FactoryForm'
import ApiTable from '../../../../utils/table/ApiTable'
import '../../../../utils/table/rowHighlight.css'

const url: string = '/main/factories/'


/**
 * Factories table component, includes shifts (sub tables) which are related to the Factory object
 * @constructor
 */
const Factories: FC = () => {
  const [stateList, setStateList] = useState<string[]>([])
  const [timezoneList, setTimezoneList] = useState<string[]>([])

  // Get factory data on page load
  useEffectApi(() => {

    document.title = 'Factories | RotoEdgePro'

    apiGet(url, {}, (resp: AxiosResponse) => {
      setStateList(resp.data.states)
      setTimezoneList(resp.data.timezones)
    })

  }, [setStateList, setTimezoneList])


  return (
    <>
      <PageTitle title='Factories'/>
      <ApiTable
        objectName='Factory'
        headers={[' ', 'Name', 'Code', 'Address', 'City', 'State', 'Zip', 'Phone', 'Timezone', '']}
        rowFields={['name', 'code', 'address', 'city', 'state', 'zip', 'phone', 'timezone']}
        dataField='objects'
        url={url}
        editable={true}
        deletable={true}
        creatable={true}
        expandable={true}
        expandableParams={{
          tableName: 'Shifts',
          objectName: 'Shift',
          dataField: 'objects',
          headers: ['Number', 'Start', 'End', ''],
          rowFields: ['number', 'start', 'end'],
          url: url,
        }}
        FormComponent={FactoryForm}
        formParams={{states: stateList, timezones: timezoneList}}
      />
    </>
  )
}

export default Factories
