import React, { FC, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
// icons
import Queue from '@mui/icons-material/Queue'
import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
// mui
import Grid from '@mui/material/Grid'
// custom
import BaseModal from './BaseModal'
import FormInputAutocomplete from '../form/FormInputAutocomplete'
import FormInputText from '../form/FormInputText'
import { getArmsFromLoadQueueId, getName } from '../../components/pages/scheduling/MachineScheduling/MachineSchedulingApi'
import {apiGet} from '../api'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import {useEffectApi} from '../globals'
import Button from '@mui/material/Button'
import FormInputNumber from '../form/FormInputNumber'
import BaseContent from '../globalComps/BaseContent'


interface AssignToArmProps {
  advancedScheduling?: boolean | undefined
  orderItemId?: number | undefined
  onAssign?: (orderItemId: number | undefined, assignData: any, machineId: number | undefined, callback: (data: any) => void) => void
  onClose?: () => void
  move?: boolean | undefined
  machineScheduled?: boolean | undefined
}
const AssignToArm: FC<AssignToArmProps> = ({advancedScheduling, orderItemId,
                                             onAssign,
                                             onClose, move,
                                             }) => {

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedObjectMachineId, setSelectedObjectMachineId] = useState<number | undefined>(undefined)
  const [selectedObjectName, setSelectedObjectName] = useState<string>('')
  const [selectedObjectId, setSelectedObjectId] = useState<number | undefined>(undefined)
  const [armList, setArmList] = useState<any[]>([])

  const [assignData, setAssignData] = useState<any[]>([
    {arm: '', count: 1}
  ])

  const [machineLoaded, setMachineLoaded] = useState<any[]>([])
  const [armScheduled, setArmScheduled] = useState<any[]>([])
  const [machineScheduled, setMachineScheduled] = useState<any[]>([])
  const [moldLoadStatus, setMoldLoadStatus] = useState<string | undefined>(undefined)

  const [moldInStock, setMoldInStock] = useState<number>(0)
  const [scheduledMoldCount, setScheduledMoldCount] = useState<number>(1)
  const [selectableArmList, setSelectableArmList] = useState<number[]>([1])
  const [maxMoldValues, setMaxMoldValues] = useState<number[] | undefined>(undefined)

  const typeName: string = advancedScheduling ? 'armScheduled' : 'loadQueue'
  const title: string = advancedScheduling ? 'Schedule to Arm' : 'Assign To Load Queue'

  useEffectApi(() => {
    setOpen(!!orderItemId)

    if (orderItemId) {
      const type = move ? typeName : 'scheduled'
      getName(orderItemId, type, (name: string, machineId: number | undefined) => {
        setSelectedObjectName(name)
        setSelectedObjectMachineId(machineId)
        setSelectableArmList([1])
        setScheduledMoldCount(1)

        getArmsFromLoadQueueId(orderItemId, type, (armList: any[]) => {
          setArmList(armList)
          setAssignData([{arm: armList[0].id, count: 1}])

          reset({arm_1: {id: armList[0].id, title: armList[0].name}, arm_mold_count_1: 1, order_item: name})
        })
      })

      apiGet(`/scheduling/machine-scheduling/${orderItemId}/`, {type: type, getScheduledInfo: true}, (resp: any) => {
        setMachineLoaded(resp.data.machineLoaded)
        setArmScheduled(resp.data.armScheduled)
        setMachineScheduled(resp.data.machineScheduled)
        setMoldLoadStatus(resp.data.moldLoadStatus)
        setMoldInStock(resp.data.moldInStock)

        if (resp.data.moldInStock === 1)
          setMaxMoldValues([1])

        setLoading(false)
      })
    }

  }, [orderItemId, open])

  const methods = useForm({defaultValues: {arm_1: {id: undefined, title: ''}, arm_mold_count_1: 1, order_item: selectedObjectName}})
  const { reset, setError, clearErrors, setValue } = methods

  const assignHandler = () => {
    const type = move ? typeName : 'scheduled'
    if (onAssign) {
      onAssign(orderItemId, {...assignData, type: type}, selectedObjectMachineId, (data: any) => {
        if (data.status === 'error')
          for (const error of data.errors)
            setError(error.name, {type: 'server', message: error.message})
        console.log('assign resp data :', data)
      })
    }
  }
  const armSelectOnChange = (data: any, armIndex: number) => {
    setSelectedObjectId(data.id)

    const newAssignData: any[] = [...assignData]
    newAssignData[armIndex - 1].arm = data.id
    setAssignData(newAssignData)

    type val = 'arm_1'
    clearErrors(`arm_${armIndex}` as val)
  }
  const moldCountOnChange = (e: any) => {
    const armIndex: number = parseInt(e.target.name.split('_')[3])
    const value: number = parseInt(e.target.value)
    const oldValue: number = assignData[armIndex - 1].count
    const decrement: boolean = value < oldValue

    const newAssignData: any[] = [...assignData]

    console.log('decrement')

    // if incrementing and scheduled molds < in stock or decrementing
    if (value > 0 && ((scheduledMoldCount < moldInStock && !decrement) || decrement)) {
      newAssignData[armIndex - 1].count = value
      setAssignData(newAssignData)

      type val = 'arm_mold_count_1'
      clearErrors(`arm_mold_count_${armIndex}` as val)

      let newScheduledMoldCount: number = scheduledMoldCount

      if (value > oldValue)
        newScheduledMoldCount = scheduledMoldCount + 1
      else if (value < oldValue)
        newScheduledMoldCount = scheduledMoldCount - 1

      setScheduledMoldCount(newScheduledMoldCount)
      handleLockMoldCounts(newScheduledMoldCount, newAssignData)
    }
  }

  /**
   * If scheduled count === mold in stock, dont allow mold count to be incremented
   */
  const handleLockMoldCounts = (newScheduledMoldCount: number, newAssignData: any) => {
    if (newScheduledMoldCount === moldInStock) {
      // do lock
      setMaxMoldValues(newAssignData.map((data: any) => data.count))
      console.log('do lock :', newAssignData.map((data: any) => data.count))
    }
    else if (newScheduledMoldCount < moldInStock) {
      // unlock
      setMaxMoldValues(undefined)
      console.log('unlock')
    }
  }

  // helpers
  const addArm = () => {
    if (scheduledMoldCount < moldInStock) {
      const armIndex = selectableArmList.length + 1
      setSelectableArmList([...selectableArmList].concat([armIndex]))
      setScheduledMoldCount(scheduledMoldCount + 1)

      const newArm: any = filterArmList()[0]

      const newAssignData: any[] = [...assignData]
      newAssignData.push({arm: newArm.id, count: 1})

      setAssignData(newAssignData)
      type val = 'arm_mold_count_1'
      setValue(`arm_mold_count_${selectableArmList.length + 1}` as val, 1)

      type val2 = 'arm_1'
      setValue(`arm_${selectableArmList.length + 1}` as val2, newArm)

      handleLockMoldCounts(scheduledMoldCount + 1, newAssignData)
    }
  }
  const removeArm = () => {
    const newSelectedableArmList: number[] = [...selectableArmList]
    newSelectedableArmList.pop()

    if (scheduledMoldCount > 0) {
      const newAssignData: any[] = [...assignData]
      const decrementAmount: number = newAssignData.pop().count
      setSelectableArmList(newSelectedableArmList)
      setScheduledMoldCount(scheduledMoldCount - decrementAmount)

      setAssignData(newAssignData)
      handleLockMoldCounts(scheduledMoldCount - decrementAmount, newAssignData)
    }
  }
  const filterArmList = () => {
    const selectedArmIds: number[] = assignData.map((data: any) => data.arm)

    // filter arm list to only include unselected arms
    return armList.filter((arm: any) => !selectedArmIds.includes(arm.id))
      .map((arm: any) => ({id: arm.id, title: arm.name}))
  }

  return (
    <BaseModal
      title={`${title} ${move ? '(Move)' : ''}`}
      open={open}
      closeHandler={onClose ? onClose : () => {}}
      closeButton
      dividers
      actions={[{text: move ? 'Move' : 'Scheduled', action: assignHandler, icon: <Queue />}]}
    >
      <BaseContent loading={loading}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}><FormInputText name='order_item' label='Order Item' disabled={true} /></Grid>
            {selectableArmList.map((armIndex: number, key: number) => (
              <React.Fragment key={key}>
                <Grid item xs={8}>
                  <FormInputAutocomplete name={`arm_${armIndex}`} label='Arm' myOnChange={(data: any) => armSelectOnChange(data, armIndex)}
                                         options={filterArmList()} />
                </Grid>
                <Grid item xs={4}>
                  <FormInputNumber name={`arm_mold_count_${armIndex}`} label='Mold Count' onChange={moldCountOnChange} min={1} max={maxMoldValues ? maxMoldValues[armIndex - 1] : undefined} />
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={6} sx={{textAlign: 'center'}}>
              <Button onClick={addArm} variant='contained' disabled={selectableArmList.length === armList.length || scheduledMoldCount === moldInStock}
                      startIcon={<Add />}>
                Arm
              </Button>
            </Grid>
            <Grid item xs={6} sx={{textAlign: 'center'}}>
              <Button onClick={removeArm} variant='contained' disabled={selectableArmList.length <= 1} startIcon={<Remove />}
                      sx={{backgroundColor: '#fc2626'}}>
                Arm
              </Button>
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography>{scheduledMoldCount}/{moldInStock} to schedule</Typography>
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
            {moldLoadStatus ?
              <>
                <Grid item><Typography variant='h6'>Mold Schedule Information</Typography></Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12}><Typography variant='subtitle1'>Mold: {moldLoadStatus} (In use/In stock)</Typography></Grid>
                </Grid>
              </>
              :
              <></>
            }
            {machineLoaded.length > 0 ?
              <Grid container item xs={12}>
                <Grid item xs={12}><Typography variant='subtitle1'>Loaded to:</Typography></Grid>
                {machineLoaded.map((obj: any, key: number) => (
                  <Grid item xs={12} key={key}>
                    <Typography variant='body1'>{obj.armName} x {obj.count}</Typography>
                  </Grid>
                ))}
              </Grid>
              :
              <></>
            }
            {armScheduled.length > 0 ?
              <Grid container item xs={12}>
                <Grid item xs={12}><Typography variant='subtitle1'>Scheduled to Arm(s):</Typography></Grid>
                {armScheduled.map((obj: any, key: number) => (
                  <Grid item xs={12} key={key}>
                    <Typography variant='body1'>{obj.armName} x {obj.moldLoadQuantity}</Typography>
                  </Grid>
                ))}
              </Grid>
              :
              <></>
            }
            {machineScheduled.length > 0 ?
              <Grid container item xs={12}>
                <Grid item xs={12}><Typography variant='subtitle1'>Scheduled to Machine(s):</Typography></Grid>
                {machineScheduled.map((obj: any, key: number) => (
                  <Grid item xs={12} key={key}>
                    <Typography variant='body1'>{obj.machineName} x {obj.count}</Typography>
                  </Grid>
                ))}
              </Grid>
              :
              <></>
            }
          </Grid>
        </FormProvider>
      </BaseContent>
    </BaseModal>
  )
}

export default AssignToArm
