/**
 * File: api.tsx
 * Desc: Holds api helper funcs for the app
 */
import { AxiosError, AxiosResponse } from 'axios'
import { api } from './globals'
import { doAlert } from './globalComps/PopupAlert/PopupAlert'

/**
 * get filename from file download
 * https://stackoverflow.com/questions/59274629/get-file-name-from-filestreamresult-when-using-ajax-axios
 * @param headers
 */
const getExportFilename = (headers: any) => {
  const contentDisposition = headers['content-disposition']
  return contentDisposition.split('filename=')[1]
}

const downloadExportFile = (data: any, filename: string) => {
  console.log('download export :', filename)
  const url = window.URL.createObjectURL(new Blob([data])) // create url object
  const link = document.createElement('a') // create html anchor

  filename = filename.replace(/['"]+/g, '')  // remove quotes from the filename
  console.log('download export :', filename)

  link.href = url // apply url to anchor
  link.setAttribute('download', filename) // or any other extension
  console.log('link :', link)
  link.click() // click to download file
  link.remove()
}

/**
 * Base get function, handles error message
 * @param url
 * @param params url params, add blob: true to these for responseType blob
 * @param successCallback
 * @param errorCallback
 * @param isActive
 */
const apiGet = (url: string, params: any, successCallback?: (resp: any) => void, errorCallback?: (error: any) => void, noMessage?: boolean, isActive?: boolean) => {

  let config: any = {}

  let i: number = 0
  for (const key in params) {
    const value: string = params[key]
    const delim: string = i === 0 ? '?' : '&'

    if (key === 'blob' && value)
      config['responseType'] = 'blob'
    else {
      url += `${delim}${key}=${value}`
      i++
    }
  }

  api.get(url, config)
    .then((resp: AxiosResponse) => {
      if (resp.data.message && resp.data.status && (noMessage === undefined || noMessage === false)) {
        doAlert(resp.data.message, resp.data.status, true)
      }

      if (successCallback && isActive === undefined)
        successCallback(resp)
      else if (successCallback && isActive)
        successCallback(resp)
    })
    .catch((error: AxiosError) => {

      if (error.response && (noMessage === undefined || noMessage === false))
        doAlert(error.response.data.message, 'error', true)

      if (errorCallback && isActive === undefined)
        errorCallback(error)
      else if (errorCallback && isActive)
        errorCallback(error)

    })
}

/**
 * Base post function, handles error message
 */
const apiPost = (url: string, params: any, successCallback?: (resp: any) => void, errorCallback?: (error: any) => void, isActive?: boolean) => {

  api.post(url, params)
    .then((resp: any) => {
      if (resp.data.message) {
        doAlert(resp.data.message, resp.data.status ? resp.data.status : 'success', true)
      }

      if (successCallback && isActive === undefined)
        successCallback(resp)
      else if (successCallback && isActive)
        successCallback(resp)
    })
    .catch((error: AxiosError) => {
      if (error.response && !errorCallback)
        doAlert(error.response.data.message, 'error', true)
      else if (errorCallback && error.response)
        errorCallback(error)
    })
}

export {
  getExportFilename,
  downloadExportFile,
  apiGet,
  apiPost
}
