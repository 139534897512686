import React, { FC, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
// custom imports
import { doAlert } from '../globalComps/PopupAlert/PopupAlert'
import { apiGet } from '../api'
import BaseForm from './BaseForm'
import {api, DateFormatBackend, DateTimeFormat, TimeFormat, useEffectApi} from '../globals'
import CopyFromModal from '../../components/pages/management/setupsheets/CopyFromModal'
import ContentCopy from '@mui/icons-material/ContentCopy'

interface ApiFormProps {
  name: string
  open: boolean
  defaultValues: any
  closeForm: (submit: boolean, data: any, create: boolean, created_id: number | undefined) => void
  id: number | undefined
  tableActions?: any[]
  fetchUrl: string
  submitUrl: string
  children: JSX.Element | JSX.Element[]
  timeFields?: string[]
  datetimeFields?: string[]
  assignFields?: string[]
  large?: boolean
  xl?: boolean
  copyFrom?: boolean
  dataOverride?: any[]
  popFields?: string[]
  editFlag?: boolean | undefined
  refresh?: boolean | undefined
  submitCallback?: ((data: any) => any | undefined) | undefined
}

/**
 * Api Form component
 * @param name
 * @param open
 * @param defaultValues
 * @param closeForm
 * @param id
 * @param tableActions
 * @param fetchUrl
 * @param submitUrl
 * @param timeFields?: Fields that need to be used with JS moment package
 * @param datetimeFields?: Fields that need to be used with JS moment package
 * @param assignFields?: Fields that need to assigned to the form once the item is fetched
 * @param children
 * @param popFields
 * @param large?: large form
 * @param xl?: extra large form
 * @param copyFrom
 * @param dataOverride
 * @param editFlag?: appends edit=true flag to the object get request if true
 * @param refresh
 * @param submitCallback?: method to check submit data in parent component
 */
const ApiForm: FC<ApiFormProps> = ({name, open, defaultValues, closeForm, id,
                                     tableActions, fetchUrl, submitUrl, timeFields,
                                     datetimeFields, assignFields, children,
                                     popFields, large, xl,
                                     copyFrom, dataOverride, editFlag,
                                     refresh, submitCallback}) => {

  const [loading, setLoading] = useState<boolean>(true)  // set loading false if id is undefined, otherwise loading true
  const [lastUpdated, setLastUpdated] = useState<string | undefined>(undefined)
  const [copyFromList, setCopyFromList] = useState<any[]>([])
  const [copyFromModalOpen, setCopyFromModalOpen] = useState<boolean>(false)
  const [forceReset, setForceReset] = useState<boolean>(false)

  const methods = useForm({defaultValues: defaultValues})
  const { handleSubmit, setError, setValue, reset } = methods

  // call api fetch method
  useEffectApi(() => {
    if (open) {
      if (copyFrom)
        apiGet(submitUrl, {copyFromList: true}, (resp: any) => {
          setCopyFromList(resp.data.copy_from_list)
        })

      if (id) {

        const getParams: any = editFlag ? {edit: true} : {}

        apiGet(fetchUrl, getParams, (data: any) => {
          if (timeFields !== undefined && timeFields.length > 0)
            for (const timeField of timeFields)
              data.data['object'][0][timeField] = moment(data.data['object'][0][timeField], TimeFormat).toDate()

          if (datetimeFields !== undefined && datetimeFields.length > 0)
            for (const datetimeField of datetimeFields)
              data.data['object'][0][datetimeField] = moment(data.data['object'][0][datetimeField], DateTimeFormat).toDate()

          if (assignFields !== undefined && assignFields.length > 0)
            for (const assignField of assignFields)
              setValue(assignField, data[assignField])

          // set null/undefined values to false
          Object.keys(data.data['object'][0]).forEach(v => {
            if (typeof data.data['object'][0][v] !== 'boolean' && typeof data.data['object'][0][v] !== 'number' && !data.data['object'][0][v])
              data.data['object'][0][v] = ''
          })

          console.log('reset :', data.data['object'][0])
          if (!lastUpdated)
            reset(data.data['object'][0])
          else if (forceReset)
            reset(data.data['object'][0])

          console.log('api form obj :', data['object'][0])

          setLastUpdated(data['object'][0].updated)
          setLoading(false)
        })
      } else {
        reset(defaultValues)
        setLoading(false)
      }

    }
    setLoading(false)
  }, [id, fetchUrl, reset, defaultValues, open, dataOverride, refresh])

  // copy from modal
  const copyFromModalClose = () => setCopyFromModalOpen(false)
  const handleCopy = (id: number | undefined) => {
    apiGet(`${submitUrl}${id}/`, {copyFrom: true}, (resp: any) => {
      console.log('handle copy resp :', resp)
      reset(resp.data.object[0])
      copyFromModalClose()
    })
  }

  // submit api form
  const onSubmit = (data: any) => {
    if (submitCallback) data = submitCallback(data)
    console.log('form submit data :', data)
    // if id we are editing
    let url: string
    if (id) {
      data['edit'] = true
      url = `${submitUrl}${id}/`
    } else {
      data['create'] = true
      url = submitUrl
    }

    if (timeFields !== undefined && timeFields.length > 0)
      for (const timeField of timeFields)
        data[timeField] = moment(data[timeField]).format(TimeFormat)

    // patch date objects back to string
    for (const key in data)
      if (data[key] instanceof Date)
        data[key] = moment(data[key]).format(DateFormatBackend)

    if (dataOverride)
      for (const overrideField of dataOverride)
        if (overrideField.value && overrideField.value !== '')
          data[overrideField.name] = overrideField.value

    if (popFields)
      for (const popField of popFields)
        delete data[popField]

    api.post(url, data)
      .then((resp: AxiosResponse) => {
        console.log('resp data errors :', resp.data.errors)
        if (resp.data.errors)
          for (const err of resp.data.errors)
            setError(err.name, {type: 'server', message: err.message})
        else {
          setForceReset(true)
          setLastUpdated(undefined)
          console.log('setLastUpdated :', undefined)
          closeForm(true, data, !id, resp.data.created_id)  // !id for create boolean
        }
      })
      .catch((err: AxiosError) => {
        console.log('err response :', err.response)
        if (err.response && err.response.data.errors)
          for (const error of err.response.data.errors) {
            console.log('err :', error)
            if (error.name === 'non_field_errors')
              doAlert(error.message, 'error', true)
            else
              setError(error.name, {type: 'server', message: error.message})
          }
        else if (err.response && err.response.status === 403) {
          doAlert(err.response.data.message, 'error', true)
          closeForm(false, {}, false, undefined)
        }
      })
  }

  // Table actions
  let actions: any[] = [
    {text: 'Reset', action: () => {reset(defaultValues)},
    outlined: true, left: true}
  ].concat(tableActions ? tableActions : [])
  if (copyFrom)
    actions.push({text: 'Copy From', action: () => setCopyFromModalOpen(true), icon: <ContentCopy />})
  actions.push({text: 'submit', action: handleSubmit(onSubmit), submit: true})


  const formCloseHandler = () => {
    closeForm(false, {}, false, undefined)
    setLastUpdated(undefined)
  }

  return (
    <>
      <BaseForm title={name} open={open} closeForm={formCloseHandler} actions={actions} loading={loading} large={large}
                xl={xl} doSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          {children}
          {lastUpdated ? <small style={{marginBottom: 5}}>Last updated at: <strong>{lastUpdated}</strong></small> : <></>}
        </FormProvider>
      </BaseForm>
      <CopyFromModal open={copyFromModalOpen} closeHandler={copyFromModalClose} doCopy={handleCopy}
                     copyFromList={copyFromList} />
    </>
  )
}

export default ApiForm
