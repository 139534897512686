import React, { FC, useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import BaseModal from "../../../../utils/modals/BaseModal";
import {FormSpacing, EnterKey, highlightTextInElement} from "../../../../utils/globals";
import FormInputNumber from "../../../../utils/form/FormInputNumber";
import FormInputText from "../../../../utils/form/FormInputText";
import InfoText from "../../../../utils/globalComps/InfoText";

interface LineplanItemQuantityModalProps {
  id?: number | undefined
  quantity: number
  onClose: () => void
  onSubmit: (data: any) => void
}
const LineplanItemQuantityModal: FC<LineplanItemQuantityModalProps> = ({id, quantity, onClose,
                                                                          onSubmit}) => {

  const [open, setOpen] = useState<boolean>(false)

  const methods = useForm();
  const { reset, handleSubmit } = methods

  useEffect(() => {

    if (id !== undefined) {
      setOpen(true)

      // focus the input element
      setTimeout(() => {
        const quantityInput: HTMLElement | null = document.getElementById('quantity-input')
        if (quantityInput) {
          quantityInput.focus()
        }
      }, 100)

      reset({quantity: quantity, id: id})
    } else
      setOpen(false)

  }, [id])

  return (
    <BaseModal
      title='Update Quantity'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Submit', action: handleSubmit(onSubmit), submit: true}
      ]}
      doSubmit={handleSubmit(onSubmit)} // allow pressing enter to submit the form
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          {/*<Grid item xs={12}><InfoText text='Press enter or click submit to update the quantity' /></Grid>*/}
          <FormInputText name='id' label='id' hidden={true} />
          <Grid item xs={12}>
            <FormInputNumber id='quantity-input' name='quantity' label='Quantity' min={1} selectOnFocus />
          </Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default LineplanItemQuantityModal