import React from 'react';
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';
import { Order } from './hooks/Interfaces'; // Adjust this import according to your project structure

interface ExportOrdersButtonProps {
	orders: Order[];
}

const ExportOrdersButton: React.FC<ExportOrdersButtonProps> = ({ orders }) => {
	const handleExport = () => {
		const workbook = XLSX.utils.book_new();

		// Map orders to the appropriate structure for export
		const worksheetData = orders.map(order => ({
			Number: order.number,
			Item: order.item_name,
			Description: order.item_description,
			Type: order.item_type.string,
			'Ship Date': order.ship_date,
			Quantity: order.quantity,
			Molded: formatPartsCell(order.molded.conforming, order.molded.non_conforming),
			Secondary: formatPartsCell(order.secondary.conforming, order.secondary.non_conforming),
			Foamed: formatPartsCell(order.foamed.conforming, order.foamed.non_conforming),
			Assembly: formatPartsCell(order.assembled.conforming, order.assembled.non_conforming),
			Shipped: formatPartsCell(order.shipped.conforming, order.shipped.non_conforming),
			'Scheduled To': formatScheduledTo(order),  // Only include non-empty scheduled arrays
		}));

		// Define the headers (the first row of the worksheet)
		const worksheet = XLSX.utils.json_to_sheet(worksheetData);
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');

		// Generate a timestamp for the filename
		const timestamp = new Date().toLocaleString().replace(/[\/,:\s]/g, '_');
		const filename = `Orders_Export_${timestamp}.xlsx`;

		// Write the Excel file
		XLSX.writeFile(workbook, filename);
	};

	// Function to format the "conforming/non-conforming" parts cell
	const formatPartsCell = (conforming: number | '-', nonConforming: number | '-') => {
		const formattedConforming = conforming !== '-' ? conforming.toString() : '-';
		const formattedNonConforming = nonConforming !== '-' ? `/${nonConforming}` : '';
		return `${formattedConforming}${formattedNonConforming}`;
	};

	// Function to format the "Scheduled To" column, omitting empty arrays
	const formatScheduledTo = (order: Order) => {
		const scheduledToParts = [];

		if (order.scheduled_to.machines.length > 0) {
			scheduledToParts.push(`Machines: ${order.scheduled_to.machines.join(', ')}`);
		}
		if (order.scheduled_to.arms.length > 0) {
			scheduledToParts.push(`Scheduled Arms: ${order.scheduled_to.arms.join(', ')}`);
		}
		if (order.scheduled_to.loaded.length > 0) {
			scheduledToParts.push(`Loaded Arms: ${order.scheduled_to.loaded.join(', ')}`);
		}
		if (order.scheduled_to.lineplans.length > 0) {
			scheduledToParts.push(`Lineplans: ${order.scheduled_to.lineplans.join(', ')}`);
		}

		return scheduledToParts.length > 0 ? scheduledToParts.join(', ') : '';  // Only include non-empty parts
	};

	return (
		<Button
			sx={{ borderRadius: 3, textWrap: 'nowrap', px: 2 }}
			variant="outlined"
			color="primary"
			onClick={handleExport}
		>
			Export Orders
		</Button>
	);
};

export default ExportOrdersButton;