import powderLabel from "./dymoLabels/powder";
import conformingLabel from "./dymoLabels/conforming";
import nonConformingLabel from "./dymoLabels/nonConforming";
import nonConformingReprintLabel from "./dymoLabels/nonConformingReprint";
import conformingReprintLabel from "./dymoLabels/conformingReprint";
import {doAlert} from "../globalComps/PopupAlert/PopupAlert";

const INFORMATION_LOAD_MS: number = 400

let framework: any | undefined = undefined
try {
  const dymoName: any = 'dymo'
  const labelName: any = 'label'
  const frameworkName: any = 'framework'
  framework = window[dymoName][labelName][frameworkName]
} catch (error) {}

const CONFORMING: number = 1
const CONFORMING_REPRINT: number = 2
const NON_CONFORMING: number = 3
const NON_CONFORMING_REPRINT: number = 4
const POWDER: number = 5

// check functions
/**
 * Returns diagnose data for the dymo software
 * Does a fake load animation to show that it is re-checking the software status
 * @param callback
 */
const checkDymoSoftware = (callback: () => void) => {

  setTimeout(() => callback(), INFORMATION_LOAD_MS)

  try {
    framework.init()
    const data: any = framework.checkEnvironment()

    if (framework) {
      return {
        version: framework['VERSION'],
        ...data,
      }
    }
  } catch (e) {}
}

/**
 * Returns diagnose data for the dymo printers
 * Does a fake load animation to show that it is re-checking the printer status
 * @param callback
 */
const getDymoPrinterData = (callback: () => void): any[] => {

  setTimeout(() => callback(), INFORMATION_LOAD_MS + 200)
  try {
    let printers: any[] = []

    if (framework)
      try {
        printers = framework.getPrinters()
      } catch (e) {}

    return printers
  } catch (e) { return [] }
}

// printing functions

/**
 * Returns the first valid dymo printer found if any
 */
const getPrinter = () => {
  for (const printer of framework.getPrinters()) {
    if (printer.isConnected)
      return printer
  }
}

/**
 * Gets the xml source for a given label type
 * @param type
 */
const getLabelSource = (type: number) => {
  let source: string = ''

  if (type === POWDER)
    source = powderLabel
  else if (type === CONFORMING)
    source = conformingLabel
  else if (type === CONFORMING_REPRINT)
    source = conformingReprintLabel
  else if (type === NON_CONFORMING)
    source = nonConformingLabel
  else if (type === NON_CONFORMING_REPRINT)
    source = nonConformingReprintLabel

  return source
}

/**
 * Creates a dymo label object and sets the label data
 * @param type
 * @param labelData
 */
const prepareLabel = (type: number, labelData: any) => {

  const source = getLabelSource(type)

  if (source !== '') {
    const label: any = framework.openLabelXml(source)  // open the dymo label xml source

    // powder label
    if (type === POWDER) {

      label.setObjectText('order', labelData.order)
      label.setObjectText('item', labelData.parentItem)
      label.setObjectText('powderItem', labelData.item)
      label.setObjectText('powderDescription', labelData.description)
      label.setObjectText('powderQuantity', labelData.quantity)
      label.setObjectText('machine', labelData.machine)

    // molded or assembly unit label
    } else {

      console.log('label data :', type, labelData)
      label.setObjectText('order', labelData.order)
      label.setObjectText('item', labelData.item)
      label.setObjectText('description', labelData.description)
      label.setObjectText('barcode', labelData.order)
      label.setObjectText('machine', labelData.machine)

      if ([NON_CONFORMING, NON_CONFORMING_REPRINT].includes(type) && labelData.ncCode)
        label.setObjectText('nonConformingCode', labelData.ncCode)

    }

    return label
  } else
    doAlert('Could not create dymo label: source not found.', 'error', true)
}

/**
 * Prints a dymo label
 * @param type
 * @param labelData
 * @param numLabels
 */
const printLabel = (type: number, labelData: any, numLabels?: number) => {
  // console.log('print label')
  if (framework) {
    // console.log('print label 2')
    const label: any = prepareLabel(type, labelData)
    // console.log('print label 3')
    const printer: any = getPrinter()

    // console.log('print label :', label, printer, type, labelData)

    // If number of labels is defined, print the specified number of labels, otherwise print one label
    if (printer) {
      if (numLabels)
        for (let i: number = 0; i < numLabels; i++)
          label.print(printer.name)
      else
        label.print(printer.name)
    } else
      doAlert('Printer not found, ensure a printer is connected and correct software is installed.', 'error', true)
  } else
    doAlert('Dymo framework not found, ensure a printer is connected and correct software is installed.', 'error', true)
}

export {
  checkDymoSoftware,
  getDymoPrinterData,
  CONFORMING,
  NON_CONFORMING,
  CONFORMING_REPRINT,
  NON_CONFORMING_REPRINT,
  POWDER,
  printLabel
}