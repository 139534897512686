import { apiGet, apiPost } from '../../../../utils/api'
import { api, DateTimeFormat } from '../../../../utils/globals'
import { AxiosError, AxiosResponse } from 'axios'
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import moment from 'moment'

/**
 * gets form data for a personnel login
 * @param id: personnel login id
 * @param callback
 */
const getFormData = (id: number, callback: (data: any) => void) => {
  apiGet(`/productivity/login/${id}/`, {}, (resp: any) => {
    callback(resp.data)
  })
}

/**
 * edit personnel login form
 * @param id personnel login id
 * @param data form data
 * @param callback
 * @param errorCallback
 */
const editLogin = (id: number, data: any, callback: (data: any) => void, errorCallback: (data: any) => void) => {

  data = {
    ...data,
    login_time: moment(data.login_time).format(DateTimeFormat.replace('dd', 'DD')),
    logout_time: moment(data.logout_time).format(DateTimeFormat.replace('dd', 'DD')),
  }
  console.log('edit login :', data)

  apiPost(`/productivity/login/${id}/`, {...data, edit: true}, (resp: any) => {
    callback(resp.data)
  }, (err: AxiosError) => errorCallback(err))
}

/**
 * do logout
 * @param id: personnel login id
 * @param callback
 */
const logoutPersonnel = (id: number, callback: (data: any) => void) => {
  apiPost(`/productivity/login/${id}/`, {doLogout: true}, (resp: any) => callback(resp.data))
}

/**
 * delete personnel login instance
 * @param id
 * @param callback
 */
const deleteLogin = (id: number, callback: (data: any) => void) => {

  api.delete(`productivity/login/${id}/`)
    .then((resp: AxiosResponse) => {
      callback(resp.data)
      doAlert(resp.data.message, 'success', true)
    })
    .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})

  apiPost(`/productivity/login/${id}/`, {doLogout: true}, (resp: any) => {
    console.log('logout personnel resp :', resp)
  })
}

export {
  getFormData, editLogin, logoutPersonnel, deleteLogin
}