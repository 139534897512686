import { apiGet } from '../../../../utils/api'

const url: string = '/orders/statistics/'

const getStatisticsData = (params: any, callback: (data: any) => void) => {
  params['data'] = true
  apiGet(url, params, (resp) => callback(resp.data))
}

const getMachineProductivityData = (params: any, callback: (data: any) => void) => {
  params['machineProductivity'] = true
  apiGet(url, params, (resp) => callback(resp.data))
}

const getOverallProductivityData = (params: any, callback: (data: any) => void) => {
  params['overallData'] = true
  apiGet(url, params, (resp) => callback(resp.data))
}

const getProgressBarData = (params: any, callback: (data: any) => void) => {
  params['progressBarData'] = true
  apiGet(url, params, (resp) => callback(resp.data))
}

export {
  getStatisticsData,
  getMachineProductivityData,
  getOverallProductivityData,
  getProgressBarData
}
