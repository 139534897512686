import React, { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
// mui icons
import { LibraryBooks, AccessTime, ScheduleSend } from '@mui/icons-material'
import Print from '@mui/icons-material/Print'
// custom imports
import { ASSEMBLY_LP, SHIPPING_LP } from '../../../utils/globals'
import { colors } from '../../../utils/colors'
import ApiTable from '../../../utils/table/ApiTable'



const LOG: number = 1

interface LineplanLogTableProps {
  id: number
  type: number
  shifts: any[]
  refresh: boolean
  suppressLoad: boolean
  logProduction: (id: number | undefined, logType: number) => void
  logProductionNC?: ((id: number | undefined) => void) | undefined
  viewProduction: (id: number | undefined) => void
  stageToLineplan?: ((id: number | undefined) => void) | undefined
  viewProcessInstructions: (id: number | undefined) => void
  canViewProduction?: boolean | undefined
  doLoad?: boolean | undefined
  pullBom?: (id: number | undefined) => void | undefined
}
/**
 * LineplanLogTable - displays all available orders to be processed for the given lineplan
 * @param id lineplan id
 * @param type lineplan type
 * @param shifts
 * @param refresh
 * @param suppressLoad
 * @param logProduction view log production modal
 * @param logProductionNC view log production modal
 * @param viewProduction view production logs modal
 * @param stageToLineplan view lineplan selection modal
 * @param viewProcessInstructions view instruction pdf modal
 * @param canViewProduction gives permissions to view production logs
 * @param doLoad: tunneled parameter to restrict loading while the dropdown is collapsed
 * @param pullBom: takes order id as an argument
 */
const LineplanLogTable: FC<LineplanLogTableProps> = ({id, type, shifts, refresh, suppressLoad,
                                                       logProduction, logProductionNC, viewProduction, stageToLineplan,
                                                       viewProcessInstructions, canViewProduction, doLoad,
                                                       pullBom}) => {

  const theme: any = useTheme()

  const [legend, setLegend] = useState<any[]>([])

  const getRowColor = (name: string) => colors[theme.palette.mode].table.row[name]

  useEffect(() => {

    if (type === ASSEMBLY_LP)
      setLegend([
        {text: 'Assembly', color: getRowColor('assembly')},
        {text: 'Kit', color: getRowColor('kit')}
      ])

  }, [id])

  const getHeaders = () => {
    const headers: string[] = ['Order', 'Item', 'Description', 'Balance', 'Available']
    for (const shift of shifts)
      headers.push(shift.min_name)
    headers.push('N/C')
    headers.push(' ')

    if (type === ASSEMBLY_LP)
      headers.unshift(' ')

    return headers
  }
  const getFields = () => {
    const fields: string[] = ['number', 'item', 'description', 'balance', 'available']
    for (const shift of shifts)
      fields.push(shift.name)
    fields.push('non_conforming_count')

    if (type === ASSEMBLY_LP && generateActions().length === 0)
      fields.push(' ')

    return fields
  }

  // row actions

  const generateActions = () => {
    const actions: any[] = [
      {icon: <AccessTime />, action: (id: number | undefined) => logProduction(id, LOG), text: 'Log Production',
        noCondition: true, permission: 'create'},
    ]

    if (pullBom)
      actions.push({icon: <Print />, action: (id: number | undefined) => pullBom(id), text: 'Pull Bom', permission: 'create'})

    if (canViewProduction)
      actions.push({icon: <LibraryBooks />, action: viewProduction, text: 'Production Logs',
        condition: 'has_production', conditionValue: true})

    if (stageToLineplan) {
      actions.push({icon: <ScheduleSend />, action: stageToLineplan, text: 'Schedule to Lineplan', condition: 'scheduled', conditionValue: false, permission: 'edit'})
      actions.push({icon: <ScheduleSend />, action: stageToLineplan, text: 'Re-Schedule to Lineplan', condition: 'scheduled', conditionValue: true, permission: 'edit'})
    }

    return actions
  }
  const generateRowClickActions = () => {
    const actions: any[] = [
      {field: 'number', action: (obj: any) => logProduction(obj.id, LOG), tooltip: 'Log Production', permission: 'create'}
    ]

    if (type !== SHIPPING_LP)
      actions.push({field: 'item', action: (obj: any) => viewProcessInstructions(obj.item_id), condition: 'has_process_instructions',
          tooltip: 'View Process Instructions'})

    return actions
  }

  return (
    <>
      <ApiTable
        doLoad={doLoad}
        legend={legend}
        refresh={refresh}
        suppressLoadDisplay={suppressLoad}
        objectName=''
        headers={getHeaders()}
        rowFields={getFields()}
        tableActions={[]}
        rowActions={generateActions()}
        rowClickActions={generateRowClickActions()}
        dataField='objects'
        url={`/processing/lineplan/${id}`}
        searchable
        refreshable
        expandable={type === ASSEMBLY_LP}
        expandableParentCondition={{
          field: 'color',
          condition: (val: string) => {
            return val === 'assembly'
          }
        }}
        expandableParams={{
          tableName: 'Children',
          refresh: refresh,
          suppressLoadDisplay: suppressLoad,
          objectName: '',
          dataField: 'objects',
          headers: ['Order', 'Item', 'Description', 'Available', ''],
          rowFields: ['number', 'item', 'description', 'available'],
          rowActions: [
            {text: 'Log Production Non Conforming', icon: <AccessTime />, action: (id: number) => logProductionNC ? logProductionNC(id) : {}, tooltip: 'Log Production', permission: 'create'}
          ],
          url: `/processing/lineplan/${id}`,
        }}
      />
    </>
  )
}

export default LineplanLogTable