import React, {FC, useState} from "react";
import { useForm, FormProvider } from 'react-hook-form';
// mui imports
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormInputAutocomplete from "../../form/FormInputAutocomplete";
import BaseTooltip from "../BaseTooltip";
import {updateSchedulingArmLoadUnloadNote} from "./MachineLoadDisplayApi";

interface SchedulingLoadUnloadNoteProps {
  note: any;
  options: any[];
}
const SchedulingLoadUnloadNote: FC<SchedulingLoadUnloadNoteProps> = ({note, options}) => {

  const { user, day_name, week_name } = note;

  const methods = useForm({defaultValues: {load_items: note.selected_load, unload_items: note.selected_unload}});

  const handleChange = (data: any[], load: boolean) => {
    data = data.map((item: any) => item.id)
    updateSchedulingArmLoadUnloadNote(note.id, {load: load, items: data});
    console.log(`${load ? 'Load' : 'Unload'} change :`, data)
  }

  return (
    <Grid container>
      <FormProvider {...methods}>
        <BaseTooltip text='These items will be loaded on this day.' placement='top'>
          <Grid item xs={12} display='flex' justifyContent='center' sx={{margin: '5px'}}>
            <FormInputAutocomplete name='load_items' label='Items to Load' options={options} fullWidth multiple myOnChange={(data: any) => handleChange(data, true)} />
          </Grid>
        </BaseTooltip>
        <BaseTooltip text='These items will be unloaded on this day.' placement='top'>
          <Grid item xs={12} display='flex' justifyContent='center' sx={{margin: '5px'}}>
            <FormInputAutocomplete name='unload_items' label='Items to Unload' options={options} fullWidth multiple myOnChange={(data: any) => handleChange(data, false)} />
          </Grid>
        </BaseTooltip>
      </FormProvider>
      <Grid item xs={12} display='flex' justifyContent='end' sx={{mr: '3px'}}>
        <Typography variant='subtitle2'>
          {!user || user === '' ? 'None' : user} - {day_name} - {week_name}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SchedulingLoadUnloadNote;

