import React, {FC} from 'react'
// mui imports
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
// custom imports
import { colors } from '../../colors'
import ProgressTooltip from './ProgressTooltip'


interface TickProps {
  position: number
  text: string
  length: number
  type: string
}
const Tick: FC<TickProps> = ({position, text, length, type}) => {

  if (position > 99.7)
    position = 99.7
  else if (position < 0)
    position = 0

  return (
    <>
      <span style={{
        position: 'absolute',
        left: `calc(${position}% - ${text.length > 4 ? length * 3.7 : length * 1.7}px)`,
        top: -17,
        textAlign: 'center',
        height: 15,
        borderRadius: 5
      }}>
        <Typography variant='subtitle2'>{text}</Typography>
      </span>
      <span style={{
        position: 'absolute',
        left: `${position}%`,
        width: '6px',
        backgroundColor: 'black',
        height: 15,
        borderRadius: 0
      }}></span>
    </>
  )
}

interface ProgressTickProps {
  progress: number
  ticks: any[]
  children: JSX.Element | JSX.Element[] | undefined
  spacing?: number | undefined
  skipped?: number | undefined
	flatEnd?: boolean
}
const ProgressTickedBar: FC<ProgressTickProps> = ({progress, ticks, children, spacing, skipped, flatEnd}) => {

  const theme: any = useTheme()

  const skippedColor: string = colors[theme.palette.mode].machineLoadDisplay.almostDone

  return (
    <Grid container item spacing={spacing ? spacing : 1.5}>
      {children ?
        <Grid item>
          {children}
        </Grid>
        :
        <></>
      }
      <Grid item sx={{width: '98%', display: 'grid'}}>

        {/* Main progress bar */}
        <LinearProgress
          sx={{
						position: 'relative', 
						height: 15, 
						borderRadius: 5,
						borderBottomRightRadius: flatEnd ? 0 : 20,
						borderTopRightRadius: flatEnd ? 0 : 20,
						width: '100%', 
						gridColumn: 1, 
						gridRow: 1
					}}
          variant='determinate'
          value={progress}
        />

				<div style={{position: 'relative', width: '100%', zIndex: 3, gridColumn: 1, gridRow: 1}}>
					{ticks.map((tick, index) => (
						tick.type === 'skipped' ? 
							<ProgressTooltip key={index} tooltip={tick.tooltip}>
									<LinearProgress
										sx={{
											position: 'absolute',
											left: `calc((${ticks[index-1] ? ticks[index-1].position : 0}%) + ${index == 0 ? 0 : 6}px)`, // 
											width: `calc(((${tick.position}% / ${index + 1}) - ${index == 0 ? 0 : 6}px))`, 
											height: 15,
											color: skippedColor,
											zIndex: 998, // Ensure this overlays the main progress bar
											gridColumn: 1, 
											gridRow: 1, 
											borderBottomLeftRadius: index == 0  ? 20 : 0,
											borderTopLeftRadius: index == 0  ? 20 : 0
										}}
										color='warning'
										variant="determinate"
										value={100}
									/>
							</ProgressTooltip>
							: null
					))}
        </div>

				<div style={{position: 'relative', width: '100%', zIndex: 2, gridColumn: 1, gridRow: 1}}>
          {(ticks.map((tick: any, key: number) => (
            <Tick {...tick} key={key} />
          )))}
        </div>

      </Grid>
    </Grid>
  )
}

export default ProgressTickedBar
