import React, { FC, useEffect, useState } from 'react';
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import { getSchedulingArmLoadUnloadNotes } from "./MachineLoadDisplayApi";
import SchedulingLoadUnloadNote from "./SchedulingLoadUnloadNote";
import BaseAccordion from "../BaseAccordion";

interface SchedulingLoadUnloadNoteContainerProps {
  arm: any;
  colspan: number;
}
const SchedulingLoadUnloadNoteContainer: FC<SchedulingLoadUnloadNoteContainerProps> = ({arm, colspan}) => {
  const [notes, setNotes] = useState<any[]>([]);
  const [days, setDays] = useState<any[]>([]);
  const [weeks, setWeeks] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectableItems, setSelectableItems] = useState<any[]>([]);

  const doRefresh = () => setRefresh(!refresh);

  useEffect(() => {
    if (arm)
      getSchedulingArmLoadUnloadNotes(arm.id, (notes: any[], days: any[], weeks: any[], items: any[]) => {
        setNotes(notes);
        setDays(days.map((day: any[]) => {return {number: day[0], name: day[1]}}));
        setWeeks(weeks.map((week: any[]) => {return {number: week[0], name: week[1]}}));
        console.log('items :', items)
        setSelectableItems(items);
      })
  }, [arm, refresh])

  // helpers
  const getNote = (day: number, week: number) => {
    for (const note of notes)
      if (note.day === day && note.week === week)
        return note;
  }

  if (arm && notes.length > 0)
    return (
      <tr>
        <td colSpan={colspan}>
          <BaseAccordion title='Planning' variant='subtitle2'>
            {weeks.map((week: any, key: number) => (
              <Grid container key={key}>
                {days.map((day: any, key: number) => (
                  <Grid item xs={12 / days.length} key={key} display='flex' justifyContent='center' sx={{border: '1px solid gray'}}>
                    <SchedulingLoadUnloadNote note={getNote(day.number, week.number)} options={selectableItems} />
                  </Grid>
                ))}
              </Grid>
            ))}
          </BaseAccordion>
        </td>
      </tr>
    );
  else
    return <></>;
}

export default SchedulingLoadUnloadNoteContainer;