import { apiGet } from "../../api";
import { api } from "../../globals";
import { AxiosError, AxiosResponse } from "axios";
import {doAlert} from "../../globalComps/PopupAlert/PopupAlert";


const URL: string = `/communication/channel-messaging/`

// GET
/**
 * getHasChannel - Checks if a channel exists for the arm or line plan id
 * @param armId
 * @param linePlanId
 * @param shipping
 * @param callBack
 */
const getHasChannel = (armId?: number, linePlanId?: number, shipping?: boolean, callBack?: any) => {

  apiGet(URL, {armId: armId, linePlanId: linePlanId, shipping: shipping, hasChannel: true}, (resp) => {
    if (callBack) callBack(resp.data);
  }, () => {
    if (callBack) callBack(false);
  })
}
/**
 * getLatestMessage - gets the latest channel message from the given arm or line plan id
 * @param armId
 * @param linePlanId
 * @param shipping
 * @param callBack
 */
const getLatestMessage = (armId?: number, linePlanId?: number, shipping?: boolean, callBack?: any) => {
  apiGet(URL, {armId: armId, linePlanId: linePlanId, latestMessage: true, shipping: shipping}, (resp) => {
    if (callBack) callBack(resp.data.message, resp.data.user_name, resp.data.created, resp.data.id, resp.data.acknowledged);
  })
}
/**
 * getMessages - get the actual channel messages
 * @param armId
 * @param linePlanId
 * @param shipping
 * @param callBack
 */
const getMessages = (armId?: number, linePlanId?: number, shipping?: boolean, callBack?: any) => {
  apiGet(URL, {armId: armId, linePlanId: linePlanId, messages: true, shipping: shipping}, (resp) => {
    if (callBack) callBack(resp.data.messages);
  })
}
/**
 * getAcknowledgedByList - gets a list of usernames who have acknowledged the message
 * @param messageId
 * @param callBack
 */
const getAcknowledgedByList = (messageId: number, callBack?: any) => {
  apiGet(`${URL}${messageId}/`, {acknowledgeUsers: true}, (resp) => {
    callBack(resp.data.users);
  })
}

// POST
/**
 * toggleMessageHidden - toggles if a message is hidden or not
 * @param messageId
 * @param callBack
 */
const toggleMessageHidden = (messageId: number, callBack: () => void) => {
  api.post(`${URL}${messageId}/`, {toggleHidden: true})
    .then(() => {if (callBack) callBack()})
    .catch((err: AxiosError) => {
      if (err.response) doAlert(err.response.data.message, 'error', true);
      if (callBack) callBack()
    })
}
/**
 * deleteMessage - deletes an individual message
 * @param messageId
 * @param callBack
 */
const deleteMessage = (messageId: number, callBack?: any) => {
  api.delete(`${URL}${messageId}/`)
    .then(() => {if (callBack) callBack(true)})
    .catch((err: AxiosError) => {
      if (err.response) doAlert(err.response.data.message, 'error', true);
      if (callBack) callBack(false)
    })
}
/**
 * acknowledgeMessage
 * @param messageId
 * @param callBack
 */
const acknowledgeMessage = (messageId: number, callBack?: any) => {
  api.post(`${URL}${messageId}/`, {acknowledgeMessage: true})
    .then(() => {
      if (callBack) callBack(true)
    })
    .catch((err: AxiosError) => {
      if (err.response) doAlert(err.response.data.message, 'error', true);
      if (callBack) callBack(false)
    })
}
/**
 * hideMessage
 * @param messageId
 * @param callBack
 */
const hideMessage = (messageId: number, callBack: any) => {
  api.post(`${URL}${messageId}/`, {hideMessage: true})
    .then(() => {
      if (callBack) callBack(true)
    })
    .catch((err: AxiosError) => {
      if (err.response) doAlert(err.response.data.message, 'error', true);
      if (callBack) callBack(false)
    })
}

export {
  getLatestMessage,
  getHasChannel,
  getMessages,
  getAcknowledgedByList,
  toggleMessageHidden,
  deleteMessage,
  acknowledgeMessage,
  hideMessage,
}