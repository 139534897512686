import React, { FC, useState } from 'react'
// mui imports
import { useTheme } from '@mui/material/styles'
import Slide from '@mui/material/Slide'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
// mui icons
import LibraryBooks from '@mui/icons-material/LibraryBooks'
import Refresh from '@mui/icons-material/Refresh'
import Search from '@mui/icons-material/Search'
import Redo from '@mui/icons-material/Redo'
import Message from '@mui/icons-material/Message'
import Remove from '@mui/icons-material/Remove'
import Typography from '@mui/material/Typography'
// custom imports
import { ItemCell, ItemQtyCell, OrderToLoadCell } from './ItemCells'
import { colors } from '../../colors'
import { getArmStatus } from '../../../components/pages/molding/MoldingApi'
import { skipProduction } from './MachineLoadDisplayApi'
import { MOLDING } from '../ProductionLog/ProductionLogHelpers'
import { apiGet } from '../../api'
import { useEffectApi } from '../../globals'
import DropdownActions from '../DropdownActions'
import BaseContent from '../BaseContent'
import EnhancedLoadQueue from '../../../components/pages/scheduling/MachineScheduling/EnhancedLoadQueue'
import FindCompatibleOrders from '../../../components/pages/scheduling/MachineScheduling/FindCompatibleOrders/FindCompatibleOrders'
import BaseTooltip from '../BaseTooltip'
import Channel from '../Channel'
import ChannelModal from '../../modals/ChannelModal/ChannelModal'
import SchedulingLoadUnloadNoteContainer from './SchedulingLoadUnloadNoteContainer'
import BaseAccordion from '../BaseAccordion'
import RotoScheduler from '../RotoScheduler/RotoScheduler'
import LoadQueueBox from './LoadQueueBox'
import PageTitle from '../PageTitle'
import ProductionLogModal from '../ProductionLog/ProductionLogModal'
import Legend from '../Legend'

const OVERLOADED: number = 0
const	SHUTDOWN: number = 1
const	IN_CYCLE: number = 2

interface MachineLoadingProps {
  machine: any
  schedulingDisplay?: boolean
  removable?: boolean
  doRefresh?: boolean
  unloadOrderItem?: (orderName: string, armName: string, orderId: number | undefined) => void
  enhancedLoadQueue?: boolean
  findCompatible?: boolean
  forceRefresh?: boolean
  doLoad?: boolean
  advancedScheduling?: boolean
  onItemClick?: (armId: number) => void
  notCollapsible?: boolean
  unloadAllMoldsArm?: ((machineId: number, armId: number) => void) | undefined
  permissions?: any | undefined
  light?: boolean | undefined
  loadToArmAction?: (data: any, cb: () => void) => void
  skipArmCallback?: (() => void) | undefined
}
const MachineLoadDisplay: FC<MachineLoadingProps> = ({machine, schedulingDisplay,
                                                       removable, doRefresh,
                                                       unloadOrderItem, enhancedLoadQueue,
                                                       findCompatible, forceRefresh,
                                                       doLoad, advancedScheduling,
                                                       onItemClick, notCollapsible,
                                                       unloadAllMoldsArm, permissions, light,
                                                       loadToArmAction, skipArmCallback}) => {
  const theme = useTheme()

  const [itemCount, setItemCount] = useState<number>(0)
  const [armList, setArmList] = useState<any[]>([])
  const [orderList, setOrderItemList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [findCompatibleOrdersIdList, setFindCompatibleOrdersIdList] = useState<any[]>([])
  const [channelId, setChannelId] = useState<number | undefined>(undefined)
  const [armStatus, setArmStatus] = useState<any>({})
  const [machineLegend, setMachineLegend] = useState<any[]>([])

	const [schedulerKey, setSchedulerKey] = useState(0)

  const [canViewProduction, setCanViewProduction] = useState<boolean>(false)
  const [canViewChannels, setCanViewChannels] = useState<boolean>(false)

  const [productionLogsOpen, setProductionLogsOpen] = useState<boolean>(false)

  const [refreshChannels, setRefreshChannels] = useState<boolean>(false)

  // colors
  const headerCellColor: string = colors[theme.palette.mode].machineLoadDisplay.headerCellColor
  const leftColumnColor: string = colors[theme.palette.mode].machineLoadDisplay.leftColumnColor

  useEffectApi(() => {

    // console.log(`machine load display use effect machine=${machine.name} 1`)

    if (doLoad || doLoad === undefined && permissions) {
      // console.log(`machine load display use effect machine=${machine.name} 2`)
      if (doRefresh !== undefined && doRefresh !== refresh)
        setRefresh(doRefresh)

      getArmStatus(machine.id, (data: any) => {
        setArmStatus(data)
      })
			
			setLoading(true)
      apiGet('/inventory/machine-display/', {machineId: machine.id}, (resp: any) => {
        setArmList(resp.data.arms.map((arm: any) => ({...arm, refresh: false})))
        setOrderItemList(resp.data.orders)
        setCanViewProduction(resp.data.can_view_production)
        setCanViewChannels(resp.data.can_view_channels)
        for (const arm of resp.data.arms)
          setItemCount(itemCount + arm.orders.length)
        setLoading(false)
				setSchedulerKey(prev => prev + 1);
      }, () => setLoading(false))

      let machineLegend = [
        {color: colors[theme.palette.mode].machineLoadDisplay.overLoaded, text: 'Overloaded'},
        {color: colors[theme.palette.mode].machineLoadDisplay.shutdown, text: 'Shutdown/Idle'},
        {color: colors[theme.palette.mode].machineLoadDisplay.almostDone, text: 'Almost Done'},
      ]
      if (machine && machine.enforce_arm_order)
        machineLegend = [
          {color: colors[theme.palette.mode].machineLoadDisplay.inCycle, text: 'In Cycle'},
          ...machineLegend
        ]

      setMachineLegend(machineLegend)

    } else {
      // setLoading(false)
    }

  }, [machine, refresh, doRefresh, forceRefresh, permissions])

  // actions
  // production logs
  const openProductionLogs = (id: number) => {
    setProductionLogsOpen(true)
  }
  const closeProductionLogs = () => {
    setProductionLogsOpen(false)
  }
  // refresh
  const refreshDisplay = (machineId: number) => {
    setLoading(true)
  }
  // skip production
  const doSkipProduction = (machineId: number) => {
    skipProduction(machineId, (data: any) => {
      setRefresh(!refresh)
      if (skipArmCallback) skipArmCallback()
    })
  }

  const generateActions = (armId: number, key: number) => {

    let actions: any[] = []

    if (key === 0) {
      actions.push({text: 'Refresh', tooltip: 'Refresh Machine Display', action: refreshDisplay, icon: <Refresh />})

      if (canViewProduction)
        actions.push({text: 'Production', tooltip: 'Machine Production Logs', action: openProductionLogs, icon: <LibraryBooks />})
    }

    if (findCompatible && getOrderItemsFromArm(armId).length > 0)
      actions.push({text: 'Find Compatible Orders', tooltip: 'Find compatible orders for the orders loaded to this arm.',
        action: (machineId: number, armId: number) => openFindCompatibleOrders(armId), icon: <Search />})

    if (unloadAllMoldsArm && permissions?.edit)
      actions.push({text: 'Remove All Molds', action: unloadAllMoldsArm, icon: <Remove />})

    if (armStatus && armStatus[armId] && armStatus[armId].status === IN_CYCLE)
      actions.push({text: 'Skip Production', action: doSkipProduction, icon: <Redo />})

    if (canViewChannels)
      actions.push({text: 'Open Channel', action: openChannel, icon: <Message />})

    return actions
  }

  const armExpand = (_: any, expanded: boolean, armId: number) => {
    if (expanded && armId) {
      setArmList([...armList].map((arm: any) => {
        if (arm.id === armId)
          arm.refresh = !arm.refresh

        return arm
      }))
    }
  }

  // component helpers
  const getOrderItemsFromArm = (armId: number) => orderList.filter((order: any) => parseFloat(order.arm_id) === armId)
  // find compatible orders
  const openFindCompatibleOrders = (armId: number) => {
    const loadedOrders: any[] = getOrderItemsFromArm(armId)
    setFindCompatibleOrdersIdList(loadedOrders.map((order: any) => order.id))
  }
  const closeFindCompatibleOrders = () => {
    setFindCompatibleOrdersIdList([])
  }
  // open channel
  const openChannel = (machineId: number, armId: number) => setChannelId(armId)
  const closeChannel = () => {
    setChannelId(undefined)
  }
  const reloadChannelDisplay = () => setRefreshChannels(!refreshChannels)
  // arm status
  const getArmStatusColor = (armId: number) => {
    if (armStatus[armId])
      if (armStatus && armStatus[armId].status === OVERLOADED)
        return colors[theme.palette.mode].machineLoadDisplay.overLoaded
      else if (armStatus && armStatus[armId].status === IN_CYCLE)
        return colors[theme.palette.mode].machineLoadDisplay.inCycle
      else
        return colors[theme.palette.mode].machineLoadDisplay.shutdown
    else
      return colors[theme.palette.mode].machineLoadDisplay.shutdown
  }
  const getArmClickable = (armId: number) => armStatus && armStatus[armId] && armStatus[armId].clickable

  const armTitle = (arm: any) => {

    return (
      <>
        <Typography variant='h5'>{arm.name} <strong>{arm.shutdown ? '- SHUTDOWN' : ''}</strong></Typography>
        <Typography>
          {arm.cycle_info ? `${arm.cycle_info} | ` : ''}
          {arm.takt_time ? `${arm.takt_time} | ` : ''}
          {arm.max_cooling_time ? arm.max_cooling_time : ''}
        </Typography>
      </>
    )
  }

  return (
    <>
      <PageTitle title=''  />
      <BaseContent loading={loading}>
        {armList.length > 0 ?
          <Table sx={{width: '100%'}}>
            <TableBody>

              {armList.map((arm: any, key: number) => (
                <TableRow key={key}>
                  <TableCell sx={{borderBottom: '0 !important', p: 0}}>
                    <BaseAccordion title={arm.name} onChange={(_: any, expand: boolean) => armExpand(_, expand, arm.id)}
                                   enabled={!notCollapsible} light={light}>
											<Legend legendItems={machineLegend} />
                      <TableContainer component={Paper}>
                        <Table style={{width: '100%'}}>
                          <TableBody>
                            <TableRow style={{height: '20%'}}>
                              {!schedulingDisplay ?
                                <TableCell style={{borderBottomWidth: 0, backgroundColor: leftColumnColor, width: '10%'}}>
                                </TableCell>
                                :
                                <></>}
                              <Slide direction='right' in={true}>
																
                                <TableCell style={{backgroundColor: getArmStatusColor(arm.id), borderBottomWidth: '0 !important'}}
                                           colSpan={!schedulingDisplay ? 1 : 4}>
                                  <div>
																		
                                    <Grid container>
                                      {/* arm number */}
                                      <Grid item>
                                        {armTitle(arm)}
                                      </Grid>
                                      {/* Machine actions */}
                                      <Grid item sx={{ml: 'auto'}}>
                                        {generateActions(arm.id, key).length > 0 ? <div>
                                          <Grid container justifyContent='center' sx={{mt: '10px'}}>
                                            <DropdownActions noMin>
                                              {generateActions(arm.id, key).map((act: any, key: number) => (
                                                <BaseTooltip text={act.tooltip} placement='right' key={key}>
                                                  <MenuItem onClick={() => act.action(arm.machine_id, arm.id)} sx={{fontSize: '10px'}}>
                                                    <ListItemIcon sx={{fontSize: 'inherit', minWidth: '32px'}}>{act.icon}</ListItemIcon>
                                                    <ListItemText>{act.text}</ListItemText>
                                                  </MenuItem>
                                                </BaseTooltip>
                                              ))}
                                            </DropdownActions>
                                          </Grid>
                                        </div> : <></>}
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Channel armId={arm.id} forceRefresh={refreshChannels} />
                                </TableCell>
                              </Slide>
                            </TableRow>
                            <TableRow>
                              {!schedulingDisplay ?
                                <TableCell style={{backgroundColor: leftColumnColor, border: '0 !important'}}>
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell style={{textAlign: 'center', padding: 10, borderBottomWidth: 0}}>
                                          {!advancedScheduling ? <LoadQueueBox armName={arm.name} armId={arm.id} refresh={arm.refresh} /> : <></>}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableCell>
                                :
                                <></>
                              }

                              <TableCell>
                                <Table style={{width: '100%'}}>
                                  <TableBody>
                                    <TableRow>
                                      {getOrderItemsFromArm(arm.id).map((order: any, key: number) => (
                                        <ItemCell armId={arm.id} key={key} order={order} available={false} mode={theme.palette.mode}
                                                  cellWidth={100 / (getOrderItemsFromArm(arm.id).length + 1)} removable={removable && permissions?.edit}
                                                  unloadOrderItem={(orderName: string, orderId: number | undefined) => unloadOrderItem ? unloadOrderItem(orderName, arm.name, orderId) : () => {}}
                                                  onItemClick={onItemClick} clickable={getArmClickable(arm.id)} />


                                      ))}
                                      <ItemCell armId={arm.id} available={true} availableAmount={arm.capacity_left} availableMax={arm.capacity} mode={theme.palette.mode}
                                                cellWidth={100 / (getOrderItemsFromArm(arm.id).length + 1)} onItemClick={onItemClick} clickable={getArmClickable(arm.id)} />
                                    </TableRow>
                                    <TableRow style={{margin: 0}}>
                                      {getOrderItemsFromArm(arm.id).map((order: any, key: number) => (
                                        <ItemQtyCell key={key}
                                                     quantity={order.balance} />
                                      ))}
                                    </TableRow>
                                    {schedulingDisplay ?
                                      <TableRow style={{margin: 0}}>
                                        {getOrderItemsFromArm(arm.id).map((order: any, key: number) => (
                                          <OrderToLoadCell key={key} machineLoadedOrderId={order.id} />
                                        ))}
                                      </TableRow> : <></>}
                                    {!advancedScheduling ?

                                      <>
                                        {schedulingDisplay ? <SchedulingLoadUnloadNoteContainer arm={arm} colspan={getOrderItemsFromArm(arm.id).length + 1} />
                                          : <></>}
                                        {enhancedLoadQueue ?
                                          <EnhancedLoadQueue arm={arm} />
                                          : <></>}
                                      </>

                                      :

                                      <TableRow>
                                        <TableCell colSpan={getOrderItemsFromArm(arm.id).length + 1}>

																					<RotoScheduler key={`${schedulerKey}-${arm.id}`} armId={arm.id} machineName={machine.name} refresh={arm.refresh || schedulerKey }
                                                         armChoices={armList.map((arm: any) => ({id: arm.id, title: arm.name}))}
                                                         readOnly={!schedulingDisplay} canEdit={permissions?.edit}
                                                         canDelete={permissions?.delete} loadToArmAction={loadToArmAction} />

                                        </TableCell>
                                      </TableRow>
                                    }
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>

                      </TableContainer>
                    </BaseAccordion>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          :
          <Typography>Error displaying machine 2 loading={`${loading}`}</Typography>
        }
      </BaseContent>

      <FindCompatibleOrders orderIdList={findCompatibleOrdersIdList} onClose={closeFindCompatibleOrders} machine={machine} />
      <ChannelModal armId={channelId} channelClose={closeChannel} reloadChannelDisplay={reloadChannelDisplay} />

      <ProductionLogModal open={productionLogsOpen} type={MOLDING} onClose={closeProductionLogs}
                          orderId={undefined} machineId={machine.id} />
    </>
  )
}

export default MachineLoadDisplay
