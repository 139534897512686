import { EventStore } from "@bryntum/scheduler";
import Order from "./Order.js";

export default class UnassignedStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: Order,
    };
  }

  constructor(config) {
    super(config);
    // this.lastServerData = config.data;
  }

  // onCommit() {
  //   this.lastServerData = this.allRecords;
  //   super.commit();
  // }

  // revertChanges() {
  //   this.removeAll();
  //   this.add(this.lastServerData);
  // }

  bindData(data) {
    this.beginBatch();
    data.forEach((unassignedOrder) => {
      const recExists = this.allRecords.find(
        (f) => f.id === unassignedOrder.id
      );
      if (!recExists) {
        // unassignedOrder
        this.add(unassignedOrder);
      }
    });
    this.endBatch();
    // this.lastServerData = data;
  }

  getRecords() {
    return this.records.map((rec) => rec.originalData);
  }
}
