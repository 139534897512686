import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";

const useAccounting = () => {
	const [data, setData] = useState<any>({
		page_state: {
			filters: {
				type: '1',
				start: null,
				end: null,
				shift: null, 
				personnel: null, 
				order_search: null, 
				item_search: null
			}
		},
		accounting: {
			shifts: [], 
			machines: [], 
			personnel: [], 
			item_data: []
		}
	});

	const { loading, sendMessage, isVisible, setPageState } = useWebsocket('/processing/accounting/', setData);

	let pageState = data.page_state;
	const accounting = useMemo(() => {console.log(data.accounting); return data.accounting}, [data]);

	// Log and potentially re-set state when visibility changes
	useEffect(() => {
		if (!isVisible) {
			setPageState(pageState);
		}
	}, [isVisible]);

	return {
		loading, sendMessage, isVisible, setPageState,
		pageState, accounting
	};
};

export default useAccounting;
