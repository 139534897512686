import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";


const useInventory = () => {
	const [data, setData] = useState<any>({
		page_state: {
			filter: {
				item_type: 3
			}
		},
		inventory: [],
	});

	const { loading, sendMessage, isVisible, setPageState } = useWebsocket('/inventory/inventory/', setData);

	const pageState = data.page_state;

	const clearInventory = () => {
		let temp_data = {...data}
		temp_data.inventory = []
		setData(temp_data)
	}

	// Log and potentially re-set state when visibility changes
	useEffect(() => {
		if (!isVisible) {
			setPageState(pageState);
		}
	}, [isVisible]);

	const inventory = useMemo(() => {
		return data.inventory
	}, [data]);

	return {
		loading, sendMessage, pageState, clearInventory,
		inventory
	};
};

export default useInventory;
