import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";

export interface FinancialSummary {
	machines: string[];
	lineplans: string[];
	financials_month: Record<string, any>;
	financials_shift: Record<string, any>;
}

const useFinancialSummary = (timePeriod: 'shift' | 'month') => {
	const [financialSummary, setFinancialSummary] = useState<FinancialSummary>({
		machines: [],
		lineplans: [],
		financials_month: {},
		financials_shift: {}
	});

	const { } = useWebsocket('/processing/financial_summary/', setFinancialSummary);

	const financials = useMemo(() => {
		if (timePeriod == 'shift') {
			return financialSummary.financials_shift
		} else {
			return financialSummary.financials_month
		}
	}, [financialSummary, timePeriod])

	const machines = useMemo(() => financialSummary.machines || [], [financialSummary])
	const projectedSales = useMemo(() => financials.actual_vs_projected || {}, [financials])
	const scrapSales = useMemo(() => financials.scrap_vs_sales || { non_conforming_cost: 0, revenue: 0 }, [financials])

	return {
		machines, projectedSales, scrapSales
	};
};

export default useFinancialSummary;
