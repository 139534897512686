import {apiGet} from "../../api";


/**
 * Gets lineplan or machine overall productivity progress data for a given shift
 * @param data: {machineId?: number, lineplanId?: number, shiftId: number}
 * @param callback
 */
const getOverallProgress = (data: any, callback: (data: any) => void) => {
  apiGet('/productivity/progress/', {...data, overall: true}, (resp: any) => {
    callback(resp.data)
  })
}

/**
 * gets the logged lineplan productivity progress data
 * @param id: lineplan id
 * @param shiftId
 * @param callback
 */
const getLineplanLoggedProgress = (id: number, shiftId: number, callback: (data: any) => void) => {
  apiGet('/productivity/progress/', {lineplanId: id, shiftId: shiftId, loggedProductivity: true}, (resp: any) => {
    callback(resp.data)
  })
}

/**
 * gets the overall shift productivity %, logged arm count, overall target arm count
 * @param id
 * @param shiftId
 * @param callback
 */
const getMachineLoggedProgress = (id: number, shiftId: number, callback: (data: any) => void) => {
  apiGet('/productivity/progress/', {machineId: id, shiftId: shiftId, loggedProductivity: true}, (resp: any) => {
    callback(resp.data)
  })
}

export {
  getOverallProgress, getLineplanLoggedProgress, getMachineLoggedProgress
}