import {apiGet, apiPost} from "../../../utils/api";

/**
 * Gets permission data for shipping
 * @param callback
 */
const getData = (callback: (data: any) => void) => {
  apiGet('/processing/shipping/', {getData: true}, (resp: any) => callback(resp.data))
}

/**
 * Logs production on the shipping page
 * @param data shipping log production data
 * @param callback
 */
const logProduction = (data: any, callback: (data: any) => void) => {
  apiPost('/processing/shipping/', {...data, logProduction: true}, (resp: any) => callback(resp.data))
}

export {
  getData, logProduction
}
