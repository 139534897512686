import {FC} from "react";
import HighlightOffSharp from '@mui/icons-material/HighlightOffSharp';
import CheckCircleSharp from '@mui/icons-material/CheckCircleSharp';
import BaseTooltip from "./BaseTooltip";

interface TrueFalseIconProps {
  isTrue: boolean
  disableTooltip?: boolean;
  sx?: any
}
const TrueFalseIcon: FC<TrueFalseIconProps> = ({isTrue, disableTooltip, sx}) => {


  if (disableTooltip)
    return isTrue ?
      <CheckCircleSharp sx={{...sx, color: 'green', fontSize: '1.5em'}} />
      :
      <HighlightOffSharp sx={{...sx, color: 'red', fontSize: '1.5em'}} />
  else
    return isTrue ?
      <BaseTooltip text='True' placement='top'><CheckCircleSharp sx={{...sx, color: 'green', fontSize: '1.5em'}} /></BaseTooltip>
      :
      <BaseTooltip text='False' placement='top'><HighlightOffSharp sx={{...sx, color: 'red', fontSize: '1.5em'}}/></BaseTooltip>
}

export default TrueFalseIcon;
