import React, { FunctionComponent, useMemo } from 'react';
import { Box, Typography, useTheme, Grid } from '@mui/material/';
import useScrapSummary from './useScrapSummary';
import ScrapPieChart from '../../charts/ScrapPieChart';
import ScrapBarChart from '../../charts/ScrapBarChart';
import { useTimePeriod } from '../../TimePeriodContext';

const ScrapSummary: FunctionComponent = () => {
	const theme = useTheme();
	const { timePeriod } = useTimePeriod();
	const { pieChartData, barChartData } = useScrapSummary(timePeriod);

	const {
		nonConformingPercent, nonConformingParts, 
		conformingPercent, conformingParts
	} = pieChartData

	return (
		<Box sx={{ flex: 1, pt: 3, px: 2, pb: 2, border: 2, borderRadius: 3, borderColor: theme.palette.primary.main, boxShadow: 1, height: '100%' }}>
			<Typography variant='h4' sx={{ textAlign: 'center', mb: 3 }}>Scrap Summary</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
						<ScrapPieChart
							conforming={conformingPercent}
							conformingParts={conformingParts}
							nonConforming={nonConformingPercent}
							nonConformingParts={nonConformingParts}
							height={300}
						/>
				</Grid>
				<Grid item xs={12} md={6}>
						<ScrapBarChart
							data={barChartData}
							height={270}
						/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ScrapSummary;
