import { AxiosError, AxiosResponse } from "axios";
// custom imports
import { api } from "../../../../utils/globals";
import { doConfirm } from "../../../../utils/modals/Confirm";
import { doAlert } from "../../../../utils/globalComps/PopupAlert/PopupAlert";
import {apiGet, apiPost} from "../../../../utils/api";

// helpers

/**
 * getName - Gets an order name.
 * For the scheduled/loadqueue/machineloaded order types, the machineId it is assigned to is also returned by this api call
 * @param orderId: order id
 * @param type: order type (unscheduled/scheduled/loadqueue/machineloaded)
 * @param callback: callback
 */
const getName = (orderId: number | undefined, type: string,
                 callback: (name: string, machineId: number | undefined) => void) => {

  if (orderId) {

    api.get(`/scheduling/machine-scheduling/${orderId}/?getOrderName=${true}&type=${type ? type : 'loadQueue'}`)
      .then((resp: AxiosResponse) => {
        callback(resp.data.name, resp.data.machine_id);
      })
      .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
  }
}
/**
 * getArmsFromLoadQueueId
 * @param orderId: order
 * @param type: order type (loadqueue/machineloaded)
 * @param successCallback
 */
const getArmsFromLoadQueueId = (orderId: number | undefined, type: string, successCallback: (armList: any[]) => void) => {
  apiGet(`/scheduling/machine-scheduling/${orderId}/`, {getArmList: true, type: type},
    (resp: AxiosResponse) => {
      successCallback(resp.data.arms);
    });
}

/**
 * getLoadAfterOptions
 * @param orderId: loadqueue id to grab other loadqueue/machineLoad orders under the same arm
 * @param successCallback
 */
const getLoadAfterOptions = (orderId: number | undefined, successCallback: (loadAfterList: any[]) => void) => {
  apiGet(`/scheduling/enhanced-loadqueue/${orderId}/`, {getLoadAfterOptions: true},
    (resp: AxiosResponse) => successCallback(resp.data.load_after_list));
}

const getUpdateMoldQuantityData = (orderId: number, successCallback: (data: any) => void) => {
  if (orderId)
    apiGet(`/scheduling/machine-scheduling/${orderId}/`, {getMoldUpdateQuantityData: true},
      (resp: any) => {
        successCallback(resp.data)
      })
}

/**
 * scheduleToMachine (post)
 * @param orderItemId: order to schedule
 * @param machineId: machine to schedule to
 * @param move: moving already scheduled item?
 * @param successCallback
 * @param addType: type of order to schedule, (i.e. scheduled or loadqueue or machineloaded)
 */
const scheduleToMachine = (orderItemId: number | undefined, machineId: number | undefined, move: boolean, successCallback: () => void, addType?: string) => {
  api.post(`/scheduling/machine-scheduling/${orderItemId}/`, {scheduleToMachine: true, machineId: machineId, move: move, addType: addType})
    .then((resp: AxiosResponse) => {
      doAlert(resp.data.message, resp.data.status ? resp.data.status : 'success', true);
      successCallback();
    })
    .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
}
/**
 * removeScheduledItem (post)
 * @param scheduledItemId: scheduled order id
 * @param machineId: machine to remove from
 * @param successCallback
 * @param type: order type
 */
const removeScheduledItem = (scheduledItemId: number | undefined, machineId: number | undefined, successCallback: () => void, type?: string) => {
  console.log('remove scheduled item :', scheduledItemId, machineId);

  getName(scheduledItemId, type ? type : 'scheduled', (name: string, machineId: number | undefined) => {

    let message: string = `Unschedule ${name}?`;
    if (type === 'loadQueue')
      message = `Unschedule ${name}? This will also remove it from the Load Queue.`;
    else if (type === 'machineLoad')
      message = `Unschedule ${name}? This will also unload it from the Machine.`;

    doConfirm(message, () => {
      api.post(`/scheduling/machine-scheduling/${scheduledItemId}/`, {unscheduleFromMachine: true, type: type})
        .then((resp: AxiosResponse) => {
          console.log('remove scheduled item resp :', resp)
          doAlert(resp.data.message, resp.data.level ? resp.data.level : 'success', true);
          successCallback();
        })
        .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
    })
  })
}
/**
 * assignToArm
 * @param orderItemId: order id (either scheduled or loadqueue order type)
 * @param assignData: contains list of arm ids and mold counts
 * @param move: moving from other load queue?
 * @param callback
 */
const assignToArm = (orderItemId: number | undefined, assignData: any, move: boolean, callback: (data: any) => void) => {
  api.post(`/scheduling/machine-scheduling/${orderItemId}/`, {...assignData, assignToArm: true, move: move})
    .then((resp: AxiosResponse) => {
      console.log('assign to arm resp :', resp)

      if (resp.data.status === 'success')
        doAlert(resp.data.message, 'success', true)

      callback(resp.data)
    })
    .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
}
/**
 * unassignFromArm
 * @param orderItemId: load queue order id
 * @param armId: arm load queue to remove from
 * @param successCallback
 */
const unassignFromArm = (orderItemId: number | undefined, armId: number | undefined, successCallback: () => void, type?: string) => {

  getName(orderItemId, type ? type : 'loadQueue', (name: string, machineId: number | undefined) => {
    doConfirm(`Unassign ${name} from arm?`, () => {
      api.post(`/scheduling/machine-scheduling/${orderItemId}/`,
        {removeFromArm: true, type: type ? type : 'loadQueue', machineId: machineId})
        .then((resp: AxiosResponse) => {
          console.log(`doAlert("${resp.data.message}", "${resp.data.level}", true)`)
          doAlert(resp.data.message, resp.data.level ? resp.data.level : 'success', true);
          successCallback();
        })
        .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
    })
  })

}
/**
 * loadToMachine
 * @param machineName: name of machine to load to
 * @param orderItemId: order id (loadqueue type) to load
 * @param successCallback
 * @param errorCallback
 */
const loadToArm = (data: any, callback: (data: any) => void) => {

  console.log('load to arm :', data)

  apiPost(`/scheduling/arm-loading/${data.orderId}/`, {...data, loadToArm: true}, (resp: AxiosResponse) => {
    callback(resp.data)
  })

  // if (orderItemId)
  //   api.get(`/scheduling/arm-loading/${orderItemId}/?getOrderName=${true}&type=${type}`)
  //     .then((resp: AxiosResponse) => {
  //       console.log('load to machine 1')
  //       doConfirm(`Load ${resp.data.name} to ${machineName}?`, () => {
  //         console.log('load to machine 2')
  //
  //         api.post(`/scheduling/arm-loading/${orderItemId}/`, {loadToMachine: true})
  //           .then((resp: AxiosResponse) => {
  //             if (successCallback) successCallback();
  //             doAlert(resp.data.message, 'success', true);
  //           })
  //           .catch((err: AxiosError) => {if (err.response) {
  //             doAlert(err.response.data.message, 'error', true)
  //             if (errorCallback) errorCallback()
  //           }})
  //
  //       })
  //     })
  //     .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
}

/**
 * doAssignLoadAfter
 * @param orderId: order that is to be loaded after
 * @param loadAfterId: order that will be unloaded
 * @param loadAfterType: order type that will be unloaded (loadQueue/machineLoad)
 * @param successCallback
 */
const assignLoadAfter = (orderId: number | undefined, loadAfterId: number | undefined, loadAfterType: string | undefined,
                           successCallback?: () => void) => {
  api.post(`/scheduling/enhanced-loadqueue/${orderId}/`, {loadAfterId: loadAfterId, loadAfterType: loadAfterType})
    .then((resp: AxiosResponse) => {if (successCallback) successCallback()})
}
/**
 * removeLoadAfter
 * @param loadAfterOrderId: load after order id to remove
 * @param machineLoadedId: machine loaded id to remove from
 * @param successCallback
 */
const removeLoadAfter = (loadAfterOrderId: number | undefined, machineLoadedId: number | undefined,
                         successCallback?: (removeId: number) => void) => {
  api.post(`/scheduling/enhanced-loadqueue/${loadAfterOrderId}/`, {machineLoadedId: machineLoadedId, removeLoadAfter: true})
    .then((resp: AxiosResponse) => {if (successCallback) successCallback(resp.data.id)})
    .catch(() => {})
}
/**
 * updateMoldLoadQuantity - updates a load queue mold quantity
 */
const updateMoldLoadQuantity = (loadQueueOrderId: number, quantity: number, successCallback: () => void) => {
  api.post(`/scheduling/machine-scheduling/${loadQueueOrderId}/`, {updateMoldQuantity: quantity})
    .then((resp: AxiosResponse) => {
      console.log('update mold load quantity resp :', resp)
      successCallback()
    })
    .catch((err: AxiosError) => {if (err.response) doAlert(err.response.data.message, 'error', true)})
}

export {
  getName,
  getArmsFromLoadQueueId,
  getLoadAfterOptions,
  getUpdateMoldQuantityData,
  scheduleToMachine,
  assignToArm,
  loadToArm,
  unassignFromArm,
  removeScheduledItem,
  assignLoadAfter,
  removeLoadAfter,
  updateMoldLoadQuantity
}
