import {apiGet, apiPost} from "../../../../utils/api";

const URL: string = '/orders/orders'

const getData = (callback: (data: any) => void) => {
  apiGet(URL, {getData: true}, (resp: any) => callback(resp.data))
}

/**
 * @param id: order id
 * @param type: order type (either molding=1, secondary=2, foaming=3)
 * @param callback
 */
const getOrderData = (id: number, type: number, callback: (data: any) => void) => {
  apiGet(`${URL}/${id}`, {type: type}, (resp: any) => callback(resp.data))
}

const doSetOrderStatus = (id: number, status: number, callback: (data: any) => void) => {
  apiPost(`${URL}/${id}/`, {status: status, updateStatus: true}, (resp: any) => callback(resp.data))
}

/**
 * @param type: order type (either molding=1, secondary=2, foaming=3)
 * @param data: log production data
 * @param callback
 */
const logProduction = (type: number, data: any, callback: (data: any) => void) => {
  apiPost(`${URL}/`, {logProduction: true, type: type, ...data}, (resp :any) => callback(resp.data))
}

export {
  getData, getOrderData, doSetOrderStatus, logProduction
}