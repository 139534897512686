// react imports
import React, { FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// MUI imports
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import { AxiosResponse } from 'axios'
import {
	IconButton, Toolbar, Divider, List, ListItem, ListItemIcon, ListItemText, Box, Collapse, Backdrop,
	ListItemButton, CircularProgress, Fade
} from '@mui/material/'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import MuiDrawer from '@mui/material/Drawer'
// Icon imports
import Menu from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronLeft'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
// Nav icons
import Dashboard from '@mui/icons-material/Dashboard'
import Input from '@mui/icons-material/Input'
import Cached from '@mui/icons-material/Cached'
import EngineeringIcon from '@mui/icons-material/Engineering'
import BubbleChart from '@mui/icons-material/BubbleChart'
import Build from '@mui/icons-material/Build'
import LocalShipping from '@mui/icons-material/LocalShipping'
import Assessment from '@mui/icons-material/Assessment'
import Summarize from '@mui/icons-material/Summarize'
import EventNote from '@mui/icons-material/EventNote'
import People from '@mui/icons-material/People'
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings'
import ChevronRight from '@mui/icons-material/ChevronRight'
import AutoAwesome from '@mui/icons-material/AutoAwesome'
import Factory from '@mui/icons-material/Factory'
// Custom import
import Body from '../body/body'
import AccountMenu from './accountMenu'
import { getAccountId } from '../../utils/auth'
import { api, useEffectApi } from '../../utils/globals'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import BaseTooltip from '../../utils/globalComps/BaseTooltip'
import { getAccountData } from './navHeaderApi'

// ======================================================= copy pasted from MUI docs for the permanent sidebar
const drawerWidth: number = 240
const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(5)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
})

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for pages to be below app bar
	...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
)
// ============================================================================================ end copy paste

interface NavigationProps {
	openDrawer: () => void
	closeDrawer: () => void
	drawerIsOpen: boolean
	drawerStickOpen: boolean
}

/**
 * The sidebar navigation comp
 * @param openDrawer opens the parent component drawer
 * @param closeDrawer closes the parent component drawer
 * @param drawerIsOpen track drawer open status, when closed, close all opened nav lists
 * @param drawerStickOpen
 */
const Navigation: FC<NavigationProps> = ({ openDrawer, closeDrawer, drawerIsOpen, drawerStickOpen }) => {
	const [loadingOpen, setLoadingOpen] = useState<boolean>(false)
	const [moldingOpen, setMoldingOpen] = useState<boolean>(false)
	const [secondaryOpen, setSecondaryOpen] = useState<boolean>(false)
	const [foamingOpen, setFoamingOpen] = useState<boolean>(false)
	const [assemblyOpen, setAssemblyOpen] = useState<boolean>(false)
	const [accountingOpen, setAccountingOpen] = useState<boolean>(false)
	const [reportingOpen, setReportingOpen] = useState<boolean>(false)
	const [schedulingOpen, setSchedulingOpen] = useState<boolean>(false)
	const [managementOpen, setManagementOpen] = useState<boolean>(false)
	const [servicesOpen, setServicesOpen] = useState<boolean>(false)
	const [qualityOpen, setQualityOpen] = useState<boolean>(false)
	const [facilityOpen, setFacilityOpen] = useState<boolean>(false)
	const [navList, setNavList] = useState<any>([])

	const toggleFunc = (toggle: ((val: boolean) => void) | undefined, val: boolean | undefined) => {
		val = val === undefined ? false : val
		// if toggling open, open the drawer as well
		if (!val)
			drawerIsOpen = true
		openDrawer()
		if (toggle) toggle(!val)
	}

	useEffect(() => {
		let isActive = true

		// console.log(`navigation open=${drawerIsOpen}`)

		api.get(`/main/pages/`)
			.then((resp: AxiosResponse) => {
				if (isActive) setNavList(resp.data)
			})

		return () => { isActive = false }
	}, [drawerIsOpen])

	const toggleFuncs = [
		{ name: 'loading', func: setLoadingOpen, val: loadingOpen },
		{ name: 'molding', func: setMoldingOpen, val: moldingOpen },
		{ name: 'secondary', func: setSecondaryOpen, val: secondaryOpen },
		{ name: 'foaming', func: setFoamingOpen, val: foamingOpen },
		{ name: 'assembly', func: setAssemblyOpen, val: assemblyOpen },
		{ name: 'accounting', func: setAccountingOpen, val: accountingOpen },
		{ name: 'reporting', func: setReportingOpen, val: reportingOpen },
		{ name: 'scheduling', func: setSchedulingOpen, val: schedulingOpen },
		{ name: 'management', func: setManagementOpen, val: managementOpen },
		{ name: 'services', func: setServicesOpen, val: servicesOpen },
		{ name: 'quality', func: setQualityOpen, val: qualityOpen },
		{ name: 'factory', func: setFacilityOpen, val: facilityOpen },
	]
	const getToggleFunc = (name: string) => {
		for (const item of toggleFuncs)
			if (item.name === name) return item.func
	}
	const getToggleVal = (name: string) => {
		for (const item of toggleFuncs)
			if (item.name === name)
				return item.val
	}
	const icons = [
		{ name: 'dashboard', icon: <Dashboard /> },
		{ name: 'loading', icon: <Input /> },
		{ name: 'molding', icon: <Cached /> },
		{ name: 'secondary', icon: <EngineeringIcon /> },
		{ name: 'foaming', icon: <BubbleChart /> },
		{ name: 'assembly', icon: <Build /> },
		{ name: 'shipping', icon: <LocalShipping /> },
		{ name: 'accounting', icon: <Summarize /> },
		{ name: 'reporting', icon: <Assessment /> },
		{ name: 'scheduling', icon: <EventNote /> },
		{ name: 'management', icon: <People /> },
		{ name: 'services', icon: <AdminPanelSettings /> },
		{ name: 'quality', icon: <AutoAwesome /> },
		{ name: 'factory', icon: <Factory /> },
	]
	const getIcon = (name: string) => {
		for (const icon of icons)
			if (icon.name === name) return icon.icon
	}

	const doCloseDrawer = () => {
		console.log('drawer stick open :', drawerStickOpen)
		if (!drawerStickOpen) {
			closeDrawer()
			for (const toggleFunc of toggleFuncs)
				toggleFunc.func(false)
		}
	}

	/**
	 * navDropdown subcomponent
	 * @param nav
	 * @param index
	 */
	const navDropdown: FC = (nav: any, index: number) => {
		return (
			<div key={index}>
				<ListItem button title={nav.name}
					onClick={() => toggleFunc(getToggleFunc(nav.icon_text), getToggleVal(nav.icon_text))}>
					<ListItemIcon>{getIcon(nav.icon_text)}</ListItemIcon>
					<ListItemText primary={nav.name} />
					{getToggleVal(nav.icon_text) ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={drawerIsOpen && getToggleVal(nav.icon_text)} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{nav.page_set.map((nav: any, index: number) => (
							<ListItemButton title={nav.name} component={Link} to={nav.location} onClick={doCloseDrawer} key={index}>
								<ListItemIcon sx={{ marginRight: '-1rem' }}><ChevronRight /></ListItemIcon>
								<ListItemText primary={nav.name} />
							</ListItemButton>
						))}
					</List>
				</Collapse>
			</div>
		)
	}
	/**
	 * navLink subcomponent
	 * @param nav
	 * @param index
	 */
	const navLink: FC = (nav: any, index: number) => {
		return (
			<div key={index}>
				<ListItemButton title={nav.name} component={Link} to={nav.location}>
					{nav.icon_text ? <ListItemIcon>{getIcon(nav.icon_text)}</ListItemIcon> : <></>}
					<ListItemText primary={nav.name} />
				</ListItemButton>
			</div>
		)
	}

	return (
		<List>
			{navList.map((nav: any, index: number) => {
				return (
					<div key={`outer-${index}`}>
						{nav.page_set ? navDropdown(nav, index) : navLink(nav, index)}
						{nav.name === 'Quality' ? <Divider /> : <></>}  {/* Append divider after app process links */}
					</div>
				)
			})}
		</List>
	)
}

interface NavHeaderProps {
	doLogout: () => void
	setTheme: (theme: string) => void
}
/**
 * Drawerheader comp
 * @constructor
 */
const NavHeader: FC<NavHeaderProps> = ({ doLogout, setTheme }) => {
	const theme = useTheme()

	const [accountMenuOpen, setAccountMenuOpen] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)
	const [account, setAccount] = useState<any | undefined>(undefined)
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

	const handleDrawerOpen = () => {
		console.log(`setDrawerOpen(${true}) drawer actually open=${true || account?.drawer_open}`)
		setDrawerOpen(true)
	}
	const handleDrawerClose = () => {
		console.log(`setDrawerOpen(${false}) drawer actually open=${false || account?.drawer_open}`)
		setDrawerOpen(false)
	}
	const toggleDrawerOpen = () => setDrawerOpen(!drawerOpen)
	const handleAccountMenuToggle = (state: boolean) => setAccountMenuOpen(state)

	useEffectApi(() => {
		const accountId: string | null = getAccountId()

		if (accountId && parseInt(accountId))
			getAccountData(parseInt(accountId), (account: any) => {
				setTheme(account.dark_mode ? 'dark' : 'light')
				setDrawerOpen(account.drawer_open)
				setAccount(account)
				setLoading(false)
			})

	}, [setTheme, setLoading, setDrawerOpen])

	return (
		<Box sx={{ display: 'flex' }}>
			{loading
				? <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
					<CircularProgress color='inherit' />
				</Backdrop>
				: <>
					<AppBar position='fixed' open={account?.drawer_open}>
						<Toolbar style={{ justifyContent: 'space-between' }}>
							<IconButton size='medium' edge='start' color='inherit' aria-label='menu' title='Display menu'
								onClick={toggleDrawerOpen}>
								<Menu />
							</IconButton>

							<Grid container justifyContent='center'>
								<Fade in={!!account?.factory}>
									<Grid item xs={6} sx={{ textAlign: 'center' }}>
										<BaseTooltip text='Factory'>
											<Typography variant='h6'>{account?.factory ? account?.factory : '-'}</Typography>
										</BaseTooltip>
									</Grid>
								</Fade>
								<Fade in={!!account?.shift}>
									<Grid item xs={6} sx={{ textAlign: 'center' }}>
										<BaseTooltip text='Shift'>
											<Typography variant='h6'>{account?.shift ? account?.shift : '-'}</Typography>
										</BaseTooltip>
									</Grid>
								</Fade>
							</Grid>

							<BaseTooltip text='Display account menu'>
								<IconButton size='medium' edge='start' color='inherit' aria-label='account'
									onClick={() => handleAccountMenuToggle(true)}>
									<AccountCircleIcon />
								</IconButton>
							</BaseTooltip>
						</Toolbar>
					</AppBar>
					<Drawer
						anchor='left'
						variant='permanent'
						open={drawerOpen || account?.drawer_open}
						onClose={handleDrawerClose}
					>
						<DrawerHeader>
							<IconButton onClick={handleDrawerClose}>
								{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
							</IconButton>
						</DrawerHeader>
						<Divider />
						<Navigation openDrawer={handleDrawerOpen} closeDrawer={handleDrawerClose} drawerIsOpen={drawerOpen || account?.drawer_open}
							drawerStickOpen={account?.drawer_open} />
					</Drawer>
					<Box component='main' sx={{ flexGrow: 1, p: 3 }}>
						<DrawerHeader />
						<AccountMenu
							doLogout={doLogout}
							isOpen={accountMenuOpen}
							changeTheme={setTheme}
							triggerParentUpdate={() => handleAccountMenuToggle(false)}
							navOpen={toggleDrawerOpen}
							dark={account?.dark_mode}
							drawerOpen={drawerOpen}
							userName={account?.username}
							isSuperUser={account?.is_superuser}
							isAdmin={account?.is_admin}
							setDrawerStickOpen={(open: boolean) => { console.log('drawer stick open old :', account?.drawer_open, 'new :', open) }}
							labelPrintingEnabled={true}
						/>
						<Body />
					</Box>
				</>
			}
		</Box>
	)
}

export default NavHeader
