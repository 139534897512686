import React, { FC, useState } from 'react'
// mui imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
// custom imports
import { exportMRPData, getBaseData, MACHINE } from './MrpApi'
import { useEffectApi } from '../../../../utils/globals'
import { doConfirm} from '../../../../utils/modals/Confirm'
import { downloadExportFile, getExportFilename } from '../../../../utils/api'
import MachineForm from './MachineForm'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion'
import MachineTable from './MachineTable'
import InfoText from '../../../../utils/globalComps/InfoText'

interface MachineProps {
}
const Machine: FC<MachineProps> = ({}) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [machines, setMachines] = useState<any[]>([])
  const [refreshMachineTable, setRefreshMachineTable] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<any>(undefined)

  const doRefreshMachineTable = () => setRefreshMachineTable(!refreshMachineTable)

  useEffectApi(() => {

    document.title = 'Machine MRP | RotoEdgePro'

    getBaseData(MACHINE, (data: any) => {
      setMachines(data.machines)
      setPermissions(data.permissions)
      setLoading(false)
    })
  }, [])

  const handleExpand = (e: any, expanded: boolean) => {
    if (expanded)
      doRefreshMachineTable()
  }

  const doExport = (type: number) => {
    doConfirm(`Export all ${type === 1 ? 'Powder' : 'Component'} MRPs?`, () => exportMRPData(MACHINE, type, (resp: any) => {
      downloadExportFile(resp.data, getExportFilename(resp.headers))
    }))
  }

  return (
    <>
      <PageTitle title='Machine MRP' />
      <BaseContent loading={loading}>
        <Grid container spacing={2}>

          <Grid container item justifyContent='right'>
            <Button variant='contained' sx={{mr: 1}} onClick={() => doExport(1)}>Export Powder</Button>
            <Button variant='contained' onClick={() => doExport(2)}>Export Components</Button>
          </Grid>

          <Grid item sx={{width: '100%'}}>
            <InfoText text='Reports will automatically clear 24hrs after they have been generated.' />
            {machines.map((machine: any, key: number) => (
              <BaseAccordion title={machine.name} key={key} onChange={handleExpand}>
                <Grid container>
                  <Grid item xs={12} sx={{textAlign: 'center', mb: '20px'}}>
                    <MachineTable id={machine.id} name={machine.name} refresh={refreshMachineTable} canEdit={permissions?.edit} />
                  </Grid>
                  {permissions?.edit ?
                    <Grid item xs={12} sx={{textAlign: 'center'}}>
                      <MachineForm id={machine.id} name={machine.name} refreshTable={doRefreshMachineTable} />
                    </Grid>
                    :
                    <></>
                  }
                </Grid>
              </BaseAccordion>
            ))}
          </Grid>

        </Grid>
      </BaseContent>
    </>
  )
}

export default Machine
