import React, {FC} from 'react'
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import ApiForm from "../../../../utils/form/ApiForm";
import {FormSpacing} from "../../../../utils/globals";
import FormInputText from "../../../../utils/form/FormInputText";
import FormInputSwitch from "../../../../utils/form/FormInputSwitch";

interface NonconformingCodeFormProps {
  id: number | undefined
  open: boolean
  closeForm: (submit: boolean, data: any, create: boolean, createdId: number | undefined) => void
}
const NonconformingCodeForm: FC<NonconformingCodeFormProps> = ({id, open, closeForm}) => {
  return (
    <ApiForm
      name='Non-conforming Code Form'
      open={open}
      defaultValues={{}}
      closeForm={closeForm}
      id={id}
      fetchUrl={id ? `/inventory/qa-codes/${id}/` : ''}
      submitUrl={'/inventory/qa-codes/'}
    >
      <Grid container spacing={FormSpacing}>
        <Grid item md={3}><FormInputText name='code_name' label='Name' /></Grid>
        <Grid item md={9}><FormInputText name='description' label='Description' /></Grid>
        <Grid item xs><FormInputSwitch name='molding_enabled' label='Molding' /></Grid>
        <Grid item xs><FormInputSwitch name='secondary_enabled' label='Secondary' /></Grid>
        <Grid item xs><FormInputSwitch name='foaming_enabled' label='Foaming' /></Grid>
        <Grid item xs><FormInputSwitch name='assembly_enabled' label='Assembly' /></Grid>
        <Grid item xs><FormInputSwitch name='counted' label='Counted' /></Grid>
      </Grid>
    </ApiForm>
  )

}

export default NonconformingCodeForm