// useWebsocket.tsx

import { useRef, useState, useEffect, useCallback } from "react";
import { WSUrl } from "../../utils/globals"; // Ensure this path is correct
import usePageState from "./usePageState";
import { AuthService } from "../../utils/auth"; // Import AuthService

type DataCallback = (data: any) => void;

interface WebsocketHookReturn {
	ws: WebSocket | null;
	loading: boolean;
	isReady: boolean;
	sendMessage: (message: string, data?: { [key: string]: any }, refresh?: boolean) => void;
	isVisible: boolean;
	setPageState: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}

// Toggle debug logging
const debug = false;

/**
 * Custom hook to handle WebSocket connection with page state persistence.
 *
 * @param url - The WebSocket URL endpoint.
 * @param setData - Callback function to handle incoming data.
 * @param initialData - Initial data to send upon WebSocket connection.
 * @returns Object containing WebSocket controls and states.
 */
const useWebsocket = (
	url: string,
	setData: DataCallback,
	initialData: { [key: string]: any } = {}
): WebsocketHookReturn => {
	const [pageState, setPageState] = usePageState<{ [key: string]: any }>("websocket_pageState_" + url, {});
	const [isVisible, setIsVisible] = useState<boolean>(true);
	const ws = useRef<WebSocket | null>(null);
	const retryTimeout = useRef<NodeJS.Timeout | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [isReady, setIsReady] = useState<boolean>(false); // New state for tracking WebSocket readiness

	// Track if component is mounted
	const isMounted = useRef<boolean>(false);

	// Initialize AuthService
	const authService = new AuthService();

	/**
	 * Sends a message through the WebSocket connection.
	 *
	 * @param message - The message type to send.
	 * @param data - Additional data to send with the message.
	 * @param refresh - Whether to refresh the data.
	 */
	const sendMessage = useCallback(
		(message: string, data: { [key: string]: any } = {}, refresh: boolean = false) => {
			if (ws.current && ws.current.readyState === WebSocket.OPEN) {
				const messageBundle = {
					message,
					refresh,
					...data,
				};
				ws.current.send(JSON.stringify(messageBundle));
				if (debug) console.log("Message sent:", messageBundle);
			} else {
				console.error("WebSocket is not open");
			}
		},
		[]
	);

	/**
	 * Establishes a WebSocket connection.
	 */
	const connect = useCallback(() => {
		if (ws.current && (ws.current.readyState === WebSocket.OPEN || ws.current.readyState === WebSocket.CONNECTING)) {
			return; // Prevent multiple connections
		}

		const token = authService.getToken();
		const wsUrl = token ? `${WSUrl}${url}?token=${token}` : `${WSUrl}${url}`;

		ws.current = new WebSocket(wsUrl);

		ws.current.onopen = () => {
			if (!isMounted.current) return; // Prevent state update if unmounted
			if (debug) console.log("WebSocket Connected");
			setLoading(false);
			setIsReady(true); // Set ready state to true

			// Send initial data if present
			if (Object.keys(initialData).length > 0) {
				sendMessage("init_data", { init_data: initialData });
				if (debug) console.log("Initial data sent:", initialData);
			}

			// Send page state if present
			if (Object.keys(pageState).length > 0) {
				sendMessage("init_page", { page_state: pageState });
				if (debug) console.log("Page state sent:", pageState);
			}
		};

		ws.current.onmessage = (event: MessageEvent) => {
			if (!isMounted.current) return; // Prevent state update if unmounted
			try {
				const receivedData = JSON.parse(event.data);
				setData(receivedData);
				if (debug) console.log("Data received:", receivedData);
			} catch (error) {
				console.error('Failed to parse WebSocket message:', error);
			}
		};

		ws.current.onclose = (event) => {
			if (!isMounted.current) return; // Prevent state update if unmounted
			if (debug) console.log("WebSocket Disconnected");
			setIsReady(false); // Set ready state to false
			if (!event.wasClean) {
				console.log(`WebSocket Disconnected unexpectedly: ${event.code}`);
				setLoading(true);
				retryConnection();
			}
		};

		ws.current.onerror = (error: Event) => {
			if (!isMounted.current) return; // Prevent state update if unmounted
			console.error("WebSocket error:", error);
			setIsReady(false); // Set ready state to false
			ws.current?.close();
		};
	}, [url, pageState, sendMessage, setData]);

	/**
	 * Attempts to reestablish the WebSocket connection.
	 */
	const retryConnection = useCallback(() => {
		if (retryTimeout.current) {
			clearTimeout(retryTimeout.current);
		}
		retryTimeout.current = setTimeout(() => {
			console.log("Attempting to reestablish the WebSocket connection...");
			connect();
		}, 1000);
	}, [connect]);

	useEffect(() => {
		isMounted.current = true; // Component is mounted

		/**
		 * Handles visibility change events to manage the WebSocket connection.
		 */
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				if (debug) console.log("Tab is now active. Page state:", pageState);
				setIsVisible(true);
				if (!ws.current || ws.current.readyState === WebSocket.CLOSED) {
					connect();
				}
			} else {
				if (debug) console.log("Tab is now inactive");
				setIsVisible(false);
				if (ws.current) {
					ws.current.close();
				}
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);
		connect(); // Initial connection

		return () => {
			isMounted.current = false; // Component is unmounted
			if (ws.current) {
				ws.current.close();
			}
			if (retryTimeout.current) clearTimeout(retryTimeout.current);
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, [connect, pageState]);

	return {
		ws: ws.current,
		loading,
		isReady, // Return the ready state
		sendMessage,
		isVisible,
		setPageState,
	};
};

export default useWebsocket;
