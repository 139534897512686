import React, {FC, useEffect, useState} from 'react'
import BaseModal from "./BaseModal";
import RotoScheduler from "../globalComps/RotoScheduler/RotoScheduler";

interface SchedulerModalProps {
  id?: number | undefined
  name: string
  refresh?: boolean | undefined
  readOnly?: boolean | undefined
  onClose: () => void
}
const SchedulerModal: FC<SchedulerModalProps> = ({id, name, refresh, readOnly,
                                                   onClose}) => {
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setOpen(id !== undefined)
  }, [id, refresh])

  return (
    <BaseModal
      title={`${name} Schedule`}
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      maxWidth='xl'
    >
      {id && refresh !== undefined ?
        <RotoScheduler armId={id} refresh={refresh} armChoices={[]} readOnly={readOnly ? readOnly : false} />
        :
        <></>}
    </BaseModal>
  )
}

export default SchedulerModal
