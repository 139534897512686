import React, { useState, useMemo } from 'react';
import {
	Dialog, DialogTitle, DialogContent, DialogActions,
	Button, TextField, IconButton, Tooltip, FormControl, InputLabel, Chip, Box, FormHelperText, Autocomplete, Paper
} from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MaintenanceRecord, MaintenanceType } from '../hooks/Interfaces';
import { SendMessageFunction } from '../../../../../websocket/hooks/types';

interface MaintenanceFormDialogProps {
	selectedRecord: MaintenanceRecord | null;
	machineOrMold: string;
	sendMessage: SendMessageFunction;
	maintenanceTypes: MaintenanceType[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MaintenanceFormDialog: React.FC<MaintenanceFormDialogProps> = ({ selectedRecord, machineOrMold, sendMessage, maintenanceTypes }) => {
	const [open, setOpen] = useState(false);
	const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
	const [description, setDescription] = useState<string>('');
	const [typeError, setTypeError] = useState<boolean>(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedTypes([]);
		setDescription('');
		setTypeError(false);
	};

	const handleSubmit = () => {
		if (selectedRecord) {
			if (selectedTypes.length === 0) {
				setTypeError(true);
				return;
			}
			sendMessage('submit_maintenance_form', {
				machine_or_mold: machineOrMold,
				record_id: selectedRecord.id,
				types: selectedTypes,
				description: description
			}, true);
		}
		handleClose();
	};

	const handleTypeChange = (event: React.ChangeEvent<{}>, value: string[]) => {
		setSelectedTypes(value);
		if (value.length > 0) {
			setTypeError(false);
		}
	};

	// Filter maintenance types based on machineOrMold
	const filteredMaintenanceTypes = useMemo(() => {
		return maintenanceTypes.filter(type => machineOrMold === 'Mold' ? type.for_mold : type.for_machine).map(type => type.type);
	}, [maintenanceTypes, machineOrMold]);

	return (
		<>
			<Tooltip title="Log Maintenance" arrow>
				<IconButton
					onClick={handleOpen}
					color='primary'
					sx={{ border: 1 }}
					// sx={{ backgroundColor: 'primary.main', color: 'white, '&:hover': { backgroundColor: 'primary.dark' } }}
				>
					<ConstructionIcon />
				</IconButton>
			</Tooltip>
			<Dialog open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
				<DialogTitle>{selectedRecord ? `${selectedRecord.name}` : ''} Maintenance Form</DialogTitle>
				<DialogContent dividers>
					<FormControl fullWidth margin="normal" variant="outlined" error={typeError}>
						<Autocomplete
							multiple
							options={filteredMaintenanceTypes}
							getOptionLabel={(option) => option}
							value={selectedTypes}
							onChange={handleTypeChange}
							renderTags={(value: string[], getTagProps) =>
								value.map((option: string, index: number) => (
									<Chip label={option} {...getTagProps({ index })} />
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Type"
									placeholder="Select maintenance type"
								/>
							)}
							PaperComponent={({ children }) => (
								<Paper sx={{ maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, overflow: 'auto' }}>
									{children}
								</Paper>
							)}
						/>
						{typeError && <FormHelperText>Please select at least one type.</FormHelperText>}
					</FormControl>
					<TextField
						label="Description"
						fullWidth
						margin="normal"
						multiline
						rows={4}
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</DialogContent>
				<DialogActions sx={{ padding: 2, justifyContent: 'space-between' }}>
					<Button onClick={handleClose} variant="outlined" color="error">Cancel</Button>
					<Button onClick={handleSubmit} variant="contained" color="primary">Submit</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default MaintenanceFormDialog;
