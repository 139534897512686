import React, { FC } from 'react';
// mui imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from "@mui/material/Grid";
// custom imports

interface DataProgressBarProps {
  labelText: string;
  value: number;
}
const DataProgressBar: FC<DataProgressBarProps> = ({labelText, value}) => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Grid container justifyContent='center'>
        <Grid item sx={{width: '100%'}}>
          <LinearProgress variant='determinate' value={value} sx={
            {height: '18px', borderRadius: '10px', border: '2px solid black'}
          } />
        </Grid>
        <Grid item sx={{pt: '10px'}}>
          <Typography variant='body2'>
            {labelText}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DataProgressBar;
