// ScrapSummary.tsx
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Box, Typography, useTheme, Grid } from '@mui/material/';
import useFinancialSummary from './useFinancialSummary';
import { useTimePeriod } from '../../TimePeriodContext';
import ProjectedSalesChart from '../../charts/ProjectedSalesChart';
import ScrapSalesChart from '../../charts/ScrapSalesChart';

const FinancialSummary: FunctionComponent = () => {
	const theme = useTheme();
	const { timePeriod } = useTimePeriod();
	const { projectedSales, scrapSales, machines } = useFinancialSummary(timePeriod)

	return (
		<Box sx={{ flex: 1, pt: 3, px: 3, pb: 1, border: 2, borderRadius: 3, borderColor: theme.palette.primary.main, boxShadow: 1 }}>
			<Typography variant='h4' sx={{ textAlign: 'center', mb: 3 }}>Financial Summary</Typography>
			<Grid container>
				<Grid item xs={12} md={8}>
					<ProjectedSalesChart 
						machines={machines} 
						projectedSales={projectedSales}
						height={350}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<ScrapSalesChart 
						scrapSales={scrapSales}
						height={350}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default FinancialSummary;
