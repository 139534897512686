import React, { FC, useState, useEffect } from 'react';
// mui imports
// mui icon imports
// custom imports
import BaseModal from "../BaseModal";
import { getHasChannel, getMessages } from "./ChannelModalApi";
import ChannelMessage from "./ChannelMessage";
import ChannelMessageForm from "./ChannelMessageForm";
import Typography from "@mui/material/Typography";


interface ChannelModalProps {
  armId?: number | undefined;
  linePlanId?: number | undefined;
  channelClose: () => void;
  reloadChannelDisplay?: () => void
  shipping?: boolean | undefined
  overrideOpen?: boolean | undefined
}
const ChannelModal: FC<ChannelModalProps> = ({armId, linePlanId, channelClose,
                                               reloadChannelDisplay, shipping,
                                               overrideOpen}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [channelName, setChannelName] = useState<string>('');
  const [channelMessages, setChannelMessages] = useState<any[]>([]);
  const [messageFormOpen, setMessageFormOpen] = useState<boolean>(false);
  const [messageFormId, setMessageFormId] = useState<number | undefined>(undefined);
  const [channelId, setChannelId] = useState<number | undefined>(undefined);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<any>(undefined)

  useEffect(() => {
    let isActive = true;

    if ((armId !== undefined || linePlanId !== undefined || shipping) && isActive) {
      getHasChannel(armId, linePlanId, shipping, (data: any) => {
        setChannelName(data.channel_name);
        if (data.channel_id !== undefined)
          setChannelId(data.channel_id);
        setOpen(data.channel_name !== '');

        setPermissions(data.permissions)
        console.log('permissions :', data.permissions)

        getMessages(armId, linePlanId, shipping, (messages: any[]) => setChannelMessages(messages));
      })
    }

    return () => {isActive = false};
  }, [armId, linePlanId, shipping, refresh])

  // helpers
  const reloadChannel = () => {
    console.log('reload channel :', !refresh)
    setRefresh(!refresh);
  }
  const closeHandler = () => {
    channelClose();
    setOpen(false);
  }

  // form
  const openMessageFormEdit = (id: number | undefined) => {
    console.log('open form edit!')
    setMessageFormId(id);
    setMessageFormOpen(true);
  }
  const openMessageForm = () => setMessageFormOpen(true);
  const closeMessageForm = () => {
    setMessageFormOpen(false);
    setMessageFormId(undefined);
    reloadChannel()
    if (reloadChannelDisplay) reloadChannelDisplay()
  }

  const getActions = () => {
    const actions: any[] = []

    if (permissions?.create)
      actions.push({text: 'New Message', action: openMessageForm, left: true})

    return actions
  }

  return (
    <>
      <BaseModal
        title={`Channel Messaging - ${channelName}`}
        open={open}
        closeHandler={closeHandler}
        closeButton
        dividers
        maxWidth='md'
        actions={getActions()}
      >
        <>
          {channelMessages.length > 0 ? channelMessages.map((channelMessage: any, key: number) => (
            <ChannelMessage
              key={key}
              id={channelMessage.id}
              text={channelMessage.message}
              userName={channelMessage.user_name}
              createdAt={channelMessage.created}
              hidden={channelMessage.hidden}
              editedAt={channelMessage.updated}
              setEditId={openMessageFormEdit}
              reloadChannel={reloadChannel}
              editable={channelMessage.editable}
              hiddenAt={channelMessage.hidden_at}
              permissions={permissions}
            />
          )) : <Typography variant='subtitle2'>No Messages</Typography>}
        </>
      </BaseModal>
      <ChannelMessageForm id={messageFormId} open={messageFormOpen} onClose={closeMessageForm} armId={armId}
                          linePlanId={linePlanId} channelId={channelId} reloadChannel={reloadChannel} />
    </>
  );
}

export default ChannelModal;