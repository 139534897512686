import React, { FC, useEffect } from 'react'
import { arrayMoveImmutable } from 'array-move'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { AxiosError } from 'axios'
// mui import
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
// mui icons
import CircularProgress from '@mui/material/CircularProgress'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
// custom imports
import DropdownActions from '../globalComps/DropdownActions'
import TableActions from './TableActions'
import BaseRow from './BaseRow'
import ExpandableRow from './ExpandableRow'
import SearchBar from '../globalComps/SearchBar'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import './rowHighlight.css'
import { colors } from '../colors'
import { api } from '../globals'
import { doAlert } from '../globalComps/PopupAlert/PopupAlert'
import Legend from '../globalComps/Legend'
import BaseTooltip from '../globalComps/BaseTooltip'
import Paper from '@mui/material/Paper'
import InfoText from "../globalComps/InfoText";

const SortableItem = SortableElement(({key, rowData}: {rowData: any, key: number | string}) => {
  return (
    <BaseRow
      key={key}
      id={rowData.id}
      data={rowData.data}
      object={rowData.object}
      tooltip={rowData.tooltip}
      highlight={rowData.highlight}
      actions={rowData.actions}
    />
  )
})
const SortableList = SortableContainer(({rows}: any) => {
  return (
    <TableBody>
      <>
        {rows.map((rowData: any, key: any) => (
          <SortableItem key={`item-${key}`} index={key} {...{rowData: rowData}} />
        ))}
      </>
    </TableBody>
  )
})

interface BaseBodyProps {
  sortable?: boolean
  sortableUrl?: any
  expandable?: boolean
  expandableParams?: any
  setExpandedId?: (id: number | undefined) => void
  rows: any[]
  refreshObject?: (object: any, create: boolean) => void
  updateDataOrder: (data: any[]) => void
  expandableCondition?: any
  expandableParentCondition?: any
}
const BaseBody: FC<BaseBodyProps> = ({sortable, sortableUrl, expandable,
                                       expandableParams, setExpandedId, rows, refreshObject,
                                       updateDataOrder, expandableCondition, expandableParentCondition}) => {

  const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
    rows = arrayMoveImmutable(rows, oldIndex, newIndex)
    updateDataOrder(rows)
    console.log(`url=${sortableUrl.url} data :`, {oldIndex: oldIndex, newIndex: newIndex, sortParentId: sortableUrl.id})
    if (sortableUrl)
      api.post(sortableUrl.url, {oldIndex: oldIndex, newIndex: newIndex, sortParentId: sortableUrl.id})
        .catch((err: AxiosError) => {if (err.response) {doAlert(err.response.data.message, 'error', true)}})
  }

  useEffect(() => {}, [expandableParams])

  const breakColor: string = colors[useTheme().palette.mode].table.row.break
  const row = (data: any, key: number) => {
    if (expandable)
     return (
       <ExpandableRow
         key={key}
         parentRow={data}
         expandableParams={expandableParams}
         expandableCondition={expandableCondition}
         expandableParentCondition={expandableParentCondition}
       >
       </ExpandableRow>
     )
    else if (data === 'break')
      return (
        <tr key={key}>
          <td colSpan={Object.keys(rows[0].data).length + 1} style={{backgroundColor: breakColor, height: '10px'}}> </td>
        </tr>
      )
    else
      return (
        <BaseRow
          color={data.color}
          key={key}
          id={data.id}
          data={data.data}
          object={data.object}
          tooltip={data.tooltip}
          highlight={data.highlight}
          actions={data.actions}
          setExpandedId={setExpandedId}
          refreshObject={refreshObject}
        />
      )
  }

  if (sortable)
    return (
      <>
        <SortableList onSortEnd={onSortEnd} useWindowAsScrollContainer {...{rows: rows}} />
      </>
    )
  else
    return (
      <TableBody>
        {rows.length > 0 ? rows.map((rowData: any, key: number) => row(rowData, key)) : <></>}
      </TableBody>
    )
}

interface BaseTableProps {
  name: string | undefined
  titleVariant: any
  headers: any[]
  rows: any[]
  actions: any[]
  clickActions?: any[]
  search?: (q: string) => void
  searchPlaceholder?: string
  childSearch?: (q: string) => void,
  childSearchPlaceholder?: string
  orderable?: boolean
  orderableBlacklist?: string[]
  orderFunc?: (text: string, ascending: boolean) => void
  orderStatus?: any
  sortable?: boolean
  sortableUrl?: any
  expandable?: boolean
  expandableActions?: any[]
  expandableParams?: any
  setExpandedId?: (id: number | undefined) => void
  refreshObject?: (object: any, create: boolean) => void
  q: string
  childQ: string
  bottomLoading: boolean
  filterable?: boolean
  filterButtonText?: string
  filterOptions?: any[]
  updateDataOrder: (data: any[]) => void
  expandableCondition?: any
  expandableParentCondition?: any
  legend?: any[]
  totalItemCount?: number
  filterForm?: JSX.Element
  currentPage?: number
  totalPages?: number
  hidePages?: boolean | undefined
  tableHeadText?: string | undefined
}
/**
 * Base table component
 * @param name
 * @param titleVariant
 * @param sortable
 * @param headers
 * @param rows
 * @param actions
 * @param search
 * @param searchPlaceholder
 * @param expandable
 * @param expandableActions
 * @param expandableParams?
 * @param q
 * @param bottomLoading
 * @param filterable?
 * @param filterButtonText?
 * @param filterOptions?
 * @param hidePages? hides the page: #/# display at the bottom of the table
 * @param tableHeadText: text to display above the table
 */
const BaseTable: FC<BaseTableProps> = ({name, titleVariant, sortable, sortableUrl,
                                         orderable, orderFunc, orderStatus, headers, rows, actions, clickActions,
                                         search, childSearch, searchPlaceholder,
                                         childSearchPlaceholder, refreshObject,
                                         expandable, expandableParams, setExpandedId, q, childQ,
                                         bottomLoading, filterable, filterButtonText,
                                         filterOptions, updateDataOrder, orderableBlacklist,
                                         expandableCondition, expandableParentCondition, legend, totalItemCount,
                                         filterForm, currentPage, totalPages,
                                         hidePages, tableHeadText}) => {

  const theme = useTheme()

  // search bars
  let searchBar: any
  let childSearchBar: any
  if (search)
      searchBar =
        <Grid item sx={childSearch ? {marginRight: '5px'} : {}}>
          <SearchBar outlined={true} width={100} onSearch={search} q={q} defaultText={searchPlaceholder} />
        </Grid>
  else
    searchBar = <></>
  if (childSearch)
    childSearchBar =
      <Grid item>
        <SearchBar outlined={true} width={100} onSearch={childSearch} q={childQ} defaultText={childSearchPlaceholder} />
      </Grid>

  // filter dropdown
  let filterDropdown: JSX.Element
  if (filterable && filterButtonText && filterOptions && filterOptions.length > 0)
    filterDropdown =
      <DropdownActions name={filterButtonText} noMin>
        {filterOptions.map((filter: any) => (
          <MenuItem onClick={() => filter.action(filter.text)} key={filter.text} sx={{fontSize: '20px'}}>
            <ListItemText>{filter.text}</ListItemText>
          </MenuItem>
        ))}
      </DropdownActions>
  else
    filterDropdown = <></>

  // orderable headers
  const header = (text: string, key: number, tooltip: string | undefined) => {
    // console.log(`header=${text} key=${key} tooltip=${tooltip} orderable=${orderable} text=${text}`)

    orderableBlacklist = orderableBlacklist ? orderableBlacklist : []
    if (orderable && text && text !== '' && !orderableBlacklist.includes(text)) {
      // console.log('text :', text)

      const orderButtonColor: any = orderStatus && orderStatus.headerText === text ? theme.palette.primary.main : undefined
      let OrderIcon: any

      if (orderStatus && !orderStatus.ascending && orderStatus.headerText === text)
        OrderIcon = ArrowDropUp
      else
        OrderIcon = ArrowDropDown

      if (tooltip)
        return (
          <BaseTooltip key={key} text={tooltip}>
            <TableCell align='center'>
              {text}
              <IconButton size='small' onClick={() => orderFunc ? orderFunc(text, !orderStatus.ascending) : () => {}}>
                <OrderIcon sx={orderButtonColor ? {color: orderButtonColor} : {}} />
              </IconButton>
            </TableCell>
          </BaseTooltip>
        )
      else
        return (
          <TableCell align='center' key={key}>
            {text}
            <IconButton size='small' onClick={() => orderFunc ? orderFunc(text, !orderStatus.ascending) : () => {}}>
              <OrderIcon sx={orderButtonColor ? {color: orderButtonColor} : {}} />
            </IconButton>
          </TableCell>
        )
    } else {
      if (tooltip)
        return <BaseTooltip key={key} text={tooltip}><TableCell align='center' key={key}>{text}</TableCell></BaseTooltip>
      else
        return <TableCell align='center' key={key}>{text}</TableCell>
    }
  }

  // loading
  const circularLoad =
    <Box sx={{display: 'flex', marginTop: '20px'}}>
      <CircularProgress sx={{margin: '0 auto 1rem'}} color='inherit' />
    </Box>

  const backgroundColor = theme.palette.mode === 'dark' ? colors.dark.table.header : colors.light.table.header

  const pageCountHelper = () => {
    if (currentPage && !hidePages)
      return `Page: ${currentPage === 1 ? currentPage : currentPage - 1} / ${totalPages}`
  }

  return (
    <>
      {(name && name.length > 0) || (legend && legend.length > 0)  ?
        <Grid container>
          <Grid item xs={6}>{name ? <Typography variant={titleVariant}>{name}</Typography> : <></>}</Grid>
          <Grid item xs={6}>{legend ? <Legend legendItems={legend} /> : <></>}</Grid>
        </Grid>
        :
        <></>}
      {filterForm || filterable || search || childSearch ?
        <Grid container sx={(name && name.length > 0) || (legend && legend.length > 0) ? {mt: 2} : {}}>
          <Grid item xs={search || childSearch ? 6 : 12}>
            {filterDropdown} {/* TODO: Move this to filter form */}
            {filterForm ? filterForm : <></>}
            {!!totalItemCount ? <Typography>Total items: ${totalItemCount}</Typography> : <></>}
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='flex-end'>
              {searchBar}
              {childSearchBar}
            </Box>
          </Grid>
        </Grid>
        :
        <></>
      }
      <TableActions actions={actions} />
      {sortable ? <InfoText text='This table is sortable, drag and drop rows to re-order the data.' mt={10} /> : <></>}
      {tableHeadText ? <InfoText text={tableHeadText} mt={10} /> : <></>}
      <TableContainer sx={{marginTop: '15px'}} component={Paper}>
        <Table size='small'>
          <TableHead sx={{backgroundColor: backgroundColor}}>
            <TableRow>
              {typeof headers[0] === 'string' ?
                headers.map((headerText: string, key: number) => header(headerText, key, undefined))
                :
                headers.map((headerObj: any, key: number) => header(headerObj.text, key, headerObj.tooltip))
              }
            </TableRow>
          </TableHead>
          <BaseBody
            expandable={expandable}
            expandableParams={expandableParams}
            setExpandedId={setExpandedId}
            sortable={sortable}
            sortableUrl={sortableUrl}
            rows={rows}
            refreshObject={refreshObject}
            updateDataOrder={updateDataOrder}
            expandableCondition={expandableCondition}
            expandableParentCondition={expandableParentCondition}
          />
        </Table>
      </TableContainer>
      {currentPage && totalPages ?
        <Typography variant='subtitle2' sx={{pt: 1, pb: 1, textAlign: 'center'}}>
          {pageCountHelper()}
        </Typography>
        :
        <></>}
      {bottomLoading ? circularLoad : <></>}
    </>
  )
}

export default BaseTable
