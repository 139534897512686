import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import Typography from '@mui/material/Typography'

interface InfoTextProps {
  text: string
  contained?: boolean
  mt?: number
  noMb?: boolean
}
const InfoText: FC<InfoTextProps> = ({text, contained, mt, noMb}) => {

  const style = mt ? {mb: noMb ? 0 : '10px', mt: `${mt}px`} : {mb: noMb ? 0 : '10px'}

  return (
    <Grid container justifyContent='left' sx={style}>
      {contained ?
        <>
          <Grid item xs={1}><InfoOutlined sx={{fontSize: 18}} /></Grid>
          <Grid item xs={11}>
            <Typography sx={{ml: '5px', fontSize: 13}}>{text}</Typography>
          </Grid>
        </>
        :
        <>
          <InfoOutlined sx={{fontSize: 18, mt: 'auto', mb: 'auto'}} />
          <Typography sx={{ml: '5px', fontSize: 13, width: '90%'}}>{text}</Typography>
        </>}
    </Grid>
  )
}

export default InfoText
