import React, { useState, useEffect } from 'react';
import { DateRangePicker, LocalizationProvider, DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, InputAdornment, Chip, Paper, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';
import { startOfMonth, endOfToday, formatISO } from 'date-fns';
import { Search } from '@mui/icons-material';
import { PageState, Shift, Machine, Personnel } from './hooks/Interfaces';

interface AccountingFiltersProps {
	pageState: PageState;
	sendMessage: (filters: any) => void;
	shifts: Shift[];
	machines: Machine[];
	personnel: Personnel[];
	itemData: any[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const AccountingFilters: React.FC<AccountingFiltersProps> = ({ pageState, sendMessage, shifts, machines, personnel, itemData }) => {
	const [dateRange, setDateRange] = useState<DateRange<Date>>([
		startOfMonth(new Date()),
		endOfToday()
	]);

	const [selectedShift, setSelectedShift] = useState<Shift | null>(() => {
		const shift = pageState.filters.shift;
		return shift ? shift : { id: 'all', name: 'All' };
	});

	const [selectedMachine, setSelectedMachine] = useState<Machine | null>(() => {
		const machine = pageState.filters.machine;
		return machine ? machine : { id: 'all', name: 'All' };
	});

	const [itemSearch, setItemSearch] = useState<string>('');
	const [orderSearch, setOrderSearch] = useState<string>('');
	const [itemType, setItemType] = useState<string>('1');
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedPersonnel, setSelectedPersonnel] = useState<string[]>([]);

	const handleShiftChange = (event: SelectChangeEvent<string>) => {
		const shiftId = event.target.value;
		const shift = shiftId === 'all' ? { id: 'all', name: 'All' } : shifts.find(s => s.id === shiftId) || null;
		pageState.filters.shift = shift
		setSelectedShift(shift);
	};

	const handleMachineChange = (event: SelectChangeEvent<string>) => {
		const machineId = event.target.value;
		const machine = machineId === 'all' ? { id: 'all', name: 'All' } : machines.find(m => m.id === machineId) || null;
		pageState.filters.machine = machine
		setSelectedMachine(machine);
	};

	const handlePersonnelChange = (event: React.ChangeEvent<{}>, value: string[]) => {
		// pageState.filters.personnel = value
		setSelectedPersonnel(value);	
	};

	const handleItemSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		pageState.filters.item_search = event.target.value
		setItemSearch(event.target.value);
	};

	const handleOrderSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		pageState.filters.order_search = event.target.value
		setOrderSearch(event.target.value);
	};

	const handleItemTypeChange = (event: SelectChangeEvent<string>) => {
		const newType = event.target.value;
		pageState.filters.type = newType
		setItemType(newType);
	};

	const handleSubmit = () => {
		if (dateRange[0] && dateRange[1]) {
			const start = formatISO(dateRange[0], { representation: 'complete' });
			const end = formatISO(dateRange[1], { representation: 'complete' });

			setLoading(true);
			sendMessage({
				start,
				end,
				shift: selectedShift,
				machine: selectedMachine,
				personnel: personnel.filter(p => selectedPersonnel.includes(p.name)),
				item_search: itemSearch,
				order_search: orderSearch,
				item_type: itemType
			});
		}
	};

	useEffect(() => {
		setLoading(false);
	}, [itemData]);

	useEffect(() => {
		if (itemType !== '1') {
			setSelectedMachine({ id: 'all', name: 'All' });
		}
	}, [itemType]);

	const StyledDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
		'.MuiInputBase-root': {
			width: '120px'
		}
	}));

	const isSubmitDisabled = itemType === '4' && !(itemSearch || orderSearch);
	const highlightFields = itemType === '4';
	const highlightColor = '#64b5f6';

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 3 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Box sx={{ display: 'flex', gap: 2 }}>
					<FormControl sx={{ minWidth: 120 }}>
						<InputLabel id="shift-select-label">Shift</InputLabel>
						<Select
							labelId="shift-select-label"
							value={selectedShift?.id || 'all'}
							label="Shift"
							onChange={handleShiftChange}
						>
							<MenuItem value="all">All</MenuItem>
							{shifts.map((shift) => (
								<MenuItem key={shift.id} value={shift.id}>
									{shift.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ minWidth: 120 }}>
						<InputLabel id="machine-select-label">Machine</InputLabel>
						<Select
							labelId="machine-select-label"
							value={selectedMachine?.id || 'all'}
							label="Machine"
							onChange={handleMachineChange}
							disabled={itemType !== '1'}
						>
							<MenuItem value="all">All</MenuItem>
							{machines.map((machine) => (
								<MenuItem key={machine.id} value={machine.id}>
									{machine.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ minWidth: 120 }}>
						<InputLabel id="item-type-select-label">Item Type</InputLabel>
						<Select
							labelId="item-type-select-label"
							value={itemType}
							label="Item Type"
							onChange={handleItemTypeChange}
						>
							<MenuItem value="1">Moldable</MenuItem>
							<MenuItem value="2">Assembly</MenuItem>
							<MenuItem value="3">Powder</MenuItem>
							<MenuItem value="4">Component</MenuItem>
							<MenuItem value="5">Kit</MenuItem>
						</Select>
					</FormControl>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<StyledDateRangePicker
							value={dateRange}
							onChange={(newValue) => setDateRange(newValue as DateRange<Date>)}
							sx={{ maxHeight: 40 }}
						/>
					</LocalizationProvider>
					<FormControl sx={{ minWidth: 160 }} variant="outlined">
						<Autocomplete
							multiple
							options={personnel.map((p) => p.name)}
							getOptionLabel={(option) => option}
							value={selectedPersonnel}
							onChange={handlePersonnelChange}
							renderTags={(value: string[], getTagProps) =>
								value.map((option: string, index: number) => (
									<Chip label={option} {...getTagProps({ index })} />
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Personnel"
									placeholder="Select personnel"
								/>
							)}
							PaperComponent={({ children }) => (
								<Paper sx={{ maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, overflow: 'auto' }}>
									{children}
								</Paper>
							)}
						/>
					</FormControl>
				</Box>

				<Box sx={{ ml: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
					<TextField
						label="Item"
						value={itemSearch}
						onChange={handleItemSearchChange}
						variant="outlined"
						size="small"
						InputLabelProps={{
							style: {
								color: highlightFields && !itemSearch ? highlightColor : undefined
							}
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Search
										sx={{
											color: highlightFields && !itemSearch ? highlightColor : undefined,
											borderRadius: '50%',
											padding: '4px',
											border: highlightFields && !itemSearch ? `2px solid ${highlightColor}` : undefined
										}}
									/>
								</InputAdornment>
							),
							style: {
								borderRadius: '30px'
							}
						}}
					/>
					<TextField
						label="Order"
						value={orderSearch}
						onChange={handleOrderSearchChange}
						variant="outlined"
						size="small"
						InputLabelProps={{
							style: {
								color: highlightFields && !orderSearch ? highlightColor : undefined
							}
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Search
										sx={{
											color: highlightFields && !orderSearch ? highlightColor : undefined,
											borderRadius: '50%',
											padding: '4px',
											border: highlightFields && !orderSearch ? `2px solid ${highlightColor}` : undefined
										}}
									/>
								</InputAdornment>
							),
							style: {
								borderRadius: '30px'
							}
						}}
					/>
					<Button
						variant="contained"
						onClick={handleSubmit}
						disabled={loading || isSubmitDisabled}
						sx={{ maxHeight: 50 }}
					>
						{loading ? <CircularProgress size={24} /> : 'Submit'}
					</Button>
				</Box>
			</Box>

			{highlightFields && (
				<Typography color="primary" sx={{ mr: '7%', textAlign: 'right' }}>
					Item or Order search is required for component types.
				</Typography>
			)}
		</Box>
	);
};

export default AccountingFilters;
