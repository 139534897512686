import React, {FC, useEffect, useState} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// mui imports
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
// custom imports

interface FormInputDropdownProps {
  name: string,
  label: string,
  options: any
  multiple?: boolean,
  myOnChange?: (e: any) => void;
  myOnFocus?: (e: any, value: any) => void;
  myOnBlur?: (e: any, value: any) => void;
  overrideValue?: any;
  disabled?: boolean;
  helper?: string | undefined
  sx?: any | undefined
  clearable?: boolean | undefined
  blurOnChange?: boolean | undefined
}
/**
 * FormInputText comp
 * @param name
 * @param label
 * @param options
 * @param multiple
 * @param myOnChange
 * @param myOnFocus
 * @param myOnBlur
 * @param overrideValue
 * @param disabled
 * @param helper
 * @param sx
 * @param clearable: allow the select input to be clearable
 * @param blurOnChange: blurs the input on change
 * @constructor
 */
const FormInputDropdown: FC<FormInputDropdownProps> = ({name, label, options, multiple,
                                                         myOnChange, myOnFocus, myOnBlur,
																												  overrideValue, disabled,
                                                         helper, sx, clearable, blurOnChange}) => {

  const [open, setOpen] = useState<boolean>(false)
  const [firstOpen, setFirstOpen] = useState<boolean>(true)

  const { control } = useFormContext();

  useEffect(() => {
    // console.log('form input dropdown use effect :', firstOpen)
    setFirstOpen(true)
  }, [disabled])

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: '300px'
      }
    }
  }

  const getValue = (value: any) => {
    if (overrideValue)
      return overrideValue
    else if (value)
      return value
    else if (multiple)
      return []
    else
      return ''
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setFirstOpen(false)
  }
  const toggleOpen = () => setOpen(!open)
  const handleFocus = () => {
    // console.log('dropdown focus!')
    if (firstOpen)
      handleOpen()
  }
  const handleBlur = () => {
    setTimeout(() => {
      // console.log('on blur!')
      handleClose()
    }, 50)
  }

  return (

    <>
      {options.length > 0 ?
        <Controller
          name={name as never}
          control={control}
          defaultValue={undefined}
          render={({
                     field: {onChange, value, ref},
                     fieldState: {error},
                     formState,
                   }: any) => (
            <FormControl
              variant='outlined'
              size='small'
              sx={{width: '100%'}}
            >
              <InputLabel error={!!error} id={`${name}-select-label`}>
                {label}
              </InputLabel>
              <Select
                name={name}
                inputRef={ref}
                labelId={`${name}-select-label`}
                onChange={(e) => {
                  console.log('on change!')
                  onChange(e)
                  if (myOnChange) myOnChange(e)
                  if (!multiple && blurOnChange)
                    handleBlur()
                }}
                value={getValue(value)}
                defaultValue=''
                label={label}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                onFocus={(e) => {
									if (myOnFocus) myOnFocus(e, getValue(value))
                  handleFocus()
                }}
                onBlur={(e) => {
									if (myOnBlur) myOnBlur(e, getValue(value))
                  handleBlur()
                }}
                multiple={!!multiple}
                MenuProps={menuProps}
                error={!!error}
                disabled={disabled !== undefined ? disabled : false}
                sx={sx}
              >
                {options.map((opt: any, key: number) => (
                  <MenuItem key={key} value={opt.value}>
                    {multiple && value ? <Checkbox checked={value.indexOf(opt.value) > -1} /> : <></>}
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
              {helper ? <FormHelperText>{helper}</FormHelperText> : <></>}
              {error ? <FormHelperText error={true}>{error.message}</FormHelperText> : <></>}
            </FormControl>
          )} />
      :
        <></>
      }
    </>
  );
}

export default FormInputDropdown;
