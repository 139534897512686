import { FC, Fragment } from 'react'
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  rescheduleOrders,
} from "./lib/Util";


interface PopupRescheduleProps {
  // onSave: any;
  open: boolean;
  onClose: any;
  rescheduleContext: any;
  armId: any;
}

const PopupReschedule: FC<PopupRescheduleProps> = ({
  // onSave,
  open,
  onClose,
  rescheduleContext,
  armId
}) => {

  if (!open || !rescheduleContext) {
    return <Fragment></Fragment>;
  }
  const { eventRecord, intersectingOrder, callback } = rescheduleContext 

  const closePopup = (callbackAlreadyInvoked: boolean) => {
    onClose()
    if (!callbackAlreadyInvoked && callback !== undefined) callback(false)
  }

  return (
    <Dialog onClose={() => closePopup(false)} open={open} maxWidth={'xs'}>
      <Box mx={4} my={2}>
        <Typography variant="h6">Auto-Schedule Conflict</Typography>
        <Typography variant={'body1'}> Order {eventRecord.data.name} was scheduled to load after order {intersectingOrder.data.name}. Configure how orders should be shifted:</Typography>
        <Button
          variant="outlined"
          size={"small"}
          sx={{ display: "flex", marginTop: 2 }}
          onClick={() => {
            rescheduleOrders(eventRecord, intersectingOrder, armId, false)
            if (callback !== undefined) callback(true)
            closePopup(true)
          }}
        >
          Schedule After
        </Button>
        <Button
          variant="outlined"
          size={"small"}
          sx={{ display: "flex", marginTop: 2 }}
          onClick={() => {
            rescheduleOrders(eventRecord, intersectingOrder, armId, true)
            if (callback !== undefined) callback(true)
            closePopup(true)
          }}
        >
          Schedule After & Shift Future Orders
        </Button>
        <Button
          variant="outlined"
          size={"small"}
          color='error'
          sx={{ display: "flex", marginTop: 2 }}
          onClick={() => {
            if (callback !== undefined) callback(false)
            closePopup(true)
          }}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};

export default PopupReschedule