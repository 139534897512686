import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
// mui imports
import Grid from '@mui/material/Grid'
// custom imports
import { useEffectApi } from '../../../../utils/globals'
import { apiGet } from '../../../../utils/api'
import SetupsheetView from './SetupsheetView'

const SetupsheetNewTab: FC = () => {

  const { itemName, machineName } = useParams()

  const [data, setData] = useState<any | undefined>(undefined)

  console.log('setupsheet params :', itemName, machineName)
  useEffectApi(() => {

    apiGet('/manufacturing/setupsheets/', {getItem: itemName, machineName: machineName},
      (resp: AxiosResponse) => {
        setData(resp.data)
        console.log('resp :', resp.data)
        // console.log('override pdf :', data.item[`${machineName}_setupsheet_override_pdf`])
      })

  }, [])

  return (
    <Grid>
      {data && machineName ?
        <SetupsheetView open={true} close={() => {}} url='/manufacturing/setupsheets/' id={data.item[machineName]}
                        bomItems={data.item.bom_items} dualTimes={data.item[`${machineName}_dual_times`]}
                        timeDiffTemp={data.item.time_diff_temp} printLoading={false} firstArticle={data.item.first_article}
                        hasPdf={data.item[`${machineName}_has_machine_pdf`]} newTab />
        :
        <></>}
    </Grid>
  )
}

export default SetupsheetNewTab
