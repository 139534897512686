import React, {FC, useEffect, useState} from 'react'
import { useForm, FormProvider } from 'react-hook-form'
// mui imports
import Grid from '@mui/material/Grid'
// custom imports
import BaseModal from '../../../../utils/modals/BaseModal'
import FormInputText from '../../../../utils/form/FormInputText'
import {FormSpacing} from "../../../../utils/globals";


interface ChildOrderFormProps {
  orderDefaults?: any | undefined
  onSubmit: (data: any) => void
  onClose: () => void
}
const ChildOrderForm: FC<ChildOrderFormProps> = ({orderDefaults, onSubmit, onClose}) => {

  const [open, setOpen] = useState<boolean>(false)

  const methods: any = useForm()
  const { reset, handleSubmit } = methods

  useEffect(() => {
    console.log('default :', orderDefaults)
    if (orderDefaults) {
      setOpen(true)
      reset(orderDefaults)
    } else
      setOpen(false)
  }, [orderDefaults])

  return (
    <BaseModal
      title='Confirm Create Child Order'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Reset', action: () => reset(orderDefaults), left: true, outlined: true},
        {text: 'Submit', action: handleSubmit(onSubmit)},
      ]}
      doSubmit={handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          <FormInputText name='childBomId' label='bom id' hidden />
          <Grid item xs={12}><FormInputText name='number' label='Child Order Number' /></Grid>
          <Grid item xs={12}><FormInputText name='item' label='Item Number' disabled /></Grid>
          <Grid item xs={12}><FormInputText name='description' label='Item Description' disabled /></Grid>
          <Grid item xs={12}><FormInputText name='type' label='Item Type' disabled /></Grid>
          <Grid item xs={12}><FormInputText name='quantity' label='Item Quantity' /></Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default ChildOrderForm