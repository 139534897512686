import React, { FC, useEffect, useState } from 'react'
// mui import
import Grid from '@mui/material/Grid'
// custom imports
import { FormProvider, useForm } from 'react-hook-form'
import { FormSpacing } from '../../../utils/globals'
import { getMaxMolds } from '../../../utils/globalComps/RotoScheduler/RotoSchedulerApi'
import BaseModal from '../../../utils/modals/BaseModal'
import FormInputDropdown from '../../../utils/form/FormInputDropdown'
import FormInputText from '../../../utils/form/FormInputText'
import InfoText from '../../../utils/globalComps/InfoText'
import FormInputNumber from '../../../utils/form/FormInputNumber'

interface LoadToArmModalProps {
  id?: number | undefined
  orderName?: string | undefined
  count?: number | undefined
  rotoScheduled?: boolean | undefined
  arms: any
  givenArm: number
  canChoose: boolean | undefined
  onClose: () => void
  onSubmit: (data: any, cb: () => void) => void
  loadingMore?: boolean | undefined
}
const LoadToArmModal: FC<LoadToArmModalProps> = ({
  id, orderName, count, rotoScheduled, arms, givenArm,
  canChoose, onClose, onSubmit, loadingMore
}) => {

  const [maxMolds, setMaxMolds] = useState<number>(1);
  const methods: any = useForm();
  const { handleSubmit, reset } = methods;
  const [open, setOpen] = useState<boolean>(false);
  const [armChoices, setArmChoices] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      setOpen(true);
      setArmChoices(arms.map((arm: any) => ({label: arm.name, value: arm.id})));
      getMaxMolds(id, false, !!loadingMore, (data: any) => {
        setMaxMolds(data.max);
        reset({order: orderName, arm: givenArm, orderId: id, count: count && data.max > count ? count : data.max, loadingMore: loadingMore});
      });
    } else {
      setOpen(false);
    }
  }, [id, orderName, loadingMore]);

  return (
    <BaseModal
      title='Load to Arm'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Submit', action: handleSubmit(onSubmit), disabled: maxMolds === 0}
      ]}
      doSubmit={handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          <FormInputText name='orderId' label='' hidden />
          <FormInputText name='loadingMore' label='' hidden />
          <Grid item xs={12}><FormInputText name='order' label='Order' disabled /> </Grid>
          <Grid item xs={12}>
            <FormInputNumber
              name='count'
              label={ maxMolds == 0 ? 'All molds in use' : '# of Molds to Load' }
              disabled={!canChoose || maxMolds === 0}
              min={1}
              max={maxMolds}
              error={maxMolds === 0}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputDropdown name='arm' label='Arm' options={armChoices} disabled={!canChoose} />
          </Grid>
          {!canChoose ? <InfoText text='Override privileges to choose a different arm.' /> : <></>}
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default LoadToArmModal;
