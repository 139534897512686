import React, { FC } from 'react';
// mui imports
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
// custom imports
import InfoText from "../../../../utils/globalComps/InfoText";

interface BomItemListProps {
  bomItems: any[];
  textColor?: string;
  displayNote?: boolean;
}
const BomItemList: FC<BomItemListProps> = ({bomItems, displayNote, textColor}) => {
  return (
    <Grid container>
      <TableContainer sx={{mb: '10px'}}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell sx={textColor ? {color: textColor} : {}}>Name</TableCell>
              <TableCell sx={textColor ? {color: textColor} : {}}>Description</TableCell>
              <TableCell sx={textColor ? {color: textColor} : {}}>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bomItems.map((item: any, key: number) => (
              <TableRow key={key}>
                <TableCell sx={textColor ? {color: textColor} : {}}>{item.name}</TableCell>
                <TableCell sx={textColor ? {color: textColor} : {}}>{item.description}</TableCell>
                <TableCell sx={textColor ? {color: textColor} : {}}>{item.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {displayNote ? <InfoText text='BOM items can be edited in BOM Management' /> : <></>}
    </Grid>
  );
}

export default BomItemList;