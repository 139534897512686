import React, {FC, useEffect, useState} from 'react';
// mui imports
import { useTheme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
// mui icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// custom imports
import { Actions } from './BaseRow';
import ApiTable from './ApiTable';
import exp from "constants";
import BaseTooltip from "../globalComps/BaseTooltip";
import {colors} from "../colors";
// import exp from "constants";

interface ExpandCellProps {
  open: boolean;
  openHandler: () => void;
}
/**
 * ExpandCell subcomponent
 * @param open
 * @param openHandler
 */
const ExpandCell: FC<ExpandCellProps> = ({open, openHandler}) => {
  return (
    <TableCell>
      <IconButton aria-label='Expand row' size='small' onClick={openHandler}>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </TableCell>
  );
}

interface ExpandableRowProps {
  parentRow: any;
  expandableParams: any;
  expandableCondition?: any;
  expandableParentCondition?: any;
  children?: JSX.Element | JSX.Element[];
}
const ExpandableRow: FC<ExpandableRowProps> = ({parentRow, expandableParams, expandableCondition, expandableParentCondition,
                                                 children}) => {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false)
  const [url, setUrl] = useState<string>('')

  useEffect(() => {
    setUrl(`${expandableParams.url}?parentId=${parentRow.id}`)

  }, [open, expandableParams.url])

  // Handles opening of child rows if expandable row
  const openHandler = () => setOpen(!open);

  const expandCell = () => {
    if ((expandableCondition === undefined && expandableParentCondition === undefined)
      || (!!expandableCondition && expandableCondition.condition(parentRow.data[expandableCondition.col]))
      || (!!expandableParentCondition && expandableParentCondition.condition(parentRow[expandableParentCondition.field])))
      return <ExpandCell open={open} openHandler={openHandler} />
    else
      return <TableCell> </TableCell>
  }

  const getRowColor = (color: string) => colors[theme.palette.mode].table.row[color];

  const expandRow = () => {
    let sx: any = parentRow.highlight
    if (parentRow.color)
      sx = {...sx, backgroundColor: getRowColor(parentRow.color)}
    const content =
      <TableRow sx={sx} className={theme.palette.mode === 'dark' ? 'dark' : 'light'}>
        {expandCell()}
        {parentRow.data.map((item: any, key: number) => (
          <TableCell key={key} align='center'>
            {typeof item !== 'object' || (item.type && item.type.name && item.type.name == BaseTooltip.name) ? item : item.title}
          </TableCell>
        ))}
        {parentRow.actions && parentRow.actions.length > 0 ? <Actions actions={parentRow.actions} id={parentRow.id}
                                                                      setExpandedId={expandableParams.setExpandedId}
                                                                      name={parentRow.data[0]} /> : <TableCell></TableCell>}
      </TableRow>;

    if (parentRow.tooltip && parentRow.tooltip !== '')
      return <BaseTooltip text={parentRow.tooltip}>{content}</BaseTooltip>;
    else
     return content;
  }

  return (
    <>
      {expandRow()}
      <TableRow>
        <TableCell style={{padding: 0}} colSpan={parentRow.data.length + 2}>
          <Collapse in={open} timeout='auto' unmountOnExit>

            <ApiTable
              suppressLoadDisplay={expandableParams.suppressLoadDisplay}
              tableName={expandableParams.tableName}
              objectName={expandableParams.objectName}
              titleVariant={expandableParams.titleVariant}
              headers={expandableParams.headers}
              rowFields={expandableParams.rowFields}
              rowActions={expandableParams.rowActions}
              clickToToggleFields={expandableParams.clickToToggleFields}
              tableActions={expandableParams.tableActions}
              dataField={expandableParams.dataField}
              url={url}
              editable={expandableParams.editable}
              deletable={expandableParams.deletable}
              overrideFormParams={expandableParams.overrideFormParams}
              FormComponent={expandableParams.formComponent}
              formParams={expandableParams.formParams}
              setExpandedId={expandableParams.setExpandedId}
              id={parentRow.id}
              orderable={expandableParams.orderable}
              orderableBlacklist={expandableParams.orderableBlacklist}
              refresh={expandableParams.refresh}
              expandableCondition={expandableParams.expandableCondition}
              extraParams={expandableParams.extraParams}
            />

          </Collapse>
        </TableCell>
      </TableRow>
      {children}
    </>
  );
}

export default ExpandableRow;
