import { FC, Fragment } from 'react'
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { DateHelper} from "@bryntum/scheduler";


interface PopupOrderDetailProps {
  event: any;
  closeOrderDetail: any;
  orderDetailOpen: boolean;
}

const PopupOrderDetail: FC<PopupOrderDetailProps> = ({
  event,
  closeOrderDetail,
  orderDetailOpen,
}) => {
  if (!event) {
    return <Fragment></Fragment>;
  }
  const { data } = event;
  const durationUnrounded = DateHelper.as("hour", event.duration);
  const durationRounded = Math.round(durationUnrounded * 100) / 100;
  return (
    <Dialog onClose={closeOrderDetail} open={orderDetailOpen} maxWidth={false}>
      <Box mx={4} my={2}>
        <Typography variant="h6">{`Order: ${data.work_order_number} (${data.balance} Parts)`}</Typography>
        <Divider sx={{ margin: "10px 0" }} />
        <InfoLabel label={"Order:"} value={data.work_order_number} />
        <InfoLabel label={"Item:"} value={data.item} />
        <InfoLabel label={"Balance:"} value={data.balance} />
        <InfoLabel label={"Description:"} value={data.description} />
        <InfoLabel label={"Molds to Load:"} value={data.mold_load_quantity} />
        <Divider sx={{ margin: "10px 0" }} />

        <InfoLabel
          label={"Scheduled Start Date:"}
          value={DateHelper.format(event.startDate, "dddd, h:mmA, M/D/YY")}
        />
        <InfoLabel
          label={"Scheduled End Date:"}
          value={DateHelper.format(event.endDate, "dddd, h:mmA, M/D/YY")}
        />
        <InfoLabel
          label={"Scheduled Duration:"}
          value={`${durationRounded} Hours`}
        />
        <InfoLabel label={"Ship Date:"} value={data.ship_date} />
        <Divider sx={{ margin: "10px 0" }} />
        <InfoLabel label={"Mold Volume:"} value={data.mold_volume} />
        <InfoLabel label={"Oven Temp:"} value={data.temp} />
        <InfoLabel label={"Oven Time:"} value={data.time} />
        <InfoLabel label={"Takt Time:"} value={data.mold_takt} />
      </Box>
    </Dialog>
  );
};


interface InfoLabelProps {
  label: string;
  value: string;
  variant?: any; // Need MUI Variant typescript type
}

const InfoLabel: FC<InfoLabelProps> = ({ label, value, variant = "body1" }) => {
  return (
    <Box display="flex" justifyContent={"space-between"}>
      <Typography mr={4} variant={variant}>
        {label}
      </Typography>
      <Typography variant={variant}> {value}</Typography>
    </Box>
  );
};

export default PopupOrderDetail