import { useEffect, useMemo, useState } from "react";
import { WSUrl } from "../../../../../utils/globals";
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";
import { ProductivityData } from "./Interfaces";
import { useTheme } from '@mui/material/';

const useDepartmentProductivity = (timeFrame: 'shift' | 'month') => {
	const [data, setData] = useState<ProductivityData>();
	const { loading, sendMessage } = useWebsocket('/productivity/department_productivity/', setData);
	const theme = useTheme();

	// Select data based on timeFrame
	const selectedData = useMemo(() => {
		if (timeFrame === 'month') {
			return data?.productivity_mtd;
		} else {
			return data?.productivity_shift;
		}
	}, [data, timeFrame]);
	
	// machine
	const overallMachineProductivity = useMemo(() => selectedData?.machine_data?.overall.productivity ?? null, [selectedData]);
	const machines = useMemo(() => selectedData?.machine_data?.machines ?? [], [selectedData]);
	// secondary
	const overallSecondaryProductivity = useMemo(() => selectedData?.secondary_data?.overall.productivity ?? null, [selectedData]);
	const secondaryLines = useMemo(() => selectedData?.secondary_data?.lineplans ?? [], [selectedData]);
	// foaming
	const overallFoamingProductivity = useMemo(() => selectedData?.foaming_data?.overall.productivity ?? null, [selectedData]);
	const foamingLines = useMemo(() => selectedData?.foaming_data?.lineplans ?? [], [selectedData]);
	// assembly
	const overallAssemblyProductivity = useMemo(() => selectedData?.assembly_data?.overall.productivity ?? null, [selectedData]);
	const assemblyLines = useMemo(() => selectedData?.assembly_data?.lineplans ?? [], [selectedData]);

	// productivity color logic
	const productivityColor = useMemo(() => {
		let productivities = [
			overallMachineProductivity, overallSecondaryProductivity,
			overallFoamingProductivity, overallAssemblyProductivity
		];
		const filteredArray = productivities.filter((value): value is number => value !== null);
		const sum = filteredArray.reduce((acc, value) => acc + value, 0);
		const average = sum / filteredArray.length;
		if (average! > 80) return theme.palette.primary.main; // Blue
		if (average! > 60) return theme.palette.warning.light; // Light Orange
		return theme.palette.error.main; // Red
	}, [overallMachineProductivity, overallSecondaryProductivity, overallFoamingProductivity, overallAssemblyProductivity]);

	return {
		loading,
		overallMachineProductivity, machines,
		overallSecondaryProductivity, secondaryLines,
		overallFoamingProductivity, foamingLines,
		overallAssemblyProductivity, assemblyLines,
		productivityColor
	};
};

export default useDepartmentProductivity;
