import React, { FC, useState, useEffect } from 'react';
// mui imports
import { useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
// mui icons
import SearchIcon from '@mui/icons-material/Search';

interface SearchBarProps {
  defaultText?: string;
  outlined: boolean;
  width: number;
  onSearch: (q: string) => void;
  q: string;
}
const SearchBar: FC<SearchBarProps> = ({defaultText, outlined, width, onSearch, q}) => {
  const [value, setValue] = useState<string>(q);
  const [text, setText] = useState<string>('Search...');

  useEffect(() => {
    if (defaultText)
      setText(defaultText)
  }, [defaultText, setText])

  const handleQ = (e: any) => setValue(e.target.value);
  const handleSearchSubmit = () => onSearch(value);

  const handleOnEnter = (e: any) => {
    if (e.key === 'Enter')
      handleSearchSubmit();
  }

  // Colors based on dark/light theme
  const theme = useTheme();
  const outlineColor = theme.palette.primary.main;
  const backgroundColor = theme.palette.primary.main;
  const iconColor = theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white;
  // const placeHolderColor = makeStyles((theme: any) => ({
  //   placeholder: {
  //     color: theme.palette.primary.main
  //   }
  // }))

  // inline CSS
  const height: string = '40px';
  const borderHeight: string = '38px';
  const searchHeight: string = '36px';
  const searchBorder = outlined ?
    {border: `1px solid ${outlineColor}`, borderRadius: '8px', width: `${width}%`,
    height: height, maxWidth: '250px', minWidth: '150px'} :
    {width: `${width}%`, height: height, maxWidth: '250px', minWidth: '150px'};
  const iconBorder = {borderLeft: `1px solid ${outlineColor}`, backgroundColor: backgroundColor,
    borderTopRightRadius: '7px', borderBottomRightRadius: '7px', height: borderHeight};

  return (
    <Grid sx={searchBorder} justifyContent='flex-end' alignItems='right'>
      <Grid container sx={{height: 'inherit'}}>
        <Grid item xs={10}>
          <InputBase sx={{marginTop: '2px', marginLeft: '10px', height: searchHeight}} placeholder={text}
                     inputProps={{'aria-label': 'search'}} onChange={handleQ} onKeyPress={handleOnEnter} value={value} />
        </Grid>
        <Grid item xs={2}>
          <Box sx={iconBorder}>
            <IconButton onClick={handleSearchSubmit} sx={{height: height, color: iconColor}}><SearchIcon /></IconButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SearchBar;
