import {apiGet} from "../../api";

const URL: string = '/manufacturing/lineplan/live/'

/**
 * gets live line plan items for the line plan
 * @param id lineplan id
 * @param callback
 */
const getLineplanLiveData = (id: number, callback: (data: any[]) => void) => {
  apiGet(`${URL}${id}/`, {}, (resp: any) => callback(resp.data))
}

export {
  getLineplanLiveData
}