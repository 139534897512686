import React, {FC, useEffect} from 'react';
// mui imports
import { useTheme } from '@mui/material/styles';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography, { TypographyTypeMap } from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// mui icons
import ExpandMore from '@mui/icons-material/ExpandMore';
import {getAccordionBackgroundColor} from "../globals";

interface BaseAccordionProps {
  title: string;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
  variant?: TypographyTypeMap['props']['variant'];
  size?: string;
  enabled?: boolean | undefined;
  expanded?: boolean | undefined;
  light?: boolean | undefined
  children: JSX.Element | JSX.Element[];
}
const BaseAccordion: FC<BaseAccordionProps> = ({title, onChange, variant, size,
                                                 enabled, expanded, light,
                                                 children}) => {
  const theme = useTheme();

  useEffect(() => {
  }, [title, expanded])

  let style: any;
  if (size === 'sm')
    style = {backgroundColor: getAccordionBackgroundColor(theme, light), boxShadow: 3, height: '40px'};
  else
    style = {backgroundColor: getAccordionBackgroundColor(theme, light), boxShadow: 3};

  return (
    enabled || enabled === undefined ?
      <Accordion sx={style} onChange={onChange} expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container spacing={0} alignItems='center' justifyContent='center'>
            <Typography variant={variant ? variant : 'h5'}>{title}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    : <>{children}</>
  );
}

export default BaseAccordion;
