import React, { FC, useRef, useState, useEffect } from 'react'
import { AxiosResponse, AxiosError } from 'axios'
import { FileUploader } from 'react-drag-drop-files'
// mui
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
// mui icons
import FileUpload from '@mui/icons-material/FileUpload'
// custom
import { api, button } from '../../globals'
import { doAlert } from '../../globalComps/PopupAlert/PopupAlert'
import { doConfirm } from '../Confirm'
import InfoText from '../../globalComps/InfoText'
import './ImportModal.css'
import DataProgressBar from '../../globalComps/DataProgressBar'
import ApiTable from '../../table/ApiTable'
import BaseModal from '../BaseModal'
import { apiGet } from '../../api'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, Breakpoint, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

const syncCheckTime: number = 500 // ms

interface ImportModalProps {
	open: boolean
	closeHandler: (uploaded: boolean, file: any | undefined) => void
	url: string
	title?: string
	suppressAlert?: boolean
	extraInfo?: string[]
	typeName: string
	appendData?: any
	doCheck?: boolean
	maxWidth?: Breakpoint
	excelImportId?: string
	additionalInputs?: React.ReactNode[] | (() => React.ReactNode[])
	tableHeaders?: string[][]
}

const ImportModal: FC<ImportModalProps> = ({
	title, open, url, closeHandler, suppressAlert, extraInfo, maxWidth,
	typeName, appendData, doCheck, excelImportId, additionalInputs, tableHeaders
}) => {
	const [file, setFile] = useState<any>({})
	const [uploadedFile, setUploadedFile] = useState<any | undefined>(undefined)
	const [fileName, setFileName] = useState<string | undefined>(undefined)
	const [loading, setLoading] = useState<boolean>(false)
	const [progressLabel, setProgressLabel] = useState<string>('')
	const [progressPercent, setProgressPercent] = useState<number>(0)
	const [refreshTable, setRefreshTable] = useState<boolean>(false)
	const [selectedImportId, setSelectedImportId] = useState<number | undefined>(undefined)
	const [importing, setImporting] = useState<boolean>(false)
	const importingRef = useRef<boolean>(false)
	const formRef = useRef(null)
	importingRef.current = importing

	const methods = useForm()

	useEffect(() => { }, [open])

	const theme = useTheme()

	const handleFileChange = (file: any) => {
		setFile(file)
		setFileName(file.name)
	}

	const generateAdditionalInputs = () => {
		if (additionalInputs) {
			// Determine if additionalInputs is a function and execute it, or use it directly
			const inputs = typeof additionalInputs === 'function' ? additionalInputs() : additionalInputs;

			// Filter out non-elements and assign a unique key to each element
			return inputs.map((input, index) => {
				// Type guard to check if input is a ReactElement
				if (React.isValidElement(input)) {
					// Input is a valid ReactElement, so it's safe to clone it
					return React.cloneElement(input, { key: index });
				}
				// Return null for non-element nodes (or handle them as needed)
				return null;
			}).filter(input => input !== null); // Optionally filter out nulls if non-elements were present
		}
	}

	const handleUpload = (data: any) => {
		let formData: any = formRef.current ? new FormData(formRef.current) : new FormData()

		// Append the file to formData if present
		if (file) { // Assuming `file` is the File object from the state
			formData.append('files', file); // Adjust the field name as per your backend expectation
		}

		// Append additional data from `data` or `methods.getValues()`
		const formDataValues = methods.getValues()
		Object.keys(formDataValues).forEach((key) => {
			const value = formDataValues[key]
			if (value !== undefined && key !== 'files') { // Skip 'files' to avoid duplicates
				formData.append(key, value)
			}
		})

		// Append `appendData` if present
		if (appendData) {
			Object.keys(appendData).forEach(key => {
				formData.append(key, appendData[key])
			})
		}

		// Append 'check' if needed
		if (doCheck) {
			formData.append('check', 'true')
		}

		setLoading(true)
		api.post(url, formData)
			.then((resp: AxiosResponse) => {
				formData.delete('check')
				console.log(resp)
				if (!resp.data.created && doCheck) {
					doConfirm(resp.data.message, () => doUpload(data))
				} else {
					if (excelImportId) {
						console.log('excel import :', resp.data)
						setUploadedFile(file)
						setProgressLabel(`${resp.data.total}/${resp.data.total}`)
						setProgressPercent(100)
						doAlert(resp.data.message, 'success', true)
						setRefreshTable(!refreshTable)
						setTimeout(() => {
							setImporting(false)
							setProgressLabel('')
							setProgressPercent(0)
						}, syncCheckTime)
						doAlert(resp.data.message, resp.data.color ? resp.data.color : 'success', true)
					} else {
						doAlert(resp.data.message, resp.data.color ? resp.data.color : 'success', true)
						closeHandler(true, uploadedFile)
					}
				}
				setLoading(false)
			})
			.catch((err: AxiosError) => {
				if (err.response) doAlert(err.response.data.message, 'error', true)
				setLoading(false)
			})

		console.log('excelImportId :', excelImportId)
		if (excelImportId) {
			setImporting(true)
			setTimeout(initProgressCheck, 1500)
		}
	}

	// progress tracking
	const initProgressCheck = () => doProgressCheck()
	const doProgressCheck = () => {
		const checkUrl = `${url}?checkImport=${true}&excelId=${excelImportId}`

		apiGet(url, { checkImport: true, excelId: excelImportId }, (resp: any) => {
			console.log('syncing :', importingRef.current, 'done :', !resp.data.done)
			console.log('import modal resp :', resp.data)
			if (importingRef.current && !resp.data.done) {
				setProgressLabel(`${resp.data.current}/${resp.data.total}`)
				setProgressPercent(resp.data.percent)

				setTimeout(doProgressCheck, syncCheckTime)
			}
		})
	}

	const doUpload = (formData: any) => {
		setLoading(true)

		api.post(url, formData)
			.then((resp: AxiosResponse) => {
				setLoading(false)

				if (resp.data.message)
					if (!suppressAlert)
						doAlert(resp.data.message, resp.data.color ? resp.data.color : 'success', true)

				console.log('do upload callback')
				setTimeout(() => {
					setImporting(false)
					setProgressLabel('')
					setProgressPercent(0)
				}, syncCheckTime)
				closeHandler(true, uploadedFile)
			})
			.catch((err: AxiosError) => {
				if (err.response) doAlert(err.response.data.message, 'error', true)
				setLoading(false)
			})
	}

	const fileTypeMismatch = (err: string) => doAlert(err, 'error', true)

	const content = () => {
		let fileUploadClass: string = ''
		if (theme.palette.mode === 'dark')
			fileUploadClass = 'file-upload-dark-mode'

		if (!loading)
			return (
				<Grid container justifyContent='center'>
					<FileUploader handleChange={handleFileChange} multiple={false} types={[typeName]}
						onTypeError={fileTypeMismatch} classes={fileUploadClass} />
				</Grid>
			)
	}

	const generateExtraInfo = () => {
		if (extraInfo) {
			return (
				<>
					{extraInfo.map((info: any, key: number) => (
						<InfoText text={info} key={key} />
					))}
				</>
			)
		}
	}

	const generateTableHeaders = () => {
		if (tableHeaders) {
			return (
				<Box marginY={2}>
					<Typography variant='h6'>Example Header</Typography>
					<TableContainer component={Paper} style={{ maxHeight: '200px', overflow: 'auto' }}>
						<Table stickyHeader>
							<TableHead>
								{tableHeaders.map((headerRow, rowIndex) => (
									<TableRow key={rowIndex}>
										{headerRow.map((header, colIndex) => (
											<TableCell key={colIndex} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>{header}</TableCell>
										))}
									</TableRow>
								))}
							</TableHead>
							<TableBody>
								{/* You can add a sample row or leave the body empty */}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			)
		}
	}

	const defaultTitle = () => {
		if (typeName === 'xlsx')
			return 'Import Xlsx'
		else if (typeName === 'pdf')
			return 'Import PDF'
	}

	// conditional comps
	const loadBar = () => {
		if (importing)
			return (
				<Grid sx={{ mt: 5, mr: 5, ml: 5 }}>
					<DataProgressBar labelText={`Rows: ${progressLabel}`} value={progressPercent} />
				</Grid>
			)
		else
			return <></>
	}

	const setExpandedId = (id: number | undefined) => {
		setSelectedImportId(id)
	}

	return (
		<>
			<BaseModal
				title={title ? title : defaultTitle()}
				open={open}
				closeHandler={() => closeHandler(!!uploadedFile, uploadedFile)}
				closeButton
				dividers
				maxWidth={maxWidth ? maxWidth : 'md'}
				actions={!additionalInputs ?
					[
						{ text: 'Upload', outlined: true, icon: <FileUpload />, action: handleUpload }
					] : []}
			>
				<>
					{typeName === 'xlsx' ? <><InfoText text='Each import file should have an overall import header, as well as individual row headers.' />
						<InfoText text='Run an export of this object to see how an import file should be formatted.' />
						<InfoText text='After dropping or selecting a file, press the upload button below.' /></> : <></>}
					{generateExtraInfo()}
					{generateTableHeaders()}
					{/* If there are additional form inputs, render an isolated form with upload */}
					{additionalInputs && additionalInputs.length > 0 ?
						<FormProvider {...methods}>
							<form ref={formRef} onSubmit={methods.handleSubmit(handleUpload)}>
								<div style={{ marginBottom: '10px' }}>
									{generateAdditionalInputs()}
								</div>
								{content()}
								{fileName !== '' ? <Typography align='center' sx={{ mt: '10px' }}>Filename: {fileName}</Typography> : <></>}
								<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
									{button({ text: 'Upload', submit: 'submit', outlined: true, icon: <FileUpload /> }, 1)}
								</Box>
							</form>
						</FormProvider>
						: <>
							<div>
								{content()}
							</div>
							<div>
								{fileName !== '' ? <Typography align='center' sx={{ mt: '10px' }}>Filename: {fileName}</Typography> : <></>}
							</div>
						</>}
					{loadBar()}
					{typeName === 'xlsx' ?
						<ApiTable
							tableName='Last 5 Imports'
							titleVariant='h6'
							objectName=''
							headers={['', 'Created', 'Updated', 'Errors', 'Imported At', '']}
							rowFields={['objects_created', 'objects_updated', 'error_count', 'created']}
							dataField='objects'
							url={`/main/excel-sync/?excelId=${excelImportId}`}
							refresh={refreshTable}
							expandable={true}
							setExpandedId={setExpandedId}
							expandableCondition={{
								col: 2, condition: (val: number) => {
									return val > 0
								}
							}}
							expandableParams={{
								tableName: 'Errors',
								objectName: 'Excel Import Error',
								titleVariant: 'h6',
								headers: ['Type', 'Message', ''],
								rowFields: ['type_name', 'message'],
								url: `/main/excel-sync-errors/`,
								setExpandedId: { setExpandedId },
                dataField: 'objects'
							}}
						/>
						:
						<></>
					}
				</>
			</BaseModal>
		</>
	)
}

export default ImportModal
