import {apiGet, apiPost} from "../../../utils/api";

const URL: string = '/processing/molding';

// get
const getMoldingMachineDisplayData = (machineId: number, callback: (data: any) => void) => {
  apiGet(`${URL}/${machineId}/`, {getData: true}, (resp: any) => {
    callback(resp.data)
  })
  callback({});
}
const preCheckOpenLogProduction = (id: number, arm: boolean, callback: (data: any) => void) => {
  apiGet(`${URL}`, {id: id, type: arm ? 'arm' : 'individual', openLogProductionCheck: true}, (resp: any) => {
    callback({success: true})
  }, () => {
    callback({success: false})
  })
}
const getArmStatus = (machineId: number, callback: (data: any) => void) => {
  apiGet(`/processing/arm-status/${machineId}/`, {}, (resp: any) => {
    callback(resp.data)
  })
}

// post
const logProduction = (machineId: number, data: any, callback: (data: any) => void) => {
  apiPost(`${URL}/${machineId}/`, {...data, logProduction: true}, (resp: any) => {
    callback(resp.data)
  })
}

export {
  getMoldingMachineDisplayData, preCheckOpenLogProduction, getArmStatus, logProduction
};
