import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
// mui imports
import Grid from "@mui/material/Grid";
// custom imports
import { FormSpacing, useEffectApi } from "../../globals";
import BaseModal from "../../modals/BaseModal";
import FormInputNumber from "../../form/FormInputNumber";
import FormInputText from "../../form/FormInputText";
import { getMaxMolds } from "./RotoSchedulerApi";
import InfoText from "../InfoText";


interface UpdateMoldCountModalProps {
  orderId?: number | undefined
  quantity: number
  onSubmit: (data: any) => void
  onClose: () => void
  rotoScheduled?: boolean | undefined
}
const UpdateMoldCountModal: FC<UpdateMoldCountModalProps> = ({orderId, quantity, onClose,
                                                               onSubmit, rotoScheduled}) => {

  const [open, setOpen] = useState<boolean>(false)
  const [newQuantity, setNewQuantity] = useState<number>(1)
  const [maxMolds, setMaxMolds] = useState<number>(1)

  const methods = useForm()
  const { reset, handleSubmit, setFocus } = methods

  useEffectApi(() => {

    if (orderId) {
      setNewQuantity(quantity)

      // get max molds
      getMaxMolds(orderId, rotoScheduled ? rotoScheduled : false, false, (data: any) => {
        setMaxMolds(data.max < quantity ? quantity : data.max)
      })
      reset({id: orderId, count: quantity})

      setOpen(true)

      // focus the input element
      setTimeout(() => {
        const quantityInput: HTMLElement | null = document.getElementById('mold-count-input')
        if (quantityInput) quantityInput.focus()
      }, 100)
    } else
      setOpen(false)

  }, [orderId, quantity])

  const onChange = (e: any) => {
    if (e.target.value > maxMolds)
      setNewQuantity(maxMolds)
    else if (e.target.value < 1)
      setNewQuantity(1)
    else
      setNewQuantity(parseInt(e.target.value))
  }

  return (
    <BaseModal
      title='Update Mold Count'
      open={open}
      closeHandler={onClose}
      closeButton
      dividers
      actions={[
        {text: 'Submit', action: handleSubmit(onSubmit), submit: true}
      ]}
      doSubmit={handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <Grid container spacing={FormSpacing}>
          <FormInputText name='id' label='id' hidden={true} />
          <Grid item xs={12}><InfoText text={`${newQuantity}/${maxMolds} to schedule`} /></Grid>
          <Grid item xs={12}>
            <FormInputNumber id='mold-count-input' name='count' label='Mold Count' min={1} max={maxMolds} onChange={onChange}
                             selectOnFocus />
          </Grid>
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default UpdateMoldCountModal