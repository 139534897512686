// TimePeriodContext.tsx
import React, { createContext, useContext, useState, FunctionComponent, ReactNode } from 'react';

type TimePeriod = 'shift' | 'month';

interface TimePeriodContextProps {
	timePeriod: TimePeriod;
	setTimePeriod: (timePeriod: TimePeriod) => void;
}

const TimePeriodContext = createContext<TimePeriodContextProps | undefined>(undefined);

export const TimePeriodProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
	const [timePeriod, setTimePeriod] = useState<TimePeriod>('shift');

	return (
		<TimePeriodContext.Provider value={{ timePeriod, setTimePeriod }}>
			{children}
		</TimePeriodContext.Provider>
	);
};

export const useTimePeriod = (): TimePeriodContextProps => {
	const context = useContext(TimePeriodContext);
	if (context === undefined) {
		throw new Error('useTimePeriod must be used within a TimePeriodProvider');
	}
	return context;
};
