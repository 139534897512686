import {apiGet} from "../../utils/api";


/**
 * Gets account data with the given account id
 * @param id: account id
 * @param callback
 */
const getAccountData = (id: number, callback: (accountData: any) => void) => {
  apiGet(`/main/accounts/${id}/`, {}, (resp: any) => {
    callback(resp.data.object[0])
  })
}

export {
  getAccountData
}
