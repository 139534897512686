import React, {FC} from 'react'
// mui imports
import Grid from '@mui/material/Grid'
// custom imports
import LinearProgress from '@mui/material/LinearProgress'


interface ProgressBarProps {
  progress: number
  children?: JSX.Element | JSX.Element[] | undefined
}
const ProgressBar: FC<ProgressBarProps> = ({progress, children}) => {
  return (
    <Grid container item>
      {children ?
        <Grid item>
          {children}
        </Grid>
        :
        <></>
      }
      <Grid item sx={{width: '100%'}}>
        <LinearProgress sx={{height: 15, borderRadius: 5}} variant='determinate' value={progress} />
      </Grid>
    </Grid>
  )
}

export default ProgressBar
