import React, {FC, useState} from 'react'
// mui imports
import {useTheme} from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
// custom imports
import {DateFormat, useEffectApi} from '../../../../../utils/globals'
import { getFindCompatibleItemData } from './FindCompatibleOrderApi'
import BaseModal from '../../../../../utils/modals/BaseModal'
import BaseTooltip from '../../../../../utils/globalComps/BaseTooltip'
import FindCompatibleFilterForm from './FindCompatibleFilterForm'
import BaseContent from '../../../../../utils/globalComps/BaseContent'
import ApiTable from "../../../../../utils/table/ApiTable";
import ScheduleSend from "@mui/icons-material/ScheduleSend";
import {colors} from "../../../../../utils/colors";
import ScheduleToMachine from "../../../../../utils/modals/ScheduleToMachine";
import moment from "moment/moment";
import {scheduleToMachine} from "../MachineSchedulingApi";


interface FindCompatibleOrdersProps {
  orderIdList: number[]
  machine: any
  onClose: () => void
  type?: string
}


/**
 * @param orderIdList: Orders to select from to find compatible with
 * @param machine: Machine used to calculate compatible orders
 * @param onClose
 * @param type: Order type (if weird type)
 */
const FindCompatibleOrders: FC<FindCompatibleOrdersProps> = ({orderIdList, machine, onClose, type}) => {

  const theme = useTheme()

  const [loading, setLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [itemList, setItemList] = useState<any[]>([])
  const [machineList, setMachineList] = useState<any[]>([])
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>(undefined)
  const [refreshTable, setRefreshTable] = useState<boolean>(false)
  const [silentLoad, setSilentLoad] = useState<boolean>(false)

  const [scheduleToMachineId, setScheduleToMachineId] = useState<number | undefined>(undefined)
  const [scheduleToMachineType, setScheduleToMachineType] = useState<string>('')

  const [filterValues, setFilterValues] = useState<any>({})

  useEffectApi(() => {

    if (orderIdList.length > 0 && !!machine) {
      getFindCompatibleItemData(orderIdList, machine.id, (data: any) => {
        console.log(' getFindCompatibleItemData 1 :', data)
        setLoading(false)

        setItemList(data.items.map((item: any, i: number) => {
          if (i === 0)
            return {...item, selected: true}
          else
            return {...item, selected: false}
        }))
        setMachineList(data.machineList)

        console.log(' getFindCompatibleItemData 2')
        setFilterValues({
          ship_date: moment(data.shipDate).format(DateFormat),
          balance__gt: 10,
          balance__lt: 100,
          oven_temp: data.items[0].oven_temp,
          oven_time: data.items[0].oven_time,
          temp_diff: data.tempDiff,
          time_diff: data.timeDiff,
          machine_id: machine.id,
          primary_machine: false
        })

        console.log(' getFindCompatibleItemData 3')
        console.log(' setLoading(false)')
      }, type)

      setOpen(true)
    } else
      setOpen(false)

  }, [orderIdList])


  // helpers
  const selectItem = (itemId: number) => {
    // sets item in itemList with passed id selected to true, sets all others to false
    setItemList([...itemList].map((item: any) => ({...item, selected: item.id === itemId})))
    doFilter(filterValues)
  }
  const getSelectedItem = () => {
    for (const item of itemList)
      if (item.selected)
        return item
  }
  const silentRefreshTable = () => {
    setSilentLoad(true)
    setRefreshTable(!refreshTable)
  }

  // actions
  const doFilter = (newFilterParams: any) => {
    console.log('do filter :', newFilterParams)

    const selectedItem: any = getSelectedItem()

    if (typeof newFilterParams.ship_date === 'object' || newFilterParams.ship_date.includes('-')) {
      newFilterParams.ship_date = moment(newFilterParams.ship_date).format(DateFormat)
    }

    setFilterValues({...newFilterParams, oven_temp: selectedItem.oven_temp, oven_time: selectedItem.oven_time})
    setRefreshTable(!refreshTable)
    console.log('do filter!')
  }

  // schedule to machine
  const openScheduleToMachine = (orderId: number) => {
    setScheduleToMachineId(orderId)
  }
  const doScheduleToMachine = (orderId?: number, machineId?: number) => {
    console.log('do schedule to machine :', orderId, machineId)
    scheduleToMachine(orderId, machineId, false, () => {
      closeScheduleToMachine()
      silentRefreshTable()
    }, 'order')
  }
  const closeScheduleToMachine = () => {
    setScheduleToMachineId(undefined)
  }

  return (
    <>
      <BaseModal
        title={'Find Compatible Orders'}
        open={open}
        closeHandler={onClose}
        maxWidth='xl'
        closeButton
        dividers
      >
        <BaseContent loading={loading}>
          <Grid container spacing={2}>

            <Grid item xs={12} container justifyContent='center' spacing={2}>
              {itemList.map((item: any, key: number) => (
                <Grid item key={key}>
                  <BaseTooltip text={item.selected ? 'Current selected item' : ''}>
                    <Button variant={item.selected ? 'outlined' : 'contained'} onClick={() => selectItem(item.id)}>
                      {item.name}
                    </Button>
                  </BaseTooltip>
                </Grid>
              ))}


              {itemList.map((item: any, key: number) => (
                <React.Fragment key={key}>
                  {item.selected ?
                    <Grid item xs={12} sx={{textAlign: 'center'}}>
                      <Typography variant='subtitle2'>Selected oven temperature: {item.oven_temp}</Typography>
                      <Typography variant='subtitle2'>Selected oven time: {item.oven_time}</Typography>
                    </Grid>
                    :
                    <></>}
                </React.Fragment>
              ))}

            </Grid>

            <Grid item xs={12}>
              <FindCompatibleFilterForm
                values={filterValues}
                machineName={machine ? machine.name : ''}
                tempDiff={20}
                timeDiff={5}
                onFilter={doFilter}
              />
            </Grid>

            {itemList.length > 0 && !!machine ?
              <Grid item xs={12}>
                <ApiTable
                  legend={[
                    {color: colors[theme.palette.mode].table.row['loaded'], text: 'Loaded', tooltipText: 'Item is loaded to an Arm on the Machine.'},
                    {color: colors[theme.palette.mode].table.row['in_loadqueue'], text: 'Arm Scheduled', tooltipText: 'Item is scheduled to an Arm on the Machine.'},
                    {color: colors[theme.palette.mode].table.row['scheduled'], text: 'Machine Scheduled', tooltipText: 'Item is scheduled to this Machine.'},
                  ]}
                  objectName=''
                  headers={['Order', 'Item', 'Arm/Machine', 'Description', 'Ship Date', 'Balance', 'Volume', 'Mold Takt', 'Oven Temperature', 'Oven Time', '']}
                  rowFields={['order', 'item', 'arm_machine', 'description', 'ship_date', 'balance', 'volume', 'mold_takt', 'oven_temp', 'oven_time']}
                  rowActions={[
                    {icon: <ScheduleSend />, action: (id: number) => openScheduleToMachine(id), text: 'Schedule to Machine'},
                  ]}
                  dataField='objects'
                  url={'/scheduling/find-compatible-orders/'}
                  refreshable
                  orderable
                  orderableBlacklist={['Arm/Machine', 'Volume']}
                  extraParams={filterValues}
                  refresh={refreshTable}
                  suppressLoadDisplay={silentLoad}
                />
              </Grid>
              :
              <></>
            }

          </Grid>
        </BaseContent>
      </BaseModal>
      <ScheduleToMachine orderItemId={scheduleToMachineId} closeHandler={closeScheduleToMachine} doSchedule={doScheduleToMachine}
                         addMachineItemType={scheduleToMachineType}
                         machineList={machineList.map((machine: any) => {return {id: machine.id, title: machine.name}})} />
    </>
  )
}

export default FindCompatibleOrders