import React, { FC, useEffect, useState } from 'react';
// mui imports
import Grid from "@mui/material/Grid";
import { AxiosResponse } from "axios";
// custom imports
import ApiForm from "../../../../utils/form/ApiForm";
import FormInputText from '../../../../utils/form/FormInputText';
import FormInputDropdown from "../../../../utils/form/FormInputDropdown";
import FormInputNumber from "../../../../utils/form/FormInputNumber";
import { api, FormSpacing } from "../../../../utils/globals";

interface CycleFormProps {
  id: number | undefined;
  open: boolean;
  closeForm: (submit: boolean, data: any, create: boolean) => void;
  machines: any[];
  factoryTemp: number;
  machine: any;
}
const CycleForm: FC<CycleFormProps> = ({id, open, closeForm, machines, factoryTemp,
                                         machine}) => {

  const [name, setName] = useState<string>('');

  const fetchUrl: string = id ? `/inventory/cycles/${id}/` : '';
  const submitUrl: string = '/inventory/cycles/';

  // get arm name
  useEffect(() => {
    let isActive = true;
    api.get(`${submitUrl}?getName=${machine}`)
      .then((resp: AxiosResponse) => {
        setName(resp.data.name);
      })
    return () => {isActive = false}
  }, [machine, open])

  return (
    <ApiForm
      name='Cycle Form'
      open={open}
      defaultValues={{machine_id: machine, name: name, hi_time: '', lo_time: '', temp: ''}}
      closeForm={closeForm}
      id={id}
      fetchUrl={fetchUrl}
      submitUrl={submitUrl}
      timeFields={[]}
    >
      <Grid container spacing={FormSpacing}>
        <Grid item xs={6}><FormInputDropdown name='machine_id' label='Machine' options={machines} multiple={false} /></Grid>
        <Grid item xs={6}><FormInputText name='name' label='Name' /></Grid>
        <Grid item xs={4}><FormInputNumber name='hi_time' label={`Minutes (Factory < ${factoryTemp}°F)`} /></Grid>
        <Grid item xs={4}><FormInputNumber name='lo_time' label={`Minutes (Factory > ${factoryTemp}°F)`} /></Grid>
        <Grid item xs={4}><FormInputNumber name='temp' label='Temperature °F' /></Grid>
      </Grid>
    </ApiForm>
  );
}

export default CycleForm;
