import React, { FC, useState } from 'react'
import { jsPDF as JSpdf } from 'jspdf'
import { AxiosResponse } from 'axios'
// mui imports
import { useTheme } from '@mui/material/styles'
// custom imports
import { useEffectApi } from '../../../../utils/globals'
import { apiGet } from '../../../../utils/api'
import SetupsheetView from './SetupsheetView'


interface SetupsheetViewHelperProps {
  open: boolean
  doClose: () => void
  itemName: string | undefined
  machineName?: string | undefined
  machineSelectable?: boolean | undefined
  newTab?: boolean | undefined
}
const SetupsheetViewHelper: FC<SetupsheetViewHelperProps> = ({open, doClose, itemName,
                                                               machineName, machineSelectable,
                                                               newTab}) => {
  const theme: any = useTheme()

  const [id, setId] = useState<number | undefined>(undefined)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [printLoading, setPrintLoading] = useState<boolean>(false)
  const [bomItems, setBomItems] = useState<any[]>([])
  const [machineDualTimes, setMachineDualTimes] = useState<boolean>(false)
  const [firstArticle, setFirstArticle] = useState<boolean>(false)
  const [timeDiffTemp, setTimeDiffTemp] = useState<number>(0)
  const [pdf, setPdf] = useState<boolean>(false)

  const [machineList, setMachineList] = useState<any[]>([])
  const [selectedMachineName, setSelectedMachineName] = useState<string | undefined>(undefined)

  useEffectApi(() => {
    // console.log('new tab :', newTab, id, open)
    if (newTab && (id || id === undefined) && open) {
      window.open(`/setupsheet/${itemName}/${machineName}/`, '_blank')
    } else {
      if (machineSelectable === undefined)
        machineSelectable = false

      // console.log('setupsheet view api resp :', itemName, machineName)
      if ((id || id === undefined) && open) {

        apiGet('/manufacturing/setupsheets/', {getItem: itemName, machineSelectable: machineSelectable},
          (resp: AxiosResponse) => {

            let selectedMachine: string | undefined = machineName ? machineName : selectedMachineName
            if (resp.data.machines && resp.data.machines.length > 0 && selectedMachine === undefined)
              selectedMachine = resp.data.machines[0].name

            setBomItems(resp.data.item.bom_items)
            setId(selectedMachine ? resp.data.item[selectedMachine] : undefined)
            setFirstArticle(resp.data.item.first_article)
            setMachineDualTimes(resp.data.item[`${selectedMachine}_dual_times`])
            setTimeDiffTemp(resp.data.item.time_diff_temp)
            setPdf(resp.data.item[`${selectedMachine}_has_machine_pdf`])
            setMachineList(resp.data.machines)
            setSelectedMachineName(selectedMachine)

        })
      }
    }

  }, [open, selectedMachineName, newTab, itemName])

  const handleClose = () => {
    setSelectedMachineName(undefined)
    doClose()
  }

  const printHandler = (cb: () => void) => {
    const content: HTMLElement | null = document.getElementById('setupsheet-info')

    if (theme.palette.mode === 'dark' && content) {
      const headers = Array.from(content.getElementsByClassName('MuiTypography-h5') as HTMLCollectionOf<HTMLElement>)
      const texts = Array.from(content.getElementsByClassName('MuiTypography-body1') as HTMLCollectionOf<HTMLElement>)
      for (const header of headers)
        header.style.color = 'black'
      for (const text of texts)
        text.style.color = 'black'
    }

    if (content) {
      setPrintLoading(true)
      const prevWidth: string = content.style.width
      content.style.marginTop = '100px'
      content.style.marginLeft = '75px'
      content.style.width = '90%'
      const doc = new JSpdf('p', 'pt', 'letter')

      const dateTime = new Date().toLocaleString()
      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()
      doc.setFontSize(8)
      doc.text(dateTime, width / 2, height - 5, {align: 'center'})

      doc.html(content, {
        callback: (doc) => {
          window.open(doc.output('bloburl'), '_blank')
          if (content) {
            content.style.marginTop = '0px'
            content.style.marginLeft = '0px'
            content.style.width = prevWidth
            if (theme.palette.mode === 'dark') {
              const headers = Array.from(content.getElementsByClassName('MuiTypography-h5') as HTMLCollectionOf<HTMLElement>)
              const texts = Array.from(content.getElementsByClassName('MuiTypography-body1') as HTMLCollectionOf<HTMLElement>)
              for (const header of headers)
                header.style.color = 'white'
              for (const text of texts)
                text.style.color = 'white'
            }
          }
          setPrintLoading(false)
          cb()
        },
        html2canvas: { scale: 0.6 }
      })
    }
  }

  // actions
  const selectMachine = (machineName: string) => {
    for (const machine of machineList)
      if (machine.name === machineName) {
        setSelectedMachineName(machineName)
        setRefresh(!refresh)
      }
  }

  return id ?
    <SetupsheetView open={open} close={handleClose} url='/manufacturing/setupsheets/' id={id}
                    bomItems={bomItems} dualTimes={machineDualTimes} timeDiffTemp={timeDiffTemp}
                    doPrint={printHandler} printLoading={printLoading} firstArticle={firstArticle}
                    hasPdf={pdf} machineList={machineList} selectedMachine={selectedMachineName}
                    doSelectMachine={selectMachine} refresh={refresh} />
    : <></>
}

export default SetupsheetViewHelper
