import React, {FC, useEffect} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// mui imports
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import BaseTooltip from '../globalComps/BaseTooltip'

interface FormInputSwitchProps {
  name: string
  label: string
  helpText?: string
  disabled?: boolean
  onChange?: (e: any) => void
}

const FormInputSwitch: FC<FormInputSwitchProps> = ({name, label, helpText, disabled, onChange}) => {
  const { control } = useFormContext()

  useEffect(() => {}, [name, disabled])

  const handleChange = (e: any) => {
    if (onChange) onChange(e)
    console.log('handle change :', e.target.checked)
    return e
  }

  return (
    <Controller
      name={name as never}
      control={control}
      render={({
        field: {onChange, value},
      }: any) => (
        <Grid container spacing={0} alignItems='center' justifyContent='center'>
          <BaseTooltip text={helpText}>
            <Grid>
              <FormControlLabel
                value={value}
                checked={!!value}
                label={label}
                disabled={!!disabled}
                onChange={onChange}
                control={<Switch onChange={(e: any) => onChange(handleChange(e))} checked={!!value} value={value} />}
              />
            </Grid>
          </BaseTooltip>
        </Grid>
    )}/>
  )
}

export default FormInputSwitch
