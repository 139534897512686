import React, { FC } from 'react';
import create from 'zustand';
// mui imports
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
// custom imports
import './PopupAlert.css';

interface popupAlertStoreProps {
  text?: string;
  type?: AlertColor;
  open?: boolean;
  close: () => void;
}
const usePopupAlertStore = create((set: any) => ({
  text: undefined,
  type: undefined,
  open: false,
  close: () => set({ open: false })
} as popupAlertStoreProps));
/**
 * Global popup alert component
 * @constructor
 */
const PopupAlert: FC = () => {

  const { text, type, open, close } = usePopupAlertStore();

  return (
    <div className='popup'>
      <Snackbar open={open} autoHideDuration={10000} onClose={close}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={close} severity={type} sx={{width: '100%'}} variant='filled'>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PopupAlert;
// export { alert };
export const doAlert = (text: string | undefined, type: AlertColor | undefined, open: boolean | undefined) => {
  usePopupAlertStore.setState({
    text,
    type,
    open
  });
}
