import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";

export interface ScrapSummary {
	machines: string[];
	lineplans: string[];
	parts_produced_mtd: Record<string, any>;
	parts_produced_tdy: Record<string, any>;
}

const useScrapSummary = (timePeriod: 'shift' | 'month') => {
	const [scrapSummary, setScrapSummary] = useState<ScrapSummary>({
		machines: [],
		lineplans: [],
		parts_produced_mtd: {},
		parts_produced_tdy: {}
	});

	const { } = useWebsocket('/processing/scrap_summary/', setScrapSummary);

	const partsProduced = useMemo(() => {
		return timePeriod === 'month' ? scrapSummary.parts_produced_mtd : scrapSummary.parts_produced_tdy
	}, [scrapSummary])

	const pieChartData = useMemo(() => {
		const partsProduced = timePeriod === 'month' ? scrapSummary.parts_produced_mtd : scrapSummary.parts_produced_tdy;
		// non conforming values
		const nonConformingPercent = partsProduced?.non_conforming_percent?.machines
		const nonConformingParts = partsProduced?.non_conforming?.machines
		// conforming values
		const conformingParts = partsProduced?.conforming?.machines
		const conformingPercent = 100 - nonConformingPercent
		return {
			nonConformingPercent, nonConformingParts, 
			conformingPercent, conformingParts
		}
	}, [scrapSummary, timePeriod])

	const barChartData = useMemo(() => {
		const partsProduced = timePeriod === 'month' ? scrapSummary.parts_produced_mtd : scrapSummary.parts_produced_tdy;
		const machineNames = scrapSummary.machines;

		return machineNames.map(machine => ({
			name: machine,
			conforming: partsProduced.conforming?.[machine] || 0,
			nonConforming: partsProduced.non_conforming?.[machine] || 0,
		}));
	}, [scrapSummary, timePeriod]);

	return {
		pieChartData, barChartData
	};
};

export default useScrapSummary;
