import { Theme } from '@mui/material/styles';

export const getStyles = (theme: Theme) => ({
	stickyColumnHeaderStyle: {
		position: 'sticky',
		left: 0,
		backgroundColor: theme.palette.background.stickyHeader,
		zIndex: 4,
		pl: 4,
		fontWeight: 'bold',
		borderRight: `1px solid ${theme.palette.divider}`,
		textAlign: 'center',
	},

	stickyColumnStyle: {
		position: 'sticky',
		left: 0,
		backgroundColor: 'inherit',
		zIndex: 2,
		width: {
			xs: '100px',
			sm: '150px',
			md: '200px',
		},
		textAlign: 'center',
		fontWeight: 'bold',
		borderRight: `1px solid ${theme.palette.divider}`,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	stickyHeaderStyle: {
		position: 'sticky',
		top: 0,
		backgroundColor: theme.palette.background.stickyHeader,
		zIndex: 3,
	},

	cellStyle: {
		width: {
			xs: '100px',
			sm: '150px',
			md: '200px',
		},
		textAlign: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	unitStyle: {
		width: {
			xs: '50px',
			sm: '50px',
			md: '50px',
		},
		textAlign: 'center',
	},

	machineChipStyle: {
		backgroundColor: '#96d2ea',
		color: theme.palette.getContrastText('#96d2ea'),
		margin: '2px',
	},

	armChipStyle: {
		backgroundColor: '#eadc96',
		color: theme.palette.getContrastText('#eadc96'),
		margin: '2px',
	},

	loadedChipStyle: {
		backgroundColor: '#90EE90',
		color: theme.palette.getContrastText('#90EE90'),
		margin: '2px',
	},

	lineplanChipStyle: {
		backgroundColor: '#EABFFF',
		color: theme.palette.getContrastText('#EABFFF'),
		margin: '2px',
	},

	nonConformingStyle: {
		color: theme.palette.mode == 'dark' ? theme.palette.error.light : theme.palette.error.main,
		fontWeight: 'bold',
	},
});

export type OrderTableStyles = ReturnType<typeof getStyles>;