import React, {FC, useEffect, useState} from 'react'
import BaseModal from "../../../../utils/modals/BaseModal";
import ApiTable from "../../../../utils/table/ApiTable";
import VentMaterialForm from "./VentMaterialForm";

interface VentMaterialModalProps {
  open: boolean
  onClose: () => void
}
/**
 * Vent material management modal
 * @constructor
 */
const VentMaterialModal: FC<VentMaterialModalProps> = ({open, onClose}) => {

  const [doLoad, setDoLoad] = useState<boolean>(false)

  useEffect(() => {
    setDoLoad(open)
  }, [open])

  return (
    <BaseModal
      title='Vent Materials'
      open={open}
      closeHandler={onClose}
      maxWidth='lg'
      closeButton
      dividers
    >
      <ApiTable
        tableName=''
        objectName='Vent Material'
        headers={['Name', '']}
        rowFields={['name']}
        dataField='objects'
        url='/inventory/vent-materials/'
        editable
        deletable
        creatable
        hidePages
        FormComponent={VentMaterialForm}
      />
    </BaseModal>
  )
}

export default VentMaterialModal
